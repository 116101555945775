import React, { FC } from 'react'
import { CSSProperties } from '@xstyled/styled-components'
import Box from '~/components/Box/Box'
import IconEmptySearchResult from '~/assets/images/empty-search-result.svg'
import { useTranslation } from 'next-i18next'
import Typography from '~/components/Typography/Typography'

interface NoSearchProps {
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  subtitle?: string
}
const NoSearch: FC<NoSearchProps> = ({ width = '100%', height = '100%', subtitle }) => {
  const { t } = useTranslation('common')

  return (
    <Box w={width} height={height} display="flex" justifyContent="center" alignItems="center">
      <Box display="flex" flexDirection="column" gap="20px" alignItems="center">
        <IconEmptySearchResult color="var(--color-gray-400)" width="56px" height="56px" />
        <Box display="flex" flexDirection="column" alignItems="center" gap="4px">
          <Typography variant="subtitle" fontWeight="600" color="gray-700">
            {t('common_msg.no_search_result')}
          </Typography>
          <Typography variant="caption1" fontWeight="400" color="gray-600">
            {subtitle ?? t('common_msg.do_search')}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default NoSearch
