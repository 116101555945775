import { FC, useState } from 'react'
import Typography from '~/components/Typography/Typography'
import LoadMoreDataGrid from '~/templates/place/detail/LoadMoreDataGrid'
import Box from '~/components/Box/Box'
import { useTranslation } from 'next-i18next'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { formatISODateYmd } from '~/libs/utils/date'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import MessageComponent from '~/templates/place/detail/registration/MessageComponent'
import { useGetChangeEvents } from '~/libs/apis/registration/api'
import ChangeInfoGuidePopover from '~/templates/common/popover/ChangeInfoGuidePopover'
import { useDetailInfo } from '~/templates/DetailTypeProvider'

interface ChangeResultGridProps {
  pnu: string
}

const PAGE_SIZE = '5'
const ChangeResultGrid: FC<ChangeResultGridProps> = ({ pnu }) => {
  const { t } = useTranslation('common', { keyPrefix: 'registration' })
  const { t: common } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: source } = useTranslation('common', { keyPrefix: 'source' })
  const [page, setPage] = useState('1')
  const { data: changeResult } = useGetChangeEvents({ pnu, page, size: PAGE_SIZE })
  const hasChangeResultData = !changeResult || changeResult?.data?.length !== 0
  const { detailType } = useDetailInfo()
  const isFull = detailType === 'full'

  const columnModels: ColumnModel[] = [
    {
      field: 'registrationReceiptDate',
      textAlign: 'Left',
      headerText: common('receipt_date'),
      width: isFull ? undefined : 110,
      valueAccessor: (field: string, data: any) => formatISODateYmd(data[field]),
    },
    {
      field: 'registrationObjective',
      textAlign: 'Left',
      headerText: t('registration_objective'),
      clipMode: 'EllipsisWithTooltip',
      valueAccessor: useNilableValueAccessor,
    },
    {
      field: 'registrationStatus',
      textAlign: 'Left',
      headerText: t('status'),
      clipMode: 'EllipsisWithTooltip',
      valueAccessor: useNilableValueAccessor,
    },
  ]
  const handlePageChange = (page: number) => setPage(page.toString())

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box display="flex" flexDirection="column" gap={2.5}>
        <Box display="flex" gap={2.5}>
          <Box maxWidth="90px">
            <Typography variant="subtitle" color="gray-800" fontWeight="semibold">
              {t('change_result')}
            </Typography>
          </Box>
          <ChangeInfoGuidePopover />
        </Box>
        <Typography variant="caption1" color="gray-700">
          {t('change_result_message')}
        </Typography>
      </Box>
      {hasChangeResultData ? (
        <LoadMoreDataGrid
          columns={columnModels}
          dataSource={changeResult?.data}
          pageModel={changeResult?.page}
          onPageChange={handlePageChange}
          allowSelection={false}
        />
      ) : (
        <MessageComponent message={t('no_change_result_information')} />
      )}
      <Box textAlign="right">
        <Typography variant="caption2" color="gray-600">
          [{source('origin')}: {source('korean_court_registration_application_case')},{' '}
          {formatISODateYmd(changeResult?.registrationSourceDate)} {source('update')}]
        </Typography>
      </Box>
    </Box>
  )
}

export default ChangeResultGrid
