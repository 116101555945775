import { DefaultValue, selector } from 'recoil'
import { LEASE_PRODUCT_FILTER_KEYS_FROM_DIALOG, LeaseProductFilterParams } from '~/libs/constants/topic'
import leaseProductFilterStateFamily from '~/recoil/topic-lease-product-filter/atom'

const leaseProductFilterDialogSelector = selector({
  key: 'leaseProductFilterDialogSelector',
  get: ({ get }) =>
    LEASE_PRODUCT_FILTER_KEYS_FROM_DIALOG.reduce<LeaseProductFilterParams>(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: get(leaseProductFilterStateFamily(currentValue)),
      }),
      {},
    ),
  set: ({ set }, newValue) => {
    LEASE_PRODUCT_FILTER_KEYS_FROM_DIALOG.forEach((key) =>
      set(leaseProductFilterStateFamily(key), newValue instanceof DefaultValue ? newValue : undefined),
    )
  },
})

export default leaseProductFilterDialogSelector
