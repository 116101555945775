import { FC, useEffect, useState } from 'react'
import Box from '~/components/Box/Box'
import DowntimeNotice from '~/templates/DowntimeNotice'
import useAuth from '~/libs/hooks/useAuth'
import { usePrevious } from '@toss/react'
import useDowntimeNotices from '~/libs/hooks/useDowntimeNotices'

const DowntimeOverlay: FC = () => {
  const { logout } = useAuth()
  const { data } = useDowntimeNotices()
  const notice = data?.at(0)
  const previousNotice = usePrevious(notice)
  const [isShowOverlay, setIsShowOverlay] = useState(false)

  useEffect(() => {
    if (previousNotice?.isDowntime === true && !notice?.isDowntime) {
      logout()
      return
    }
    if (notice?.isDowntime) {
      setIsShowOverlay(true)
    }
  }, [notice, previousNotice, logout])

  return (
    isShowOverlay && (
      <Box
        position="fixed"
        backgroundColor="purple-600"
        zIndex={10000000}
        left={0}
        right={0}
        top={0}
        bottom={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width="560px" height="580px">
          <DowntimeNotice data={notice} />
        </Box>
      </Box>
    )
  )
}

export default DowntimeOverlay
