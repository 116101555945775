import { Dispatch, FC, SetStateAction, useMemo, useRef } from 'react'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { useTranslation } from 'next-i18next'
import { PagerModel } from '@syncfusion/ej2-grids/src/pager/pager-model'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import { Page } from '~/libs/apis/data/model'
import { formatISODateYmd } from '~/libs/utils/date'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import Tooltip from '~/components/Tooltip/Tooltip'
import InfoIcon from '~/assets/images/icons/info.svg'
import { TooltipComponent } from '@syncfusion/ej2-react-popups/src/tooltip'
import { isAfter, subMonths } from 'date-fns'
import NewIcon from '~/assets/images/icons/new.svg'
import RegistrationGrid from '~/templates/common/grid/RegistrationGrid'
import { useR3CommonCodeValueAccessor } from '~/libs/hooks/useR3CommonCode'

interface RegistrationOwnershipGridProps {
  dataSource: object[] | undefined
  activeIndex: number | undefined
  setActiveIndex: Dispatch<SetStateAction<number | undefined>>
  pagerModel?: PagerModel
  page?: Page
  onPageChange: (object: { currentPage: number }) => void
}

const RegistrationOwnershipGrid: FC<RegistrationOwnershipGridProps> = ({
  dataSource,
  activeIndex,
  setActiveIndex,
  pagerModel,
  page,
  onPageChange,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'registration' })
  const { t: common } = useTranslation('common', { keyPrefix: 'common_term' })
  const registrationOwnerNameRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<TooltipComponent>(null)
  const r3CommonCodeValueAccessor = useR3CommonCodeValueAccessor('PROPERTY')

  const isWithinLastMonth = (dateToCheck: Date): boolean => {
    const today = new Date()
    const oneMonthAgo = subMonths(today, 1)
    return isAfter(dateToCheck, oneMonthAgo)
  }

  const columns: ColumnModel[] = useMemo(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () => [
      {
        field: 'registrationCompletedAt',
        headerText: common('registration_record_access_date'),
        textAlign: 'Left',
        width: 120,
        clipMode: 'EllipsisWithTooltip',
        template: (props: any) => (
          <Box display="flex" alignItems="center" gap="9px">
            <Typography>{formatISODateYmd(props.registrationCompletedAt)}</Typography>
            {isWithinLastMonth(props.registrationCompletedAt) && (
              <NewIcon width="12px" height="12px" color="var(--color-purple-600)" />
            )}
          </Box>
        ),
      },
      {
        field: 'registrationPropertyTypeCode',
        headerText: common('division'),
        textAlign: 'Left',
        width: 60,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: r3CommonCodeValueAccessor,
      },
      {
        field: 'address',
        headerText: common('registration_address'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'registrationRealEstateNumber',
        headerText: common('real_estate_number'),
        textAlign: 'Left',
        width: 160,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'registrationOwnerName',
        headerTemplate: () => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onMouseOver={() => tooltipRef?.current?.open(registrationOwnerNameRef.current || undefined)}
            onMouseOut={() => tooltipRef?.current?.close()}
          >
            <Typography fontWeight="semibold">{t('ownership_name')}</Typography>
            <Tooltip ref={tooltipRef} content={t('ownership_name_message')} width="225px" align="right">
              <Box ref={registrationOwnerNameRef}>
                <InfoIcon width="12px" height="12px" color="var(--color-gray-800)" />
              </Box>
            </Tooltip>
          </Box>
        ),
        textAlign: 'Left',
        width: 150,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'registrationOwnerRole',
        headerText: t('ownership_division'),
        width: 90,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'registrationShareFraction',
        headerText: t('ownership_share'),
        width: 90,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  return (
    <RegistrationGrid
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      dataSource={dataSource}
      pagerModel={pagerModel}
      columns={columns}
      page={page}
      onPageChange={onPageChange}
    />
  )
}

export default RegistrationOwnershipGrid
