import { FC } from 'react'
import Button from '~/components/Button/Button'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { useTranslation } from 'react-i18next'
import Box from '~/components/Box/Box'
import GraphicPublicDataIcon from '~/assets/images/graphic-public-data.svg'

const LandInformationButton: FC<{ pnu: string }> = ({ pnu }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { showLand } = useFullDetailDialog()

  const handleButtonClick = () => {
    showLand(pnu)
  }

  return (
    <Box>
      <Button
        icon={<GraphicPublicDataIcon />}
        color="guide"
        content={t('land_information')}
        onClick={handleButtonClick}
      />
    </Box>
  )
}

export default LandInformationButton
