import { FC, MouseEvent, PropsWithChildren, useState } from 'react'
import Button from '~/components/Button/Button'
import InfomationIcon from '~/assets/images/icons/information.svg'
import Box from '~/components/Box/Box'
import GuideOverlay from '~/templates/common/popover/GuideOverlay'
import { PopperPosition } from '~/components/Dropdown/Popper'
import { Property } from 'csstype'
import { ImpressionArea } from '@toss/impression-area'

type HorizontalPosition = 'left' | 'right'

interface GuidePopoverTemplateProps {
  buttonTitle: string
  popperWidth: Property.Width
  popperOffset?: number
  popperPosition?: HorizontalPosition
  iconPosition?: HorizontalPosition
}

const getBottomPosition = (anchorElRect: DOMRect, position: HorizontalPosition, offset: number): PopperPosition => {
  const offsetPosition = position === 'left' ? { left: offset } : { right: offset }
  return {
    top: anchorElRect.height,
    ...offsetPosition,
  }
}

const GuidePopover: FC<PropsWithChildren<GuidePopoverTemplateProps>> = ({
  buttonTitle,
  popperWidth,
  children,
  popperOffset = 0,
  popperPosition = 'right',
  iconPosition = 'right',
}) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleGuideButtonClick = (e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)
  const handleOverlayClose = () => {
    setAnchorEl(null)
  }
  const getPosition = (anchorElRect: DOMRect) => getBottomPosition(anchorElRect, popperPosition, popperOffset)

  return (
    <Box position="relative" ref={(ref) => setContainer(ref)}>
      <Button
        color="guide"
        size="sm"
        content={buttonTitle}
        icon={<InfomationIcon width="12px" height="12px" color="var(--color-blue-700)" />}
        iconPosition={iconPosition}
        onClick={handleGuideButtonClick}
      />
      <ImpressionArea onImpressionEnd={handleOverlayClose}>
        <GuideOverlay
          popoverProps={{
            open: !!anchorEl,
            onClose: handleOverlayClose,
            anchorEl: anchorEl,
            popperWidth: popperWidth,
            getPosition: getPosition,
            portalContainer: container,
            zIndex: 10,
          }}
        >
          <Box>{children}</Box>
        </GuideOverlay>
      </ImpressionArea>
    </Box>
  )
}

export default GuidePopover
