import { patchUsersConfiguration, useGetUserConfiguration } from '~/libs/apis/service/api'
import { PatchUsersConfigurationRequest } from '~/libs/apis/service/model'
import { useCallback } from 'react'

const Units = ['PY', 'M2'] as const
const CurrencyUnits = ['KRW', 'USD'] as const
const Languages = ['KOREAN', 'ENGLISH'] as const

const isAreaUnit = (value: NullishString): value is AreaUnit => {
  return Units.includes(value as AreaUnit)
}

const isCurrencyUnit = (value: NullishString): value is CurrencyUnit => {
  return CurrencyUnits.includes(value as CurrencyUnit)
}

const isLanguage = (value: NullishString): value is Language => {
  return Languages.includes(value as Language)
}

const useUserConfiguration = () => {
  const { data: userConfiguration, mutate } = useGetUserConfiguration({
    swr: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  })
  const getAreaUnit = useCallback((): AreaUnit => {
    if (isAreaUnit(userConfiguration?.areaType)) {
      return userConfiguration!.areaType
    } else {
      return 'PY'
    }
  }, [userConfiguration])

  const getCurrencyUnit = useCallback((): CurrencyUnit => {
    if (isCurrencyUnit(userConfiguration?.currency)) {
      return userConfiguration!.currency
    } else {
      return 'KRW'
    }
  }, [userConfiguration])

  const getLanguage = useCallback((): Language => {
    if (isLanguage(userConfiguration?.language)) {
      return userConfiguration!.language
    } else {
      return 'KOREAN'
    }
  }, [userConfiguration])

  const setUserConfiguration = async (configuration: PatchUsersConfigurationRequest) => {
    return patchUsersConfiguration(configuration).then(() => mutate(configuration))
  }

  return {
    areaUnit: getAreaUnit(),
    currencyUnit: getCurrencyUnit(),
    language: getLanguage(),
    setUserConfiguration,
  }
}

export default useUserConfiguration
