import ChartWrapper from '~/components/Chart/ChartWrapper'
import {
  ChartProps,
  commonChartComponentProps,
  commonLegendSettings,
  commonTooltipSettings,
  zoomSettings,
} from '~/libs/constants/chart'
import {
  AreaSeries,
  AxesDirective,
  AxisDirective,
  BarSeries,
  Category,
  ChartComponent,
  ColumnSeries,
  Crosshair,
  DataLabel,
  DateTime,
  DateTimeCategory,
  Export,
  Inject,
  Legend,
  LineSeries,
  RangeAreaSeries,
  ScatterSeries,
  ScrollBar,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
  Zoom,
} from '@syncfusion/ej2-react-charts'
import React, { forwardRef } from 'react'
import { CrosshairSettingsModel } from '@syncfusion/ej2-charts/src/chart/chart-model'
import { AxisModel } from '@syncfusion/ej2-charts/src/chart/axis/axis-model'
import useCommonChartPalette from '~/libs/hooks/useCommonChartPalette'

interface CustomizableChartProps extends ChartProps {
  additionalAxis?: AxisModel
  crosshairSettings?: CrosshairSettingsModel
}

const CustomizableChart = forwardRef<ChartComponent, CustomizableChartProps>(
  (
    {
      width,
      height,
      chartId,
      isCursorPointer,
      beforeExport,
      afterExport,
      isLoading,
      series,
      primaryXAxis,
      primaryYAxis,
      additionalAxis,
      crosshairSettings,
      tooltipSettings,
      axisLabelRender,
      legendSettings,
      noData,
    },
    ref,
  ) => {
    const commonChartPalette = useCommonChartPalette()

    const refCallback = (chartComponent: ChartComponent) => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(chartComponent)
        } else {
          ref.current = chartComponent
        }
      }
    }

    return (
      <ChartWrapper
        chartId={chartId}
        isLoading={isLoading}
        isCursorPointer={isCursorPointer}
        width={width}
        height={height}
        noData={noData}
      >
        <ChartComponent
          {...commonChartComponentProps}
          id={chartId}
          ref={refCallback}
          palettes={commonChartPalette}
          primaryXAxis={{ ...primaryXAxis, edgeLabelPlacement: 'Shift' }}
          primaryYAxis={primaryYAxis}
          zoomSettings={zoomSettings}
          legendSettings={{ ...commonLegendSettings, ...legendSettings }}
          tooltip={{ ...commonTooltipSettings, ...tooltipSettings }}
          crosshair={crosshairSettings}
          beforeExport={beforeExport}
          afterExport={afterExport}
          width={width?.toString()}
          height={height?.toString()}
          allowExport
          axisLabelRender={axisLabelRender}
        >
          {additionalAxis && (
            <AxesDirective>
              <AxisDirective {...additionalAxis} />
            </AxesDirective>
          )}
          <Inject
            services={[
              DateTime,
              RangeAreaSeries,
              AreaSeries,
              LineSeries,
              BarSeries,
              ColumnSeries,
              Legend,
              Category,
              ScatterSeries,
              Tooltip,
              DataLabel,
              Crosshair,
              Zoom,
              Export,
              DateTimeCategory,
              ScrollBar,
            ]}
          />
          {series && (
            <SeriesCollectionDirective>
              {series.map((seriesDirective, index) => (
                <SeriesDirective key={index} {...seriesDirective} />
              ))}
            </SeriesCollectionDirective>
          )}
        </ChartComponent>
      </ChartWrapper>
    )
  },
)

CustomizableChart.displayName = 'CustomizableChart'

export default CustomizableChart
