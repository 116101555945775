import { useGetBuildingsDetailsDataV1BuildingsDetailsGet } from '~/libs/apis/data/buildings/buildings'
import useBuildingsQuery from '~/templates/place/hooks/useBuildingsQuery'
import { useRecoilValue } from 'recoil'
import mapContentTypeState from '~/recoil/map-content-type/atom'
import buildingOrderByState from '~/recoil/building-order-by/atom'
import placeDataEnabledState from '~/recoil/place-data-enabled/atom'

const TABLE_VIEW_PAGE_SIZE = 500

const useBuildingTableViewData = (revalidateOnMount: boolean = false) => {
  const mapContentType = useRecoilValue(mapContentTypeState('place'))
  const orderByGroup = useRecoilValue(buildingOrderByState)
  const placeDataEnabled = useRecoilValue(placeDataEnabledState)
  const params = { ...useBuildingsQuery(), pageSize: TABLE_VIEW_PAGE_SIZE, orderByGroup }

  return useGetBuildingsDetailsDataV1BuildingsDetailsGet(params, {
    swr: {
      enabled: mapContentType === 'table' && placeDataEnabled,
      keepPreviousData: true,
      revalidateOnMount: revalidateOnMount,
    },
  })
}

export default useBuildingTableViewData
