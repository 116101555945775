import { FC, useEffect, useRef } from 'react'
import { isNotNil } from '@toss/utils'
import Accordion, { AccordionData } from '~/components/Accordion/Accordion'
import { useTranslation } from 'next-i18next'
import Ledger from '~/templates/place/detail/common-data/Ledger'
import Permission from '~/templates/place/detail/common-data/Permission'
import Demolish from '~/templates/place/detail/common-data/Demolish'
import { OfficeOutPnu } from '~/libs/apis/data/model'
import { AccordionComponent } from '@syncfusion/ej2-react-navigations'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { commonDatas } from '~/templates/place/detail/building/BuildingInfo'
import { DETAIL_STICKY_AREA_HEIGHT, DETAIL_STICKY_AREA_HEIGHT_WITH_GROUP } from '~/libs/constants/place'
import styled, { css } from '@xstyled/styled-components'

interface BuildingCommonDataProps {
  pnu: OfficeOutPnu
  isGroup?: boolean
  isCompletion?: boolean
}

const commonDataContent = ['ledger', 'permission', 'demolish']

const BuildingCommonData: FC<BuildingCommonDataProps> = ({ pnu, isGroup, isCompletion }) => {
  const ref = useRef<AccordionComponent>(null)
  const { t } = useTranslation('common', { keyPrefix: 'detail.common_data' })
  const { landing } = useDetailInfo()
  const { detailContent } = useDetailInfo()
  const isLand = detailContent === 'land'
  const hasPnu = isNotNil(pnu)
  const data: AccordionData[] = [
    {
      header: t(commonDatas[0]),
      content: hasPnu && <Ledger pnu={pnu} onClose={() => ref?.current?.expandItem(false, 0)} />,
      disabled: !isCompletion,
    },
    {
      header: t(commonDatas[1]),
      content: hasPnu && <Permission pnu={pnu} onClose={() => ref?.current?.expandItem(false, 1)} />,
    },
    {
      header: t(commonDatas[2]),
      content: hasPnu && <Demolish pnu={pnu} onClose={() => ref?.current?.expandItem(false, 2)} />,
    },
  ]

  useEffect(() => {
    if (isLand && ref.current) {
      ref.current.expandItem(true, 0)
    }
    if (ref.current && landing && commonDataContent.includes(landing)) {
      ref.current.element.scrollIntoView()
      ref.current.expandItem(
        true,
        commonDatas.findIndex((item) => item === landing),
      )
    }
  }, [isLand, landing])

  return (
    <Wrapper $isGroup={isGroup}>
      <Accordion ref={ref} data={data} />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $isGroup?: boolean }>`
  .e-accordion {
    ${({ $isGroup }) => css`
      scroll-margin-top: ${$isGroup ? DETAIL_STICKY_AREA_HEIGHT_WITH_GROUP : DETAIL_STICKY_AREA_HEIGHT}px;
    `}
  }
`

export default BuildingCommonData
