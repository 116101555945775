import { atom } from 'recoil'

export type DataType = 'building' | 'land'

/**
 * RA에서 표현되는 데이터의 형태가 알스퀘어 데이터인지 공공데이터인지를 판단하는 atom
 */
const dataTypeState = atom<DataType>({
  key: 'dataTypeState',
  default: 'building',
})

export default dataTypeState
