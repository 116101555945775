import { useTranslation } from 'next-i18next'

type UnitFormat = 'chart' | 'filter'
const useGetLabelFormat = (
  areaUnit: AreaUnit,
  currencyUnit: CurrencyUnit,
  type: UnitFormat = 'chart',
  krwUnit?: 'man' | 'eok',
) => {
  const { t: chart } = useTranslation('chart', { keyPrefix: 'label_format' })
  const { t: common } = useTranslation('common', { keyPrefix: 'common_term' })
  const krwUnitString = krwUnit ? `${krwUnit}_` : ''
  const currency = currencyUnit === 'KRW' ? `${krwUnitString}won` : 'dollar'
  const unitKey = `${currency}_${areaUnit.toLowerCase()}`
  return type === 'chart' ? chart(unitKey) : common(unitKey)
}

export default useGetLabelFormat
