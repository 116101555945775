/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.6.0
 */

export type NavigationRegionGubun = typeof NavigationRegionGubun[keyof typeof NavigationRegionGubun];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NavigationRegionGubun = {
  sido: 'sido',
  sigungu: 'sigungu',
  dong: 'dong',
} as const;
