import { FC } from 'react'
import Button from '~/components/Button/Button'
import { useTranslation } from 'next-i18next'
import Tooltip from '~/components/Tooltip/Tooltip'
import { useNextRouter } from '~/libs/hooks/useNextRouter'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { filterQuery } from '~/libs/utils/query'
import { OfficeOutBuildingSizeCode } from '~/libs/apis/data/model'

interface ChartToolButtonProps {
  buildingSizeId?: string
  raIds: string
  propertyType: string
  valueType: string
  period?: string
  neLat?: string
  neLng?: string
  swLat?: string
  swLng?: string
  boeCodes?: string
  buildingSizeCode?: OfficeOutBuildingSizeCode
}

const ChartToolButton: FC<ChartToolButtonProps> = ({
  buildingSizeId,
  raIds,
  propertyType,
  valueType,
  period,
  neLat,
  neLng,
  swLat,
  swLng,
  boeCodes,
  buildingSizeCode,
}) => {
  const { t } = useTranslation('common')
  const { hide } = useFullDetailDialog()
  const router = useNextRouter()

  const handleButtonClick = () => {
    hide?.()
    return router.push({
      pathname: '/chart',
      query: filterQuery({
        buildingSizeId,
        raIds,
        propertyType,
        valueType,
        period,
        neLat,
        neLng,
        swLat,
        swLng,
        boeCodes,
        buildingSizeCode,
      }),
    })
  }

  return (
    <Tooltip content={t('message.chart_tool_tooltip')}>
      <Button content={t('common_term.chart_tool')} color="gray" onClick={handleButtonClick} />
    </Tooltip>
  )
}

export default ChartToolButton
