import { css } from '@xstyled/styled-components'

const ContextMenuStyle = css`
  .e-contextmenu-wrapper {
    .e-menu-parent {
      padding: 8px;
      border-radius: 8px;
      border-color: var(--color-gray-300);
      .e-menu-item {
        padding: 8px;
        border-radius: 8px;
        font-family: var(--font-family);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        height: fit-content;
        &.e-focused {
          background-color: var(--color-gray-200);
        }
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }
`

export default ContextMenuStyle
