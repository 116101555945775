import { useRecoilValue } from 'recoil'
import { getSelectedEntriesFiltered } from '~/libs/utils/query'
import landFilterDialogSelector from '~/recoil/land-filter/landFilterDialogSelector'

/**
 * useLandsQuery 의 서브셋으로,
 * 공공 데이터 탐색 조회 전체 리퀘스트 파라미터 landFilterParamState 중 필터 다이얼로그에 표시하는 항목들만 리턴합니다.
 */
const useLandsFilterQuery = () => {
  const params = useRecoilValue(landFilterDialogSelector)
  return getSelectedEntriesFiltered(params)
}

export default useLandsFilterQuery
