import { FC, MouseEventHandler, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '~/components/Button/Button'
import Tooltip from '~/components/Tooltip/Tooltip'

type BuildingActionButtonColor = 'analysisPurple' | 'guide'
interface BuildingActionButtonProps {
  hasPermission: boolean
  isAvailable: boolean
  contentKey: string
  handleClick: MouseEventHandler
  icon: ReactNode
  color: BuildingActionButtonColor
}

const BuildingActionButton: FC<BuildingActionButtonProps> = ({
  hasPermission,
  isAvailable,
  contentKey,
  handleClick,
  icon,
  color,
}) => {
  const { t } = useTranslation('place', { keyPrefix: 'place_term' })
  const { t: permissionTerm } = useTranslation('common', { keyPrefix: 'no_permission' })
  const button = (
    <Button content={t(contentKey)} size="lg" color={color} onClick={handleClick} icon={icon} disabled={!isAvailable} />
  )

  return hasPermission ? (
    button
  ) : (
    <Tooltip content={permissionTerm('has_no_permission_tooltip')} position="BottomCenter" opensOn="Hover">
      {button}
    </Tooltip>
  )
}

export default BuildingActionButton
