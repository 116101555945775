import { useRecoilValue } from 'recoil'
import mapContentTypeState from '~/recoil/map-content-type/atom'
import useLandsQuery from '~/templates/place/hooks/useLandsQuery'
import landOrderByState from '~/recoil/land-order-by/atom'
import { useGetLandDetailsDataV1LandsDetailsGet } from '~/libs/apis/data/land/land'
import placeDataEnabledState from '~/recoil/place-data-enabled/atom'

const TABLE_VIEW_PAGE_SIZE = 500

const useLandTableViewData = (revalidateOnMount: boolean = false) => {
  const mapContentType = useRecoilValue(mapContentTypeState('place'))
  const orderByGroup = useRecoilValue(landOrderByState)
  const placeDataEnabled = useRecoilValue(placeDataEnabledState)
  const params = { ...useLandsQuery(), pageSize: TABLE_VIEW_PAGE_SIZE, orderByGroup: orderByGroup }

  return useGetLandDetailsDataV1LandsDetailsGet(params, {
    swr: {
      enabled: mapContentType === 'table' && placeDataEnabled,
      keepPreviousData: true,
      revalidateOnMount: revalidateOnMount,
    },
  })
}

export default useLandTableViewData
