import React, { FC } from 'react'
import Box from '~/components/Box/Box'
import NoDataImage from '~/assets/images/icons/system/system-face-x.svg'
import Typography from '~/components/Typography/Typography'
import { useTranslation } from 'next-i18next'
import Button from '~/components/Button/Button'

const ErrorTemplate: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'common_msg' })

  const handleClickBackButton = () => {
    location.href = '/'
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" backgroundColor="gray-100" height="100vh">
      <Box
        display="flex"
        flexDirection="column"
        gap={10}
        alignItems="center"
        justifyContent="center"
        width="310px"
        height="350px"
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap={10}>
          <NoDataImage width="58px" height="58px" color="#C7CCD7" />
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="h5" fontWeight="semibold" textAlign="center" color="gray-700">
              {t('page_not_found_title')}
            </Typography>
            <Typography variant="body" color="gray-600" textAlign="center">
              {t('page_not_found_description')}
            </Typography>
          </Box>
          <Button
            variant="filled"
            size="lg"
            color="darkGray"
            content={t('go_to_search_page')}
            onClick={handleClickBackButton}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ErrorTemplate
