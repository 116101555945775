import Image, { ImageProps as NextImageProps } from 'next/image'
import React, { FC, ReactElement, useState } from 'react'
import styled from '@xstyled/styled-components'

export interface ImageProps
  extends Omit<NextImageProps, 'src' | 'width' | 'height' | 'alt' | 'blurDataURL' | 'onError'> {
  url?: NullishString
  width: number
  height: number
  fallbackImage?: ReactElement
}

const blurryF2f2f2 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP89B8AAukB8/71MdcAAAAASUVORK5CYII='

const NextImage: FC<ImageProps> = ({ url, width, height, fallbackImage, ...props }) => {
  const [isValidImage, setIsValidImage] = useState<boolean>(true)
  const onImageError = () => {
    if (fallbackImage) {
      setIsValidImage(false)
    }
  }

  return url && isValidImage ? (
    <StyledImage
      src={url}
      width={width}
      height={height}
      placeholder="blur"
      blurDataURL={blurryF2f2f2}
      onError={onImageError}
      alt="image"
      {...props}
    />
  ) : (
    (fallbackImage ?? null)
  )
}

const StyledImage = styled(Image)`
  object-fit: cover;
`

export default NextImage
