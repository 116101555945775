import { FC } from 'react'
import Box from '~/components/Box/Box'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'

const BuildingSkeleton: FC = () => {
  return (
    <Box display="flex" flexDirection="column" backgroundColor="white">
      {/*info*/}
      <Box>
        <SkeletonComponent shape="Rectangle" width="400px" height="240px" />
        <Box padding="24px 0" display="flex" flexDirection="column" justifyContent="space-between" height="145px">
          <Box display="flex" flexDirection="column" gap="8px" alignItems="center">
            <SkeletonComponent shape="Text" width="225px" height="40px" />
            <SkeletonComponent shape="Text" width="250px" height="20px" />
            <SkeletonComponent shape="Text" width="150px" height="20px" />
          </Box>
        </Box>
        {/*tab*/}
        <Box display="flex" padding="5px 10px">
          <SkeletonComponent shape="Rectangle" height="60px" />
          <SkeletonComponent shape="Rectangle" height="60px" />
          <SkeletonComponent shape="Rectangle" height="60px" />
          <SkeletonComponent shape="Rectangle" height="60px" />
          <SkeletonComponent shape="Rectangle" height="60px" />
        </Box>
      </Box>
      {/*건물*/}
      <Box display="flex" flexDirection="column" padding="20px 20px" gap="24px">
        <Box display="flex" gap="20px" flexDirection="column">
          <SkeletonComponent shape="Rectangle" width="100%" height="480px" />
          <Box height="40px" display="flex" gap="8px">
            <SkeletonComponent shape="Rectangle" />
            <SkeletonComponent shape="Rectangle" />
            <SkeletonComponent shape="Rectangle" />
          </Box>
          <Box alignSelf="flex-end" width="80%">
            <SkeletonComponent shape="Text" width="100%" height="12px" />
          </Box>
          <SkeletonComponent shape="Rectangle" width="100%" height="20px" />
          <SkeletonComponent shape="Rectangle" width="108px" height="30px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="130px" />
          <Box alignSelf="flex-end" width="40%">
            <SkeletonComponent shape="Text" width="100%" height="12px" />
          </Box>
        </Box>
      </Box>
      <Box height="16px" />
      {/*임대*/}
      <Box display="flex" flexDirection="column" padding="32px" gap="24px">
        <SkeletonComponent shape="Text" width="35px" height="30px" />
        <SkeletonComponent shape="Rectangle" width="100%" height="174px" />
        <SkeletonComponent shape="Rectangle" width="100%" height="130px" />
        <SkeletonComponent shape="Rectangle" width="100%" height="954px" />
      </Box>
      <Box height="16px" />
      {/*임차인*/}
      <Box display="flex" flexDirection="column" padding="32px" gap="24px">
        <SkeletonComponent shape="Text" width="35px" height="30px" />
        <SkeletonComponent shape="Rectangle" width="100%" height="444px" />
        <SkeletonComponent shape="Rectangle" width="100%" height="32px" />
        <Box alignSelf="flex-end" width="40%">
          <SkeletonComponent shape="Text" width="100%" height="12px" />
        </Box>
      </Box>
      {/*거래*/}
      <Box display="flex" flexDirection="column" padding="32px" gap="24px">
        <SkeletonComponent shape="Text" width="35px" height="30px" />
        <SkeletonComponent shape="Rectangle" width="100%" height="310px" />
        <SkeletonComponent shape="Rectangle" width="100%" height="446px" />
        <Box alignSelf="flex-end" width="40%">
          <SkeletonComponent shape="Text" width="100%" height="12px" />
        </Box>
      </Box>
      <Box height="16px" />
      {/*공공데이터*/}
      <Box display="flex" flexDirection="column" padding="32px" gap="24px">
        <SkeletonComponent shape="Text" width="35px" height="30px" />
        <Box>
          <SkeletonComponent shape="Rectangle" width="100%" height="70px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="70px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="70px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="70px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="70px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="70px" />
        </Box>
      </Box>
      {/*footer*/}
      <SkeletonComponent shape="Rectangle" width="100%" height="132px" />
    </Box>
  )
}

export default BuildingSkeleton
