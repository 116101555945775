import { FC, PropsWithChildren } from 'react'
import Box from '../Box/Box'
import { styled } from '@xstyled/styled-components'

interface ErrorMessageProps {
  id?: string
}

const ErrorMessage: FC<PropsWithChildren<ErrorMessageProps>> = ({ id, children }) => {
  return <ErrorMessageBox id={id}>{children}</ErrorMessageBox>
}

const ErrorMessageBox = styled(Box)`
  height: 0;
  padding-left: 8px;
  transform: translateY(4px);
  text-align: left;
  color: var(--color-event-error);
  font-size: caption1;
  .e-error {
    color: var(--color-event-error);
    font-size: caption1;
  }
`

export default ErrorMessage
