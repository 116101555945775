import { FC } from 'react'
import Box from '~/components/Box/Box'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'

const LandSkeleton: FC = () => {
  return (
    <Box display="flex" flexDirection="column" backgroundColor="white">
      {/*info*/}
      <Box>
        <Box display="flex" justifyContent="space-between" padding="15px 20px 5px 20px">
          <SkeletonComponent shape="Rectangle" width="30px" height="30px" />
          <SkeletonComponent shape="Rectangle" width="30px" height="30px" />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" height="80px">
          <SkeletonComponent shape="Text" width="300px" height="40px" />
        </Box>
        <Box display="flex">
          <SkeletonComponent shape="Rectangle" height="60px" />
          <SkeletonComponent shape="Rectangle" height="60px" />
          <SkeletonComponent shape="Rectangle" height="60px" />
          <SkeletonComponent shape="Rectangle" height="60px" />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" padding="20px 20px" gap="24px">
        <Box display="flex" gap="20px" flexDirection="column">
          <SkeletonComponent shape="Rectangle" width="100%" height="180px" />
          <Box height="40px" display="flex" gap="8px">
            <SkeletonComponent shape="Rectangle" />
            <SkeletonComponent shape="Rectangle" />
            <SkeletonComponent shape="Rectangle" />
          </Box>
          <Box alignSelf="flex-end" width="80%">
            <SkeletonComponent shape="Text" width="100%" height="12px" />
          </Box>
          <SkeletonComponent shape="Rectangle" width="100%" height="20px" />
          <SkeletonComponent shape="Rectangle" width="108px" height="30px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="410px" />
          <Box height="40px" display="flex" gap="8px">
            <SkeletonComponent shape="Rectangle" />
            <SkeletonComponent shape="Rectangle" />
            <SkeletonComponent shape="Rectangle" />
          </Box>
          <Box alignSelf="flex-end" width="90%">
            <SkeletonComponent shape="Text" width="100%" height="12px" />
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" padding="15px 20px" gap="24px">
        <SkeletonComponent shape="Rectangle" width="120px" height="30px" />
        <Box>
          <SkeletonComponent shape="Rectangle" width="100%" height="70px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="70px" />
          <SkeletonComponent shape="Rectangle" width="100%" height="70px" />
        </Box>
      </Box>
      {/*footer*/}
      <SkeletonComponent shape="Rectangle" width="100%" height="132px" />
    </Box>
  )
}

export default LandSkeleton
