import { useRecoilValue } from 'recoil'
import mapContentTypeState from '~/recoil/map-content-type/atom'
import { BUILDINGS_PAGE_SIZE } from '~/libs/constants/place'
import { GetLandDataV1LandsGetParams, type LandPagingOut } from '~/libs/apis/data/model'
import useSWRInfinite, { SWRInfiniteFetcher } from 'swr/infinite'
import { getGetLandDataV1LandsGetKey, getLandDataV1LandsGet } from '~/libs/apis/data/land/land'
import useLandsQuery from '~/templates/place/hooks/useLandsQuery'
import landOrderByState from '~/recoil/land-order-by/atom'
import placeDataEnabledState from '~/recoil/place-data-enabled/atom'

const useLands = () => {
  const mapContentType = useRecoilValue(mapContentTypeState('place'))
  const orderByGroup = useRecoilValue(landOrderByState)
  const placeDataEnabled = useRecoilValue(placeDataEnabledState)
  const params = { ...useLandsQuery(), pageSize: BUILDINGS_PAGE_SIZE, orderByGroup } as GetLandDataV1LandsGetParams

  const getKey = (pageIndex: number, previousPageData: LandPagingOut) => {
    if ((previousPageData && !previousPageData.data.length) || mapContentType !== 'card' || !placeDataEnabled) {
      return null
    }
    if (pageIndex === 0) {
      return getGetLandDataV1LandsGetKey(params)
    } else {
      return getGetLandDataV1LandsGetKey({ ...params, pageNum: pageIndex + 1 })
    }
  }
  const fetcher: SWRInfiniteFetcher<LandPagingOut | undefined, typeof getKey> = (key) => {
    return getLandDataV1LandsGet(key[1])
  }

  return useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
  })
}

export default useLands
