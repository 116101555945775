// TODO: 추후 admin쪽 스펙이 확정되면 변경필요
// eslint-disable-next-line import/no-unused-modules
import { ColumnModel } from '@syncfusion/ej2-react-grids'

export const SOURCE_UPDATE_ID = {
  detail: {
    building: {
      info: [1, 8],
      ledger: [8],
      permission: [9],
      demolish: [10],
    },
    land: {
      info: [6, 15],
      owner: [14],
      price: [7],
    },
    lease: [2],
    lessee: {
      rsquare: [3],
      sbiz: [12],
      naver: [16],
      kakao: [17],
    },
    trade: {
      rsquare: [4, 5],
      gov: [11],
    },
    prd: {
      rsquare: [19],
    },
  },
  land_detail: {
    building: {
      info: [8],
      permission: [9],
    },
  },
  nearby: {
    outline: {
      building: [1, 8],
      land: [6, 15],
      official_price: [7],
      lessee: [3],
    },
    nearby: {
      lease: [2],
      trade: [4, 5],
      supply: [1],
    },
    market: {
      lease: [2],
      cap_rate: [4, 5],
      trade_trend: [4, 5],
      trade_example: [4, 5],
      new_supply_area: [1],
      new_supply_example: [1],
    },
    similar: {
      lease: [2],
      trade: [4, 5],
    },
  },
}

export type ViewType = 'chart' | 'data'
export type EstateType = 'office' | 'lwh' | 'land'

// 지하철 호선별 ID
export const SUBWAY_LINE_ID: Record<string, Record<string, string>> = {
  // 수도권
  1000: {
    1: 'line1', // 1호선
    2: 'line2', // 2호선
    3: 'line3', // 3호선
    4: 'line4', // 4호선
    5: 'line5', // 5호선
    6: 'line6', // 6호선
    7: 'line7', // 7호선
    8: 'line8', // 8호선
    9: 'line9', // 9호선
    21: 'incheon_line1', //인천 1호선
    22: 'incheon_line2', //인천 2호선
    91: 'gtx_a', // GTX A
    101: 'airport_railroad', // 공항철도
    104: 'gyeongui_central', // 경의중앙선
    107: 'everline', // 에버라인
    108: 'gyeongchun', // 경춘선
    109: 'shinbundang', // 신분당선
    110: 'lrt_uijeongbu', //의정부 경전철
    112: 'gyeonggang', // 경강선
    113: 'lrt_uisinseol', // 우이신설선
    114: 'sso', // 서해선
    115: 'gimpo_goldline', // 김포골드라인
    116: 'suinbundang', // 수인분당선
    117: 'lrt_sillim', // 신림선
  },
  // 대전
  3000: {
    31: 'daejeon_line1',
  },
  // 대구
  4000: {
    41: 'daegu_line1',
    42: 'daegu_line2',
    43: 'daegu_line3',
  },
  // 광주
  5000: {
    51: 'gwangju_line1',
  },
  // 부산, 울산
  7000: {
    71: 'busan_line1',
    72: 'busan_line2',
    73: 'busan_line3',
    74: 'busan_line4',
    78: 'dong_hae', // 동해선
    79: 'gimhae_light_rail', // 부산김해경전철
  },
}

export interface Property<FieldType = string> extends Omit<ColumnModel, 'field'> {
  field: FieldType
  isDefault?: boolean
  isRequired?: boolean
  needToHideInDialogs?: boolean
}

export const SERVICE_ERROR_CODE = {
  DUPLICATE_COMPARE: '40038',
  DUPLICATE_FILTER: '40035',
  LIMIT_INTEREST: '40039',
  LIMIT_FILTER: '40040',
  LIMIT_COMPARE: '40036',
  INQUIRY_CONTENT_EMPTY: '40041',
}

export const PASSWORD_REGEX = /^(?=.*?[a-zA-Z])(?=.*?\d)(?=.*?[!@#$%^&*?_~]).{8,20}$/
export const KOREAN_REGEX = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g

export const PASSWORD_MAX_LENGTH = 20

export const HEADER_HEIGHT = '60px'

export const HIGHEST_Z_INDEX = 1100000001

export const CHANNELTALK_Z_INDEX = HIGHEST_Z_INDEX - 10
export const POPPER_Z_INDEX = 1000000001

export const SWR_IMMUTABLE_OPTION = {
  swr: { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false },
}

export const SEARCH_DEBOUNCE_WAIT = 300

export const LAND_USE_ETC_LAWS_KEY = '기타 법률'
export const LAND_USE_TERRITORY_LAWS_KEY = '국토의 계획 및 이용에 관한 법률'

export const COMMON_NAVER_MAP_OPTIONS = {
  scaleControl: true,
  logoControlOptions: {
    position: 12, // naver.maps.Position.BOTTOM_RIGHT
  },
}

export const DOWNTIME_NOTICE_CLOSED_KEY = 'downtime-closed'
export const DOWNTIME_NOTICE_SKIP_TIME_KEY = 'downtime-skip-time'
export const IMAGE_NOTICE_SKIP_ID_KEY = 'image_notice_skip_id'
export const TEXT_NOTICE_SKIP_ID_KEY = 'text_notice_skip_id'

export const MAP_LAYER_Z_INDEX = {
  REGION_LAYER: 1,
  REGION_LAYER_ACTIVE: 2,
  DEVELOP_AREA_LAYER: 2,
  DEVELOP_AREA_MARKER: 3,
  UNDEVELOPED_LAND_LAYER: 4,
  BUILDING_LOT_LAYER: 5,
  DRAW_CIRCLE_POINTER: 7,
  NEARBY_MARKER: 8,
  MAP_CONTROL: 199,
  GUIDE_PANEL: 200,
  BUILDING_DETAIL_PANEL: 201,
  BUILDING_PANEL: 202,
  FILTER: 203,
  SEARCH: 204,
  BUILDING_NEARBY_PANEL: 205,
  MORE_INFO: 206,
  LAND_DOT_MARKER: 995,
  OFFICE_DOT_MARKER: 995,
  OFFICE_MARKER: 996,
  OFFICE_DETAIL_MARKER: 997,
  BUILDING_TOOLTIP_MARKER_ACTIVE: 998,
  SELECT_LAYER: 999,
  DRAW_LINE: 1000,
  DRAW_POINTER: 1001,
  BUILDING_TOOLTIP_MARKER_SEARCH: 1002,
  CLUSTER_MARKER: 1003,
  CLUSTER_MARKER_ACTIVE: 1004,
}

export enum NMapZoomLevel {
  '100km' = 6,
  '50km',
  '30km',
  '20km',
  '10km',
  '5km',
  '3km',
  '1,000m',
  '500m',
  '300m',
  '100m',
  '50m',
  '30m',
  '20m',
  '10m',
  '5m',
}

export const EmptyRateArray = [
  'emptyRate',
  'emptyRateExcludeNewest',
  'roomEmptyRate',
  'roomEmptyRateExcludeNewest',
  'lowEmptyRate',
  'lowEmptyRateExcludeNewest',
]
export const EmptyAreaArray = ['emptyArea', 'roomEmptyArea', 'lowEmptyArea']

export const FULL_TABLE_VIEW_SPACE = 28

export const TABLE_VIEW_MAX_DOWNLOAD_COUNT = 100

export const PANEL_HEIGHT = 'calc(100vh - 142px)'

export const PANEL_TOP = 130

export const ERROR_STATUS_DATAHUB = 599

export const MARKER_ANCHOR_X = 54

export const CONTENT_MARKER_ANCHOR_X = 55

export const UNDEFINED_ANCHOR_Y = 36

export const TOOLTIP_MARKER_ANCHOR_Y = 34
