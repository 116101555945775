import React, { ChangeEventHandler, forwardRef } from 'react'
import { ClickArea } from '@toss/react'
import { x } from '@xstyled/styled-components'
import IconSearch from '~/assets/images/icons/search.svg'
import IconX from '~/assets/images/icons/x-graphic.svg'
import { Box } from '~/components'
import Button from '~/components/Button/Button'
import { isNotNil } from '@toss/utils'
import { CommonSearchProps } from '~/components/Search/searchTypes'

interface BasicSearchProps extends CommonSearchProps {
  size?: 's' | 'l'
  maxLength?: number
}

const BasicSearch = forwardRef<HTMLInputElement, BasicSearchProps>(
  (
    {
      value,
      width = '100%',
      placeholder,
      onChange,
      disabled,
      onFocus,
      onBlur,
      onKeyDown,
      onClear,
      size = 's',
      onSearchIconClick,
      isClearButton,
      maxLength,
    },
    ref,
  ) => {
    const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange(e.target.value)
    }

    const handleClearInput = () => {
      onClear?.()
    }

    return (
      <Box display="flex" alignItems="center" gap="10px">
        <Box
          width={width}
          display="flex"
          alignItems="center"
          backgroundColor="system-white"
          borderRadius={{ _: '8px', input: '8px' }}
          border={{
            _: '1px solid var(--color-gray-400)',
            focusWithin: '1px solid var(--color-gray-800) !important',
            '&:has(input:not(:placeholder-shown))': { _: '1px solid var(--color-gray-800)' },
          }}
          fontSize={{ input: size === 's' ? '14px' : '16px' }}
          height={size === 's' ? { _: '36px', input: '20px' } : { _: '56px', input: '24px' }}
          paddingLeft={size === 's' ? '16px' : '10px'}
        >
          <x.input
            type="text"
            ref={ref}
            value={value}
            onChange={handleChangeInput}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            disabled={disabled}
            flex="1"
            placeholder={placeholder}
            outline={{ focus: 'none' }}
            color={{ placeholder: 'gray-500' }}
            maxLength={maxLength}
          />
          <ClickArea onClick={onSearchIconClick} enabled={isNotNil(onSearchIconClick)}>
            <Box
              width="50px"
              height={size === 's' ? '36px' : '56px'}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconSearch width={size === 's' ? '16px' : '18px'} height={size === 's' ? '16px' : '18px'} />
            </Box>
          </ClickArea>
        </Box>
        {isClearButton && (
          <Button
            size="md"
            color="gray"
            onClick={handleClearInput}
            icon={<IconX width="12px" height="12px" color="var(--color-gray-700)" />}
          />
        )}
      </Box>
    )
  },
)

BasicSearch.displayName = 'BasicSearch'

export default BasicSearch
