import { createContext, ReactNode, RefObject, useContext, useRef } from 'react'
import { DialogComponent } from '@syncfusion/ej2-react-popups'

const ConfigurationContext = createContext<RefObject<DialogComponent> | null>(null)

const ConfigurationContextProvider = ({ children }: { children: ReactNode }) => {
  const dialogRef = useRef<DialogComponent>(null)

  return <ConfigurationContext.Provider value={dialogRef}>{children}</ConfigurationContext.Provider>
}

const useConfigurationDialog = () => {
  return useContext(ConfigurationContext)
}

export { useConfigurationDialog }

export default ConfigurationContextProvider
