import { ButtonComponent } from '@syncfusion/ej2-react-buttons'
import { CSSProperties, forwardRef, MouseEventHandler, ReactNode } from 'react'
import styled, { css } from '@xstyled/styled-components'
import classNames from 'classnames'

type ButtonVariant = 'line' | 'filled'
type ButtonColor = 'primary' | 'gray' | 'darkGray' | 'black' | 'guide' | 'analysisPurple'
type LineButtonColor = 'primary' | 'gray'
type ButtonSize = 'xl' | 'lg' | 'md' | 'sm'

interface ButtonProps {
  content?: string | ReactNode
  size?: ButtonSize
  color?: ButtonColor
  variant?: ButtonVariant
  disabled?: boolean
  type?: HTMLButtonElement['type']
  icon?: ReactNode
  iconPosition?: 'left' | 'right'
  onClick?: MouseEventHandler
  width?: CSSProperties['width']
  whitespace?: CSSProperties['whiteSpace']
  height?: CSSProperties['height']
  form?: string
  active?: boolean
}

const Button = forwardRef<ButtonComponent, ButtonProps>(
  (
    {
      content,
      icon,
      disabled,
      active,
      size = 'md',
      color = 'primary',
      variant = 'filled',
      iconPosition = 'left',
      ...rest
    },
    ref,
  ) => {
    const noContent = !content || content === ''

    return (
      <StyledButton
        ref={ref}
        size={size}
        color={color}
        variant={variant}
        disabled={disabled}
        cssClass={classNames(!disabled && active ? 'e-active' : '')}
        $noContent={noContent}
        {...rest}
      >
        {icon && iconPosition === 'left' && (
          <IconWrapper $position={iconPosition} size={size} $noContent={noContent}>
            {icon}
          </IconWrapper>
        )}
        {content}
        {icon && iconPosition === 'right' && (
          <IconWrapper $position={iconPosition} size={size} $noContent={noContent}>
            {icon}
          </IconWrapper>
        )}
      </StyledButton>
    )
  },
)

export default Button

Button.displayName = 'Button'

/**
 * Button Icon Styles
 */
const iconSizes = {
  sm: css`
    & > svg {
      width: 10px;
      height: 10px;
    }
  `,
  md: css`
    & > svg {
      width: 12px;
      height: 12px;
    }
  `,
  lg: css`
    & > svg {
      width: 14px;
      height: 14px;
    }
  `,
  xl: css`
    & > svg {
      width: 14px;
      height: 14px;
    }
  `,
}

const iconMarginSizes: Record<ButtonSize, number> = {
  xl: 8,
  lg: 8,
  md: 6,
  sm: 4,
}

const IconWrapper = styled.div<{ $position?: string; size: ButtonSize; $noContent: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  ${({ size, $position, $noContent }) => {
    if ($noContent) {
      return
    }
    return $position === 'left'
      ? `margin-right: ${iconMarginSizes[size]}px;`
      : `margin-left: ${iconMarginSizes[size]}px;`
  }}
  ${({ size }) => iconSizes[size]}
`

/**
 * Default style
 */
const ButtonCommonStyle = css`
  font-family: var(--font-family);
  font-weight: 600;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: var(--color-gray-200);
    color: var(--color-gray-500);
    border: 1px solid var(--color-gray-200);
    cursor: default;
    pointer-events: none;
  }
`

/**
 * Button Size Styles
 */
const buttonSizes = {
  sm: css`
    padding: 3px 9px;
    font-size: 12px;
    line-height: 18px;
    border-radius: 6px;
  `,
  md: css`
    padding: 7px 15px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
  `,
  lg: css`
    padding: 10px 19px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 10px;
  `,
  xl: css`
    padding: 8px 20px;
    font-size: 16px;
    line-height: 40px;
    border-radius: 12px;
  `,
}

const iconOnlySizes = {
  sm: css`
    padding: 7px;
  `,
  md: css`
    padding: 11px;
  `,
  lg: css`
    padding: 15px;
  `,
  xl: css`
    padding: 20px 19px;
  `,
}

/**
 * Filled Button Style
 */
const ButtonFilledLightGray = css`
  background-color: var(--color-gray-200);
  color: var(--color-gray-700);
  border-color: var(--color-gray-200);

  &.e-active,
  &:active:not(:disabled) {
    background-color: var(--color-gray-700);
    color: var(--color-system-white);
    border-color: var(--color-gray-700);
  }
`
const ButtonFilledDarkGray = css`
  background-color: var(--color-gray-300);
  color: var(--color-gray-700);
  border-color: var(--color-gray-300);

  &.e-active,
  &:active:not(:disabled) {
    background-color: var(--color-gray-700);
    color: var(--color-system-white);
    border-color: var(--color-gray-700);
  }
`
const ButtonFilledPrimary = css`
  background-color: var(--color-purple-700);
  color: var(--color-system-white);
  border-color: var(--color-purple-700);

  &.e-active,
  &:active:not(:disabled) {
    background-color: var(--color-purple-800);
    border-color: var(--color-purple-800);
  }
`
const ButtonFilledBlack = css`
  background-color: var(--color-gray-800);
  color: var(--color-system-white);
  border-color: var(--color-gray-800);
`
const ButtonFilledGuide = css`
  background-color: var(--color-blue-200);
  color: var(--color-blue-700);
  border-color: var(--color-blue-200);
`

const ButtonFilledAnalysisPurple = css`
  background-color: var(--color-purple-200);
  color: var(--color-purple-700);
  border-color: var(--color-purple-200);
`

/**
 * Line Button Styles
 */
const ButtonLinePrimary = css`
  background-color: var(--color-system-white);
  color: var(--color-purple-700);
  border-color: var(--color-purple-700);

  &.e-active,
  &:active {
    background-color: var(--color-purple-700);
    color: var(--color-system-white);
    border-color: var(--color-purple-700);
  }
`
const ButtonLineGray = css`
  background-color: var(--color-system-white);
  color: var(--color-gray-700);
  border-color: var(--color-gray-300);

  &.e-active,
  &:active {
    background-color: var(--color-gray-800);
    color: var(--color-system-white);
    border-color: var(--color-gray-800);
  }
`

const LineButtonStyles = {
  primary: ButtonLinePrimary,
  gray: ButtonLineGray,
}

const FilledButtonStyles = {
  primary: ButtonFilledPrimary,
  gray: ButtonFilledLightGray,
  darkGray: ButtonFilledDarkGray,
  black: ButtonFilledBlack,
  guide: ButtonFilledGuide,
  analysisPurple: ButtonFilledAnalysisPurple,
}

const StyledButton = styled(ButtonComponent)`
  &.e-btn {
    ${ButtonCommonStyle}
    ${({ width }) => `width: ${width};`}
    ${({ height }) => `height: ${height};`}
    ${({ whitespace }) => `white-space: ${whitespace};`}
    ${({ size }) => buttonSizes[size as ButtonSize]}
    ${({ $noContent, size }) => $noContent && iconOnlySizes[size as ButtonSize]}
    ${({ variant, color }) => (variant === 'line' ? LineButtonStyles[color as LineButtonColor] : FilledButtonStyles[color as ButtonColor])}
  }
}
`
