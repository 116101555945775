import { ExceptionResponse } from '~/libs/apis/service/model'

class RaServiceError extends Error {
  public responseBody: ExceptionResponse
  public status: number
  constructor(status: number, responseBody: ExceptionResponse) {
    super(responseBody.message)
    this.status = status
    this.responseBody = responseBody
  }
}

export default RaServiceError
