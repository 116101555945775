import { DefaultValue, selector } from 'recoil'
import landFilterStateFamily from '~/recoil/land-filter/atom'
import { LAND_FILTER_KEYS_FROM_UI, LandsFilterParams } from '~/libs/constants/place'
import mapEventFamilyState from '~/recoil/map-event/atom'

const landFilterParamState = selector<LandsFilterParams>({
  key: 'landFilterParamState',
  get: ({ get }) => {
    const filterParams = LAND_FILTER_KEYS_FROM_UI.reduce<LandsFilterParams>(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: get(landFilterStateFamily(currentValue)),
      }),
      {},
    )

    const mapEvent = get(mapEventFamilyState('place'))
    if (mapEvent?.bounds) {
      filterParams.swLat = mapEvent.bounds.sw.lat
      filterParams.swLng = mapEvent.bounds.sw.lng
      filterParams.neLat = mapEvent.bounds.ne.lat
      filterParams.neLng = mapEvent.bounds.ne.lng
    }
    return filterParams
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      LAND_FILTER_KEYS_FROM_UI.forEach((key) => set(landFilterStateFamily(key), newValue))
    } else {
      Object.entries(newValue).forEach(([key, value]) => {
        if ((LAND_FILTER_KEYS_FROM_UI as string[]).includes(key)) {
          set(landFilterStateFamily(key as keyof LandsFilterParams), value?.toString())
        }
      })
    }
  },
})

export default landFilterParamState
