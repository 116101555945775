import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '~/components/Box/Box'
import TextBox from '~/components/TextBox/TextBox'
import usePreventSubmitEvent from '~/libs/hooks/usePreventSubmitEvent'
import InquiryTypeSelect from '~/templates/common/inquiry/InquiryTypeSelect'
import { GuestInquiryType } from '~/libs/utils/inquiry'
import LabeledContainer from '~/components/LabeledContainer/LabeledContainer'

const FormFieldSetForGuest: FC = () => {
  const { t: term } = useTranslation('common', { keyPrefix: 'data_inquiry' })
  const [inquiryType, setInquiryType] = useState(GuestInquiryType['usage'])

  const preventSubmit = usePreventSubmitEvent()

  return (
    <>
      <Box flex="1" display="flex" gap="20px">
        <LabeledContainer label={term('field.name')} width="50%" isRequired>
          <TextBox ref={preventSubmit} name="name" value="" maxLength={50} placeholder={term('placeholder.name')} />
        </LabeledContainer>
        <LabeledContainer label={term('field.email')} width="50%" isRequired>
          <TextBox
            ref={preventSubmit}
            name="email"
            value=""
            errorMessageId="email"
            placeholder={term('placeholder.email')}
          />
        </LabeledContainer>
      </Box>
      <Box flex="1" display="flex" gap="20px">
        <LabeledContainer label={term('field.inquiry_type')}>
          <InquiryTypeSelect defaultValue={inquiryType} onClick={setInquiryType} isGuest />
        </LabeledContainer>
      </Box>
    </>
  )
}

export default FormFieldSetForGuest
