import { useLwhDetailDataV1BuildingsLwhRaIdGet } from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import { useOfficeDetailDataV1BuildingsOfficeRaIdGet } from '~/libs/apis/data/buildings-office/buildings-office'
import { useGetLandDataV1AreaLandGet } from '~/libs/apis/data/area/area'
import { DetailContent } from '~/templates/FullDetailDialogProvider'
import { AreaLandOut, LwhOut, OfficeOut } from '~/libs/apis/data/model'
import { useRecoilValue } from 'recoil'
import dataInquiryState from '~/recoil/data-inquiry/atom'

const isLandType = (data: OfficeOut | LwhOut | AreaLandOut): data is AreaLandOut => !('raId' in data)

const formatResult = (data: OfficeOut | LwhOut | AreaLandOut) => {
  if (isLandType(data)) {
    return {
      inquiryTargetType: 'PNU',
      buildingName: data.address,
      address: data.address,
      pnu: data.pnu,
    }
  }

  return {
    inquiryTargetType: 'BUILDING',
    raId: data.raId,
    buildingName: data.buildingName,
    address: data.address,
    roadAddress: data.roadAddress,
    propertyTypeCode: data.propertyTypeCode,
  }
}
const useGetInquiryTargetDetail = () => {
  const inquiryInfo = useRecoilValue(dataInquiryState)

  const { data: officeData } = useOfficeDetailDataV1BuildingsOfficeRaIdGet(inquiryInfo?.targetId ?? '', {
    swr: {
      enabled: Boolean(inquiryInfo?.type === 'office'),
    },
  })

  const { data: lwhData } = useLwhDetailDataV1BuildingsLwhRaIdGet(inquiryInfo?.targetId ?? '', {
    swr: {
      enabled: Boolean(inquiryInfo?.type === 'lwh'),
    },
  })

  const { data: landData } = useGetLandDataV1AreaLandGet(
    { pnu: inquiryInfo?.targetId ?? '' },
    { swr: { enabled: Boolean(inquiryInfo?.type === 'land') } },
  )

  const ResponseAllDataObject: Record<DetailContent, OfficeOut | LwhOut | AreaLandOut | undefined> = {
    office: officeData,
    lwh: lwhData,
    land: landData,
  }

  if (!inquiryInfo?.type) {
    return undefined
  }

  const response = ResponseAllDataObject[inquiryInfo.type]

  if (!response) {
    return undefined
  }

  return formatResult(response)
}

export default useGetInquiryTargetDetail
