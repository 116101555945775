interface ErrorResponse {
  detail: ErrorDetail
}
interface ErrorDetail {
  error: string
  message: string
  description: string
}

class RaDataError extends Error {
  public status: number
  public responseBody: ErrorResponse
  constructor(status: number, error: ErrorResponse) {
    super(error.detail.message)
    this.status = status
    this.responseBody = error
  }
}

export default RaDataError
