import Box from '../components/Box/Box'
import Dialog from '~/components/Dialog/Dialog'
import { useTranslation } from 'next-i18next'
import Button from '~/components/Button/Button'
import { FC, useState } from 'react'
import { useConfigurationDialog } from '~/templates/ConfigurationContextProvider'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import { useToast } from '~/templates/ToastContextProvider'
import Dropdown from '~/components/Dropdown/Dropdown'
import LabeledContainer from '~/components/LabeledContainer/LabeledContainer'
import InfoIcon from '~/assets/images/icons/info.svg'
import Tooltip from '~/components/Tooltip/Tooltip'
import { formatDateYm } from '~/libs/utils/date'
import useUsdExchange from '~/libs/hooks/useUsdExchange'
import { useRouter } from 'next/router'
import { toLocaledUrl } from '~/libs/utils/query'
import { commaizeNumber, omit } from '@toss/utils'
import { FILTER_KEYS_FROM_UI } from '~/libs/constants/place'
import { Option } from '~/components/Dropdown/ListSelect'

interface ConfigurationDialogProps {}

const ConfigurationDialog: FC<ConfigurationDialogProps> = () => {
  const ref = useConfigurationDialog()
  const { t } = useTranslation()
  const { areaUnit, language, currencyUnit, setUserConfiguration } = useUserConfiguration()
  const usdExchange = useUsdExchange()
  const { show } = useToast()
  const router = useRouter()
  const [areaType, setAreaType] = useState<string | undefined>(areaUnit)
  const [currencyType, setCurrencyType] = useState<string | undefined>(currencyUnit)
  const [languageType, setLanguageType] = useState<string | undefined>(language)
  const areaOptions: Option[] = [
    {
      label: t('common_term.py'),
      value: 'PY',
    },
    {
      label: '㎡',
      value: 'M2',
    },
  ]

  const currencyOptions: Option[] = [
    {
      label: t('configuration.krw'),
      value: 'KRW',
    },
    {
      label: t('configuration.usd'),
      value: 'USD',
    },
  ]

  const languageOptions: Option[] = [
    {
      label: t('configuration.korean'),
      value: 'KOREAN',
    },
    {
      label: t('configuration.english'),
      value: 'ENGLISH',
    },
  ]

  const handleCancelClick = () => {
    ref?.current?.hide()
  }

  const handleChangeClick = () => {
    saveConfiguration()
  }

  const handleBeforeOpenDialog = () => {
    setAreaType(areaUnit)
    setCurrencyType(currencyUnit)
    setLanguageType(language)
  }

  const saveConfiguration = () => {
    setUserConfiguration({ areaType: areaType!, currency: currencyType!, language: languageType! })
      .then(() => {
        const locale = languageType === 'KOREAN' ? 'ko' : 'en'
        return router.replace({
          pathname: toLocaledUrl(locale, router.pathname),
          query: omit(router.query, FILTER_KEYS_FROM_UI),
        })
      })
      .catch(() => {
        show(t('common_term.unknown_error'))
      })
    ref?.current?.hide()
  }

  const handleAreaTypeChanged = (value?: string) => {
    setAreaType(value)
  }
  const handleCurrencyTypeChanged = (value?: string) => {
    setCurrencyType(value)
  }
  const handleLanguageTypeChanged = (value?: string) => {
    setLanguageType(value)
  }

  const footerTemplate = () => (
    <Box display="flex" gap={4} alignItems="center" justifyContent="center">
      <Button
        content={t('common_term.cancel')}
        size="lg"
        color="gray"
        variant="line"
        width="120px"
        onClick={handleCancelClick}
      />
      <Button content={t('common_term.change')} size="lg" width="120px" onClick={handleChangeClick} />
    </Box>
  )

  return (
    <Dialog
      ref={ref}
      visible={false}
      beforeOpen={handleBeforeOpenDialog}
      title={t('nav.setting')}
      width="460px"
      target="#dialog-target"
      variant="basic"
      footerTemplate={footerTemplate}
    >
      <Box p="30px" display="flex" flexDirection="column" gap={5}>
        <Box display="flex" gap={3}>
          <Box flex="1 1 0">
            <LabeledContainer label={t('configuration.unit')}>
              <Dropdown value={areaType} options={areaOptions} onChange={handleAreaTypeChanged} />
            </LabeledContainer>
          </Box>
          <Box flex="1 1 0">
            <LabeledContainer
              label={t('configuration.currency')}
              tooltip={
                <Tooltip
                  content={t('configuration.exchange_info', {
                    updateDate: formatDateYm(usdExchange.updateDateYm),
                    amount: commaizeNumber(usdExchange.ammount.toFixed(2)),
                  })}
                  position="TopCenter"
                  align="center"
                >
                  <InfoIcon width="16px" height="16px" color="var(--color-gray-500)" />
                </Tooltip>
              }
            >
              <Dropdown value={currencyType} options={currencyOptions} onChange={handleCurrencyTypeChanged} />
            </LabeledContainer>
          </Box>
        </Box>
        <Box>
          <LabeledContainer label={t('configuration.language')}>
            <Dropdown value={languageType} options={languageOptions} onChange={handleLanguageTypeChanged} />
          </LabeledContainer>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ConfigurationDialog
