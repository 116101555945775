import Box from '~/components/Box/Box'
import styled, { useTheme } from '@xstyled/styled-components'
import BookmarkSolidIcon from '~/assets/images/icons/star-solid.svg'
import BookmarkLineIcon from '~/assets/images/icons/star-line.svg'
import StreetViewIcon from '~/assets/images/icons/street-view.svg'
import Tooltip from '~/components/Tooltip/Tooltip'
import XGraphicIcon from '~/assets/images/icons/x-graphic.svg'

export interface ButtonData {
  iconKey: 'streetview' | 'interest' | 'close'
  label: string
  active?: boolean
  onClick?: (active?: boolean) => void
}

const OverviewButton = ({ iconKey, label, active, onClick }: ButtonData) => {
  const theme = useTheme()
  const iconColor = theme.colors['gray-600']
  const icons = {
    streetview: <StreetViewIcon color={iconColor} width={18} height={18} />,
    interest: active ? (
      <BookmarkSolidIcon color={theme.colors['event-bookmark']} width={18} height={18} />
    ) : (
      <BookmarkLineIcon fill={iconColor} width={18} height={18} />
    ),
    close: <XGraphicIcon color={theme.colors['gray-700']} width={16} height={16} />,
  }

  const handleClick = () => onClick?.(active)

  return (
    <Tooltip content={label} position="BottomCenter" opensOn="Hover" align="left">
      <Button onClick={handleClick}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {icons[iconKey]}
        </Box>
      </Button>
    </Tooltip>
  )
}

const Button = styled.button`
  width: 26px;
  background: transparent;
  padding: 0;

  &:focus {
    outline: none;
  }
`

export default OverviewButton
