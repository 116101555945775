import { ValueAccessor } from '@syncfusion/ej2-grids/src/grid/base/type'
import useCommonCode from '~/libs/hooks/useCommonCode'

/**
 * 데이터그리드에서 dataSource의 field명을 이용해 공통코드로 value를 전환해주는 valueAccessor
 */
const useCodeNameValueAccessor = (): ValueAccessor => {
  const { codeToName } = useCommonCode()
  return (field, data: any) => {
    return codeToName(data[field]) ?? '-'
  }
}

export default useCodeNameValueAccessor
