import { LwhLeaseMarketTrendValueType } from '~/libs/apis/data/model'
import Box from '~/components/Box/Box'
import LwhMarketLeasePriceComparisonGrid from '~/templates/place/detail/lease/lwh/LwhMarketLeasePriceComparisonGrid'

interface LwhMarketLeasePriceComparisonContentProps {
  type: LwhLeaseMarketTrendValueType
}

const LwhMarketLeasePriceComparisonContent = ({ type }: LwhMarketLeasePriceComparisonContentProps) => {
  return (
    <Box pt={1.5}>
      <LwhMarketLeasePriceComparisonGrid type={type} />
    </Box>
  )
}

export default LwhMarketLeasePriceComparisonContent
