import React, { ComponentType, forwardRef, PropsWithChildren } from 'react'
import { SystemProps } from '@xstyled/styled-components'
import { TextVariants } from '~/libs/theme/fonts'
import { x } from '~/xstyled.config'
import { LineClampProps } from '~/libs/styles/line-clamp'
import { WordBreakProps } from '~/libs/styles/word-break'

interface TypographyStyleProps {
  variant?: TextVariants
  className?: string
  id?: string
  as?: ComponentType<any> | keyof React.JSX.IntrinsicElements | string
}

type Props = PropsWithChildren<TypographyStyleProps> & Omit<SystemProps & LineClampProps & WordBreakProps, 'text'>

const convertVariantToTags = (text?: TextVariants) => {
  switch (text) {
    case 'jumbo':
      return 'h1'
    case 'h1':
      return 'h2'
    case 'h2':
      return 'h3'
    case 'h3':
      return 'h4'
    case 'h4':
      return 'h5'
    case 'h5':
    case 'subtitle':
      return 'h6'
    case 'caption1':
    case 'caption2':
      return 'span'
    default:
      return 'p'
  }
}

const Typography = forwardRef<HTMLHeadingElement, Props>(
  ({ variant = 'body', color = '#2E2E2E', children, ...rest }, ref) => {
    return (
      <x.p
        ref={ref}
        color={color}
        as={convertVariantToTags(variant.toString() as TextVariants)}
        text={variant}
        {...rest}
      >
        {children}
      </x.p>
    )
  },
)

Typography.displayName = 'Typography'

export default Typography
