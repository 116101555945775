import { FC, useMemo, useRef } from 'react'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { Page } from '~/libs/apis/data/model'
import LoadMoreDataGrid from '~/templates/place/detail/LoadMoreDataGrid'
import MultiTemplate from '~/components/DataGrid/MultiTemplate'
import { formatDateYmd } from '~/libs/utils/date'
import { useTranslation } from 'next-i18next'
import { isNotNil } from '@toss/utils'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import Tooltip from '~/components/Tooltip/Tooltip'
import Typography from '~/components/Typography/Typography'
import { useAreaFormat, useAreaValueAccessor, useCurrencyFormat, usePerUnitFormat } from '~/libs/hooks/useFormat'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import useCommonCode from '~/libs/hooks/useCommonCode'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import { formatVacancyRate } from '~/libs/utils/format'
import InfoIcon from '~/assets/images/icons/info.svg'
import Box from '../../../../components/Box/Box'
import { TooltipComponent } from '@syncfusion/ej2-react-popups/src/tooltip'

interface TradeHistoryGridProps {
  dataSource: object[] | undefined
  pageModel: Page | undefined
  onPageChange: (page: number) => void
}

const TradeHistoryGrid: FC<TradeHistoryGridProps> = ({ dataSource, pageModel, onPageChange }) => {
  const { detailType } = useDetailInfo()
  const isFull = detailType === 'full'
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: place } = useTranslation('place')
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const isKrw = currencyUnit === 'KRW'
  const areaValueAccessor = useAreaValueAccessor(areaUnit)
  const areaFormatter = useAreaFormat()
  const unitFormatter = usePerUnitFormat()
  const currencyFormatter = useCurrencyFormat()
  const { codeToName } = useCommonCode()
  const capRateRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<TooltipComponent>(null)

  const columns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'date_ymd',
        width: 100,
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        headerTemplate: () => (
          <MultiTemplate isHeader content={[t('contract_date_ymd'), t('complete_pay_ymd')]} textAlign="left" />
        ),
        template: (props: any) => (
          <MultiTemplate
            content={[
              props.contractDateYmd ? formatDateYmd(props.contractDateYmd) : undefined,
              props.completePayDateYmd ? formatDateYmd(props.completePayDateYmd) : undefined,
            ]}
            textAlign="left"
          />
        ),
      },
      {
        field: 'tradeAreaAndBuyType',
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 110,
        headerTemplate: () => <MultiTemplate isHeader content={[t('trade_area'), t('buy_type')]} textAlign="left" />,
        template: (props: any) => (
          <MultiTemplate
            content={[areaFormatter(props.tradeArea, { rounded: 'n2' }), isNotNil(props.buyType) ? props.buyType : '-']}
            textAlign="left"
          />
        ),
      },
      {
        field: 'trade',
        width: 100,
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        headerTemplate: () => (
          <MultiTemplate isHeader content={[t('trade_amount'), t('trade_price')]} textAlign="left" />
        ),
        template: (props: any) => (
          <MultiTemplate
            content={[
              currencyFormatter(props.price, { format: 'eok', rounded: isKrw ? 'n1' : undefined }),
              unitFormatter(props.pricePerM2, { format: 'man' }),
            ]}
            textAlign="left"
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areaUnit, currencyUnit, t],
  )

  const fullColumns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'registGubunCode',
        headerText: t('division'),
        textAlign: 'Left',
        width: 49,
        valueAccessor: (field: string, data: any) => (isNotNil(data[field]) ? (codeToName(data[field]) ?? '-') : '-'),
      },
      {
        field: 'date_ymd',
        textAlign: 'Left',
        width: 110,
        headerTemplate: () => (
          <MultiTemplate isHeader content={[t('contract_date_ymd'), t('complete_pay_ymd')]} textAlign="left" />
        ),
        template: (props: any) => (
          <MultiTemplate
            content={[
              props.contractDateYmd ? formatDateYmd(props.contractDateYmd) : undefined,
              props.completePayDateYmd ? formatDateYmd(props.completePayDateYmd) : undefined,
            ]}
            textAlign="left"
          />
        ),
      },
      {
        field: 'buildingName',
        headerText: t('building_name'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 152,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'tradeArea',
        headerText: t('trade_area'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 108,
        valueAccessor: areaValueAccessor({ rounded: 'n2' }),
      },
      {
        field: 'trade',
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 106,
        headerTemplate: () => (
          <MultiTemplate isHeader content={[t('trade_amount'), t('trade_price')]} textAlign="left" />
        ),
        template: (props: any) => (
          <MultiTemplate
            content={[
              currencyFormatter(props.price, { format: 'eok', rounded: isKrw ? 'n1' : undefined }),
              unitFormatter(props.pricePerM2, { format: 'man' }),
            ]}
            textAlign="left"
          />
        ),
      },
      {
        field: 'sellerAndBuyer',
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        headerTemplate: () => (
          <MultiTemplate
            isHeader
            content={[place('place_term.bargainer'), place('place_term.buyer')]}
            textAlign="left"
          />
        ),
        template: (props: any) => <MultiTemplate content={[props.seller, props.buyer]} textAlign="left" />,
      },
      {
        field: 'buyType',
        headerText: t('buy_type'),
        textAlign: 'Left',
        width: 70,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'tradeType',
        headerText: t('trade_type'),
        textAlign: 'Left',
        width: 70,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'vehicle',
        headerText: place('place_term.invest_vehicle'),
        textAlign: 'Left',
        width: 98,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'capRate',
        textAlign: 'Left',
        width: 95,
        headerTemplate: () => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onMouseOver={() => tooltipRef?.current?.open(capRateRef.current || undefined)}
            onMouseOut={() => tooltipRef?.current?.close()}
          >
            <Typography variant="body" fontWeight="semibold" as="span">
              Cap Rate
            </Typography>
            <Tooltip ref={tooltipRef} content={place('message.near_by_trade_cap_rate')} width="324px" align="right">
              <Box ref={capRateRef}>
                <InfoIcon width="12px" height="12px" color="var(--color-gray-800)" />
              </Box>
            </Tooltip>
          </Box>
        ),
        valueAccessor: (field: string, data: any) => formatVacancyRate(data[field]),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areaUnit, currencyUnit, t],
  )

  return (
    <LoadMoreDataGrid
      columns={isFull ? fullColumns : columns}
      dataSource={dataSource}
      pageModel={pageModel}
      onPageChange={onPageChange}
      allowSelection={false}
    />
  )
}

export default TradeHistoryGrid
