import { Box } from '~/components'
import { FC, MouseEventHandler, ReactNode } from 'react'
import Tooltip from '~/components/Tooltip/Tooltip'
import { useBooleanState } from '@toss/react'

interface CommonMenuItemProps {
  icon: ReactNode
  selectedIcon?: ReactNode
  selected?: boolean
  text: string
  onClick?: MouseEventHandler
}

const CommonMenuItem: FC<CommonMenuItemProps> = ({ icon, selectedIcon, selected, text, onClick }) => {
  const [hover, setHover, setLeave] = useBooleanState(false)

  return (
    <Box
      onClick={onClick}
      onMouseEnter={setHover}
      onMouseLeave={setLeave}
      cursor="pointer"
      color={hover ? 'purple-700' : 'gray-600'}
      userSelect="none"
    >
      <Tooltip content={text}>{selected ? selectedIcon : icon}</Tooltip>
    </Box>
  )
}

export default CommonMenuItem
