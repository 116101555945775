import { useState } from 'react'
import { RegistrationOut } from '~/libs/apis/data/model/registrationOut'
import SelectRegistrationDialog, { RegistrationListItem } from '~/templates/common/dialog/SelectRegistrationDialog'

interface SelectRegistrationDetailDialogProps {
  list: RegistrationOut[]
  visible: boolean
  address: string | null
  selectedIndex: number
  onConfirm: (selectedIndex: number) => void
  onClose: () => void
}

const SelectRegistrationDetailDialog = ({
  list,
  visible,
  address,
  selectedIndex,
  onConfirm,
  onClose,
}: SelectRegistrationDetailDialogProps) => {
  const [clickedIndex, setClickedIndex] = useState(selectedIndex)

  const detailRegistrationList: RegistrationListItem[] = list.map((item) => ({
    registrationId: item.registrationId,
    address: item.address,
    recordAccessDate: item.registrationCompletedAt,
    propertyTypeCode: item.registrationPropertyTypeCode,
    realEstateNumber: item.registrationRealEstateNumber,
  }))

  return (
    <SelectRegistrationDialog
      list={detailRegistrationList}
      visible={visible}
      address={address}
      clickedIndex={clickedIndex}
      setClickedIndex={setClickedIndex}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  )
}

export default SelectRegistrationDetailDialog
