import { CarouselComponent } from '@syncfusion/ej2-react-navigations'
import { useBooleanState } from '@toss/react'
import styled from '@xstyled/styled-components'
import { FC, useRef } from 'react'
import AngleLeft from '~/assets/images/icons/angle-left.svg'
import AngleRight from '~/assets/images/icons/angle-right.svg'
import { Box } from '~/components'
import BuildingImage from '~/components/Image/BuildingImage'
import { GetNoticesResponseItem, GetNoticesResponseItemNoticeImagesItem } from '~/libs/apis/service/model'
import { IMAGE_NOTICE_SKIP_ID_KEY } from '~/libs/constants/common'
import NoticeDialog from '~/templates/common/content/NoticeDialog'

interface NoticeImageDialogProps {
  notice: GetNoticesResponseItem | undefined
}

const sortImageOrder = (a: GetNoticesResponseItemNoticeImagesItem, b: GetNoticesResponseItemNoticeImagesItem) =>
  (a.order ?? 0) - (b.order ?? 0)

const NoticeImageDialog: FC<NoticeImageDialogProps> = ({ notice }) => {
  const [isDialogVisibled, renderCarousel] = useBooleanState(false)
  const carouselRef = useRef<CarouselComponent>(null)

  const isShowIndicators = (notice?.noticeImages?.length ?? 0) > 1

  const previousButtonTemplate = () => (
    <Box
      width="22px"
      height="22px"
      position="absolute"
      top="16px"
      right="44px"
      backgroundColor="gray-800"
      borderRadius="12px"
      color="system-white"
      cursor="pointer"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <AngleLeft width={8} height={8} />
    </Box>
  )

  const nextButtonTemplate = () => (
    <Box
      width="22px"
      height="22px"
      position="absolute"
      top="16px"
      right="16px"
      backgroundColor="gray-800"
      borderRadius="12px"
      color="system-white"
      cursor="pointer"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <AngleRight width={8} height={8} />
    </Box>
  )

  const handleOpen = () => {
    renderCarousel()
  }

  const itemTemplate = (image: GetNoticesResponseItemNoticeImagesItem) => {
    return (
      <Box
        cursor={image.link ? 'pointer' : 'normal'}
        onClick={() => {
          if (image.link) {
            window.open(image.link, '_blank', 'noopener')
          }
        }}
      >
        <BuildingImage url={image.imageUrl} width={400} height={440} />
      </Box>
    )
  }

  const dataSource = notice?.noticeImages?.toSorted(sortImageOrder)

  return (
    <NoticeDialog storageKey={IMAGE_NOTICE_SKIP_ID_KEY} notice={notice} onOpen={handleOpen}>
      <Box width="400px" height="440px" className="control-container">
        {isDialogVisibled && (
          <StyledCarousel
            ref={carouselRef}
            selectedIndex={0}
            indicatorsType="Fraction"
            previousButtonTemplate={previousButtonTemplate}
            nextButtonTemplate={nextButtonTemplate}
            dataSource={dataSource}
            itemTemplate={itemTemplate}
            showIndicators={isShowIndicators}
            interval={(notice?.imageInterval ?? 2) * 1000}
          ></StyledCarousel>
        )}
      </Box>
    </NoticeDialog>
  )
}

const StyledCarousel = styled(CarouselComponent)`
  .e-fraction {
    top: 16px;
    bottom: unset;
    right: 72px;
    justify-content: flex-end;
    min-height: 22px !important;

    .e-indicator-bars {
      padding: 2px 10px;
      background-color: var(--color-gray-800);
      border-radius: 12px;
      font-weight: bold;
      font-size: 12px;
    }
  }
`

export default NoticeImageDialog
