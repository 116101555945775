import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import useUsdExchange from '~/libs/hooks/useUsdExchange'

/**
 * - currencyConverterForClient: 데이터의 흐름이 서버 -> 클라이언트인 경우  e.g.) API 응답을 화면에 렌더
 * - currencyConverterForServer: 데이터의 흐름이 클라이언트 -> 서버인 경우  e.g.) 사용자 입력값을 필터로 설정
 */
const useCurrencyUnitTransformer = () => {
  const { currencyUnit } = useUserConfiguration()
  const { ammount } = useUsdExchange()
  const isKrw = currencyUnit === 'KRW'

  return {
    currencyConverterForClient: (number: number) => {
      if (isKrw) {
        return number
      }
      return number / ammount
    },
    currencyConverterForServer: (number: number) => {
      if (isKrw) {
        return number
      }
      return number * ammount
    },
  }
}

export default useCurrencyUnitTransformer
