import { css } from 'styled-components'

export const commonDialogCss = css`
  white-space: pre-wrap;
  border-radius: 20px;
  border: none;
  box-shadow: none;

  &.e-dialog.e-popup {
    width: fit-content;
  }

  .e-dlg-header-content {
    border-radius: 20px 20px 0 0;

    .e-dlg-header {
      width: 100%;
      text-align: center;
      font-size: h5;
      font-weight: semibold;
      color: gray-800;
    }
  }

  .e-dlg-header-content + .e-dlg-content {
    padding-top: 0;
  }

  .e-footer-content {
    height: 90px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-top: 1px solid var(--color-gray-300);
    border-radius: 0 0 20px 20px;
    padding: 0 32px;

    .e-control.e-btn.e-lib {
      margin-left: 0;
    }
  }
`

export const basicDialogCss = css`
  .e-dlg-header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    padding: 0;
    border-bottom: 1px solid var(--color-gray-300);
  }

  .e-dlg-header-content + .e-dlg-content,
  .e-dlg-content {
    padding: 0;
  }
`

export const simpleDialogCss = css`
  width: 384px;

  .e-dlg-header-content {
    padding: 40px 32px 24px;
    border-bottom: none;
  }

  .e-dlg-content {
    text-align: center;
    padding: 0 32px 36px;
    white-space: pre-wrap;
    color: gray-800;
    font-size: subtitle;
    line-height: subtitle;

    &:empty {
      padding: 0 32px 12px;
    }
  }
`

const dialogCss = css`
  .e-dlg-container > .e-dialog {
    position: relative !important;
  }
  .e-alert-dialog.e-control.e-dialog.e-lib.e-dlg-modal.e-popup,
  .e-confirm-dialog.e-control.e-dialog.e-lib.e-dlg-modal.e-popup {
    ${commonDialogCss}
    ${simpleDialogCss}

    .e-footer-content {
      .e-control.e-btn.e-lib.e-flat {
        width: 120px;
        height: 46px;
        border-radius: 10px;
        color: gray-700;
        font-size: subtitle;
        font-weight: semibold;
        background-color: system-white;
        border: 1px solid var(--color-gray-300);

        &:hover {
          color: gray-700;
          background-color: system-white;
          border: 1px solid var(--color-gray-300);
        }

        &.e-active,
        &:active:not(:disabled) {
          color: system-white;
          background-color: gray-800;
          border-color: gray-800;
          box-shadow: none;
        }

        &:focus {
          color: gray-700;
          background-color: system-white;
          border: 1px solid var(--color-gray-300);
          box-shadow: none;
        }

        &.e-primary {
          color: system-white;
          background-color: purple-700;
          border: 1px solid var(--color-purple-700);

          &:hover {
            color: system-white;
            background-color: purple-700;
            border: 1px solid var(--color-purple-700);
          }

          &.e-active,
          &:active:not(:disabled) {
            color: system-white;
            background-color: purple-800;
            border-color: purple-800;
            box-shadow: none;
          }

          &:focus {
            color: system-white;
            background-color: purple-700;
            border: 1px solid var(--color-purple-700);
            box-shadow: none;
          }
        }
      }
    }
  }
`

export default dialogCss
