import { FC } from 'react'
import Typography from '~/components/Typography/Typography'
import { TextVariants } from '~/libs/theme/fonts'
import { CSSProperties } from '@xstyled/styled-components'

interface GuideContentDotProps {
  variant?: TextVariants
  paddingLeft?: CSSProperties['paddingLeft']
}

const GuideContentDot: FC<GuideContentDotProps> = ({ variant = 'caption1', paddingLeft = '6px' }) => {
  return (
    <Typography variant={variant} color="gray-800" whiteSpace="pre-wrap" paddingLeft={paddingLeft} paddingRight="6px">
      •
    </Typography>
  )
}

export default GuideContentDot
