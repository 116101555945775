import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import Dropdown from '~/components/Dropdown/Dropdown'
import { GuestInquiryType, UserInquiryType } from '~/libs/utils/inquiry'

interface InquiryTypeSelectProps {
  defaultValue?: string
  onClick: (val: string) => void
  isGuest?: boolean
}

const InquiryTypeSelect: FC<InquiryTypeSelectProps> = ({ defaultValue, onClick, isGuest }) => {
  const { t: term } = useTranslation('common', { keyPrefix: 'data_inquiry' })

  const options = Object.entries(isGuest ? GuestInquiryType : UserInquiryType).map(([key, value]) => ({
    label: term(`inquiry_type.${key}`),
    value: value,
  }))

  const onChange = (value?: string) => {
    if (value) {
      onClick(value)
    }
  }

  return (
    <>
      <Dropdown options={options} value={defaultValue ?? options[0].value} onChange={onChange} />
      {/* form data를 위해 추가 */}
      <input type="hidden" name="inquiryType" value={defaultValue ?? options[0].value} />
    </>
  )
}

export default InquiryTypeSelect
