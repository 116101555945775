import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import drawState from '~/recoil/draw/atom'
import useMapMover from '~/libs/hooks/useMapMover'
import searchInfoState from '~/recoil/search-info/atom'
import buildingFilterStateFamily from '~/recoil/building-filter/atom'
import detailTypeState from '~/recoil/detail/atom'
import { SearchBuildingOut, SearchSubwayOut } from '~/libs/apis/data/model'
import isItemSearchState from '~/recoil/search-info/isItemSearchState'
import isAreaSearchState from '~/recoil/search-info/isAreaSearchState'
import drawingControlState from '~/recoil/map-control/drawingControlState.ts'
import tenantFilterStateFamily from '~/recoil/topic-tenant-filter/atom'
import useMapType from '~/libs/hooks/useMapType'
import { NMapZoomLevel } from '~/libs/constants/common'

const useMapFilterHandler = () => {
  const { mapType, filterType, filterStateFamily } = useMapType()
  const boeTypeCode =
    filterType === 'building' ? buildingFilterStateFamily('boeTypeCode') : tenantFilterStateFamily('boeTypeCode')

  const setRegionCode = useSetRecoilState(filterStateFamily('regionCode'))
  const setDraw = useSetRecoilState(drawState(mapType))
  const setDrawControl = useSetRecoilState(drawingControlState(mapType))
  const setRadius = useSetRecoilState(filterStateFamily('radius'))
  const setCircleLat = useSetRecoilState(filterStateFamily('circleLat'))
  const setCircleLng = useSetRecoilState(filterStateFamily('circleLng'))
  const setBoeTypeCode = useSetRecoilState(boeTypeCode)
  const setClientIds = useSetRecoilState(tenantFilterStateFamily('clientIds'))
  const setRaIds = useSetRecoilState(tenantFilterStateFamily('raIds'))
  const resetClientIds = useResetRecoilState(tenantFilterStateFamily('clientIds'))
  const resetRaIds = useResetRecoilState(tenantFilterStateFamily('raIds'))
  const resetRegionCode = useResetRecoilState(filterStateFamily('regionCode'))
  const resetBoeTypeCode = useResetRecoilState(boeTypeCode)
  const resetRadius = useResetRecoilState(filterStateFamily('radius'))
  const resetCircleLat = useResetRecoilState(filterStateFamily('circleLat'))
  const resetCircleLng = useResetRecoilState(filterStateFamily('circleLng'))
  const setPaths = useSetRecoilState(filterStateFamily('paths'))
  const resetPaths = useResetRecoilState(filterStateFamily('paths'))
  const resetSearchInfo = useResetRecoilState(searchInfoState(mapType))
  const resetDetailType = useResetRecoilState(detailTypeState(mapType))
  const [searchInfo, setSearchInfo] = useRecoilState(searchInfoState(mapType))
  const [detailType, setDetailType] = useRecoilState(detailTypeState(mapType))
  const isItemSearch = useRecoilValue(isItemSearchState(mapType))
  const isAreaSearch = useRecoilValue(isAreaSearchState(mapType))
  const handleMapMove = useMapMover(mapType)

  const resetDraw = () => {
    setDraw('NONE')
    setDrawControl(undefined)
    resetCircleLat()
    resetCircleLng()
    resetRadius()
    resetPaths()
  }

  const resetSearch = () => {
    const searchType = searchInfo?.searchType
    resetSearchInfo()
    if (searchType === detailType?.dataType) {
      resetDetailType()
    }

    if (isAreaSearch) {
      resetRegionCode()
    }

    if (searchType === 'client') {
      resetClientIds()
    }

    if (searchType === 'building' && filterType === 'tenant') {
      resetRaIds()
    }
  }

  const handleItemSelect = () => {
    if (isItemSearch) {
      resetSearch()
    }
  }

  const handleAreaSelect = () => {
    if (isAreaSearch) {
      resetSearch()
    }
  }

  const searchBuilding = (keyword: string, buildingInfo: SearchBuildingOut) => {
    clearTenantFilters(false)
    handleMapMove({
      center: { lat: buildingInfo.lat, lng: buildingInfo.lng },
      zoomLevel: NMapZoomLevel['20m'],
    })
    resetRegionCode()
    switch (mapType) {
      case 'place':
        setDetailType({ uid: buildingInfo.raId, dataType: 'building', propertyType: buildingInfo.propertyTypeCode })
        break
      case 'tenant':
        setRaIds(buildingInfo.raId)
        break
    }
    setSearchInfo({ keyword, searchType: 'building', isSearch: true, buildingInfo })
  }

  const searchArea = async (regionCode: string, regionName: string) => {
    clearTenantFilters(false)
    resetDraw()
    resetBoeTypeCode()
    setRegionCode(regionCode)
    setSearchInfo({ keyword: regionName, searchType: 'area', isSearch: true })
  }

  const searchLand = async (pnu: string, address: string) => {
    resetRegionCode()
    setDetailType({ uid: pnu, dataType: 'land' })
    setSearchInfo({ keyword: address, searchType: 'land', isSearch: true })
  }

  const searchSubway = async (keyword: string, subwayInfo: SearchSubwayOut) => {
    handleMapMove({
      center: { lat: subwayInfo.lat, lng: subwayInfo.lng },
      zoomLevel: NMapZoomLevel['50m'],
    })
    resetRegionCode()
    setSearchInfo({ keyword, searchType: 'subway', isSearch: true, subwayInfo })
  }

  const searchClient = async (keyword: string, clientId: string) => {
    clearTenantFilters(false)
    clearAreaQuery()
    setSearchInfo({ keyword, searchType: 'client', isSearch: true })
    setClientIds(clientId)
  }

  const selectBuilding = (raId: string, propertyTypeCode: string, isGroup?: boolean, toggle: boolean = true) => {
    handleItemSelect()
    if (!toggle) {
      setDetailType({ uid: raId, propertyType: propertyTypeCode, dataType: 'building' })
      return
    }

    if (detailType?.uid !== raId) {
      setDetailType({ uid: raId, propertyType: propertyTypeCode, dataType: 'building' })
    } else if (!isGroup) {
      resetDetailType()
    }
  }

  const selectLand = (pnu: string, selectedRegistrationId?: number, toggle: boolean = true) => {
    handleItemSelect()
    if (!toggle) {
      setDetailType({ uid: pnu, dataType: 'land', selectedRegistrationId })
      return
    }

    if (pnu !== detailType?.uid) {
      setDetailType({ uid: pnu, dataType: 'land', selectedRegistrationId })
    } else {
      resetDetailType()
    }
  }

  const selectBoe = (boeTypeCode: string) => {
    handleAreaSelect()
    resetDraw()
    resetRegionCode()
    clearTenantFilters(false)
    setBoeTypeCode(boeTypeCode)
  }

  const drawCircle = async (radius: number, circleLat: number, circleLng: number) => {
    clearTenantFilters(true)
    handleAreaSelect()
    resetRegionCode()
    resetBoeTypeCode()
    setRadius(radius.toString())
    setCircleLat(circleLat.toString())
    setCircleLng(circleLng.toString())
  }

  const clearTenantFilters = (preventResetDraw?: boolean) => {
    if (mapType === 'tenant') {
      resetSearchInfo()
      resetClientIds()
      resetRaIds()
      clearAreaQuery(preventResetDraw)
    }
  }

  const clearCircle = () => {
    setDraw('NONE')
    setDrawControl(undefined)
    resetRadius()
    resetCircleLat()
    resetCircleLng()
  }

  const drawPolygon = async (paths: string) => {
    clearTenantFilters(true)
    handleAreaSelect()
    resetRegionCode()
    resetBoeTypeCode()
    setPaths(paths)
  }

  const clearPolygon = async () => {
    setDraw('NONE')
    setDrawControl(undefined)
    resetPaths()
  }

  const closeLandDetail = () => {
    handleItemSelect()
    resetDetailType()
  }

  const clearAreaQuery = (preventResetDraw?: boolean) => {
    resetRegionCode()
    resetBoeTypeCode()
    if (!preventResetDraw) {
      resetDraw()
    }
    if (isAreaSearch) {
      resetSearchInfo()
    }
  }

  return {
    searchBuilding,
    searchArea,
    searchLand,
    searchSubway,
    searchClient,
    selectBuilding,
    selectLand,
    selectBoe,
    drawCircle,
    clearCircle,
    drawPolygon,
    clearPolygon,
    closeLandDetail,
    clearAreaQuery,
    resetSearch,
  }
}

export default useMapFilterHandler
