import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InquiryTypeSelect from '~/templates/common/inquiry/InquiryTypeSelect'
import InquiryTargetField from '~/templates/common/inquiry/InquiryTargetField'
import { UserInquiryType } from '~/libs/utils/inquiry'
import { GetUsersInquiryResponseInquiry } from '~/libs/apis/service/model'
import LabeledContainer from '~/components/LabeledContainer/LabeledContainer'
import InquiryItemField from '~/templates/common/inquiry/InquiryItemField'
import { useRecoilValue } from 'recoil'
import dataInquiryState from '~/recoil/data-inquiry/atom'

interface FormFieldSetForUserProps {
  data?: GetUsersInquiryResponseInquiry
}

const FormFieldSetForUser: FC<FormFieldSetForUserProps> = ({ data }) => {
  const inquiryInfo = useRecoilValue(dataInquiryState)
  const [inquiryType, setInquiryType] = useState(UserInquiryType['data'])
  const { t: term } = useTranslation('common', { keyPrefix: 'data_inquiry' })

  useEffect(() => {
    if (data?.inquiryType) {
      setInquiryType(data.inquiryType)
    }
  }, [data?.inquiryType])

  return (
    <>
      <InquiryTargetField inquiryType={inquiryType} data={data?.inquiryTarget} />
      <LabeledContainer label={term('field.inquiry_type')}>
        <InquiryTypeSelect defaultValue={inquiryType} onClick={setInquiryType} />
      </LabeledContainer>
      <InquiryItemField inquiryType={inquiryType} inquiryItems={data?.inquiryItems ?? inquiryInfo?.inquiryItems} />
    </>
  )
}

export default FormFieldSetForUser
