import { SizingProps, width, height } from '@xstyled/styled-components'
import { styled } from '~/xstyled.config'

interface Props {
  height?: SizingProps['h']
  width?: SizingProps['w']
}

const Box = styled.divBox<Props>(
  (props) => width({ w: props.width }),
  (props) => height({ h: props.height }),
)

export default Box
