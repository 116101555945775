import { DefaultValue, selector } from 'recoil'
import buildingFilterStateFamily from '~/recoil/building-filter/atom'
import { BuildingsFilterParams, FILTER_KEYS_FROM_UI } from '~/libs/constants/place'
import mapEventFamilyState from '~/recoil/map-event/atom'

const buildingFilterParamState = selector<BuildingsFilterParams>({
  key: 'buildingFilterParamState',
  get: ({ get }) => {
    const filterParams = FILTER_KEYS_FROM_UI.reduce<BuildingsFilterParams>(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: get(buildingFilterStateFamily(currentValue)),
      }),
      {},
    )
    const mapEvent = get(mapEventFamilyState('place'))
    if (mapEvent?.bounds) {
      filterParams.swLat = mapEvent.bounds.sw.lat
      filterParams.swLng = mapEvent.bounds.sw.lng
      filterParams.neLat = mapEvent.bounds.ne.lat
      filterParams.neLng = mapEvent.bounds.ne.lng
    }
    return filterParams
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      FILTER_KEYS_FROM_UI.forEach((key) => set(buildingFilterStateFamily(key), newValue))
    } else {
      Object.entries(newValue).forEach(([key, value]) => {
        if ((FILTER_KEYS_FROM_UI as string[]).includes(key)) {
          set(buildingFilterStateFamily(key as keyof BuildingsFilterParams), value?.toString())
        }
      })
    }
  },
})

export default buildingFilterParamState
