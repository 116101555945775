import { FC, useRef, useState } from 'react'
import StackingPlanHeader from '~/templates/common/view/stacking-plan/StackingPlanHeader'
import Box from '~/components/Box/Box'
import OfficeStackingPlanTables from '~/templates/common/view/stacking-plan/OfficeStackingPlanTables'
import LwhStackingPlanTables from '~/templates/common/view/stacking-plan/LwhStackingPlanTables'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { SwitchCase } from '@toss/react'
import OfficeDongSelector from '~/templates/common/view/stacking-plan/OfficeDongSelector'
import LwhDongSelector from '~/templates/common/view/stacking-plan/LwhDongSelector'
import { useTranslation } from 'react-i18next'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import { Quarter } from '~/libs/apis/data/model'
import Typography from '~/components/Typography/Typography'
import Dropdown from '~/components/Dropdown/Dropdown'
import OfficeInOutLesseeStatus from '~/templates/place/detail/lessee/OfficeInOutLesseeStatus'
import LwhInOutLesseeStatus from '~/templates/place/detail/lessee/LwhInOutLesseeStatus'
import { Option } from '~/components/Dropdown/ListSelect'
import ScrollableTabButtonBox from '~/components/TabButton/ScrollableTabButtonBox'
import useOfficeStackingPlan from '~/templates/common/view/stacking-plan/useOfficeStackingPlan'
import useLwhStackingPlan from '~/templates/common/view/stacking-plan/useLwhStackingPlan'
import TenantDataSource from '~/templates/common/dataSource/TenantDataSource'

const QUARTERS = Object.keys(Quarter)
const QUARTER_COUNT = 4
type YearQuarterType = {
  year: string
  quarter: Quarter
}

const getYearQuarterOptions = (endYear: string, endQuarter: string): (Option & YearQuarterType)[] => {
  const formattedEndYear = Number(endYear !== '' ? endYear : new Date().getFullYear())

  const indexOfEndQuarter = QUARTERS.indexOf(endQuarter)

  return new Array(QUARTER_COUNT).fill(null).map((_, index) => {
    const curQuarterIndex = indexOfEndQuarter - index
    const year = curQuarterIndex > -1 ? formattedEndYear : formattedEndYear - 1
    const quarter = (QUARTERS.at(curQuarterIndex) ?? 'Q1') as Quarter
    return {
      value: `${year}-${quarter}`,
      label: `${year}-${quarter}`,
      year: year.toString(),
      quarter: quarter,
    }
  })
}

const StackingPlan: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'detail.lessee' })
  const { uid, detailContent } = useDetailInfo()
  const [dongName, setDongName] = useState<string>()
  const ref = useRef<HTMLDivElement>(null)

  const { lastUpdate } = useLastUpdateDate()
  const [yearQuarter, setYearQuarter] = useState<YearQuarterType>({
    year: lastUpdate.year,
    quarter: lastUpdate.quarter,
  })
  const params = {
    dongName: dongName,
    ...yearQuarter,
  }
  const isPastDate = params.year && params.quarter ? lastUpdate.yq !== `${params.year}-${params.quarter}` : false

  const {
    stackingPlanResponse: { data: officeStackingPlanList, isLoading: isOfficeStackingPlanLoading },
  } = useOfficeStackingPlan({
    ...params,
    raId: uid,
    enabled: detailContent === 'office',
  })
  const {
    stackingPlanResponse: { data: lwhStackingPlanList, isLoading: isLwhStackingPlanLoading },
  } = useLwhStackingPlan({
    ...params,
    raId: uid,
    enabled: detailContent === 'lwh',
  })
  const stackingPlanArray = detailContent === 'office' ? officeStackingPlanList : lwhStackingPlanList

  const handleYearQuarterChange = (_value?: string, option?: Option) => {
    if (option && 'year' in option && 'quarter' in option) {
      setYearQuarter({
        year: option.year as string,
        quarter: option.quarter as Quarter,
      })
    }
  }

  const handleDongNameSelected = (value: string) => {
    setDongName(value)
  }

  return (
    <Box ref={ref} display="flex" gap={4} flexDirection="column">
      <StackingPlanHeader subTitle={t('stacking_plan_description')} />
      <ScrollableTabButtonBox>
        <SwitchCase
          value={detailContent}
          caseBy={{
            office: (
              <OfficeDongSelector
                raId={uid}
                dongName={dongName}
                onDongSelected={handleDongNameSelected}
                year={yearQuarter.year}
                quarter={yearQuarter.quarter}
              />
            ),
            lwh: (
              <LwhDongSelector
                raId={uid}
                dongName={dongName}
                onDongSelected={handleDongNameSelected}
                year={yearQuarter.year}
                quarter={yearQuarter.quarter}
              />
            ),
          }}
        />
      </ScrollableTabButtonBox>
      <Box padding="20px" display="flex" flexDirection="column" gap="20px" backgroundColor="gray-100">
        <Box display="flex" alignItems="center" gap="16px">
          <Typography fontWeight="600">{t('lessee_change_information')}</Typography>
          <Dropdown
            width="160px"
            value={`${yearQuarter.year}-${yearQuarter.quarter}`}
            onChange={handleYearQuarterChange}
            options={getYearQuarterOptions(lastUpdate.year, lastUpdate.quarter)}
          />
        </Box>
        <SwitchCase
          value={detailContent}
          caseBy={{
            office: <OfficeInOutLesseeStatus raId={uid} {...params} />,
            lwh: <LwhInOutLesseeStatus raId={uid} {...params} />,
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <SwitchCase
          value={detailContent}
          caseBy={{
            office: (
              <OfficeStackingPlanTables
                data={officeStackingPlanList}
                isLoading={isOfficeStackingPlanLoading}
                isPastDate={isPastDate}
              />
            ),
            lwh: (
              <LwhStackingPlanTables
                data={lwhStackingPlanList}
                isLoading={isLwhStackingPlanLoading}
                isPastDate={isPastDate}
              />
            ),
          }}
        />
      </Box>
      <TenantDataSource data={stackingPlanArray} />
    </Box>
  )
}

export default StackingPlan
