import { ButtonComponent } from '@syncfusion/ej2-react-buttons'
import { CSSProperties, FC, MouseEventHandler, ReactNode } from 'react'
import styled from '@xstyled/styled-components'

interface FileSelectButtonProps {
  content?: string | ReactNode
  type?: HTMLButtonElement['type']
  icon?: ReactNode
  iconPosition?: 'left' | 'right'
  onClick?: MouseEventHandler
  width?: CSSProperties['width']
  whitespace?: CSSProperties['whiteSpace']
}

const FileSelectButton: FC<FileSelectButtonProps> = ({ content, icon, iconPosition = 'left', ...rest }) => {
  return (
    <StyledFileSelectButton {...rest}>
      {icon && iconPosition === 'left' && <IconWrapper position={iconPosition}>{icon}</IconWrapper>}
      {content}
      {icon && iconPosition === 'right' && <IconWrapper position={iconPosition}>{icon}</IconWrapper>}
    </StyledFileSelectButton>
  )
}

export default FileSelectButton

const IconWrapper = styled.div<{ position?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  ${({ position }) => {
    return position === 'left' ? `margin-right: 4px;` : `margin-left: 4px;`
  }}
  & > svg {
    width: 10px;
    height: 10px;
  }
`

const StyledFileSelectButton = styled(ButtonComponent)`
  &.e-btn {
    ${({ width }) => `width: ${width};`}
    ${({ whitespace }) => `white-space: ${whitespace};`}
    padding: 4px 10px;
    font-family: var(--font-family);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-200);
    color: var(--color-gray-800);
    border-color: var(--color-gray-300);
    border-radius: 6px;
    font-weight: 400;
    box-shadow: none !important;
    font-size: 12px;
    line-height: 18px;

    &.e-active,
    &:active:not(:disabled) {
      background-color: var(--color-gray-200);
      color: var(--color-gray-800);
      border-color: var(--color-gray-300);
    }
  }
}
`
