import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react'

interface DetailScrollContextProps {
  isTop: boolean
  setIsTop: Dispatch<SetStateAction<boolean>>
}

const defaultValues: DetailScrollContextProps = {
  isTop: true,
  setIsTop: () => {},
}

const DetailScrollContext = createContext<DetailScrollContextProps>(defaultValues)

const DetailScrollProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isTop, setIsTop] = useState(defaultValues.isTop)
  const value = useMemo(() => ({ isTop, setIsTop }), [isTop])

  return <DetailScrollContext.Provider value={value}>{children}</DetailScrollContext.Provider>
}

const useDetailScrollState = () => {
  return useContext(DetailScrollContext)
}

export { useDetailScrollState }

export default DetailScrollProvider
