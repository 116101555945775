import { InputEventArgs, TextBoxComponent, TextBoxModel } from '@syncfusion/ej2-react-inputs'
import { TextBoxCommonType } from '~/components/TextBox/textBoxTypes'
import { forwardRef, useCallback } from 'react'
import TextBoxWrapper from '~/components/TextBox/TextBoxWrapper'
import Box from '../Box/Box'
import ErrorMessage from './ErrorMessage'

type TextBoxType = Pick<
  TextBoxModel,
  | 'value'
  | 'type'
  | 'placeholder'
  | 'change'
  | 'created'
  | 'blur'
  | 'focus'
  | 'readonly'
  | 'enabled'
  | 'htmlAttributes'
  | 'multiline'
  | 'input'
  | 'autocomplete'
>

export interface TextBoxProps extends TextBoxCommonType, TextBoxType {
  maxLength?: number
  isError?: boolean
  errorMessage?: string
}

const TextBox = forwardRef<TextBoxComponent, TextBoxProps>(
  (
    {
      value,
      type,
      placeholder,
      change,
      created,
      blur,
      focus,
      readonly,
      enabled,
      htmlAttributes,
      name,
      multiline,
      input,
      maxLength,
      errorMessageId,
      isError,
      errorMessage,
      autocomplete,
      ...rest
    },
    ref,
  ) => {
    /**
     * handleInput 작업을 하는 이유: html input element의 maxlength 속성이 비영어권 문자의 경우
     * 1개를 초과한 상태로 입력이 가능하며 blur 혹은 공백문자 입력 시에만 maxlength로 수정되는 스펙이기 때문에 해당 작업이 필요함
     */
    const handleInput = useCallback(
      (e: InputEventArgs) => {
        if (e.event && maxLength) {
          const inputEl = e.event.currentTarget as HTMLInputElement | null
          if (inputEl) {
            e.value = e.value?.slice(0, maxLength) || ''
            inputEl.value = e.value
          }
        }

        input?.(e)
      },
      [maxLength, input],
    )

    return (
      <Box width="100%">
        <TextBoxWrapper {...rest}>
          <TextBoxComponent
            ref={ref}
            cssClass={isError ? 'e-error' : undefined}
            {...(!multiline && { type })}
            value={value}
            name={name}
            placeholder={placeholder}
            enabled={enabled}
            change={change}
            created={created}
            blur={blur}
            focus={focus}
            readonly={readonly}
            htmlAttributes={{
              ...htmlAttributes,
              ...(maxLength && { maxlength: maxLength.toString() }),
              ...(errorMessageId && { 'data-msg-containerid': errorMessageId }),
            }}
            multiline={multiline}
            input={handleInput}
            autocomplete={autocomplete}
          />
        </TextBoxWrapper>
        <ErrorMessage id={errorMessageId}>{errorMessage}</ErrorMessage>
      </Box>
    )
  },
)

TextBox.displayName = 'TextBox'

export default TextBox
