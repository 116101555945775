import React, { CSSProperties } from 'react'
import { Box } from '~/components'
import NoDataImage from '~/assets/images/no-data.svg'
import { useTranslation } from 'next-i18next'
import Typography from '~/components/Typography/Typography'
import { ThemeColor } from '@xstyled/styled-components'

interface NoDataProps {
  noDataMessage?: string
  height?: CSSProperties['height']
  backgroundColor?: ThemeColor
  borderRadius?: CSSProperties['borderRadius']
}

const NoData = ({ noDataMessage, height, backgroundColor = 'gray-100', borderRadius = '24px' }: NoDataProps) => {
  const { t } = useTranslation('common')
  const message = noDataMessage ?? t('message.no_data')
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="40px"
      width="100%"
      height={height}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap="24px">
        <NoDataImage width="100px" height="63px" />
        <Typography color="gray-500" fontWeight={600} variant="subtitle">
          {message}
        </Typography>
      </Box>
    </Box>
  )
}

export default NoData
