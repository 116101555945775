import { deleteUsersInterestBuilding, postUserInterestBuilding } from '~/libs/apis/service/api'
import { useTranslation } from 'react-i18next'
import { useToast } from '~/templates/ToastContextProvider'
import { useRef } from 'react'
import type { PostUsersInterestBuildingRequest, PostUsersInterestBuildingResponse } from '~/libs/apis/service/model'
import { datadogRum } from '@datadog/browser-rum'
import { useNextRouter } from '~/libs/hooks/useNextRouter'
import RaServiceError from '~/libs/errors/RaServiceError'
import { SERVICE_ERROR_CODE } from '~/libs/constants/common'

interface InterestBuilding {
  realEstateType: 'BUILDING'
  propertyTypeCode: string
  raId: string
}

interface InterestLand {
  realEstateType: 'LAND'
  pnu: string
}

const useInterestService = () => {
  const { t } = useTranslation()
  const { show } = useToast()
  const isLoading = useRef(false)
  const router = useNextRouter()

  const createToastButtonClickHandler = (interestParam: string) => () => {
    router.push(`/my-page/?selectedTab=interest_buildings&param=${interestParam}`)
  }

  const postInterestBuildingApi = async (
    params: InterestBuilding | InterestLand,
  ): Promise<PostUsersInterestBuildingResponse | undefined> => {
    const requestParam: PostUsersInterestBuildingRequest = {
      realEstateType: params.realEstateType,
    }

    if (params.realEstateType === 'BUILDING') {
      requestParam.raId = params.raId
    } else {
      requestParam.pnu = params.pnu
    }

    return await postUserInterestBuilding(requestParam)
  }

  const deleteInterestBuildingApi = async (interestBuildingId: number): Promise<void | undefined> => {
    return await deleteUsersInterestBuilding(interestBuildingId.toString())
  }

  const addInterest = (params: InterestBuilding | InterestLand, refreshCallback?: () => void) => {
    if (isLoading.current) {
      return
    }

    isLoading.current = true
    return postInterestBuildingApi(params)
      .then((response) => {
        refreshCallback?.()
        show(t('common_msg.interest_building_added'), {
          buttonText: t('common_term.move'),
          onButtonClick: createToastButtonClickHandler(
            params.realEstateType === 'BUILDING' ? params.propertyTypeCode : params.realEstateType,
          ),
        })
        return response
      })
      .catch((error) => {
        if (error instanceof RaServiceError) {
          if (error.responseBody.code === SERVICE_ERROR_CODE.LIMIT_INTEREST) {
            show(t('common_msg.interest_building_overflow'))
          } else {
            show(t('common_term.unknown_error'))
          }
        } else {
          show(t('common_term.unknown_error'))
          datadogRum.addError(error)
        }
        throw error
      })
      .finally(() => (isLoading.current = false))
  }

  const deleteInterest = (interestBuildingId: number, refreshCallback?: () => void) => {
    if (isLoading.current) {
      return
    }

    isLoading.current = true

    return deleteInterestBuildingApi(interestBuildingId)
      .then((response) => {
        refreshCallback?.()
        show(t('common_msg.interest_building_deleted'))
        return response
      })
      .catch((error) => {
        if (error instanceof RaServiceError) {
          show(error.responseBody.message)
        } else {
          show(t('common_term.unknown_error'))
          datadogRum.addError(error)
        }
        throw error
      })
      .finally(() => (isLoading.current = false))
  }

  return {
    addInterest,
    deleteInterest,
  }
}

export default useInterestService
