import { useRecoilValue } from 'recoil'
import buildingFilterDialogSelector from '~/recoil/building-filter/buildingFilterDialogSelector'
import { getSelectedEntriesFiltered } from '~/libs/utils/query'

// 실 활용도는 적으나 추후 검색된 내역을을 선택하는 UI가 나올 가능성이 있어서 hook으로 추출
const useBuildingsFilterQuery = () => {
  const params = useRecoilValue(buildingFilterDialogSelector)
  return getSelectedEntriesFiltered(params)
}

export default useBuildingsFilterQuery
