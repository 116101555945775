import React from 'react'
import { datadogRum } from '@datadog/browser-rum'

interface ErrorBoundaryProps {
  fallback: JSX.Element
  children: JSX.Element
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = { hasError: false }
  }
  static getDerivedStateFromError() {
    return { hasError: true }
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const renderingError = new Error(error.message)
    renderingError.name = `ReactRenderingError`
    renderingError.stack = errorInfo.componentStack ?? 'stack is null'
    renderingError.cause = error

    datadogRum.addError(renderingError)
  }
  render() {
    const { state, props } = this
    if (state.hasError) {
      return props.fallback
    }
    return props.children
  }
}

export default ErrorBoundary
