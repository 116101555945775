import useBuildingsQuery from '~/templates/place/hooks/useBuildingsQuery'
import { buildingsDataV1BuildingsGet, getBuildingsDataV1BuildingsGetKey } from '~/libs/apis/data/buildings/buildings'
import { BuildingsPagingOut } from '~/libs/apis/data/model'
import useSWRInfinite, { SWRInfiniteFetcher } from 'swr/infinite'
import { BUILDINGS_PAGE_SIZE } from '~/libs/constants/place'
import { useRecoilValue } from 'recoil'
import mapContentTypeState from '~/recoil/map-content-type/atom'
import buildingOrderByState from '~/recoil/building-order-by/atom'
import placeDataEnabledState from '~/recoil/place-data-enabled/atom'

const useBuildings = () => {
  const mapContentType = useRecoilValue(mapContentTypeState('place'))
  const orderByGroup = useRecoilValue(buildingOrderByState)
  const placeDataEnabled = useRecoilValue(placeDataEnabledState)
  const params = { ...useBuildingsQuery(), pageSize: BUILDINGS_PAGE_SIZE, orderByGroup: orderByGroup }
  const getKey = (pageIndex: number, previousPageData: BuildingsPagingOut) => {
    if ((previousPageData && !previousPageData.data.length) || mapContentType !== 'card' || !placeDataEnabled) {
      return null
    }
    if (pageIndex === 0) {
      return getBuildingsDataV1BuildingsGetKey(params)
    } else {
      return getBuildingsDataV1BuildingsGetKey({ ...params, pageNum: pageIndex + 1 })
    }
  }
  const fetcher: SWRInfiniteFetcher<BuildingsPagingOut | undefined, typeof getKey> = (key) => {
    return buildingsDataV1BuildingsGet(key[1])
  }

  return useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
  })
}

export default useBuildings
