import { ParsedUrlQuery } from 'querystring'
import { isNotNil, omit } from '@toss/utils'
import { parseDateYmd } from '~/libs/utils/date'
import { PropertyTypeCode } from '~/libs/apis/data/model'

export type QueryParamType = string | string[] | undefined
interface RangeQueryValue {
  min?: string
  max?: string
}

export const isSomeQueryUndefined = (queries: QueryParamType[]) => {
  return queries.some((query) => isQueryUndefined(query))
}

export const isEveryQueryUndefined = (queries: QueryParamType[]) => {
  return queries.every((query) => isQueryUndefined(query))
}

export const isEveryQueryExist = (queries: QueryParamType[]) => {
  return queries.every((query) => !isQueryUndefined(query))
}

export const isQueryExist = (query: QueryParamType, value: string) => {
  const arrayQuery = parseQueryToArray(query)
  return arrayQuery.includes(value)
}

export const isQueryUndefined = (query: QueryParamType): query is undefined => {
  if (Array.isArray(query)) {
    return query.length === 0
  }
  return query === undefined || query === ''
}

export const parseQueryToArray = (query: QueryParamType): string[] => {
  if (isQueryUndefined(query)) {
    return []
  }

  if (Array.isArray(query)) {
    return query
  }

  return query.split(',')
}

export const parseQueryToString = (query: QueryParamType): string => {
  if (isQueryUndefined(query)) {
    return ''
  }

  return query.toString()
}

export const parseQueryToNilStr = (query: QueryParamType): string | undefined => {
  if (isQueryUndefined(query)) {
    return undefined
  }
  return parseQueryToString(query)
}

export const parseQueryToNilNumber = (query: QueryParamType): number | undefined => {
  if (isQueryUndefined(query)) {
    return undefined
  }

  const parseNumber = Number(parseQueryToString(query))
  return !isNaN(parseNumber) ? parseNumber : undefined
}

/* TODO: 파라미터가 날짜 형식의 스트링이 아닌 경우에 대한 대처 추가 */
export const parseQueryToNilDate = (query: QueryParamType): Date | undefined => {
  const stringifiedQuery = parseQueryToString(query)
  if (stringifiedQuery) {
    return parseDateYmd(stringifiedQuery)
  }
}

export const parseQueryToPropertyTypeCode = (query: QueryParamType): PropertyTypeCode | '' => {
  switch (query) {
    case PropertyTypeCode.PT01_1:
      return query
    case PropertyTypeCode.PT01_2:
      return query
    default:
      return ''
  }
}

export const getQueryParamsAllEmpty = (queryKeys: string[]): ParsedUrlQuery => {
  return queryKeys.reduce((a, v) => ({ ...a, [v]: undefined }), {})
}

export const getQueryParam = (key: string, value: QueryParamType): ParsedUrlQuery => {
  return { [key]: value }
}

export const getQueryParamsSomeEmpty = (emptyQueryKey: string[], query: ParsedUrlQuery) => {
  return {
    ...getQueryParamsAllEmpty(emptyQueryKey),
    ...query,
  }
}

/**
 * 쿼리 조작을 호출할때 사용하지 않을 paramter는 제거한다
 * @param params router에서 사용할 object
 */
export const filterQuery = (params: Record<string, any>) => {
  return Object.entries(params).reduce((accumulator: Record<string, any>, [key, value]) => {
    if (isNotNil(value)) {
      accumulator[key] = value
    }
    return accumulator
  }, {})
}

/**
 * prefix만큼 글자를 제거하고 첫글자를 소문자로 변변경
 * @param key 변환될 key
 * @param prefix 제거할 접두사
 */
const toPropertyName = (key: string, prefix: string) => {
  const largeFirstCharacter = key[prefix.length].toLowerCase()
  return largeFirstCharacter + key.substring(prefix.length + 1)
}

/**
 * RangeQueryValue에 기반한 min 값이나 max를 value로 넣어주는 함수
 * @param queryObject filter query
 * @param key query key
 * @param value query param
 * @param prefix min | max
 */
const handleMinMaxQuery = (
  queryObject: Record<string, RangeQueryValue | any>,
  key: string,
  value: any,
  prefix: keyof RangeQueryValue,
) => {
  const propertyKey = toPropertyName(key, prefix)
  queryObject[propertyKey] ??= {} as RangeQueryValue
  queryObject[propertyKey][prefix] = value
}

export const filterQueryWithRange = (params: Record<string, any>) => {
  return Object.entries(params).reduce<Record<string, RangeQueryValue | string | string[]>>(
    (accumulator, [key, value]) => {
      if (isNotNil(value)) {
        if (key.startsWith('min')) {
          handleMinMaxQuery(accumulator, key, value, 'min')
        } else if (key.startsWith('max')) {
          handleMinMaxQuery(accumulator, key, value, 'max')
        } else {
          accumulator[key] = value
        }
      }
      return accumulator
    },
    {},
  )
}

const handleLesseeTypeQuery = (accumulator: Record<string, any>, key: string, value: boolean) => {
  if (!accumulator.lesseeType) {
    accumulator.lesseeType = {}
  }
  accumulator.lesseeType[key] = value
}

const handleNewPermissionStageTypeQuery = (accumulator: Record<string, any>, key: string, value: boolean) => {
  if (!accumulator.newPermissionStage) {
    accumulator.newPermissionStage = {}
  }
  accumulator.newPermissionStage[key] = value
}

export const filterQueryWithLesseeType = (params: Record<string, any>) => {
  return Object.entries(params).reduce<Record<string, boolean | string | string[]>>((accumulator, [key, value]) => {
    if (isNotNil(value)) {
      if (key.endsWith('Qoq') || key.endsWith('Yoy')) {
        handleLesseeTypeQuery(accumulator, key, value)
      } else if (key === 'isPermission' || key === 'isRealStartConstruction') {
        handleNewPermissionStageTypeQuery(accumulator, key, value)
      } else {
        accumulator[key] = value
      }
    }
    return accumulator
  }, {})
}

export const getSelectedEntriesFiltered = (params: Record<string, any>) => {
  const keysToOmit = [
    'rentFeeRateOfChangeType',
    'maintenanceFeeRateOfChangeType',
    'nocRateOfChangeType',
    'emptyRateRateOfChangeType',
    'baseRegistrationReceiptDateYmd',
  ]

  const filteredEntries = omit(params, keysToOmit)
  const filteredWithRange = filterQueryWithRange(filteredEntries)
  return filterQueryWithLesseeType(filteredWithRange)
}

export const toLocaledUrl = (locale: string, url: string) => url.replace(/^\/(en\/|ko\/|\[locale]\/)?/, `/${locale}/`)
