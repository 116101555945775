import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import {
  useDemolishDataV1GovDemolishGet,
  useGetLandUsePlanDataV1GovLandUsePlanGet,
  useGetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGet,
  useGetRealTradeLandsDataV1GovRealTradeLandsGet,
  useLandChangeOwnerDataV1GovLandChangeOwnerGet,
  useLandInfoDataV1GovLandGet,
  useLandPriceDataV1GovLandPriceGet,
  useLedgerFloorDataV1GovLedgerFloorGet,
  useLedgerOutlineDataV1GovLedgerOutlineGet,
  useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet,
  useLedgerPossessionDataV1GovLedgerPossessionGet,
  usePermissionDataV1GovPermissionGet,
  useSbizDataV1GovSbizGet,
} from '~/libs/apis/data/gov/gov'
import { isNotNil } from '@toss/utils'
import { useGetRegistrations, useIsBuilding } from '~/libs/apis/registration/api'
import { useGetLandDetailsDataV1LandsDetailsGet } from '~/libs/apis/data/land/land'
import { useDetailInfo } from '~/templates/DetailTypeProvider'

const pageParam = { pageNum: 1, pageSize: 5 }

const useLandFullDetailLoading = (pnu: string) => {
  const isFull = useDetailInfo().detailType === 'full'
  const { lastUpdate } = useLastUpdateDate()

  const { isLoading: isLandInfoLoading } = useLandInfoDataV1GovLandGet({ pnu }, { swr: { enabled: Boolean(pnu) } })
  const { isLoading: isDetailLoading } = useGetLandDetailsDataV1LandsDetailsGet(
    { pnus: pnu },
    { swr: { enabled: Boolean(pnu) } },
  )

  // 건물
  const { data: ledger, isLoading: isLedgerOutlineLoading } = useLedgerOutlineDataV1GovLedgerOutlineGet({ pnu: pnu })
  const ledgerMgmBldrgstPk = ledger?.data?.[0]?.mgmBldrgstPk
  const ledgerInfoParams = { mgmBldrgstPk: ledgerMgmBldrgstPk!, pageNum: 1, pageSize: 10 }
  const { isLoading: isLedgerOutlineSummaryLoading } = useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet(
    { pnu: pnu },
    { swr: { shouldRetryOnError: false } },
  )
  const { isLoading: isLedgerPossessionLoading } = useLedgerPossessionDataV1GovLedgerPossessionGet(ledgerInfoParams, {
    swr: {
      enabled: isNotNil(ledgerMgmBldrgstPk) && isFull,
    },
  })
  const { isLoading: isLedgerFloorLoading } = useLedgerFloorDataV1GovLedgerFloorGet(ledgerInfoParams, {
    swr: {
      enabled: isNotNil(ledgerMgmBldrgstPk) && isFull,
    },
  })
  const { isLoading: isPermissionLoading } = usePermissionDataV1GovPermissionGet(
    { pnu, ...pageParam, pageSize: 10 },
    {
      swr: {
        enabled: isFull,
      },
    },
  )
  const { isLoading: isDemolishLoading } = useDemolishDataV1GovDemolishGet(
    { pnu, pageNum: 1, pageSize: 10 },
    {
      swr: {
        enabled: isFull,
      },
    },
  )

  // 토지
  const { isLoading: isLandUsePlanLoading } = useGetLandUsePlanDataV1GovLandUsePlanGet(
    { pnu },
    {
      swr: {
        enabled: isNotNil(pnu),
      },
    },
  )
  const { isLoading: isLandChangeOwnerLoading } = useLandChangeOwnerDataV1GovLandChangeOwnerGet(
    { pnu, ...pageParam },
    {
      swr: {
        enabled: isNotNil(pnu),
      },
    },
  )
  const { isLoading: isLandPriceChartLoading } = useLandPriceDataV1GovLandPriceGet(
    {
      pnu,
    },
    {
      swr: {
        enabled: isNotNil(pnu) && isFull,
      },
    },
  )

  // 임대차
  const { isLoading: isSbizLoading } = useSbizDataV1GovSbizGet({
    pnu,
    ...pageParam,
    year: lastUpdate.year,
    quarter: lastUpdate.quarter,
  })

  // 거래
  const { isLoading: isGeneralTradeLoading } = useGetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGet({
    pnu,
    ...pageParam,
  })
  const { isLoading: isLandRealTradeLoading } = useGetRealTradeLandsDataV1GovRealTradeLandsGet(
    {
      pnu,
      ...pageParam,
    },
    {
      swr: {
        keepPreviousData: true,
      },
    },
  )

  // 등기
  const { isLoading: isRegistrationsLoading } = useGetRegistrations(
    { pnu: pnu },
    {
      swr: { enabled: Boolean(pnu), shouldRetryOnError: false },
    },
  )
  const { isLoading: isBuildingLoading } = useIsBuilding({ pnu })

  return (
    isLandInfoLoading ||
    isDetailLoading ||
    isLedgerOutlineLoading ||
    isLedgerOutlineSummaryLoading ||
    isLedgerPossessionLoading ||
    isLedgerFloorLoading ||
    isPermissionLoading ||
    isDemolishLoading ||
    isLandUsePlanLoading ||
    isLandChangeOwnerLoading ||
    isLandPriceChartLoading ||
    isSbizLoading ||
    isGeneralTradeLoading ||
    isLandRealTradeLoading ||
    isRegistrationsLoading ||
    isBuildingLoading
  )
}

export default useLandFullDetailLoading
