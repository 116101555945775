import { FC } from 'react'
import { formatDateYm, parseDateYmd } from '~/libs/utils/date'
import LineChart from '~/components/Chart/LineChart'
import { SeriesModel } from '@syncfusion/ej2-charts'
import { AxisModel } from '@syncfusion/ej2-charts/src/chart/axis/axis-model'
import { useTranslation } from 'next-i18next'
import { lowerCase } from 'lower-case'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import useUnitTransformer from '~/libs/hooks/useUnitTransformer'
import { roundToUnit } from '@toss/utils'
import { LandPriceOut } from '~/libs/apis/data/model'
import { IAxisLabelRenderEventArgs } from '@syncfusion/ej2-react-charts'
import { useAbbreviatedCurrencyUnitFormat, useAreaUnitLabel } from '~/libs/hooks/useFormat'

const primaryXAxis: AxisModel = {
  valueType: 'DateTime',
  intervalType: 'Years',
  edgeLabelPlacement: 'Shift',
}

interface LandPriceChartProps {
  data?: LandPriceOut[]
  isLoading?: boolean
  chartId: string
  height?: number
}

const LandPriceChart: FC<LandPriceChartProps> = ({ data, isLoading, chartId, height = 347 }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: chart } = useTranslation('chart', { keyPrefix: 'label_format' })
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const { unitConverterForClient } = useUnitTransformer()
  const isKrw = currencyUnit === 'KRW'
  const { getAbbreviatedCurrencyUnit } = useAbbreviatedCurrencyUnitFormat()
  const areaUnitLabel = useAreaUnitLabel()

  const datasource = data?.map((value) => ({
    ...value,
    stdDate: parseDateYmd(`${value.stdDateYm}01`),
    yearMonth: formatDateYm(value.stdDateYm),
    pricePerPy: roundToUnit(unitConverterForClient(value.pricePerM2 || 0, 'per_unit'), 1),
  }))

  const axisLabelRender = (args: IAxisLabelRenderEventArgs) => {
    if (args.axis.name === 'primaryYAxis' && isKrw) {
      args.text = `${getAbbreviatedCurrencyUnit(args.value || 0)}/${areaUnitLabel}`
    }
  }

  const series: SeriesModel[] = [
    {
      xName: 'stdDate',
      yName: 'pricePerPy',
      fill: 'var(--color-chart-7)',
      dataSource: datasource,
      name: t('divide_official_land_price'),
      tooltipMappingName: 'yearMonth',
      marker: {
        width: 7,
        height: 7,
        fill: 'var(--color-chart-7)',
        shape: 'Circle',
        visible: true,
        isFilled: true,
      },
    },
  ]

  return (
    <LineChart
      chartId={chartId}
      width="100%"
      height={height}
      isLoading={isLoading}
      primaryXAxis={primaryXAxis}
      series={series}
      axisLabelRender={axisLabelRender}
      primaryYAxis={{
        labelFormat: isKrw ? chart(`won_${lowerCase(areaUnit)}`) : chart(`dollar_${lowerCase(areaUnit)}`),
      }}
    />
  )
}

export default LandPriceChart
