// 오피스 권역 필터
export const OFFICE_BOE_FILTER_CODES = ['BO01_OS1', 'BO01_A01', 'BO01_D01', 'BO01_B01', 'BO01_I01', 'BO01_C01'] as const
export type OfficeBoeFilterCodeType = (typeof OFFICE_BOE_FILTER_CODES)[number]

// 서울전체가 빠진 오피스 권역 필터
export const OFFICE_BOE_FILTER_CODES_WITHOUT_ALL = ['BO01_A01', 'BO01_D01', 'BO01_B01', 'BO01_I01', 'BO01_C01']

// 물류창고 권역 필터
export const LWH_BOE_FILTER_CODES = [
  'BO01_LMT1',
  'BO01_LE01',
  'BO01_LF01',
  'BO01_LB01',
  'BO01_LC01',
  'BO01_LD01',
  'BO01_LA01',
] as const

export type LwhBoeFilterCodeType = (typeof LWH_BOE_FILTER_CODES)[number]

// 수도권 전체가 빠진 수도권 물류창고 권역 필터
export const LWH_BOE_FILTER_CODES_WITHOUT_ALL = [
  'BO01_LE01',
  'BO01_LF01',
  'BO01_LB01',
  'BO01_LC01',
  'BO01_LD01',
  'BO01_LA01',
]

// 전국 물류창고 권역
export const LWH_BOE_FILTER_CODES_NATION_WIDE = [
  'BO01_LE01',
  'BO01_LF01',
  'BO01_LB01',
  'BO01_LC01',
  'BO01_LD01',
  'BO01_LA01',
  'BO01_LI01',
  'BO01_LR01',
  'BO01_LP01',
  'BO01_LJ01',
  'BO01_LH01',
  'BO01_LK01',
  'BO01_LS01',
  'BO01_LL01',
  'BO01_LM01',
  'BO01_LN01',
  'BO01_LO01',
  'BO01_LQ01',
  'BO01_LG01',
  'BO01_LT01',
]

// 비수도권 물류창고 권역
export const LWH_BOE_FILTER_CODES_NON_CAPITAL = [
  'BO01_LI01',
  'BO01_LR01',
  'BO01_LP01',
  'BO01_LJ01',
  'BO01_LH01',
  'BO01_LK01',
  'BO01_LS01',
  'BO01_LL01',
  'BO01_LM01',
  'BO01_LN01',
  'BO01_LO01',
  'BO01_LQ01',
  'BO01_LG01',
  'BO01_LT01',
]

// 오피스 빌딩 규모
export const OFFICE_BUILDING_SIZE_OPTION: Readonly<Record<string, string>> = {
  all: 'BS01_OT',
  extraLarge: 'BS01_O5',
  large: 'BS01_O4',
  mediumLarge: 'BS01_O3',
  medium: 'BS01_O2',
  small: 'BS01_O1',
}

// 물류창고 빌딩 규모
export const LWH_BUILDING_SIZE_OPTION: Readonly<Record<string, string>> = {
  all: 'BS01_LT',
  extraLarge: 'BS01_L4',
  large: 'BS01_L3',
  medium: 'BS01_L2',
  small: 'BS01_L1',
}

// 오피스 시장보고서에서 공급/임대/거래 최소 연도
export const MINIMUM_YEAR_BY_OFFICE_MARKET = {
  supply: '1980',
  lease: '2010',
  trade: '2005',
}

// 물류창고 시장보고서에서 공급/임대/거래 최소 연도
export const MINIMUM_YEAR_BY_LWH_MARKET = {
  supply: '2010',
  lease: '2022',
  trade: '2010',
}

// 오피스권역 정렬
export const OFFICE_BOE_CODE_ORDER: Record<string, number> = {
  BO01_OS1: 0,
  BO01_A01: 1,
  BO01_D01: 2,
  BO01_B01: 3,
  BO01_I01: 4,
  BO01_C01: 5,
}

// 물류창고 권역 정렬
export const LWH_BOE_CODE_ORDER: Record<string, number> = {
  BO01_LMT1: 0,
  BO01_LE01: 1,
  BO01_LF01: 2,
  BO01_LB01: 3,
  BO01_LC01: 4,
  BO01_LD01: 5,
  BO01_LA01: 6,
  BO01_LI01: 7,
  BO01_LR01: 8,
  BO01_LP01: 9,
  BO01_LJ01: 10,
  BO01_LH01: 11,
  BO01_LK01: 12,
  BO01_LS01: 13,
  BO01_LL01: 14,
  BO01_LM01: 15,
  BO01_LN01: 16,
  BO01_LO01: 17,
  BO01_LQ01: 18,
  BO01_LG01: 19,
  BO01_LT01: 20,
}

// 오피스 빌딩 규모 정렬
export const OFFICE_BUILDING_SIZE_ORDER: Record<string, number> = {
  BS01_OT: 0,
  BS01_O5: 1,
  BS01_O4: 2,
  BS01_O3: 3,
  BS01_O2: 4,
  BS01_O1: 5,
}

// 물류창고 빌딩 규모 정렬
export const LWH_BUILDING_SIZE_ORDER: Record<string, number> = {
  BS01_LT: 0,
  BS01_L4: 1,
  BS01_L3: 2,
  BS01_L2: 3,
  BS01_L1: 4,
}

// 필터 범위 값
export const FILTER_RANGE = {
  minRateOfChange: -100,
  maxRateOfChange: 999.99,
  min: 0,
  maxRate: 100,
  maxAge: 99,
  maxYear: 9999,
  maxDistance: 9999,
  maxMinute: 9999,
  maxPrice: 9999999,
  maxArea: 9999999,
}

// 필터 최대 글자 수
export const FILTER_MAX_LENGTH = {
  age: 2,
  year: 4,
  distance: 4,
  minute: 4,
  rate: 6,
  decimalRate: 6,
  price: 7,
  area: 7,
}

// 필터 입력 값 범위
export const FILTER_RANGES = {
  minTotalArea: FILTER_RANGE.min,
  maxTotalArea: FILTER_RANGE.maxArea,
  minConstructDateY: FILTER_RANGE.min,
  maxConstructDateY: FILTER_RANGE.maxYear,
  minRentFeePerM2: FILTER_RANGE.min,
  maxRentFeePerM2: FILTER_RANGE.maxPrice,
  minWalkingDistanceSubway: FILTER_RANGE.min,
  maxWalkingDistanceSubway: FILTER_RANGE.maxDistance,
  minWalkingTimeSubway: FILTER_RANGE.min,
  maxWalkingTimeSubway: FILTER_RANGE.maxMinute,
  minMaintenanceFeePerM2: FILTER_RANGE.min,
  maxMaintenanceFeePerM2: FILTER_RANGE.maxPrice,
  minNocPerM2: FILTER_RANGE.min,
  maxNocPerM2: FILTER_RANGE.maxPrice,
  minEmptyRate: FILTER_RANGE.min,
  maxEmptyRate: FILTER_RANGE.maxRate,
  minEmptyArea: FILTER_RANGE.min,
  maxEmptyArea: FILTER_RANGE.maxArea,
  minTradePricePerM2: FILTER_RANGE.min,
  maxTradePricePerM2: FILTER_RANGE.maxPrice,
  minTradePrice: FILTER_RANGE.min,
  maxTradePrice: FILTER_RANGE.maxPrice,
  minCapRate: FILTER_RANGE.min,
  maxCapRate: FILTER_RANGE.maxRate,
  minTradeArea: FILTER_RANGE.min,
  maxTradeArea: FILTER_RANGE.maxArea,
  minRateOfChange: FILTER_RANGE.minRateOfChange,
  maxRateOfChange: FILTER_RANGE.maxRateOfChange,
  minPlatArea: FILTER_RANGE.min,
  maxPlatArea: FILTER_RANGE.maxArea,
  minArchArea: FILTER_RANGE.min,
  maxArchArea: FILTER_RANGE.maxArea,
  minLandArea: FILTER_RANGE.min,
  maxLandArea: FILTER_RANGE.maxArea,
  minOfficialLandPrice: FILTER_RANGE.min,
  maxOfficialLandPrice: FILTER_RANGE.maxArea,
  minRealTradeDateYm: FILTER_RANGE.min,
  maxRealTradeDateYm: FILTER_RANGE.maxYear,
  minRealTradePrice: FILTER_RANGE.min,
  maxRealTradePrice: FILTER_RANGE.maxPrice,
  minRealTradePricePerTotalNlaArea: FILTER_RANGE.min,
  maxRealTradePricePerTotalNlaArea: FILTER_RANGE.maxPrice,
  minRealTradePricePerLandArea: FILTER_RANGE.min,
  maxRealTradePricePerLandArea: FILTER_RANGE.maxPrice,
  minRentArea: FILTER_RANGE.min,
  maxRentArea: FILTER_RANGE.maxArea,
}
