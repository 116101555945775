import { BuildingsOut, LwhOut, OfficeOut } from '~/libs/apis/data/model'

type BuildingType = BuildingsOut | OfficeOut | LwhOut

export const isEqual = (param1: unknown, param2: unknown): boolean => {
  return param1 == param2
}

// 완공, 미지정
const completionStates = ['CS01_03', 'CS01_98']
// 공급예정 (공사중, 공사예정)
const supplyScheduledStates = ['CS01_01', 'CS01_02']
// 멸실
const demolishState = 'CS01_04'

export const isCompletion = (building: BuildingType) => completionStates.includes(building.constructStatusCode)
export const isSupplyScheduled = (building: BuildingType) =>
  supplyScheduledStates.includes(building.constructStatusCode)
export const isDemolish = (building: BuildingType) => building.constructStatusCode === demolishState
