import {
  Inject,
  Magnification,
  Navigation,
  PdfViewerComponent,
  Print,
  TextSearch,
  TextSelection,
  ThumbnailView,
  Toolbar,
} from '@syncfusion/ej2-react-pdfviewer'
import { buildContext } from '@toss/react'
import { FC, PropsWithChildren, useRef, useState } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { EmitType } from '@syncfusion/ej2-base'
import { BeforeOpenEventArgs, DialogComponent } from '@syncfusion/ej2-react-popups'
import styled from '@xstyled/styled-components'
import { useTranslation } from 'next-i18next'
import IconClose from '~/assets/images/icons/x-graphic.svg'
import Box from '~/components/Box/Box'
import ErrorDialog from '~/components/Dialog/ErrorDialog'
import Typography from '~/components/Typography/Typography'
import { RegistrationOut } from '~/libs/apis/data/model/registrationOut'
import { getRegistrationDownload } from '~/libs/apis/registration/api'
import useR3CommonCode from '~/libs/hooks/useR3CommonCode'
import { formatISODateTimeSeconds } from '~/libs/utils/date'

interface PdfDialogContextProps {
  open: (registration: RegistrationOut) => void
}

const [Context, usePdfDialog] = buildContext<PdfDialogContextProps>('PdfDialogContext', null)

const PdfDialogContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { codeToName } = useR3CommonCode('PROPERTY')
  const ref = useRef<DialogComponent>(null)
  const viewerRef = useRef<PdfViewerComponent>(null)
  const { t } = useTranslation('common', { keyPrefix: 'pdf_viewer' })
  const { t: commonTerm } = useTranslation('common', { keyPrefix: 'common_term' })
  const [isError, setIsError] = useState(false)

  const openDialog = async (registration: RegistrationOut) => {
    ref.current?.show()
    try {
      const downloadInfo = await getRegistrationDownload(registration.registrationId.toString())
      const { registrationRealEstateNumber, registrationCompletedAt, registrationPropertyTypeCode, address } =
        registration
      const fileName = `${registrationRealEstateNumber}_${formatISODateTimeSeconds(registrationCompletedAt)}_${registrationPropertyTypeCode ? codeToName(registrationPropertyTypeCode) : registrationPropertyTypeCode}_${address}.pdf`
      if (viewerRef.current) {
        // @ts-ignore password 없이 호출하는 싱크퓨전의 가이드를 따름
        viewerRef.current.load(downloadInfo.downloadUrl, null)
        viewerRef.current.downloadFileName = fileName
      }
    } catch (error) {
      setIsError(true)
      datadogRum.addError(error)
    }
  }

  const handleClickClose = () => {
    ref.current?.hide()
  }

  const handleBeforeOpen: EmitType<BeforeOpenEventArgs> = (args) => {
    args.maxHeight = '100vh'
  }

  const handleClose = () => {
    viewerRef.current?.unload()
  }

  const handlePdfFailed = () => {
    setIsError(true)
  }

  const handleErrorDialogClose = () => {
    setIsError(false)
    ref.current?.hide()
  }

  const handleDialogDestroyed = () => {
    if (viewerRef.current) {
      viewerRef.current.destroy() // Correct reference to the viewer
    }
  }

  const handleDocumentLoad = () => {
    // TODO: 현재 Pdf뷰어는 생성시점에 검색창의 높이를 결정하는데, 다이얼로그가 렌더링이 되어있지 않아서 높이가 0으로 설정되고 있음.
    // 별로 좋은 방법은 아니나 임시로 element를 찾아서 높이설정을 해주는 방식을 채용함.
    const searchBar = viewerRef.current?.element.getElementsByClassName('e-pv-text-search-bar')[0] as HTMLDivElement
    if (searchBar) {
      // 실제 싱크퓨전 로직과 동일하게 처리
      searchBar.style.top = (viewerRef.current?.toolbarModule.toolbarElement.clientHeight ?? 0) + 'px'
    }
  }

  return (
    <Context open={openDialog}>
      {children}
      <StyledDialog
        ref={ref}
        visible={false}
        width="1200px"
        height="100vh"
        target="#dialog-target"
        position={{ X: 'center', Y: 'top' }}
        beforeOpen={handleBeforeOpen}
        close={handleClose}
        closeOnEscape={false}
        $disableOverlayClose={true}
        isModal
        destroyed={handleDialogDestroyed}
      >
        <Box height="100%" display="flex" flexDirection="column">
          <Box p={5}>
            <Typography variant="h5" fontWeight="bold" color="gray-800">
              {t('title')}
            </Typography>
            <Box position="absolute" top={20} right={20} onClick={handleClickClose} cursor="pointer">
              <IconClose width="16px" height="16px" color="var(--color-gray-700)" />
            </Box>
          </Box>
          <Box flexGrow={1}>
            <PdfViewerComponent
              ref={viewerRef}
              id="pdf-container"
              resourceUrl="https://cdn.syncfusion.com/ej2/26.2.11/dist/ej2-pdfviewer-lib"
              height="100%"
              locale="ko"
              ajaxRequestFailed={handlePdfFailed}
              documentLoadFailed={handlePdfFailed}
              documentLoad={handleDocumentLoad}
              toolbarSettings={{
                showTooltip: true,
                toolbarItems: [
                  'PageNavigationTool',
                  'MagnificationTool',
                  'PanTool',
                  'SelectionTool',
                  'SearchOption',
                  'PrintOption',
                  'DownloadOption',
                ],
              }}
            >
              <Inject
                services={[Toolbar, Navigation, Magnification, ThumbnailView, Print, TextSelection, TextSearch]}
              />
            </PdfViewerComponent>
          </Box>
        </Box>
        <ErrorDialog visible={isError} message={commonTerm('error_title')} onClose={handleErrorDialogClose}>
          <Typography variant="subtitle">{commonTerm('error_message')}</Typography>
        </ErrorDialog>
      </StyledDialog>
    </Context>
  )
}

const StyledDialog = styled(DialogComponent)`
  user-select: none;

  &.e-dialog {
    border: 0;
    border-radius: 0;

    .e-dlg-content {
      padding: 0;
    }
  }
`

export { usePdfDialog }

export default PdfDialogContextProvider
