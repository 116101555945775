import { isNotNil } from '@toss/utils'
import { addQuarters, getQuarter } from 'date-fns'
import { datadogRum } from '@datadog/browser-rum'
import { Quarter } from '~/libs/apis/data/model'
import useDataVersion from '~/libs/hooks/useDataVersion'

type UpdateDate = {
  year: string
  quarter: Quarter
  yq?: string
}

type UpdateDateParams = {
  year: string
  quarter?: Quarter
  month?: string
}

export enum DataId {
  // 국토교통부 공시지가
  OFFICIAL_LAND_PRICE = 7,
  // 국토교통부 건축인허가
  PERMISSION = 9,
  // 국토교통부 실거래가
  REAL_TRADE_PRICE = 11,
  LAST_UPDATED = 28,
}

const getYearAndQuarterFromDate = (date: Date) => {
  return { year: date.getFullYear(), quarter: getQuarter(date) }
}

const useLastUpdateDate = (periodType?: string, dataId: DataId = DataId.LAST_UPDATED) => {
  const { data: version } = useDataVersion()
  const lastUpdatedDateYm = version?.sourceUpdate?.find((data) => data.id === dataId)?.updateDateYm

  let lastUpdate: UpdateDate = {
    year: '',
    quarter: 'Q1',
  }

  let nextUpdate: UpdateDate = {
    year: '',
    quarter: 'Q1',
  }

  let lastUpdateParams: UpdateDateParams = {
    year: '',
    quarter: undefined,
  }

  let nextUpdateParams: UpdateDateParams = {
    year: '',
    quarter: undefined,
  }

  if (isNotNil(lastUpdatedDateYm)) {
    try {
      const lastUpdateDate = new Date(lastUpdatedDateYm)
      const lastUpdateYq = getYearAndQuarterFromDate(lastUpdateDate)
      const nextUpdateYq = getYearAndQuarterFromDate(addQuarters(lastUpdateDate, 1))

      lastUpdate = {
        year: lastUpdateYq.year.toString(),
        quarter: `Q${lastUpdateYq.quarter}` as Quarter,
        yq: `${lastUpdateYq.year}-Q${lastUpdateYq.quarter}`,
      }

      nextUpdate = {
        year: (lastUpdateYq.year + 1).toString(),
        quarter: `Q${nextUpdateYq.quarter}` as Quarter,
        yq: `${nextUpdateYq.year}-Q${nextUpdateYq.quarter}`,
      }

      lastUpdateParams = {
        year: lastUpdate.year,
        quarter: periodType === 'quarter' ? lastUpdate.quarter : undefined,
        month: periodType === 'month' ? `0${lastUpdateDate.getMonth() + 1}`.slice(-2) : undefined,
      }

      nextUpdateParams = {
        year: periodType === 'quarter' ? nextUpdateYq.year.toString() : nextUpdate.year,
        quarter: periodType === 'quarter' ? nextUpdate.quarter : undefined,
        month: periodType === 'month' ? `0${lastUpdateDate.getMonth() + 1}`.slice(-2) : undefined,
      }
    } catch (e) {
      datadogRum.addError(e)
      return { lastUpdate, nextUpdate, lastUpdateParams, nextUpdateParams }
    }
  }
  return { lastUpdate, nextUpdate, lastUpdateParams, nextUpdateParams }
}

export default useLastUpdateDate
