import FullScreenModal from '~/components/Dialog/FullScreenModal'
import Box from '~/components/Box/Box'
import BuildingImage from '~/components/Image/BuildingImage'

interface ImageModalProps {
  imageUrl: string
  onClose: () => void
}

const ImageModal = ({ imageUrl, onClose }: ImageModalProps) => {
  return (
    <FullScreenModal onClose={onClose}>
      <Box height="100%" display="flex" justifyContent="center">
        <BuildingImage
          url={imageUrl}
          width={0}
          height={0}
          style={{ width: 'auto', height: 'auto', objectFit: 'contain' }}
        />
      </Box>
    </FullScreenModal>
  )
}

export default ImageModal
