import { FC, useMemo, useState } from 'react'
import { useGetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGet } from '~/libs/apis/data/gov/gov'
import BuildingTradeGrid from '~/templates/place/detail/trade/BuildingTradeGrid'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import MultiTemplate from '~/components/DataGrid/MultiTemplate'
import { GovRealTradeBuildingsMultiOwnedOut } from '~/libs/apis/data/model'
import { formatDateYmd } from '~/libs/utils/date'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import { useTranslation } from 'next-i18next'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import {
  useAreaFormat,
  useAreaValueAccessor,
  useCurrencyFormat,
  usePerUnitFormat,
  usePerUnitValueAccessor,
} from '~/libs/hooks/useFormat'

const PAGE_SIZE = 5
const BuildingMultiOwnedTradeGrid: FC<{ pnu: string }> = ({ pnu }) => {
  const { t } = useTranslation()
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const isKrw = currencyUnit === 'KRW'
  const areaValueAccessor = useAreaValueAccessor(areaUnit)
  const perUnitValueAccessor = usePerUnitValueAccessor(areaUnit, currencyUnit)

  const currencyFormat = useCurrencyFormat()
  const areaFormatter = useAreaFormat()
  const perUnitFormatter = usePerUnitFormat()

  const [pageNum, setPageNum] = useState(1)
  const { data: buildings } = useGetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGet(
    {
      pnu,
      pageNum,
      pageSize: PAGE_SIZE,
    },
    {
      swr: {
        keepPreviousData: true,
      },
    },
  )
  const handlePageChange = (page: number) => setPageNum(page)

  const summaryColumns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'realTradeType_contractDataYmd',
        headerTemplate: () => (
          <MultiTemplate
            isHeader
            content={[t('common_term.real_trade_type'), t('common_term.deal_date')]}
            textAlign="left"
          />
        ),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 100,
        template: (props: GovRealTradeBuildingsMultiOwnedOut) => (
          <MultiTemplate
            content={[props.realTradeType ?? '-', formatDateYmd(props.contractDateYmd)]}
            textAlign="left"
          />
        ),
      },
      {
        field: 'totalArea_floor',
        headerTemplate: () => (
          <MultiTemplate isHeader content={[t('common_term.nla_area'), t('common_term.floor')]} textAlign="left" />
        ),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 100,
        template: (props: GovRealTradeBuildingsMultiOwnedOut) => (
          <MultiTemplate
            content={[
              areaFormatter(props.nlaArea, { rounded: 'n2' }),
              props.floor ? `${props.floor}${t('common_term.floor')}` : '-',
            ]}
            textAlign="left"
          />
        ),
      },
      {
        field: 'price_pricePerTotalArea',
        headerTemplate: () => (
          <MultiTemplate
            isHeader
            content={[t('common_term.actual_price'), t('common_term.price_per_total_nla_area')]}
            textAlign="left"
          />
        ),
        clipMode: 'EllipsisWithTooltip',
        width: 130,
        template: (props: any) => (
          <MultiTemplate
            content={[
              currencyFormat(props.price, { format: 'eok', rounded: isKrw ? 'n1' : undefined }),
              perUnitFormatter(props.pricePerNlaArea, { format: 'man' }),
            ]}
            textAlign="left"
          />
        ),
      },
    ],
    [areaUnit, currencyUnit, t],
  )

  const fullColumns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'realTradeType',
        headerText: t('common_term.real_trade_type'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 90,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'floor',
        headerText: t('common_term.floor'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 100,
        valueAccessor: (_: any, data: any) => (data.floor ? `${data.floor}${t('common_term.floor')}` : '-'),
      },
      {
        field: 'contractDateYmd',
        headerText: t('common_term.deal_date'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 100,
        valueAccessor: (_: any, data: any) => formatDateYmd(data.contractDateYmd),
      },
      {
        field: 'price',
        headerText: t('common_term.trade_amount'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 100,
        valueAccessor: (_: any, data: any) =>
          currencyFormat(data.price, { format: 'eok', rounded: isKrw ? 'n1' : undefined }),
      },
      {
        field: 'nlaArea',
        headerText: t('common_term.nla_area'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 90,
        valueAccessor: areaValueAccessor({ rounded: 'n2' }),
      },
      {
        field: 'pricePerNlaArea',
        headerText: t('common_term.price_per_total_nla_area'),
        clipMode: 'EllipsisWithTooltip',
        width: 110,
        valueAccessor: perUnitValueAccessor({ format: 'man' }),
      },
      {
        field: 'mainPurpose',
        headerText: t('common_term.main_purpose'),
        clipMode: 'EllipsisWithTooltip',
        width: 70,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'landPurpose',
        headerText: t('common_term.land_purpose'),
        clipMode: 'EllipsisWithTooltip',
        width: 70,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'shareGubun',
        headerText: `${t('common_term.share')}${t('common_term.division')}`,
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 80,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'tradeType',
        headerText: `${t('common_term.trade')}${t('common_term.type')}`,
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 70,
        valueAccessor: useNilableValueAccessor,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areaUnit, currencyUnit, t],
  )

  return (
    <BuildingTradeGrid
      dataSource={buildings?.data}
      pageModel={buildings?.page}
      onPageChange={handlePageChange}
      fullColumns={fullColumns}
      summaryColumns={summaryColumns}
    />
  )
}

export default BuildingMultiOwnedTradeGrid
