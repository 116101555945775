import { FC, useState } from 'react'
import { useTranslation } from 'next-i18next'
import Box from '~/components/Box/Box'
import { useLandChangeOwnerDataV1GovLandChangeOwnerGet } from '~/libs/apis/data/gov/gov'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { formatDateYmd } from '~/libs/utils/date'
import Typography from '~/components/Typography/Typography'
import LoadMoreDataGrid from '~/templates/place/detail/LoadMoreDataGrid'
import { useDetailInfo } from '~/templates/DetailTypeProvider'

interface LandInfoProps {
  pnu: string
}

const PAGE_SIZE = 5

const LandChangeOwner: FC<LandInfoProps> = ({ pnu }) => {
  const { detailType } = useDetailInfo()
  const isFull = detailType === 'full'
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const [pageNum, setPageNum] = useState(1)
  const { data } = useLandChangeOwnerDataV1GovLandChangeOwnerGet(
    { pnu, pageNum, pageSize: PAGE_SIZE },
    {
      swr: {
        keepPreviousData: true,
      },
    },
  )

  const handlePageChange = (page: number) => setPageNum(page)

  const columnModels: ColumnModel[] = [
    {
      field: 'ownerClassName',
      textAlign: 'Left',
      headerText: t('owner_class_name'),
      clipMode: 'EllipsisWithTooltip',
      valueAccessor: (_: any, data: any) => data.ownerClassName ?? '-',
    },
    {
      field: 'numOfSharing',
      textAlign: 'Left',
      headerText: t('number_of_sharing'),
      clipMode: 'EllipsisWithTooltip',
      valueAccessor: (_: any, data: any) => data.numOfSharing ?? '-',
    },
    {
      field: 'changeDateYmd',
      textAlign: 'Left',
      headerText: t('change_date'),
      clipMode: 'EllipsisWithTooltip',
      width: isFull ? 221 : 100,
      valueAccessor: (_, data: any) => formatDateYmd(data.changeDateYmd),
    },
    {
      field: 'changeReason',
      textAlign: 'Left',
      headerText: t('change_reason'),
      clipMode: 'EllipsisWithTooltip',
      width: isFull ? 221 : 89,
      valueAccessor: (_: any, data: any) => data.changeReason ?? '-',
    },
  ]

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box display="flex" flexDirection="column" gap="10px">
        <Typography variant="subtitle" color="gray-800" fontWeight="semibold">
          {t('owner_change_info')}
        </Typography>
        <Typography variant="caption1" color="gray-800" whiteSpace={isFull ? undefined : 'pre-wrap'}>
          {t('owner_change_info_description')}
        </Typography>
      </Box>
      <LoadMoreDataGrid
        columns={columnModels}
        dataSource={data?.data}
        pageModel={data?.page}
        onPageChange={handlePageChange}
        allowSelection={false}
      />
    </Box>
  )
}

export default LandChangeOwner
