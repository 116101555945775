/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.6.0
 */

export type LwhMarketLeaseMetricType = typeof LwhMarketLeaseMetricType[keyof typeof LwhMarketLeaseMetricType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LwhMarketLeaseMetricType = {
  roomRentFee: 'roomRentFee',
  lowRentFee: 'lowRentFee',
  roomEmptyRate: 'roomEmptyRate',
  lowEmptyRate: 'lowEmptyRate',
  roomEmptyRateExcludeNewest: 'roomEmptyRateExcludeNewest',
  lowEmptyRateExcludeNewest: 'lowEmptyRateExcludeNewest',
} as const;
