import { useRecoilValue } from 'recoil'
import { getSelectedEntriesFiltered } from '~/libs/utils/query'
import tenantFilterDialogSelector from '~/recoil/topic-tenant-filter/tenantFilterDialogSelector'

const useTenantsFilterQuery = () => {
  const params = useRecoilValue(tenantFilterDialogSelector)
  return getSelectedEntriesFiltered(params)
}

export default useTenantsFilterQuery
