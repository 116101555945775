import { FC } from 'react'
import Box from '~/components/Box/Box'
import IndicatorItem from '~/assets/images/indicator/indicator-item.svg'
import { motion, Transition, Variants } from 'framer-motion'

interface IndicatorProps {
  loading: boolean
}

const leftAnimationVariant: Variants = {
  initial: {
    position: 'absolute',
    y: 37,
    x: 21,
    opacity: 0,
  },
  target: {
    position: 'absolute',
    y: 37,
    x: 33,
    opacity: 1,
  },
}

const rightAnimationVariant: Variants = {
  initial: {
    position: 'absolute',
    y: 44,
    x: 57,
    opacity: 0,
  },
  target: {
    position: 'absolute',
    y: 44,
    x: 45,
    opacity: 1,
  },
}

const transition: Transition = {
  ease: 'easeInOut',
  duration: 0.6,
  repeat: Infinity,
  repeatType: 'mirror',
  repeatDelay: 0.6,
}

const Indicator: FC<IndicatorProps> = ({ loading }) => {
  return (
    loading && (
      <Box position="absolute" left={0} right={0} top={0} bottom={0} zIndex={999}>
        <Box
          position="absolute"
          left="50%"
          top="50%"
          width="110px"
          height="110px"
          backgroundColor="white-a60"
          borderRadius="12px"
          transform
          translateX="-50%"
          translateY="-50%"
          overflow="hidden"
        >
          <motion.div variants={leftAnimationVariant} initial="initial" animate="target" transition={transition}>
            <IndicatorItem width={32} height={29} />
          </motion.div>
          <motion.div variants={rightAnimationVariant} initial="initial" animate="target" transition={transition}>
            <IndicatorItem width={32} height={29} />
          </motion.div>
        </Box>
      </Box>
    )
  )
}

export default Indicator
