type constructStatus = 'supplyScheduled' | 'supplyCompleted' | 'demolish' | ''
const useConstructStatus = (key?: string) => {
  const getConstructStatus = (status?: string): constructStatus => {
    switch (status) {
      case 'CS01_01':
      case 'CS01_02':
        return 'supplyScheduled'
      case 'CS01_03':
      case 'CS01_98':
        return 'supplyCompleted'
      case 'CS01_04':
        return 'demolish'
      default:
        return ''
    }
  }

  const constructStatus = getConstructStatus(key)
  const isCompletedBuilding = constructStatus === 'supplyCompleted'
  const isScheduledBuilding = constructStatus === 'supplyScheduled'

  return { constructStatus, isCompletedBuilding, isScheduledBuilding }
}

export default useConstructStatus
