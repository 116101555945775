import DataGrid from '~/components/DataGrid/DataGrid'
import { FC, useRef } from 'react'
import { ColumnModel, GridComponent } from '@syncfusion/ej2-react-grids'

interface SkeletonDataGridProps {
  columns: ColumnModel[]
  dataLength: number
}

/**
 * DataGrid를 보여주기 전에 Shimmer Effect가 들어간 DataGrid
 * @param columns 실제로 보여줄 column 정의
 * @param dataLength 로딩중에 보여줄 row의 갯수
 * @constructor
 */
const SkeletonDataGrid: FC<SkeletonDataGridProps> = ({ columns, dataLength }) => {
  const ref = useRef<GridComponent>(null)
  const mockData = new Array(dataLength).fill({})

  const handleDataBound = () => {
    ref.current?.showMaskRow()
  }

  return (
    <DataGrid
      ref={ref}
      dataBound={handleDataBound}
      columns={columns}
      dataSource={mockData}
      loadingIndicator={{ indicatorType: 'Shimmer' }}
    />
  )
}

export default SkeletonDataGrid
