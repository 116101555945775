import { atom, atomFamily } from 'recoil'
import { MapEventParam } from '@mint-ui/map'

export type MapMoveParam = Omit<MapEventParam, 'bounds'> & { needMorph?: boolean }

/**
 * MintMap의 좌표에 영향을 주고 받는 전역 state
 */

const mapMoveParamState = atomFamily<MapMoveParam, MapType>({
  key: 'mapMovePramState',
  default: undefined,
})

export default mapMoveParamState
