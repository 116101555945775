import { EstateType } from '~/libs/constants/common'
import {
  useOfficeDetailDataV1BuildingsOfficeRaIdGet,
  useOfficeLesseeDataV1BuildingsOfficeLesseeGet,
} from '~/libs/apis/data/buildings-office/buildings-office'
import {
  useLwhDetailDataV1BuildingsLwhRaIdGet,
  useLwhLesseeDataV1BuildingsLwhLesseeGet,
} from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'

interface LesseeExistOption {
  existAll: boolean // 전체 임차인 정보 유무
  existQuarter: boolean // 현재 분기
}

const useGetDetailData = (type: EstateType, raId: string) => {
  const officeResponse = useOfficeDetailDataV1BuildingsOfficeRaIdGet(raId, {
    swr: {
      enabled: type === 'office',
    },
  })

  const lwhResponse = useLwhDetailDataV1BuildingsLwhRaIdGet(raId, {
    swr: {
      enabled: type === 'lwh',
    },
  })

  return type === 'office' ? officeResponse : lwhResponse
}

const useGetLessees = (type: EstateType, raId: string) => {
  const { lastUpdateParams } = useLastUpdateDate('quarter')
  const officeResponse = useOfficeLesseeDataV1BuildingsOfficeLesseeGet(
    { raId, year: lastUpdateParams.year, quarter: lastUpdateParams.quarter! },
    {
      swr: { enabled: type === 'office' },
    },
  )

  const lwhResponse = useLwhLesseeDataV1BuildingsLwhLesseeGet(
    { raId, year: lastUpdateParams.year, quarter: lastUpdateParams.quarter! },
    {
      swr: { enabled: type === 'lwh' },
    },
  )

  return type === 'office' ? officeResponse : lwhResponse
}

const useExistLessee = (): LesseeExistOption => {
  const { uid: raId, detailContent: type } = useDetailInfo()
  const { data: detailData } = useGetDetailData(type, raId)
  const { data: currentLesseeData } = useGetLessees(type, raId)

  return {
    existAll: Boolean(detailData?.lessee),
    existQuarter: (currentLesseeData?.data?.length ?? 0) > 0,
  }
}

export default useExistLessee
