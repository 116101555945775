import { css } from 'styled-components'

const toastCss = css`
  .e-toast-container:has(.e-toast.style-guide) {
    transform: translate(-50%, 0);
    gap: 12px;
    align-items: center;
  }

  .e-toast-container .e-toast.style-guide {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.7);
    padding: 12px 24px;
    margin-bottom: 0;
    box-shadow: none;
    border: none;
    width: fit-content !important;

    .e-toast-message {
      flex-direction: row;
      gap: 12px;
      .e-toast-content {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: var(--font-family);
        white-space: pre-wrap;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .e-toast-actions button {
      margin-left: 0;
      padding: 0;
      font-size: 14px;
      line-height: 20px;
      border-radius: 8px;
      box-shadow: none !important;
      font-weight: 700;
      background: none;
      border: none;
      color: var(--color-purple-600);

      &:focus {
        outline: none;
      }
    }
  }
`

export default toastCss
