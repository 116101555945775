import { FC } from 'react'
import { CheckBoxComponent, CheckBoxModel } from '@syncfusion/ej2-react-buttons'
import styled, { css } from '@xstyled/styled-components'
import IconCheck from '~/assets/images/icons/checkn.svg?url'

type CheckboxType = Pick<CheckBoxModel, 'checked' | 'disabled' | 'label' | 'value' | 'name'>

export interface CheckboxProps extends CheckboxType {
  onChange?: CheckBoxModel['change']
  size?: 'lg' | 'md' | 'sm'
  className?: string
}

const getCheckboxSizeClass = (size: string | undefined) => {
  switch (size) {
    case 'lg':
      return 'e-large'
    case 'md':
      return 'e-medium'
    case 'sm':
      return 'e-small'
    default:
      return 'e-medium'
  }
}

const Checkbox: FC<CheckboxProps> = ({ checked, size = 'md', className, onChange, ...props }) => {
  return (
    <Wrapper className={className}>
      <CheckBoxComponent
        cssClass={`style-guide ${getCheckboxSizeClass(size)}`}
        checked={checked}
        change={onChange}
        {...props}
      />
    </Wrapper>
  )
}

export const checkboxCommonStyle = css`
  .e-frame {
    box-shadow: none;
    border: 1px solid var(--color-gray-400);
    &:hover {
      border: 1px solid var(--color-purple-600);
      box-shadow: var(--shadow-purple-01);
    }
  }
  .e-label {
    font-family: var(--font-family);
    font-weight: 600;
    color: var(--color-gray-800);
  }
  .e-check::before {
    content: '';
    display: block;
    mask-image: url(${IconCheck});
    mask-repeat: no-repeat;
    mask-size: cover;
    background: var(--color-system-white);
  }
  .e-frame.e-check {
    border-color: var(--color-purple-600);
    background-color: var(--color-purple-600);
    box-shadow: var(--shadow-purple-01);
  }
`

const Wrapper = styled.div`
  .e-checkbox-wrapper {
    &.style-guide {
      ${checkboxCommonStyle}
    }
    &.style-guide.e-small {
      .e-frame {
        height: 20px;
        width: 20px;
        border-radius: 4px;
      }
      .e-check::before {
        height: 9px;
        width: 12px;
        margin: 4px 3px;
      }
      .e-label {
        font-size: 14px;
        line-height: 20px;
      }
    }
    &.style-guide.e-medium {
      .e-frame {
        height: 24px;
        width: 24px;
        border-radius: 6px;
      }
      .e-check::before {
        height: 10.5px;
        width: 14px;
        margin: 5.5px 4px;
      }
      .e-label {
        font-size: 20px;
        line-height: 30px;
      }
    }
    &.style-guide.e-large {
      .e-frame {
        height: 28px;
        width: 28px;
        border-radius: 8px;
      }
      .e-check::before {
        height: 12px;
        width: 16px;
        margin: 7px 5px;
      }
      .e-label {
        font-size: 24px;
        line-height: 32px;
      }
    }
    &.e-checkbox-disabled {
      .e-frame {
        background-color: var(--color-gray-200);
        border-color: var(--color-gray-400);
        box-shadow: none;
      }
      .e-frame.e-check {
        background-color: var(--color-gray-200);
        border-color: var(--color-gray-400);
        color: var(--color-gray-400);
        box-shadow: none;
        &::before {
          background: var(--color-gray-400);
        }
      }
      .e-label {
        color: var(--color-gray-500);
      }
    }
  }
`

export default Checkbox
