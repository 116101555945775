import { MapMarkerWrapper, Offset } from '@mint-ui/map'
import CurrentBuilding from '~/assets/images/marker/current-building.svg'
import React from 'react'
import { MapMarkerWrapperProps } from '@mint-ui/map/dist/components/mint-map/core/wrapper/MapMarkerWrapper'

interface CurrentBuildingMarkerProps extends MapMarkerWrapperProps {
  width?: number
  height?: number
}

const CurrentBuildingMarker = ({ position, anchor, zIndex, width = 32, height = 40 }: CurrentBuildingMarkerProps) => {
  const offsetToCenter = new Offset(width / 2, height)
  if (!anchor) {
    anchor = offsetToCenter
  }

  return (
    <MapMarkerWrapper position={position} anchor={anchor} zIndex={zIndex}>
      <CurrentBuilding width={width} height={height} />
    </MapMarkerWrapper>
  )
}

export default CurrentBuildingMarker
