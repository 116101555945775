/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.6.0
 */

export type RateOfChangeType = typeof RateOfChangeType[keyof typeof RateOfChangeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RateOfChangeType = {
  year: 'year',
  quart: 'quart',
} as const;
