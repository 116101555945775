import React, { FC, useState } from 'react'
import Box from '~/components/Box/Box'
import { useTranslation } from 'next-i18next'
import {
  useLedgerOutlineDataV1GovLedgerOutlineGet,
  useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet,
} from '~/libs/apis/data/gov/gov'
import LedgerInfo from '~/templates/place/detail/common-data/LedgerInfo'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'
import LedgerSummaryTable from '~/templates/place/detail/common-data/LedgerSummaryTable'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'
import Button from '~/components/Button/Button'
import TabButton from '~/components/TabButton/TabButton'
import ScrollableTabButtonBox from '~/components/TabButton/ScrollableTabButtonBox'
import Typography from '~/components/Typography/Typography'

interface LedgerProps {
  pnu: string
  onClose: () => void
}

const Ledger: FC<LedgerProps> = ({ pnu, onClose }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: msg } = useTranslation('common', { keyPrefix: 'common_msg' })
  const { data: ledger } = useLedgerOutlineDataV1GovLedgerOutlineGet({ pnu: pnu })
  const { data: ledgerSummary } = useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet(
    { pnu: pnu },
    { swr: { shouldRetryOnError: false } },
  )
  const [selected, setSelected] = useState<string>('0')

  if (!ledger?.data) {
    return <SkeletonComponent width="100%" height={500} />
  }

  const hasBuilding = ledger.data.length !== 0

  if (!hasBuilding) {
    return (
      <Box display="flex" justifyContent="center" backgroundColor="gray-100" borderRadius={10} py={10} mb={5}>
        <Typography fontWeight="semibold" color="gray-500">
          {msg('no_building_information')}
        </Typography>
      </Box>
    )
  }

  const ledgerSummaryItem = {
    title: t('total_ledger'),
    content: ledgerSummary && <LedgerSummaryTable data={ledgerSummary} />,
  }

  const ledgerItems = ledger.data.map((data) => ({
    title: data.dongName ? `${t('ledger')}(${data.dongName})` : t('ledger'),
    content: <LedgerInfo key={data.mgmBldrgstPk} data={data} />,
  }))

  const combinedLedgerItems = ledgerSummary ? [ledgerSummaryItem, ...ledgerItems] : [...ledgerItems]

  const handleTabChange = (value: string) => {
    setSelected(value)
  }

  const tabItems = combinedLedgerItems.map((item, index) => ({
    text: item.title,
    value: index.toString(),
  }))

  const selectedContent = combinedLedgerItems[Number(selected)]?.content

  const hasMultipleTabs = combinedLedgerItems.length > 1

  return (
    <Box display="flex" flexDirection="column" gap="24px" pb={6}>
      {hasMultipleTabs ? (
        <>
          <ScrollableTabButtonBox>
            <TabButton items={tabItems} size="sm" onSelect={handleTabChange} selected={selected} />
          </ScrollableTabButtonBox>
          {selectedContent}
        </>
      ) : (
        <LedgerInfo data={ledger.data[0]} />
      )}
      <DataSourceUpdate category="detail.building.ledger" />
      <Button color="gray" content={t('fold')} onClick={onClose} />
    </Box>
  )
}

export default Ledger
