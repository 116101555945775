import Dialog from '~/components/Dialog/Dialog'
import Button from '~/components/Button/Button'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { x } from '@xstyled/styled-components'
import ScrollBox from '~/components/Box/ScrollBox'
import { useTranslation } from 'next-i18next'
import { Separated } from '@toss/react'
import { formatISODateYmd } from '~/libs/utils/date'
import useR3CommonCode from '~/libs/hooks/useR3CommonCode'
import { Dispatch, FC, SetStateAction } from 'react'

export interface RegistrationListItem {
  registrationId: number
  address: string | null
  recordAccessDate: string | null
  propertyTypeCode: string | null
  realEstateNumber: string | null
}

interface SelectRegistrationDialogProps {
  list: RegistrationListItem[]
  visible: boolean
  address: string | null
  clickedIndex: number
  setClickedIndex: Dispatch<SetStateAction<number>>
  onConfirm: (selectedIndex: number) => void
  onClose: () => void
}

const SelectRegistrationDialog: FC<SelectRegistrationDialogProps> = ({
  list,
  visible,
  address,
  clickedIndex,
  setClickedIndex,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation()
  const { codeToName } = useR3CommonCode('PROPERTY')

  const handleClick = (index: number) => () => setClickedIndex(index)
  const handleConfirm = () => {
    onConfirm(clickedIndex)
    onClose()
  }

  return (
    <Dialog
      target="#dialog-target"
      title={t('registration.select')}
      visible={visible}
      width="460px"
      close={onClose}
      footerTemplate={() => (
        <Box display="flex" gap="16px" justifyContent="center">
          <Button
            content={t('common_term.cancel')}
            variant="line"
            color="gray"
            size="lg"
            width="120px"
            onClick={onClose}
          />
          <Button
            color="black"
            content={t('common_term.selection_completed')}
            size="lg"
            width="120px"
            onClick={handleConfirm}
          />
        </Box>
      )}
    >
      <ScrollBox maxHeight="420px" px={5}>
        <Box display="flex" flexDirection="column" py={5} gap={5}>
          <Box display="flex" flexDirection="column" alignItems="center" gap="4px">
            <Typography variant="subtitle" fontWeight="semibold">
              {address ?? '-'}
            </Typography>
            <Typography variant="caption1">{t('registration.select_message')}</Typography>
          </Box>
          <x.ul display="flex" flexDirection="column" gap={2.5}>
            {list.map((item, index) => (
              <x.li
                key={item.registrationId}
                p={5}
                backgroundColor={clickedIndex === index ? 'purple-300' : 'gray-100'}
                color={clickedIndex === index ? 'purple-700' : undefined}
                borderRadius="8px"
                cursor="pointer"
                onClick={handleClick(index)}
              >
                <Typography fontWeight="semibold" mb={2.5} color="inherit" wordBreak="break-all">
                  {item.address}
                </Typography>
                <Box display="flex" alignItems="center" gap={2}>
                  <Separated with={<Box backgroundColor="gray-500" width="1px" height="14px" display="inline-block" />}>
                    <Typography variant="caption1" color="inherit" whiteSpace="nowrap">
                      {t('registration.registration_open_date', {
                        date: formatISODateYmd(item.recordAccessDate),
                      })}
                    </Typography>
                    <Typography variant="caption1" color="inherit" whiteSpace="nowrap">
                      {item.propertyTypeCode && codeToName(item.propertyTypeCode)}
                    </Typography>
                    <Typography variant="caption1" color="inherit" whiteSpace="nowrap">
                      {item.realEstateNumber}
                    </Typography>
                  </Separated>
                </Box>
              </x.li>
            ))}
          </x.ul>
        </Box>
      </ScrollBox>
    </Dialog>
  )
}

export default SelectRegistrationDialog
