import DetailTable, { DetailTableType } from '~/components/Table/DetailTable'
import { useTranslation } from 'next-i18next'
import { OfficeOut } from '~/libs/apis/data/model'
import { formatVacancyRate } from '~/libs/utils/format'
import { isNotNil } from '@toss/utils'
import Typography from '~/components/Typography/Typography'
import Tooltip from '~/components/Tooltip/Tooltip'
import { useAreaFormat, usePerUnitFormat } from '~/libs/hooks/useFormat'
import InfoIcon from '~/assets/images/icons/info.svg'
import { Box } from '~/components'
import { useRef } from 'react'
import { TooltipComponent } from '@syncfusion/ej2-react-popups/src/tooltip'

interface TypicalFloorLeasePricesProps {
  data: TableData
}

type TableData = Pick<
  OfficeOut,
  'rentFeePerM2' | 'depositFeePerM2' | 'maintenanceFeePerM2' | 'nlaRate' | 'nocPerM2' | 'rentArea' | 'nlaArea'
>

const OfficeTypicalFloorLeasePrices = ({ data }: TypicalFloorLeasePricesProps) => {
  const { t } = useTranslation()
  const { rentFeePerM2, depositFeePerM2, maintenanceFeePerM2, nlaRate, nocPerM2, rentArea, nlaArea } = data
  const areaFormatter = useAreaFormat()
  const unitFormatter = usePerUnitFormat()
  const nocRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<TooltipComponent>(null)

  const formatWonPerPy = (num: NullishNumber) => {
    return isNotNil(num) ? unitFormatter(num) : '-'
  }

  const tableData: DetailTableType[] = [
    {
      column: t('common_term.rent_fee'),
      content: formatWonPerPy(rentFeePerM2),
    },
    {
      column: t('common_term.deposit'),
      content: formatWonPerPy(depositFeePerM2),
    },
    {
      column: t('common_term.maintenance_fee'),
      content: formatWonPerPy(maintenanceFeePerM2),
    },
    {
      column: t('common_term.nla_rate'),
      content: formatVacancyRate(nlaRate),
    },
    {
      column: (
        <Box
          display="flex"
          alignItems="center"
          gap="6px"
          onMouseOver={() => tooltipRef?.current?.open(nocRef.current || undefined)}
          onMouseOut={() => tooltipRef?.current?.close()}
        >
          <Typography color="gray-700" as="span">
            {t('common_term.noc')}
          </Typography>
          <Tooltip ref={tooltipRef} content={t('detail.lease.noc_tooltip')} position="TopCenter" align="left">
            <Box ref={nocRef}>
              <InfoIcon className="noc" width="12px" height="12px" color="var(--color-gray-500)" />
            </Box>
          </Tooltip>
        </Box>
      ),
      content: formatWonPerPy(nocPerM2),
    },
    {
      column: t('common_term.rent_area'),
      content: areaFormatter(rentArea, { rounded: 'n2' }),
    },
    {
      column: t('common_term.nla_area'),
      content: areaFormatter(nlaArea, { rounded: 'n2' }),
    },
  ]

  return <DetailTable data={tableData} />
}

export default OfficeTypicalFloorLeasePrices
