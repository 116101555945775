import { simpleStorageHandler, StorageHandler } from '@piotr-cz/swr-idb-cache'

type StoreObject = {
  value: any
  dataVersion: string
}

const CACHE_KEYS = ['/data/v1/area/boe', '/data/v1/area/region']

const commonDataStorageHandler: StorageHandler = {
  ...simpleStorageHandler,
  initialize(database, storeName) {
    const objectStore = database.createObjectStore(storeName)
    objectStore.createIndex('dataVersion', 'dataVersion')
  },
  revive: (key, storeObject: StoreObject) => {
    const storedVersion = storeObject.dataVersion
    const currentVersion = localStorage.getItem('dataVersion')
    return storedVersion === currentVersion ? storeObject.value : undefined
  },
  replace: (key, value) => {
    return CACHE_KEYS.some((cacheKey) => key.includes(cacheKey))
      ? { value, dataVersion: localStorage.getItem('dataVersion') }
      : undefined
  },
}

export default commonDataStorageHandler
