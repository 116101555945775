import { atomFamily } from 'recoil'

type MapContentType = 'card' | 'table' | 'map' | 'landing'

/**
 * 지도탐색에서 정보를 어떻게 표현할지 결정하는 atom
 */
const mapContentTypeState = atomFamily<MapContentType, MapType>({
  key: 'mapContentTypeState',
  default: 'landing',
})

export default mapContentTypeState
