/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * rsquare-analytics registration API description
 * rsquare-analytics registration API description
 * OpenAPI spec version: 0.1.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  ExceptionResponse,
  GetBuildingInfo,
  GetBusinessPartnersOpenCountResponse,
  GetChangeEventsParams,
  GetChangeEventsResponse,
  GetErrorCodesResponse,
  GetR3CommonCodeParams,
  GetR3CommonCodeResponse,
  GetRegistrationDownloadResponse,
  GetRegistrationOwnershipInfoParams,
  GetRegistrationOwnershipInfosResponse,
  GetRegistrationTradesParams,
  GetRegistrationTradesResponse,
  GetRegistrationsParams,
  GetRegistrationsResponse,
  GetUsersBusinessPartnerResponse,
  GetVersionResponse,
  IsBuildingParams,
  PatchBusinessPartnersOpenCountRequest,
  PatchBusinessPartnersOpenCountResponse
} from './model'
import { raApiCall } from '../../utils/customInstance';
import { customFormData } from '../../utils/customFormData';



  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * 에러 코드 조회
 * @summary 에러 코드 조회
 */
export const getErrorCodes = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetErrorCodesResponse>(
      {url: `/registration/error-codes`, method: 'GET'
    },
      options);
    }
  

export const getGetErrorCodesKey = () => [`/registration/error-codes`] as const;

    
export type GetErrorCodesQueryResult = NonNullable<Awaited<ReturnType<typeof getErrorCodes>>>
export type GetErrorCodesQueryError = unknown

/**
 * @summary 에러 코드 조회
 */
export const useGetErrorCodes = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getErrorCodes>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetErrorCodesKey() : null);
  const swrFn = () => getErrorCodes(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 버전 체크
 * @summary 버전 체크
 */
export const getVersion = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetVersionResponse>(
      {url: `/registration/version`, method: 'GET'
    },
      options);
    }
  

export const getGetVersionKey = () => [`/registration/version`] as const;

    
export type GetVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getVersion>>>
export type GetVersionQueryError = unknown

/**
 * @summary 버전 체크
 */
export const useGetVersion = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getVersion>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetVersionKey() : null);
  const swrFn = () => getVersion(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 등기 변동정보 조회
 * @summary 등기 변동정보 조회
 */
export const getChangeEvents = (
    params: GetChangeEventsParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetChangeEventsResponse>(
      {url: `/registration/v1/change-events`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetChangeEventsKey = (params: GetChangeEventsParams,) => [`/registration/v1/change-events`, ...(params ? [params]: [])] as const;

    
export type GetChangeEventsQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeEvents>>>
export type GetChangeEventsQueryError = unknown

/**
 * @summary 등기 변동정보 조회
 */
export const useGetChangeEvents = <TError = unknown>(
 params: GetChangeEventsParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getChangeEvents>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetChangeEventsKey(params) : null);
  const swrFn = () => getChangeEvents(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 등기관련 공동코드 조회
 * @summary 등기관련 공동코드 조회
 */
export const getR3CommonCode = (
    params?: GetR3CommonCodeParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetR3CommonCodeResponse>(
      {url: `/registration/v1/r3-common-code`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetR3CommonCodeKey = (params?: GetR3CommonCodeParams,) => [`/registration/v1/r3-common-code`, ...(params ? [params]: [])] as const;

    
export type GetR3CommonCodeQueryResult = NonNullable<Awaited<ReturnType<typeof getR3CommonCode>>>
export type GetR3CommonCodeQueryError = unknown

/**
 * @summary 등기관련 공동코드 조회
 */
export const useGetR3CommonCode = <TError = unknown>(
 params?: GetR3CommonCodeParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getR3CommonCode>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetR3CommonCodeKey(params) : null);
  const swrFn = () => getR3CommonCode(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 기열람 등기 목록 조회
 * @summary 기열람 등기 목록 조회
 */
export const getRegistrations = (
    params: GetRegistrationsParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetRegistrationsResponse>(
      {url: `/registration/v1/registrations`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationsKey = (params: GetRegistrationsParams,) => [`/registration/v1/registrations`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationsQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrations>>>
export type GetRegistrationsQueryError = unknown

/**
 * @summary 기열람 등기 목록 조회
 */
export const useGetRegistrations = <TError = unknown>(
 params: GetRegistrationsParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrations>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationsKey(params) : null);
  const swrFn = () => getRegistrations(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 등기 관리 건물 여부 조회
 * @summary 등기 관리 건물 여부 조회
 */
export const isBuilding = (
    params: IsBuildingParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetBuildingInfo>(
      {url: `/registration/v1/registrations/building-info`, method: 'GET',
        params
    },
      options);
    }
  

export const getIsBuildingKey = (params: IsBuildingParams,) => [`/registration/v1/registrations/building-info`, ...(params ? [params]: [])] as const;

    
export type IsBuildingQueryResult = NonNullable<Awaited<ReturnType<typeof isBuilding>>>
export type IsBuildingQueryError = unknown

/**
 * @summary 등기 관리 건물 여부 조회
 */
export const useIsBuilding = <TError = unknown>(
 params: IsBuildingParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof isBuilding>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getIsBuildingKey(params) : null);
  const swrFn = () => isBuilding(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 회원 거래처 조회
 * @summary 회원 거래처 조회
 */
export const getUsersBusinessPartner = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUsersBusinessPartnerResponse>(
      {url: `/registration/v1/users/business-partner`, method: 'GET'
    },
      options);
    }
  

export const getGetUsersBusinessPartnerKey = () => [`/registration/v1/users/business-partner`] as const;

    
export type GetUsersBusinessPartnerQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersBusinessPartner>>>
export type GetUsersBusinessPartnerQueryError = unknown

/**
 * @summary 회원 거래처 조회
 */
export const useGetUsersBusinessPartner = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUsersBusinessPartner>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUsersBusinessPartnerKey() : null);
  const swrFn = () => getUsersBusinessPartner(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 기열람 등기 PDF 다운로드
 * @summary 기열람 등기 PDF 다운로드
 */
export const getRegistrationDownload = (
    registrationId: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetRegistrationDownloadResponse>(
      {url: `/registration/v1/registrations/${registrationId}/download`, method: 'GET'
    },
      options);
    }
  

export const getGetRegistrationDownloadKey = (registrationId: string,) => [`/registration/v1/registrations/${registrationId}/download`] as const;

    
export type GetRegistrationDownloadQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationDownload>>>
export type GetRegistrationDownloadQueryError = unknown

/**
 * @summary 기열람 등기 PDF 다운로드
 */
export const useGetRegistrationDownload = <TError = unknown>(
 registrationId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationDownload>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(registrationId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationDownloadKey(registrationId) : null);
  const swrFn = () => getRegistrationDownload(registrationId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 등기 소유지분현황 조회
 * @summary 등기 소유지분현황 조회
 */
export const getRegistrationOwnershipInfo = (
    registrationId: string,
    params?: GetRegistrationOwnershipInfoParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetRegistrationOwnershipInfosResponse>(
      {url: `/registration/v1/registrations/${registrationId}/ownership-infos`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationOwnershipInfoKey = (registrationId: string,
    params?: GetRegistrationOwnershipInfoParams,) => [`/registration/v1/registrations/${registrationId}/ownership-infos`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationOwnershipInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationOwnershipInfo>>>
export type GetRegistrationOwnershipInfoQueryError = unknown

/**
 * @summary 등기 소유지분현황 조회
 */
export const useGetRegistrationOwnershipInfo = <TError = unknown>(
 registrationId: string,
    params?: GetRegistrationOwnershipInfoParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationOwnershipInfo>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(registrationId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationOwnershipInfoKey(registrationId,params) : null);
  const swrFn = () => getRegistrationOwnershipInfo(registrationId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 등기 매매 목록 조회
 * @summary 등기 매매 목록 조회
 */
export const getRegistrationTrades = (
    registrationId: string,
    params?: GetRegistrationTradesParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetRegistrationTradesResponse>(
      {url: `/registration/v1/registrations/${registrationId}/trades`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationTradesKey = (registrationId: string,
    params?: GetRegistrationTradesParams,) => [`/registration/v1/registrations/${registrationId}/trades`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationTradesQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationTrades>>>
export type GetRegistrationTradesQueryError = unknown

/**
 * @summary 등기 매매 목록 조회
 */
export const useGetRegistrationTrades = <TError = unknown>(
 registrationId: string,
    params?: GetRegistrationTradesParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationTrades>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(registrationId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationTradesKey(registrationId,params) : null);
  const swrFn = () => getRegistrationTrades(registrationId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 거래처 등기 열람권 사용 횟수 조회
 * @summary 거래처 등기 열람권 사용 횟수 조회
 */
export const getBusinessPartnersOpenCount = (
    raBusinessPartnerId: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetBusinessPartnersOpenCountResponse>(
      {url: `/registration/r3/v1/business-partners/${raBusinessPartnerId}/open-count`, method: 'GET'
    },
      options);
    }
  

export const getGetBusinessPartnersOpenCountKey = (raBusinessPartnerId: string,) => [`/registration/r3/v1/business-partners/${raBusinessPartnerId}/open-count`] as const;

    
export type GetBusinessPartnersOpenCountQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessPartnersOpenCount>>>
export type GetBusinessPartnersOpenCountQueryError = ExceptionResponse

/**
 * @summary 거래처 등기 열람권 사용 횟수 조회
 */
export const useGetBusinessPartnersOpenCount = <TError = ExceptionResponse>(
 raBusinessPartnerId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getBusinessPartnersOpenCount>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raBusinessPartnerId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetBusinessPartnersOpenCountKey(raBusinessPartnerId) : null);
  const swrFn = () => getBusinessPartnersOpenCount(raBusinessPartnerId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 거래처 등기 열람권 사용 횟수 증가/감소
 * @summary 거래처 등기 열람권 사용 횟수 증가/감소
 */
export const patchBusinessPartnersOpenCount = (
    raBusinessPartnerId: string,
    patchBusinessPartnersOpenCountRequest: PatchBusinessPartnersOpenCountRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PatchBusinessPartnersOpenCountResponse>(
      {url: `/registration/r3/v1/business-partners/${raBusinessPartnerId}/open-count`, method: 'PATCH',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: patchBusinessPartnersOpenCountRequest
    },
      options);
    }
  


