import { useNextRouter } from '~/libs/hooks/useNextRouter'
import { useCallback } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { useSetRecoilState } from 'recoil'
import mapMoveParamState, { MapMoveParam } from '~/recoil/map-move/atom'

/**
 * 지도 현재 위치를 갱신시키는데 도와주는 hook
 */
const useMapMover = (mapType: MapType = 'place'): ((param: MapMoveParam) => void) => {
  const router = useNextRouter()
  const setMapEventState = useSetRecoilState(mapMoveParamState(mapType))

  return useCallback(
    ({ center, zoomLevel, needMorph = true }: MapMoveParam) => {
      if (!center || !zoomLevel) {
        datadogRum.addError(new Error('map param is undefined'))
        return
      }
      setMapEventState({ center, zoomLevel, needMorph })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.query],
  )
}

export default useMapMover
