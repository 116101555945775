import { FC } from 'react'
import { useToast } from '~/templates/ToastContextProvider'
import { ToastComponent, ToastModel } from '@syncfusion/ej2-react-notifications'

const ANIMATION: ToastModel['animation'] = {
  show: {
    effect: 'FadeZoomIn',
  },
  hide: {
    effect: 'FadeZoomOut',
  },
}

const TIME_OUT = 3000

const POSITION = { Y: 40, X: '50%' }
const Toast: FC = () => {
  const { ref } = useToast()

  return (
    <ToastComponent
      target="#dialog-target"
      ref={ref}
      animation={ANIMATION}
      timeOut={TIME_OUT}
      position={POSITION}
      cssClass="style-guide"
    />
  )
}

export default Toast
