import Router, { NextRouter, useRouter } from 'next/router'
import { toLocaledUrl } from '~/libs/utils/query'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import { useCallback } from 'react'

interface Options {
  suspense?: boolean
}

type RouterFunc = NextRouter['push'] | NextRouter['replace']

function waitForRouterReady() {
  return new Promise<void>((resolve) => {
    Router.ready(resolve)
  })
}

export function useNextRouter(options: Options = { suspense: true }) {
  const router = useRouter()
  const { language } = useUserConfiguration()

  const handleLocaleRoute = useCallback(
    (routerFunc: RouterFunc): RouterFunc => {
      return (url, as, options) => {
        const locale = language === 'KOREAN' ? 'ko' : 'en'
        // as에 대한 처리가 필요할 경우 동일한 로직 추가 필요
        if (typeof url === 'string') {
          return routerFunc(toLocaledUrl(locale, url), as, options)
        } else {
          if (url.pathname) {
            url.pathname = toLocaledUrl(locale, url.pathname)
          }
          return routerFunc(url, as, options)
        }
      }
    },
    [language],
  )

  if (options.suspense && !router.isReady) {
    throw waitForRouterReady()
  }

  return { ...router, push: handleLocaleRoute(router.push), replace: handleLocaleRoute(router.replace) }
}
