import { ITheme, Theme } from '@xstyled/styled-components'
import { style, SystemProp } from '@xstyled/system'
import * as CSS from 'csstype'

export interface LineClampProps<T extends ITheme = Theme> {
  lineClamp?: SystemProp<CSS.Property.LineClamp, T>
}

const lineClamp = style<LineClampProps>({
  prop: 'lineClamp',
  css: (lineClampValue) => ({
    '&': {
      display: '-webkit-box',
      '-webkit-line-clamp': lineClampValue,
      '-webkit-box-orient': 'vertical',
    },
  }),
})

export default lineClamp
