import { Box } from '~/components'
import { FC } from 'react'
import Tooltip from '~/components/Tooltip/Tooltip'
import { useTranslation } from 'next-i18next'
import { EmitType } from '@syncfusion/ej2-base'
import { TooltipEventArgs } from '@syncfusion/ej2-popups/src/tooltip/tooltip'
import useHasPermission from '~/libs/hooks/useHasPermission'
import { PagePermission } from '~/libs/constants/permission'
import { useNextRouter } from '~/libs/hooks/useNextRouter'
import Typography from '~/components/Typography/Typography'
import { useBooleanState } from '@toss/react'
import SubMenuItem from '~/templates/SubMenuItem'

export interface SubMenu {
  text: string
  url: string
}

interface TempMenuItemProps {
  text: string
  url: string
  subMenu?: SubMenu[]
}

const MenuItem: FC<TempMenuItemProps> = ({ text, url, subMenu }) => {
  const router = useNextRouter()
  const hasPermission = useHasPermission(PagePermission[url])
  const { t } = useTranslation('common', { keyPrefix: 'no_permission' })
  const isActive = router.route.startsWith(url)
  const [isHover, setHover, setLeave] = useBooleanState()

  const handleBeforeOpen: EmitType<TooltipEventArgs> = (event) => {
    if (hasPermission) {
      event.cancel = true
    }
  }
  const handleClick = () => {
    let linkedUrl = url
    if (linkedUrl === '/[locale]/topic') {
      linkedUrl = '/[locale]/topic/lease-product'
    }
    if (!subMenu && hasPermission) {
      return router.push(linkedUrl)
    }
  }

  const getColor = () => {
    if (!hasPermission) {
      return 'gray-500'
    } else if (isActive || isHover) {
      return 'purple-700'
    }
    return 'gray-700'
  }

  return (
    <Box
      position="relative"
      onClick={handleClick}
      onMouseEnter={setHover}
      onMouseLeave={setLeave}
      padding="18px 16px"
      userSelect="none"
      style={{ cursor: 'pointer' }}
    >
      <Tooltip
        content={t('has_no_permission_tooltip')}
        position="BottomCenter"
        align="left"
        beforeOpen={handleBeforeOpen}
      >
        <Typography color={getColor()} variant="subtitle" fontWeight={isActive ? 'bold' : 'semibold'}>
          {text}
        </Typography>
        {hasPermission && isHover && subMenu && (
          <Box
            position="absolute"
            left={0}
            right={0}
            width="85px"
            margin="10px auto"
            borderRadius="8px"
            backgroundColor="system-white"
            style={{ boxShadow: 'var(--shadow-black-04)', overflow: 'hidden' }}
          >
            {subMenu.map((menu) => {
              return <SubMenuItem key={menu.url} item={menu} />
            })}
          </Box>
        )}
      </Tooltip>
    </Box>
  )
}

export default MenuItem
