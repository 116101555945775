import { ColumnModel } from '@syncfusion/ej2-react-grids'
import { isNotNil } from '@toss/utils'
import { useTranslation } from 'next-i18next'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import { formatVacancyRate } from '~/libs/utils/format'
import DataGrid from '~/components/DataGrid/DataGrid'
import { OfficeLeaseMarketTrendValueType, SchemasBuildingsOfficeLeaseMarketTrendOut } from '~/libs/apis/data/model'
import { ComparisonCategories } from '~/libs/hooks/useNamesForComparison'
import { usePerUnitValueAccessor } from '~/libs/hooks/useFormat'
import RateChange from '~/templates/common/content/RateChange'
import { getValueForRateChange } from '~/libs/utils/number'
import { useCallback, useMemo } from 'react'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import { EmptyRateArray } from '~/libs/constants/common'

interface OfficeMarketLeasePriceGridData {
  category: string | null
  latestValue: NullishNumber
  previousQuarterValue: NullishNumber
  previousYearValue: NullishNumber
}

interface OfficeMarketLeasePriceComparisonGridProps {
  type: OfficeLeaseMarketTrendValueType
  data: SchemasBuildingsOfficeLeaseMarketTrendOut[] | undefined
  names: Record<ComparisonCategories, string>
}

const OfficeMarketLeasePriceComparisonGrid = ({ type, data, names }: OfficeMarketLeasePriceComparisonGridProps) => {
  const { detailType } = useDetailInfo()
  const { t } = useTranslation()
  const { lastUpdate } = useLastUpdateDate()
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const perUnitValueAccessor = usePerUnitValueAccessor(areaUnit, currencyUnit)
  const isEmptyRate = EmptyRateArray.includes(type)
  const rateChangeKey = isEmptyRate ? 'value' : 'rate'

  const qoqTemplate = useCallback(
    (props: any) => (
      <RateChange
        value={getValueForRateChange(props.previousQuarterValue, props.latestValue, rateChangeKey)}
        unit={isEmptyRate ? '%p' : '%'}
        variant="body"
        fontWeight="regular"
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  const yoyTemplate = useCallback(
    (props: any) => (
      <RateChange
        value={getValueForRateChange(props.previousYearValue, props.latestValue, rateChangeKey)}
        unit={isEmptyRate ? '%p' : '%'}
        variant="body"
        fontWeight="regular"
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  const columns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'category',
        headerText: '',
        clipMode: 'EllipsisWithTooltip',
      },
      {
        headerText: lastUpdate.yq,
        clipMode: 'EllipsisWithTooltip',
        field: 'latestValue',
        valueAccessor: isEmptyRate ? (_, data: any) => formatVacancyRate(data.latestValue) : perUnitValueAccessor(),
      },
      {
        headerText: t('common_term.qoq'),
        clipMode: 'EllipsisWithTooltip',
        visible: detailType === 'full',
        template: qoqTemplate,
      },
      {
        headerText: t('common_term.yoy'),
        clipMode: 'EllipsisWithTooltip',
        template: yoyTemplate,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, data, areaUnit, currencyUnit],
  )

  const latestDataIndex = data?.findIndex((item) => item.dateYq === lastUpdate.year + lastUpdate.quarter) ?? -1
  const latestData = data?.at(latestDataIndex)
  const previousQuarterData = data?.at(latestDataIndex - 1)
  const previousYearData = data?.at(latestDataIndex - 4)

  const dataSourceForGrid: OfficeMarketLeasePriceGridData[] = isNotNil(latestData)
    ? [
        {
          category: names.self,
          latestValue: latestData.officeValue,
          previousQuarterValue: previousQuarterData?.officeValue,
          previousYearValue: previousYearData?.officeValue,
        },
        {
          category: names.boeSize,
          latestValue: latestData.boeSizeValue,
          previousQuarterValue: previousQuarterData?.boeSizeValue,
          previousYearValue: previousYearData?.boeSizeValue,
        },
        {
          category: names.boe,
          latestValue: latestData.boeValue,
          previousQuarterValue: previousQuarterData?.boeValue,
          previousYearValue: previousYearData?.boeValue,
        },
      ]
    : []

  return <DataGrid dataSource={dataSourceForGrid} columns={columns} allowPaging={false} allowSelection={false} />
}

export default OfficeMarketLeasePriceComparisonGrid
