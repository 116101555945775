import { EventCallback, EventParamType, useMintMapController } from '@mint-ui/map'
import { useEffect, useState } from 'react'

const useZoomLevel = () => {
  const controller = useMintMapController()
  const [zoomLevel, setZoomLevel] = useState(controller.getZoomLevel())

  useEffect(() => {
    const handleZoomChanged: EventCallback<EventParamType> = ({ param }) => {
      setZoomLevel(param.zoomLevel)
    }
    if (controller !== null) {
      controller.addEventListener('ZOOM_CHANGED', handleZoomChanged)
    }
    return () => {
      if (controller !== null) {
        controller.removeEventListener('ZOOM_CHANGED', handleZoomChanged)
      }
    }
  }, [controller])

  return zoomLevel
}

export default useZoomLevel
