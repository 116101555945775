import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import LwhMarketLeasePriceComparisonContent from '~/templates/place/detail/lease/lwh/LwhMarketLeasePriceComparisonContent'
import TabButton from '~/components/TabButton/TabButton'
import { SwitchCase } from '@toss/react'
import { LwhLeaseMarketTrendValueType } from '~/libs/apis/data/model'
import Box from '~/components/Box/Box'
import Checkbox from '~/components/Checkbox/Checkbox'
import Tooltip from '~/components/Tooltip/Tooltip'
import InfoIcon from '~/assets/images/icons/info.svg'

const LwhMarketLeasePriceComparison = () => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<LwhLeaseMarketTrendValueType>('rentFee')
  const [checked, setChecked] = useState<boolean>(true)
  const tabItems = [
    { text: t('common_term.rent_fee'), value: 'rentFee' },
    {
      text: t('common_term.maintenance_fee'),
      value: 'maintenanceFee',
    },
    { text: t('common_term.vacancy_rate'), value: 'emptyRate' },
  ]

  const handleSelected = (value: string) => {
    setSelected(value as LwhLeaseMarketTrendValueType)
  }

  return (
    <>
      <TabButton items={tabItems} size="sm" onSelect={handleSelected} selected={selected} />
      {selected === 'emptyRate' && (
        <Box display="flex" alignItems="center" gap="6px">
          <Checkbox
            size="sm"
            label={t('common:common_term:empty_rate_newest')}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <Tooltip content={t('common_msg.empty_rate_lwh_notice')} align="left">
            <InfoIcon width="14px" height="14px" color="var(--color-gray-500)" />
          </Tooltip>
        </Box>
      )}

      <SwitchCase
        caseBy={{
          rentFee: <LwhMarketLeasePriceComparisonContent type="rentFee" />,
          maintenanceFee: <LwhMarketLeasePriceComparisonContent type="maintenanceFee" />,
          emptyRate: <LwhMarketLeasePriceComparisonContent type={checked ? 'emptyRate' : 'emptyRateExcludeNewest'} />,
        }}
        value={selected}
      />
    </>
  )
}

export default LwhMarketLeasePriceComparison
