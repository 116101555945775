import styled, { useTheme } from '@xstyled/styled-components'
import { IconMinus, IconPlus } from '~/assets/images/icons'
import { useMintMapController } from '@mint-ui/map'
import useZoomLevel from '~/libs/hooks/useZoomLevel'
import { MAX_ZOOM_LEVEL, MIN_ZOOM_LEVEL } from '~/libs/constants/place'
import { Box } from '~/components'
import { FC } from 'react'

interface ZoomControlProps {
  disabled?: boolean
  minZoomLevel?: number
  maxZoomLevel?: number
}

const ZoomControl: FC<ZoomControlProps> = ({
  disabled = false,
  maxZoomLevel = MAX_ZOOM_LEVEL,
  minZoomLevel = MIN_ZOOM_LEVEL,
}) => {
  const theme = useTheme()
  const zoomLevel = useZoomLevel()
  const controller = useMintMapController()
  const isPlusDisabled = zoomLevel >= maxZoomLevel || disabled
  const isMinusDisabled = zoomLevel <= minZoomLevel || disabled

  const handleIncreaseZoom = () => {
    controller.setZoomLevel(controller.getZoomLevel() + 1)
  }

  const handleDecreaseZoom = () => {
    controller.setZoomLevel(controller.getZoomLevel() - 1)
  }

  return (
    <Box display="flex" flexDirection="column" borderRadius="8px" overflow="hidden" boxShadow="black-06">
      <ZoomButton disabled={isPlusDisabled} onClick={handleIncreaseZoom}>
        <IconPlus width={18} color={isPlusDisabled ? theme.colors['gray-500'] : theme.colors['gray-800']} />
      </ZoomButton>
      <ZoomButton disabled={isMinusDisabled} onClick={handleDecreaseZoom}>
        <IconMinus width={18} color={isMinusDisabled ? theme.colors['gray-500'] : theme.colors['gray-800']} />
      </ZoomButton>
    </Box>
  )
}

const ZoomButton = styled.button<{ disabled: boolean }>`
  position: relative;
  cursor: pointer;
  width: 42px;
  height: 42px;
  background: var(--color-system-white);

  :not(:last-of-type) {
    border-bottom: 1px solid var(--color-gray-300);
  }

  svg {
    margin: auto;
  }

  &:focus {
    outline: none;
  }

  ${({ disabled }) => {
    if (!disabled) {
      return ''
    }
    return 'background-color: white; cursor: not-allowed;'
  }}
`

export default ZoomControl
