import LineChart from '~/components/Chart/LineChart'
import { OfficeLeaseMarketTrendValueType, SchemasBuildingsOfficeLeaseMarketTrendOut } from '~/libs/apis/data/model'
import { formatDateYq, parseDateYq } from '~/libs/utils/date'
import { SeriesModel } from '@syncfusion/ej2-charts'
import { commonCircleMarkerSettings, commonEmptyPointSettings } from '~/libs/constants/chart'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { ComparisonCategories } from '~/libs/hooks/useNamesForComparison'
import PeriodFilter, { PeriodType } from '~/templates/common/filter/PeriodFilter'
import { useTranslation } from 'next-i18next'
import { lowerCase } from 'lower-case'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import { toOnesNumber, toVacancyRateNumber } from '~/libs/utils/number'
import useUnitTransformer from '~/libs/hooks/useUnitTransformer'
import { isNotNil } from '@toss/utils'

interface MarketLeasePriceComparisonChartProps {
  type: OfficeLeaseMarketTrendValueType
  data: SchemasBuildingsOfficeLeaseMarketTrendOut[] | undefined
  names: Record<ComparisonCategories, string>
  onPeriodChange: (newPeriod: PeriodType) => void
}

interface LegendType {
  category: ComparisonCategories
  color: string
  dataKey: string
}

interface MarketLeasePriceData {
  dateYq: Date
  name: string | null
  value: NullishNumber | NullishString
  yearQuarter: string
}

const MarketLeasePriceComparisonChart = ({
  type,
  data,
  names,
  onPeriodChange,
}: MarketLeasePriceComparisonChartProps) => {
  const { t } = useTranslation('chart', { keyPrefix: 'label_format' })
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const isKrw = currencyUnit === 'KRW'
  const currencyUnitInString = isKrw ? 'won' : 'dollar'
  const { detailType } = useDetailInfo()
  const { unitConverterForClient } = useUnitTransformer()
  const isFull = detailType === 'full'
  const isEmptyRate = type === 'emptyRate' || type === 'emptyRateExcludeNewest'

  if (!data) {
    return null
  }

  const legend: LegendType[] = [
    {
      category: 'self',
      color: 'var(--color-chart-7)',
      dataKey: 'office',
    },
    {
      category: 'boeSize',
      color: 'var(--color-chart-8)',
      dataKey: 'boeSize',
    },
    {
      category: 'boe',
      color: 'var(--color-chart-9)',
      dataKey: 'boe',
    },
  ]

  const formatValue = (value: number) => {
    if (isEmptyRate) {
      return toVacancyRateNumber(value)
    }
    return toOnesNumber(unitConverterForClient(value, 'per_unit'))
  }

  const dataSourceForChart = legend.reduce(
    (result, legendItem) => {
      result[legendItem.category as ComparisonCategories] = data?.map((item) => {
        const key = `${legendItem.dataKey}Value` as keyof SchemasBuildingsOfficeLeaseMarketTrendOut
        return {
          dateYq: parseDateYq(item.dateYq),
          name: names[legendItem.category],
          value: isNotNil(item[key]) ? formatValue(item[key] as number) : null,
          yearQuarter: formatDateYq(item.dateYq),
        }
      })
      return result
    },
    {} as Record<ComparisonCategories, MarketLeasePriceData[]>,
  )

  const series: SeriesModel[] = legend.map((legendItem) => ({
    xName: 'dateYq',
    yName: 'value',
    fill: legendItem.color,
    dataSource: dataSourceForChart[legendItem.category],
    name: dataSourceForChart[legendItem.category][0].name ?? legendItem.category,
    tooltipMappingName: 'yearQuarter',
    marker: commonCircleMarkerSettings,
    emptyPointSettings: commonEmptyPointSettings,
  }))

  return (
    <>
      {isFull && (
        <PeriodFilter
          type="office"
          marketType="lease"
          onClick={onPeriodChange}
          showTitle={true}
          showAllPeriod={false}
          defaultValue="5"
        />
      )}
      <LineChart
        chartId={`MarketLeasePriceComparison_${detailType}`}
        height={347}
        primaryXAxis={{ valueType: 'DateTime', intervalType: 'Years' }}
        primaryYAxis={{
          labelFormat: isEmptyRate ? t('percent') : t(`${currencyUnitInString}_${lowerCase(areaUnit)}`),
        }}
        series={series}
      />
    </>
  )
}

export default MarketLeasePriceComparisonChart
