import { useCommonCodesDataV1CommonCodesGet } from '~/libs/apis/data/default/default'
import { CommonCodesOut } from '~/libs/apis/data/model'
import { SWR_IMMUTABLE_OPTION } from '~/libs/constants/common'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'

interface CommonCodeObject {
  codeToName: (code: string) => string | undefined
  upperCodeToCodes: (upperCode: string) => string[] | undefined
  filterCodes: (codes: string[]) => CommonCodesOut[] | undefined
}

const useCommonCode = (): CommonCodeObject => {
  const { data } = useCommonCodesDataV1CommonCodesGet(SWR_IMMUTABLE_OPTION)
  const { language } = useUserConfiguration()

  const codeToName = (code: string) => {
    const found = data?.find((commonCode) => commonCode.code === code)
    switch (language) {
      case 'KOREAN':
        return found?.codeName
      case 'ENGLISH':
        return found?.codeEngName
    }
  }

  const upperCodeToCodes = (upperCode: string) => {
    const codes = data
      ?.filter((commonCode) => commonCode.upperCode === upperCode)
      .map((filteredCode) => filteredCode.code)

    if (!codes) {
      return undefined
    }
    return codes.length < 10 ? codes : codes.toSorted(compareNumbersInCodes)
  }

  const filterCodes = (codes: string[]) => data?.filter((commonCode) => codes.includes(commonCode.code))
  return {
    codeToName,
    upperCodeToCodes,
    filterCodes,
  }
}

/**
 * 공통코드 배열을 code의 숫자 값을 기준으로 오름차순 정렬해 리턴하는 함수
 *
 * @remarks
 * /data/v1/common-codes는 응답을 code 필드를 기준으로 정렬하는데, code는 string 타입입니다.
 * 따라서 두 자리 이상 숫자가 포함된 경우 의도와 다르게 정렬됩니다. e.g.) CODE_1, CODE_10, CODE_2, CODE_3, ...
 * 이 경우 code의 숫자 값을 기준으로 배열을 정렬하기 위한 함수입니다.
 */
const compareNumbersInCodes = (codeA: string, codeB: string) => {
  const numberInA = Number(codeA.split('_')[1])
  const numberInB = Number(codeB.split('_')[1])

  return numberInA - numberInB
}

export default useCommonCode
