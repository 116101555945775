import { useRecoilValue } from 'recoil'
import { getSelectedEntriesFiltered } from '~/libs/utils/query'
import registrationFilterDialogSelector from '~/recoil/topic-registration-filter/registrationFilterDialogSelector'

const useRegistrationsFilterQuery = () => {
  const params = useRecoilValue(registrationFilterDialogSelector)
  return getSelectedEntriesFiltered(params)
}

export default useRegistrationsFilterQuery
