import { FC, useEffect } from 'react'
import TabButton from '~/components/TabButton/TabButton'
import useOfficeStackingPlan from '~/templates/common/view/stacking-plan/useOfficeStackingPlan'
import StackingPlanParams from '~/templates/common/view/stacking-plan/StackingPlanParams'

interface OfficeDongSelectorProps extends StackingPlanParams {
  onDongSelected: (value: string) => void
}

const OfficeDongSelector: FC<OfficeDongSelectorProps> = ({ raId, year, quarter, dongName, onDongSelected }) => {
  const {
    dongResponse: { data },
  } = useOfficeStackingPlan({ raId, year, quarter, dongName })
  useEffect(() => {
    if (dongName) {
      return
    }

    const firstDongName = data?.at(0)?.dongName
    if (firstDongName) {
      onDongSelected(firstDongName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (!data || data.length === 0) {
    return null
  }

  const items = data.map((item) => ({ text: item.dongName ?? '-', value: item.dongName ?? '' }))
  return <TabButton items={items} selected={dongName ?? undefined} onSelect={onDongSelected} />
}

export default OfficeDongSelector
