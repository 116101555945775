import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import DetailTable, { DetailTableType } from '~/components/Table/DetailTable'
import { formatVacancyRate } from '~/libs/utils/format'
import { formatDateYmd } from '~/libs/utils/date'
import { useAreaFormat } from '~/libs/hooks/useFormat'
import { LedgerOutlineOut, LedgerOutlineSummaryOut } from '~/libs/apis/data/model'

interface LedgerInfoTableProps {
  data?: LedgerOutlineSummaryOut | LedgerOutlineOut
}

const LedgerInfoTable: FC<LedgerInfoTableProps> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const areaFormat = useAreaFormat()

  const tableData: DetailTableType[] = [
    { column: t('main_purpose'), content: data?.mainPurpose },
    { column: t('use_approval_date'), content: formatDateYmd(data?.useAprDateYmd) },
    {
      column: t('plat_area'),
      content: areaFormat(data?.platArea, { rounded: 'n2' }),
    },
    {
      column: t('architecture_area'),
      content: areaFormat(data?.archArea, { rounded: 'n2' }),
    },
    { column: t('bc_rat'), content: formatVacancyRate(data?.bcRat) },
    {
      column: t('gra_py'),
      content: areaFormat(data?.totalArea, { rounded: 'n2' }),
    },
    { column: t('vl_rat'), content: formatVacancyRate(data?.vlRat) },
  ]

  return <DetailTable data={tableData} />
}
export default LedgerInfoTable
