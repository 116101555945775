import { PostUsersSignInResponse } from '~/libs/apis/service/model'

export function setAuthData(data: PostUsersSignInResponse) {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('auth', JSON.stringify(data))
  }
}

export async function truncateAuthData() {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('auth')
  }
}

export function getAuthData(): PostUsersSignInResponse | undefined {
  if (typeof window !== 'undefined') {
    const str = window.localStorage.getItem('auth')
    if (str !== null) {
      return JSON.parse(str) as PostUsersSignInResponse
    }
  }

  return undefined
}
