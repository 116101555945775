import { DialogComponent } from '@syncfusion/ej2-react-popups'
import { useStorageState } from '@toss/react'
import styled from '@xstyled/styled-components'
import { addDays, isBefore } from 'date-fns'
import { ComponentProps, FC, PropsWithChildren, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '~/components/Box/Box'
import Button from '~/components/Button/Button'
import { commonDialogCss } from '~/components/Dialog/dialogStyle'
import { GetNoticesResponseItem } from '~/libs/apis/service/model'

interface NoticeDialogProps {
  storageKey: string
  notice: GetNoticesResponseItem | undefined
  onOpen?: ComponentProps<typeof DialogComponent>['open']
}

type NoticeSkip = {
  id: number
  skipUntil?: number
}

const NoticeDialog: FC<PropsWithChildren<NoticeDialogProps>> = ({ storageKey, notice, onOpen, children }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const dialogRef = useRef<DialogComponent>(null)
  const [skip, setSkip] = useStorageState<NoticeSkip>(storageKey, { defaultValue: undefined })

  const handleClickSkipButton = () => {
    if (notice) {
      setSkip({ id: notice.noticeId })
    }
    dialogRef.current?.hide()
  }

  const handleClickSkipOneDayButton = () => {
    if (notice) {
      setSkip({ id: notice.noticeId, skipUntil: addDays(new Date(), 1).getTime() })
    }
    dialogRef.current?.hide()
  }

  const handleClickCloseButton = () => {
    dialogRef.current?.hide()
  }

  const footerTemplate = () => (
    <Box display="flex" justifyContent="center" gap={4}>
      {notice?.hideOptions === 'ONE_DAY' && (
        <Button
          size="lg"
          width="132px"
          variant="line"
          color="gray"
          content={t('skip_one_day')}
          onClick={handleClickSkipOneDayButton}
        />
      )}
      {notice?.hideOptions === 'NEVER' && (
        <Button
          size="lg"
          width="132px"
          variant="line"
          color="gray"
          content={t('skip_dialog')}
          onClick={handleClickSkipButton}
        />
      )}
      <Button size="lg" width="138px" content={t('close')} onClick={handleClickCloseButton} />
    </Box>
  )

  useEffect(() => {
    if (notice) {
      if (skip?.id === notice.noticeId) {
        if (!skip.skipUntil) {
          return
        }

        const skipUntil = new Date(skip.skipUntil)
        if (isBefore(new Date(), skipUntil)) {
          return
        }
      }
      dialogRef.current?.show()
    }
  }, [notice])

  return (
    <StyledDialog
      ref={dialogRef}
      target="#dialog-target"
      visible={false}
      footerTemplate={footerTemplate}
      isModal
      open={onOpen}
    >
      {children}
    </StyledDialog>
  )
}

const StyledDialog = styled(DialogComponent)`
  ${commonDialogCss}
  & {
    overflow: hidden;

    .e-dlg-content {
      padding: 0;
      background-color: var(--system-white);
    }
  }
`

export default NoticeDialog
