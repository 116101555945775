/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.6.0
 */

export type RealTradeLandPurposeGroup = typeof RealTradeLandPurposeGroup[keyof typeof RealTradeLandPurposeGroup];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RealTradeLandPurposeGroup = {
  주거지역: '주거지역',
  상업지역: '상업지역',
  공업지역: '공업지역',
  녹지지역: '녹지지역',
  관리지역: '관리지역',
  농림지역: '농림지역',
  자연환경: '자연환경',
} as const;
