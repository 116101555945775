import { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { DialogComponent } from '@syncfusion/ej2-react-popups'
import FullDetailDialog from '~/templates/FullDetailDialog'
import { noop } from '@toss/utils'
import { useForceUpdate } from '@toss/react'
import { useRouter } from 'next/router'
import { Arguments } from 'swr'
import ClientDetailDialog from '~/templates/ClientDetailDialog'
import DetailScrollProvider from '~/templates/DetailScrollProvider'
import PermitDetailDialog from '~/templates/PermitDetailDialog'

export type LandingContent =
  | 'ledger'
  | 'permission'
  | 'demolish'
  | 'stackingPlan'
  | 'land'
  | 'rental'
  | 'lessee'
  | 'trade'
  | 'registration'
export type DetailContent = 'office' | 'lwh' | 'land'

interface FullDetailDialogUtils {
  showOffice: (raId: string, landingContent?: LandingContent, globalSwrKeys?: Arguments[]) => void
  showLwh: (raId: string, landingContent?: LandingContent, globalSwrKeys?: Arguments[]) => void
  showLand: (
    pnu: string,
    landingContent?: LandingContent,
    globalSwrKeys?: Arguments[],
    selectedRegistrationId?: number,
  ) => void
  updateDialog: (uid?: string, newContent?: DetailContent) => void // reference 로 관리되는 상세정보 때문에 렌더링이 일어나지 않을 경우, 강제 리렌더를 실행시키기 위해
  hide: () => void
  openPermitDialog: (pk: string, pnu?: string | null) => void
  closePermitDialog: () => void
  openClientDialog: (clientId: string) => void
  closeClientDialog: () => void
}

const FullDetailContext = createContext<FullDetailDialogUtils>({
  showOffice: noop,
  showLwh: noop,
  showLand: noop,
  updateDialog: noop,
  hide: noop,
  openPermitDialog: noop,
  closePermitDialog: noop,
  openClientDialog: noop,
  closeClientDialog: noop,
})

const FullDetailContextProvider = ({ children }: { children: ReactNode }) => {
  const update = useForceUpdate()
  const router = useRouter()

  const dialogRef = useRef<DialogComponent>(null)
  const uid = useRef<string>('')
  const targetId = useRef<number>()
  const landing = useRef<LandingContent>()
  const content = useRef<DetailContent>('office')
  const mgmPmsrgstPk = useRef<string | null>(null)
  const pnu = useRef<string | null>(null)
  const globalSwrKeys = useRef<Arguments[]>()
  const clientDialogRef = useRef<DialogComponent>(null)
  const permitDialogRef = useRef<DialogComponent>(null)
  const [clientId, setClientId] = useState<string>('')

  const hideDialog = () => {
    uid.current = ''
    targetId.current = undefined
    landing.current = undefined
    globalSwrKeys.current = undefined
    dialogRef?.current?.hide()
  }

  useEffect(() => {
    const handleRouterChangeStart = () => {
      hideDialog()
      contextValue.closeClientDialog()
      contextValue.closePermitDialog()
    }
    router.events.on('routeChangeStart', handleRouterChangeStart)
    return () => {
      router.events.off('routeChangeStart', handleRouterChangeStart)
    }
  }, [router])

  const contextValue = useMemo(
    () => ({
      showOffice: (raId: string, landingContent?: LandingContent, swrKey?: Arguments[]) => {
        uid.current = raId
        content.current = 'office'
        landing.current = landingContent
        globalSwrKeys.current = swrKey
        dialogRef?.current?.show()
      },
      showLwh: (raId: string, landingContent?: LandingContent, swrKey?: Arguments[]) => {
        uid.current = raId
        content.current = 'lwh'
        landing.current = landingContent
        globalSwrKeys.current = swrKey
        dialogRef?.current?.show()
      },
      showLand: (
        pnu: string,
        landingContent?: LandingContent,
        swrKey?: Arguments[],
        selectedRegistrationId?: number,
      ) => {
        uid.current = pnu
        targetId.current = selectedRegistrationId
        content.current = 'land'
        landing.current = landingContent
        globalSwrKeys.current = swrKey
        dialogRef?.current?.show()
      },
      updateDialog: (newUid?: string, newContent?: DetailContent) => {
        if (newUid) {
          uid.current = newUid
        }
        if (newContent) {
          content.current = newContent
        }
        update()
      },
      hide: hideDialog,
      openPermitDialog: (pk: string, selectedPnu?: string | null) => {
        mgmPmsrgstPk.current = pk
        permitDialogRef.current?.show()
        if (selectedPnu) {
          pnu.current = selectedPnu
        }
      },
      closePermitDialog: () => {
        mgmPmsrgstPk.current = null
        pnu.current = null
        permitDialogRef.current?.hide()
      },
      openClientDialog: (clientId: string) => {
        setClientId(clientId)
        clientDialogRef.current?.show()
      },
      closeClientDialog: () => {
        if (clientId) {
          setClientId('')
        }
        clientDialogRef.current?.hide()
      },
    }),
    [mgmPmsrgstPk, clientId, update],
  )

  return (
    <FullDetailContext.Provider value={contextValue}>
      {children}
      <FullDetailDialog
        ref={dialogRef}
        uid={uid}
        targetId={targetId}
        content={content}
        landing={landing}
        globalSwrKeys={globalSwrKeys}
      />
      <PermitDetailDialog ref={permitDialogRef} mgmPmsrgstPk={mgmPmsrgstPk} pnu={pnu} />
      <DetailScrollProvider>
        <ClientDetailDialog ref={clientDialogRef} clientId={clientId} />
      </DetailScrollProvider>
    </FullDetailContext.Provider>
  )
}

const useFullDetailDialog = (): FullDetailDialogUtils => {
  return useContext(FullDetailContext)
}

export { useFullDetailDialog }

export default FullDetailContextProvider
