import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '~/components/Dialog/Dialog'
import { Box } from '~/components'
import Button from '~/components/Button/Button'
import InquiryForm from '~/templates/common/inquiry/InquiryForm'
import ConfirmationDialog from '~/components/Dialog/ConfirmationDialog'
import { useInquiryDialog } from '~/templates/InquiryContextProvider'
import useInquiryControl from '~/libs/hooks/useInquiryControl'
import { useBooleanState, useForceUpdate } from '@toss/react'
import { useRecoilValue } from 'recoil'
import dataInquiryState from '~/recoil/data-inquiry/atom'
import SystemCancelIcon from '~/assets/images/icons/circle-close-line.svg'
import SystemSuucessIcon from '~/assets/images/icons/system/system-success.svg'
import ScrollBox from '~/components/Box/ScrollBox'
import useInquiryValidation from '~/libs/hooks/useInquiryValidation'
import { useGetUserMe } from '~/libs/apis/service/api'

const InquiryDialog: FC = () => {
  const ref = useInquiryDialog()
  const { data: user } = useGetUserMe()
  const { t: term } = useTranslation('common', { keyPrefix: 'data_inquiry' })
  const { t: commonTerm } = useTranslation('common', { keyPrefix: 'common_term' })

  const [open, openDialog, hideDialog] = useBooleanState(false)
  const inquiryInfo = useRecoilValue(dataInquiryState)
  const {
    ref: validatorRef,
    validStatus,
    onInit,
    onValidate,
    onReset,
    isEditChecked,
  } = useInquiryValidation(inquiryInfo?.isGuest ? 'guest' : 'new')

  const { closeDialog } = useInquiryControl()

  const [closeConfirmVisible, setCloseConfirmVisible] = useState(false)
  const [successConfirmVisible, setSuccessConfirmVisible] = useState(false)

  const isSubmitEnabled = Object.values(validStatus).every((val) => val)

  const update = useForceUpdate()

  useEffect(() => {
    update()
    return () => {
      onReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current?.visible])

  useEffect(() => {
    if (validatorRef?.current && user?.phone && open) {
      validatorRef?.current.validate()
    }
  }, [open, validatorRef?.current, user?.phone])

  const handleCloseButtonClick = () => {
    if (isEditChecked()) {
      setCloseConfirmVisible(true)
    } else {
      closeDialog()
    }
  }
  const handleCloseConfirmClose = (confirmed: boolean | undefined) => {
    setCloseConfirmVisible(false)
    if (confirmed) {
      closeDialog()
    }
  }

  const handleSuccessModalClose = () => {
    setSuccessConfirmVisible(false)
  }

  const handleSubmitSuccess = () => {
    closeDialog()
    setSuccessConfirmVisible(true)
  }

  const footerTemplate = () => (
    <Box display="flex" justifyContent="center" gap="10px">
      <Button
        content={commonTerm('cancel')}
        variant="line"
        color="gray"
        width="120px"
        size="lg"
        onClick={handleCloseButtonClick}
      />
      <Button
        disabled={!isSubmitEnabled}
        content={term('on_inquiry')}
        type="submit"
        form="data-inquiry-form"
        width="120px"
        size="lg"
      />
    </Box>
  )

  return (
    <>
      <Dialog
        ref={ref}
        title={term('header')}
        id="data-inquiry-dialog"
        target="#dialog-target"
        width="600px"
        visible={false}
        beforeOpen={openDialog}
        close={hideDialog}
        disableOverlayClose={true}
        footerTemplate={footerTemplate}
      >
        {open && (
          <ScrollBox variant="vertical" maxHeight="calc(70vh - 180px)">
            <InquiryForm
              mode="new"
              isGuest={inquiryInfo?.isGuest}
              onInit={onInit}
              onValidate={onValidate}
              onSubmitSuccess={handleSubmitSuccess}
            />
          </ScrollBox>
        )}
      </Dialog>
      <div>
        <Dialog
          target="#dialog-target"
          variant="simple"
          id="inquiry-success-modal"
          icon={<SystemSuucessIcon />}
          visible={successConfirmVisible}
          title={term('success_confirm_title')}
          footerTemplate={() => (
            <Button onClick={handleSuccessModalClose} content={commonTerm('confirm')} width="120px" size="lg" />
          )}
          disableOverlayClose
        >
          {term('success_confirm_message')}
        </Dialog>
        <ConfirmationDialog
          variant="simple"
          icon={<SystemCancelIcon />}
          visible={closeConfirmVisible}
          title={term('close_confirm_title')}
          confirmButtonText={term('end')}
          onClose={handleCloseConfirmClose}
          disableOverlayClose
          animationSettings={{ effect: 'None' }}
          content={term('close_confirm_message')}
        />
      </div>
    </>
  )
}

export default InquiryDialog
