import { useInquiryDialog } from '~/templates/InquiryContextProvider'
import dataInquiryState, { DataInquiryType } from '~/recoil/data-inquiry/atom'
import { useRecoilState } from 'recoil'

const useInquiryControl = () => {
  const dialogRef = useInquiryDialog()
  const [dataInquiryInfo, setDataInquiryInfo] = useRecoilState(dataInquiryState)

  const openDialog = (props?: DataInquiryType) => {
    if (props) {
      setDataInquiryInfo(props)
    }
    dialogRef?.current?.show()
  }

  const closeDialog = () => {
    if (dataInquiryInfo) {
      setDataInquiryInfo(null)
    }
    dialogRef?.current?.hide()
  }

  return { openDialog, closeDialog }
}

export default useInquiryControl
