import { ImageLoaderProps } from 'next/image'

const imageLoader = ({ src }: ImageLoaderProps) => {
  return src
}

// next js에서 사용
// noinspection JSUnusedGlobalSymbols
// eslint-disable-next-line import/no-unused-modules
export default imageLoader
