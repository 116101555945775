/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.6.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  DemolishDataV1GovDemolishGetParams
} from '../model/demolishDataV1GovDemolishGetParams'
import type {
  DemolishPagingOut
} from '../model/demolishPagingOut'
import type {
  FloorPagingOut
} from '../model/floorPagingOut'
import type {
  GetLandUsePlanDataV1GovLandUsePlanGetParams
} from '../model/getLandUsePlanDataV1GovLandUsePlanGetParams'
import type {
  GetPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGetParams
} from '../model/getPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGetParams'
import type {
  GetPermissionsDataV1GovPermissionsGetParams
} from '../model/getPermissionsDataV1GovPermissionsGetParams'
import type {
  GetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGetParams
} from '../model/getRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGetParams'
import type {
  GetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGetParams
} from '../model/getRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGetParams'
import type {
  GetRealTradeLandsDataV1GovRealTradeLandsGetParams
} from '../model/getRealTradeLandsDataV1GovRealTradeLandsGetParams'
import type {
  GovRealTradeBuildingsGeneralPagingOut
} from '../model/govRealTradeBuildingsGeneralPagingOut'
import type {
  GovRealTradeBuildingsMultiOwnedPagingOut
} from '../model/govRealTradeBuildingsMultiOwnedPagingOut'
import type {
  GovRealTradeLandsPagingOut
} from '../model/govRealTradeLandsPagingOut'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import type {
  LandChangeOwnerDataV1GovLandChangeOwnerGetParams
} from '../model/landChangeOwnerDataV1GovLandChangeOwnerGetParams'
import type {
  LandChangeOwnerPagingOut
} from '../model/landChangeOwnerPagingOut'
import type {
  LandInfoDataV1GovLandGetParams
} from '../model/landInfoDataV1GovLandGetParams'
import type {
  LandInfoOut
} from '../model/landInfoOut'
import type {
  LandPriceDataV1GovLandPriceGetParams
} from '../model/landPriceDataV1GovLandPriceGetParams'
import type {
  LandPricePagingOut
} from '../model/landPricePagingOut'
import type {
  LandUsePlanOut
} from '../model/landUsePlanOut'
import type {
  LedgerFloorDataV1GovLedgerFloorGetParams
} from '../model/ledgerFloorDataV1GovLedgerFloorGetParams'
import type {
  LedgerOutlineDataV1GovLedgerOutlineGetParams
} from '../model/ledgerOutlineDataV1GovLedgerOutlineGetParams'
import type {
  LedgerOutlinePagingOut
} from '../model/ledgerOutlinePagingOut'
import type {
  LedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGetParams
} from '../model/ledgerOutlineSummaryDataV1GovLedgerOutlineSummaryGetParams'
import type {
  LedgerOutlineSummaryOut
} from '../model/ledgerOutlineSummaryOut'
import type {
  LedgerPossessionDataV1GovLedgerPossessionGetParams
} from '../model/ledgerPossessionDataV1GovLedgerPossessionGetParams'
import type {
  LedgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGetParams
} from '../model/ledgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGetParams'
import type {
  PermissionDataV1GovPermissionGetParams
} from '../model/permissionDataV1GovPermissionGetParams'
import type {
  PermissionFloorsPagingOut
} from '../model/permissionFloorsPagingOut'
import type {
  PermissionOverviewOut
} from '../model/permissionOverviewOut'
import type {
  PermissionPagingOut
} from '../model/permissionPagingOut'
import type {
  PermissionsDongLabelOut
} from '../model/permissionsDongLabelOut'
import type {
  PermissionsDongOut
} from '../model/permissionsDongOut'
import type {
  PossessionPagingOut
} from '../model/possessionPagingOut'
import type {
  SBizPagingOut
} from '../model/sBizPagingOut'
import type {
  SbizDataV1GovSbizGetParams
} from '../model/sbizDataV1GovSbizGetParams'
import type {
  SharedAreaPagingOut
} from '../model/sharedAreaPagingOut'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 공공데이터-건축물대장 총괄표제부 조회(사용 테이블: out_kr.mv_building_ledger_outline_summary)
 */
export const ledgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet = (
    params: LedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LedgerOutlineSummaryOut>(
      {url: `/data/v1/gov/ledger/outline-summary`, method: 'GET',
        params
    },
      options);
    }
  

export const getLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGetKey = (params: LedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGetParams,) => [`/data/v1/gov/ledger/outline-summary`, ...(params ? [params]: [])] as const;

    
export type LedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGetQueryResult = NonNullable<Awaited<ReturnType<typeof ledgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet>>>
export type LedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터-건축물대장 총괄표제부 조회(사용 테이블: out_kr.mv_building_ledger_outline_summary)
 */
export const useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet = <TError = HTTPValidationError>(
 params: LedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof ledgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGetKey(params) : null);
  const swrFn = () => ledgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터-건축물대장 표제부 조회(사용 테이블:out_kr.mv_building_ledger_outline)
 */
export const ledgerOutlineDataV1GovLedgerOutlineGet = (
    params: LedgerOutlineDataV1GovLedgerOutlineGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LedgerOutlinePagingOut>(
      {url: `/data/v1/gov/ledger/outline`, method: 'GET',
        params
    },
      options);
    }
  

export const getLedgerOutlineDataV1GovLedgerOutlineGetKey = (params: LedgerOutlineDataV1GovLedgerOutlineGetParams,) => [`/data/v1/gov/ledger/outline`, ...(params ? [params]: [])] as const;

    
export type LedgerOutlineDataV1GovLedgerOutlineGetQueryResult = NonNullable<Awaited<ReturnType<typeof ledgerOutlineDataV1GovLedgerOutlineGet>>>
export type LedgerOutlineDataV1GovLedgerOutlineGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터-건축물대장 표제부 조회(사용 테이블:out_kr.mv_building_ledger_outline)
 */
export const useLedgerOutlineDataV1GovLedgerOutlineGet = <TError = HTTPValidationError>(
 params: LedgerOutlineDataV1GovLedgerOutlineGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof ledgerOutlineDataV1GovLedgerOutlineGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLedgerOutlineDataV1GovLedgerOutlineGetKey(params) : null);
  const swrFn = () => ledgerOutlineDataV1GovLedgerOutlineGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터-건축물대장 층별 정보(사용 테이블: out_kr.mv_building_ledger_floor_summaries)
 */
export const ledgerFloorDataV1GovLedgerFloorGet = (
    params: LedgerFloorDataV1GovLedgerFloorGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<FloorPagingOut>(
      {url: `/data/v1/gov/ledger/floor`, method: 'GET',
        params
    },
      options);
    }
  

export const getLedgerFloorDataV1GovLedgerFloorGetKey = (params: LedgerFloorDataV1GovLedgerFloorGetParams,) => [`/data/v1/gov/ledger/floor`, ...(params ? [params]: [])] as const;

    
export type LedgerFloorDataV1GovLedgerFloorGetQueryResult = NonNullable<Awaited<ReturnType<typeof ledgerFloorDataV1GovLedgerFloorGet>>>
export type LedgerFloorDataV1GovLedgerFloorGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터-건축물대장 층별 정보(사용 테이블: out_kr.mv_building_ledger_floor_summaries)
 */
export const useLedgerFloorDataV1GovLedgerFloorGet = <TError = HTTPValidationError>(
 params: LedgerFloorDataV1GovLedgerFloorGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof ledgerFloorDataV1GovLedgerFloorGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLedgerFloorDataV1GovLedgerFloorGetKey(params) : null);
  const swrFn = () => ledgerFloorDataV1GovLedgerFloorGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터-건축물대장 호(전유부) 정보(사용 테이블: out_kr.mv_building_ledger_possession_ledger)
 */
export const ledgerPossessionDataV1GovLedgerPossessionGet = (
    params: LedgerPossessionDataV1GovLedgerPossessionGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PossessionPagingOut>(
      {url: `/data/v1/gov/ledger/possession`, method: 'GET',
        params
    },
      options);
    }
  

export const getLedgerPossessionDataV1GovLedgerPossessionGetKey = (params: LedgerPossessionDataV1GovLedgerPossessionGetParams,) => [`/data/v1/gov/ledger/possession`, ...(params ? [params]: [])] as const;

    
export type LedgerPossessionDataV1GovLedgerPossessionGetQueryResult = NonNullable<Awaited<ReturnType<typeof ledgerPossessionDataV1GovLedgerPossessionGet>>>
export type LedgerPossessionDataV1GovLedgerPossessionGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터-건축물대장 호(전유부) 정보(사용 테이블: out_kr.mv_building_ledger_possession_ledger)
 */
export const useLedgerPossessionDataV1GovLedgerPossessionGet = <TError = HTTPValidationError>(
 params: LedgerPossessionDataV1GovLedgerPossessionGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof ledgerPossessionDataV1GovLedgerPossessionGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLedgerPossessionDataV1GovLedgerPossessionGetKey(params) : null);
  const swrFn = () => ledgerPossessionDataV1GovLedgerPossessionGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터-건축물대장 호(전유부) 공유 면적(사용 테이블: out_kr.mv_building_ledger_shared_area)
 */
export const ledgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGet = (
    params: LedgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SharedAreaPagingOut>(
      {url: `/data/v1/gov/ledger/possession/shared_area`, method: 'GET',
        params
    },
      options);
    }
  

export const getLedgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGetKey = (params: LedgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGetParams,) => [`/data/v1/gov/ledger/possession/shared_area`, ...(params ? [params]: [])] as const;

    
export type LedgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGetQueryResult = NonNullable<Awaited<ReturnType<typeof ledgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGet>>>
export type LedgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터-건축물대장 호(전유부) 공유 면적(사용 테이블: out_kr.mv_building_ledger_shared_area)
 */
export const useLedgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGet = <TError = HTTPValidationError>(
 params: LedgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof ledgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLedgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGetKey(params) : null);
  const swrFn = () => ledgerPossessionSharedAreaDataV1GovLedgerPossessionSharedAreaGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @deprecated
 * @summary 공공데이터 인허가(사용 테이블: out_kr.mv_building_permission_overview )
 */
export const permissionDataV1GovPermissionGet = (
    params: PermissionDataV1GovPermissionGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PermissionPagingOut>(
      {url: `/data/v1/gov/permission`, method: 'GET',
        params
    },
      options);
    }
  

export const getPermissionDataV1GovPermissionGetKey = (params: PermissionDataV1GovPermissionGetParams,) => [`/data/v1/gov/permission`, ...(params ? [params]: [])] as const;

    
export type PermissionDataV1GovPermissionGetQueryResult = NonNullable<Awaited<ReturnType<typeof permissionDataV1GovPermissionGet>>>
export type PermissionDataV1GovPermissionGetQueryError = HTTPValidationError

/**
 * @deprecated
 * @summary 공공데이터 인허가(사용 테이블: out_kr.mv_building_permission_overview )
 */
export const usePermissionDataV1GovPermissionGet = <TError = HTTPValidationError>(
 params: PermissionDataV1GovPermissionGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof permissionDataV1GovPermissionGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPermissionDataV1GovPermissionGetKey(params) : null);
  const swrFn = () => permissionDataV1GovPermissionGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터 인허가(사용 테이블: out_kr.mv_building_permission_overview )
 */
export const getPermissionsDataV1GovPermissionsGet = (
    params: GetPermissionsDataV1GovPermissionsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PermissionPagingOut>(
      {url: `/data/v1/gov/permissions`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetPermissionsDataV1GovPermissionsGetKey = (params: GetPermissionsDataV1GovPermissionsGetParams,) => [`/data/v1/gov/permissions`, ...(params ? [params]: [])] as const;

    
export type GetPermissionsDataV1GovPermissionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissionsDataV1GovPermissionsGet>>>
export type GetPermissionsDataV1GovPermissionsGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터 인허가(사용 테이블: out_kr.mv_building_permission_overview )
 */
export const useGetPermissionsDataV1GovPermissionsGet = <TError = HTTPValidationError>(
 params: GetPermissionsDataV1GovPermissionsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getPermissionsDataV1GovPermissionsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetPermissionsDataV1GovPermissionsGetKey(params) : null);
  const swrFn = () => getPermissionsDataV1GovPermissionsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터 인허가 상세 (사용 테이블: out_kr.mv_building_permission_overview )
 */
export const getPermissionOverviewDataV1GovPermissionsMgmPmsrgstPkGet = (
    mgmPmsrgstPk: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PermissionOverviewOut>(
      {url: `/data/v1/gov/permissions/${mgmPmsrgstPk}`, method: 'GET'
    },
      options);
    }
  

export const getGetPermissionOverviewDataV1GovPermissionsMgmPmsrgstPkGetKey = (mgmPmsrgstPk: string,) => [`/data/v1/gov/permissions/${mgmPmsrgstPk}`] as const;

    
export type GetPermissionOverviewDataV1GovPermissionsMgmPmsrgstPkGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissionOverviewDataV1GovPermissionsMgmPmsrgstPkGet>>>
export type GetPermissionOverviewDataV1GovPermissionsMgmPmsrgstPkGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터 인허가 상세 (사용 테이블: out_kr.mv_building_permission_overview )
 */
export const useGetPermissionOverviewDataV1GovPermissionsMgmPmsrgstPkGet = <TError = HTTPValidationError>(
 mgmPmsrgstPk: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getPermissionOverviewDataV1GovPermissionsMgmPmsrgstPkGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(mgmPmsrgstPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetPermissionOverviewDataV1GovPermissionsMgmPmsrgstPkGetKey(mgmPmsrgstPk) : null);
  const swrFn = () => getPermissionOverviewDataV1GovPermissionsMgmPmsrgstPkGet(mgmPmsrgstPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터 인허가 상세 동 라벨 (사용 테이블: out_kr.mv_building_permission_overview_dong )
 */
export const getPermissionOverviewDongsLabelsDataV1GovPermissionsMgmPmsrgstPkDongsGet = (
    mgmPmsrgstPk: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PermissionsDongLabelOut[]>(
      {url: `/data/v1/gov/permissions/${mgmPmsrgstPk}/dongs`, method: 'GET'
    },
      options);
    }
  

export const getGetPermissionOverviewDongsLabelsDataV1GovPermissionsMgmPmsrgstPkDongsGetKey = (mgmPmsrgstPk: string,) => [`/data/v1/gov/permissions/${mgmPmsrgstPk}/dongs`] as const;

    
export type GetPermissionOverviewDongsLabelsDataV1GovPermissionsMgmPmsrgstPkDongsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissionOverviewDongsLabelsDataV1GovPermissionsMgmPmsrgstPkDongsGet>>>
export type GetPermissionOverviewDongsLabelsDataV1GovPermissionsMgmPmsrgstPkDongsGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터 인허가 상세 동 라벨 (사용 테이블: out_kr.mv_building_permission_overview_dong )
 */
export const useGetPermissionOverviewDongsLabelsDataV1GovPermissionsMgmPmsrgstPkDongsGet = <TError = HTTPValidationError>(
 mgmPmsrgstPk: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getPermissionOverviewDongsLabelsDataV1GovPermissionsMgmPmsrgstPkDongsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(mgmPmsrgstPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetPermissionOverviewDongsLabelsDataV1GovPermissionsMgmPmsrgstPkDongsGetKey(mgmPmsrgstPk) : null);
  const swrFn = () => getPermissionOverviewDongsLabelsDataV1GovPermissionsMgmPmsrgstPkDongsGet(mgmPmsrgstPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터 인허가 동 상세 (사용 테이블: out_kr.mv_building_permission_overview_dong )
 */
export const getPermissionOverviewDongDataV1GovPermissionsDongsMgmDongOulnPkGet = (
    mgmDongOulnPk: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PermissionsDongOut>(
      {url: `/data/v1/gov/permissions/dongs/${mgmDongOulnPk}`, method: 'GET'
    },
      options);
    }
  

export const getGetPermissionOverviewDongDataV1GovPermissionsDongsMgmDongOulnPkGetKey = (mgmDongOulnPk: string,) => [`/data/v1/gov/permissions/dongs/${mgmDongOulnPk}`] as const;

    
export type GetPermissionOverviewDongDataV1GovPermissionsDongsMgmDongOulnPkGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissionOverviewDongDataV1GovPermissionsDongsMgmDongOulnPkGet>>>
export type GetPermissionOverviewDongDataV1GovPermissionsDongsMgmDongOulnPkGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터 인허가 동 상세 (사용 테이블: out_kr.mv_building_permission_overview_dong )
 */
export const useGetPermissionOverviewDongDataV1GovPermissionsDongsMgmDongOulnPkGet = <TError = HTTPValidationError>(
 mgmDongOulnPk: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getPermissionOverviewDongDataV1GovPermissionsDongsMgmDongOulnPkGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(mgmDongOulnPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetPermissionOverviewDongDataV1GovPermissionsDongsMgmDongOulnPkGetKey(mgmDongOulnPk) : null);
  const swrFn = () => getPermissionOverviewDongDataV1GovPermissionsDongsMgmDongOulnPkGet(mgmDongOulnPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터 인허가 상세 층 (사용 테이블: out_kr.mv_building_permission_overview_floor )
 */
export const getPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGet = (
    mgmDongOulnPk: string,
    params?: GetPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PermissionFloorsPagingOut>(
      {url: `/data/v1/gov/permissions/dongs/${mgmDongOulnPk}/floors`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGetKey = (mgmDongOulnPk: string,
    params?: GetPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGetParams,) => [`/data/v1/gov/permissions/dongs/${mgmDongOulnPk}/floors`, ...(params ? [params]: [])] as const;

    
export type GetPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGet>>>
export type GetPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터 인허가 상세 층 (사용 테이블: out_kr.mv_building_permission_overview_floor )
 */
export const useGetPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGet = <TError = HTTPValidationError>(
 mgmDongOulnPk: string,
    params?: GetPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(mgmDongOulnPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGetKey(mgmDongOulnPk,params) : null);
  const swrFn = () => getPermissionOverviewFloorsDataV1GovPermissionsDongsMgmDongOulnPkFloorsGet(mgmDongOulnPk,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터 폐쇄말소대장 (사용 테이블: out_kr.mv_building_demolish_outline)
 */
export const demolishDataV1GovDemolishGet = (
    params: DemolishDataV1GovDemolishGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<DemolishPagingOut>(
      {url: `/data/v1/gov/demolish`, method: 'GET',
        params
    },
      options);
    }
  

export const getDemolishDataV1GovDemolishGetKey = (params: DemolishDataV1GovDemolishGetParams,) => [`/data/v1/gov/demolish`, ...(params ? [params]: [])] as const;

    
export type DemolishDataV1GovDemolishGetQueryResult = NonNullable<Awaited<ReturnType<typeof demolishDataV1GovDemolishGet>>>
export type DemolishDataV1GovDemolishGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터 폐쇄말소대장 (사용 테이블: out_kr.mv_building_demolish_outline)
 */
export const useDemolishDataV1GovDemolishGet = <TError = HTTPValidationError>(
 params: DemolishDataV1GovDemolishGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof demolishDataV1GovDemolishGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDemolishDataV1GovDemolishGetKey(params) : null);
  const swrFn = () => demolishDataV1GovDemolishGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터 국토부실거래 - 일반건물 (사용 테이블: out_kr.mv_real_trade_price)
 */
export const getRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGet = (
    params: GetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GovRealTradeBuildingsGeneralPagingOut>(
      {url: `/data/v1/gov/real-trade/buildings/general`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGetKey = (params: GetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGetParams,) => [`/data/v1/gov/real-trade/buildings/general`, ...(params ? [params]: [])] as const;

    
export type GetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGet>>>
export type GetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터 국토부실거래 - 일반건물 (사용 테이블: out_kr.mv_real_trade_price)
 */
export const useGetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGet = <TError = HTTPValidationError>(
 params: GetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGetKey(params) : null);
  const swrFn = () => getRealTradeBuildingsGeneralDataV1GovRealTradeBuildingsGeneralGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터 국토부실거래 - 집합건물 (사용 테이블: out_kr.mv_real_trade_price)
 */
export const getRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGet = (
    params: GetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GovRealTradeBuildingsMultiOwnedPagingOut>(
      {url: `/data/v1/gov/real-trade/buildings/multi-owned`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGetKey = (params: GetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGetParams,) => [`/data/v1/gov/real-trade/buildings/multi-owned`, ...(params ? [params]: [])] as const;

    
export type GetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGet>>>
export type GetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터 국토부실거래 - 집합건물 (사용 테이블: out_kr.mv_real_trade_price)
 */
export const useGetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGet = <TError = HTTPValidationError>(
 params: GetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGetKey(params) : null);
  const swrFn = () => getRealTradeBuildingsMultiOwnedDataV1GovRealTradeBuildingsMultiOwnedGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터 국토부실거래 - 토지 (사용 테이블: out_kr.mv_real_trade_price)
 */
export const getRealTradeLandsDataV1GovRealTradeLandsGet = (
    params: GetRealTradeLandsDataV1GovRealTradeLandsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GovRealTradeLandsPagingOut>(
      {url: `/data/v1/gov/real-trade/lands`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRealTradeLandsDataV1GovRealTradeLandsGetKey = (params: GetRealTradeLandsDataV1GovRealTradeLandsGetParams,) => [`/data/v1/gov/real-trade/lands`, ...(params ? [params]: [])] as const;

    
export type GetRealTradeLandsDataV1GovRealTradeLandsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRealTradeLandsDataV1GovRealTradeLandsGet>>>
export type GetRealTradeLandsDataV1GovRealTradeLandsGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터 국토부실거래 - 토지 (사용 테이블: out_kr.mv_real_trade_price)
 */
export const useGetRealTradeLandsDataV1GovRealTradeLandsGet = <TError = HTTPValidationError>(
 params: GetRealTradeLandsDataV1GovRealTradeLandsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRealTradeLandsDataV1GovRealTradeLandsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRealTradeLandsDataV1GovRealTradeLandsGetKey(params) : null);
  const swrFn = () => getRealTradeLandsDataV1GovRealTradeLandsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공공데이터 소상공인시장진흥공단 상가정보 (사용 테이블: out_kr.mv_semas_sbiz)
 */
export const sbizDataV1GovSbizGet = (
    params: SbizDataV1GovSbizGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SBizPagingOut>(
      {url: `/data/v1/gov/sbiz`, method: 'GET',
        params
    },
      options);
    }
  

export const getSbizDataV1GovSbizGetKey = (params: SbizDataV1GovSbizGetParams,) => [`/data/v1/gov/sbiz`, ...(params ? [params]: [])] as const;

    
export type SbizDataV1GovSbizGetQueryResult = NonNullable<Awaited<ReturnType<typeof sbizDataV1GovSbizGet>>>
export type SbizDataV1GovSbizGetQueryError = HTTPValidationError

/**
 * @summary 공공데이터 소상공인시장진흥공단 상가정보 (사용 테이블: out_kr.mv_semas_sbiz)
 */
export const useSbizDataV1GovSbizGet = <TError = HTTPValidationError>(
 params: SbizDataV1GovSbizGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof sbizDataV1GovSbizGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSbizDataV1GovSbizGetKey(params) : null);
  const swrFn = () => sbizDataV1GovSbizGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 필지 정보 조회 (사용 테이블: out_kr.mv_land_info)
 */
export const landInfoDataV1GovLandGet = (
    params?: LandInfoDataV1GovLandGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LandInfoOut[]>(
      {url: `/data/v1/gov/land`, method: 'GET',
        params
    },
      options);
    }
  

export const getLandInfoDataV1GovLandGetKey = (params?: LandInfoDataV1GovLandGetParams,) => [`/data/v1/gov/land`, ...(params ? [params]: [])] as const;

    
export type LandInfoDataV1GovLandGetQueryResult = NonNullable<Awaited<ReturnType<typeof landInfoDataV1GovLandGet>>>
export type LandInfoDataV1GovLandGetQueryError = HTTPValidationError

/**
 * @summary 필지 정보 조회 (사용 테이블: out_kr.mv_land_info)
 */
export const useLandInfoDataV1GovLandGet = <TError = HTTPValidationError>(
 params?: LandInfoDataV1GovLandGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof landInfoDataV1GovLandGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLandInfoDataV1GovLandGetKey(params) : null);
  const swrFn = () => landInfoDataV1GovLandGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 필지 공시지가 조회 (사용 테이블: out_kr.mv_land_characteristics_price)
 */
export const landPriceDataV1GovLandPriceGet = (
    params: LandPriceDataV1GovLandPriceGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LandPricePagingOut>(
      {url: `/data/v1/gov/land/price`, method: 'GET',
        params
    },
      options);
    }
  

export const getLandPriceDataV1GovLandPriceGetKey = (params: LandPriceDataV1GovLandPriceGetParams,) => [`/data/v1/gov/land/price`, ...(params ? [params]: [])] as const;

    
export type LandPriceDataV1GovLandPriceGetQueryResult = NonNullable<Awaited<ReturnType<typeof landPriceDataV1GovLandPriceGet>>>
export type LandPriceDataV1GovLandPriceGetQueryError = HTTPValidationError

/**
 * @summary 필지 공시지가 조회 (사용 테이블: out_kr.mv_land_characteristics_price)
 */
export const useLandPriceDataV1GovLandPriceGet = <TError = HTTPValidationError>(
 params: LandPriceDataV1GovLandPriceGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof landPriceDataV1GovLandPriceGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLandPriceDataV1GovLandPriceGetKey(params) : null);
  const swrFn = () => landPriceDataV1GovLandPriceGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 토지 소유자 변동 정보 (사용 테이블: out_kr.)
 */
export const landChangeOwnerDataV1GovLandChangeOwnerGet = (
    params: LandChangeOwnerDataV1GovLandChangeOwnerGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LandChangeOwnerPagingOut>(
      {url: `/data/v1/gov/land/change-owner`, method: 'GET',
        params
    },
      options);
    }
  

export const getLandChangeOwnerDataV1GovLandChangeOwnerGetKey = (params: LandChangeOwnerDataV1GovLandChangeOwnerGetParams,) => [`/data/v1/gov/land/change-owner`, ...(params ? [params]: [])] as const;

    
export type LandChangeOwnerDataV1GovLandChangeOwnerGetQueryResult = NonNullable<Awaited<ReturnType<typeof landChangeOwnerDataV1GovLandChangeOwnerGet>>>
export type LandChangeOwnerDataV1GovLandChangeOwnerGetQueryError = HTTPValidationError

/**
 * @summary 토지 소유자 변동 정보 (사용 테이블: out_kr.)
 */
export const useLandChangeOwnerDataV1GovLandChangeOwnerGet = <TError = HTTPValidationError>(
 params: LandChangeOwnerDataV1GovLandChangeOwnerGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof landChangeOwnerDataV1GovLandChangeOwnerGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLandChangeOwnerDataV1GovLandChangeOwnerGetKey(params) : null);
  const swrFn = () => landChangeOwnerDataV1GovLandChangeOwnerGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 토지 이용 계획 (사용 테이블: out_kr.mv_land_use_plan)
 */
export const getLandUsePlanDataV1GovLandUsePlanGet = (
    params: GetLandUsePlanDataV1GovLandUsePlanGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LandUsePlanOut[]>(
      {url: `/data/v1/gov/land/use-plan`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLandUsePlanDataV1GovLandUsePlanGetKey = (params: GetLandUsePlanDataV1GovLandUsePlanGetParams,) => [`/data/v1/gov/land/use-plan`, ...(params ? [params]: [])] as const;

    
export type GetLandUsePlanDataV1GovLandUsePlanGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLandUsePlanDataV1GovLandUsePlanGet>>>
export type GetLandUsePlanDataV1GovLandUsePlanGetQueryError = HTTPValidationError

/**
 * @summary 토지 이용 계획 (사용 테이블: out_kr.mv_land_use_plan)
 */
export const useGetLandUsePlanDataV1GovLandUsePlanGet = <TError = HTTPValidationError>(
 params: GetLandUsePlanDataV1GovLandUsePlanGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLandUsePlanDataV1GovLandUsePlanGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLandUsePlanDataV1GovLandUsePlanGetKey(params) : null);
  const swrFn = () => getLandUsePlanDataV1GovLandUsePlanGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

