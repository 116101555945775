import { useRecoilValue } from 'recoil'
import landFilterParamState from '~/recoil/land-filter/landFilterParamState'

/**
 * 공공 데이터 관련 API 리퀘스트 파라미터를 리턴합니다.
 */
const useLandsQuery = () => {
  return useRecoilValue(landFilterParamState)
}

export default useLandsQuery
