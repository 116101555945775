import React, { FC } from 'react'
import { useTranslation } from 'next-i18next'
import DetailTable, { DetailTableType } from '~/components/Table/DetailTable'
import { formatTotalUnitsInfo, formatVacancyRate } from '~/libs/utils/format'
import { LedgerOutlineSummaryOut } from '~/libs/apis/data/model'
import OverflowWithTooltip from '~/components/Tooltip/OverflowWithTooltip'
import { formatDateYmd } from '~/libs/utils/date'
import { useAreaFormat } from '~/libs/hooks/useFormat'
import { commaizeNumber, isNotNil } from '@toss/utils'

interface LedgerTableProps {
  data: LedgerOutlineSummaryOut
}

const LedgerSummaryTable: FC<LedgerTableProps> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: unit } = useTranslation('common', { keyPrefix: 'unit' })
  const areaFormatter = useAreaFormat()

  const ledgerSummaryData: DetailTableType[] = [
    { column: t('regist_division'), content: data.registGubunName },
    {
      column: t('bld_name'),
      content: data.buildingName,
    },
    { column: t('bylot_cnt'), content: data.bylotCnt },
    {
      column: t('plat_area'),
      content: areaFormatter(data.platArea, { rounded: 'n2' }),
    },
    {
      column: t('architecture_area'),
      content: areaFormatter(data.archArea, { rounded: 'n2' }),
    },
    { column: t('bc_rat'), content: formatVacancyRate(data.bcRat) },
    {
      column: t('gra_py'),
      content: areaFormatter(data.totalArea, { rounded: 'n2' }),
    },
    {
      column: t('vl_rat_estm_tot_area'),
      content: areaFormatter(data.vlRatEstmTotArea, { rounded: 'n2' }),
    },
    { column: t('vl_rat'), content: formatVacancyRate(data.vlRat) },
    { column: t('main_purpose'), content: data.mainPurpose },
    {
      column: t('etc_purpose'),
      content: isNotNil(data?.etcPurpose) ? (
        <OverflowWithTooltip cellText={data?.etcPurpose} tooltipText={data?.etcPurpose} fontWeight={600} width={400} />
      ) : (
        '-'
      ),
    },
    { column: t('total_units'), content: formatTotalUnitsInfo(t, data?.hhldCnt, data?.hoCnt, data?.fmlyCnt) },
    { column: t('main_building_cnt'), content: data.mainBldCnt },
    { column: t('atch_bld_cnt'), content: data.atchBldCnt },
    {
      column: t('atch_bld_area_py'),
      content: areaFormatter(data.atchBldArea, { rounded: 'n2' }),
    },
    {
      column: t('total_park_cnt'),
      content: data.totalParkCnt ? unit('dae_count', { number: commaizeNumber(data.totalParkCnt) }) : '-',
    },
    { column: t('permission_date'), content: formatDateYmd(data.permissionConstructionDateYmd) },
    { column: t('start_construction_date'), content: formatDateYmd(data.startConstructionDateYmd) },
    { column: t('use_approval_date'), content: formatDateYmd(data.useAprDateYmd) },
    { column: t('energy_efficiency_grade'), content: data.energyEfficiencyGrade },
    { column: t('energy_savings_rate'), content: data.energySavingsRate },
    { column: t('epi_score'), content: data.epiScore },
    { column: t('green_building_grade'), content: data.greenBuildingGrade },
    { column: t('green_building_score'), content: data.greenBuildingScore },
    { column: t('smart_building_grade'), content: data.smartBuildingGrade },
    { column: t('smart_building_score'), content: data.smartBuildingScore },
  ]

  return <DetailTable data={ledgerSummaryData} />
}

export default LedgerSummaryTable
