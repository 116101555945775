import BuildingImage from '~/components/Image/BuildingImage'
import Box from '~/components/Box/Box'
import { FC, useState } from 'react'
import { createPortal } from 'react-dom'
import ImageModal from '~/templates/place/detail/overview/ImageModal'
import { OfficeOutImageUrl } from '~/libs/apis/data/model'

interface BuildingImageAreaProps {
  imageUrl: OfficeOutImageUrl
}

const BuildingImageArea: FC<BuildingImageAreaProps> = ({ imageUrl }) => {
  const [showImageModal, setShowImageModal] = useState(false)
  const toggleShowImageModal = () => setShowImageModal((prev) => !prev)

  return (
    <>
      <Box cursor="pointer" onClick={toggleShowImageModal}>
        <BuildingImage url={imageUrl} width={400} height={240} />
      </Box>
      {showImageModal &&
        imageUrl &&
        createPortal(<ImageModal imageUrl={imageUrl} onClose={toggleShowImageModal} />, document.body)}
    </>
  )
}

export default BuildingImageArea
