import {
  useGetOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGet,
  useOfficeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGet,
} from '~/libs/apis/data/buildings-office/buildings-office'
import { isNotNil } from '@toss/utils'
import StackingPlanParams from '~/templates/common/view/stacking-plan/StackingPlanParams'

interface StackingPlan {
  dongResponse: ReturnType<typeof useGetOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGet>
  stackingPlanResponse: ReturnType<typeof useOfficeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGet>
}

interface StackingPlanProps extends StackingPlanParams {
  enabled?: boolean
}

const useOfficeStackingPlan = ({ raId, year, quarter, dongName, enabled = true }: StackingPlanProps): StackingPlan => {
  const dongResponse = useGetOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGet(
    raId,
    {
      year,
      quarter,
    },
    { swr: { enabled } },
  )
  return {
    dongResponse,
    stackingPlanResponse: useOfficeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGet(
      raId,
      {
        year,
        quarter,
        dongName,
      },
      {
        swr: {
          keepPreviousData: true,
          enabled: dongResponse.data?.length === 0 || (isNotNil(dongName) && enabled),
        },
      },
    ),
  }
}

export default useOfficeStackingPlan
