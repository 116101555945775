import React, { FC } from 'react'
import { Separated } from '@toss/react'
import { Box } from '~/components'
import Typography from '~/components/Typography/Typography'
import { useTranslation } from 'next-i18next'

interface FooterLinksProps {
  showInquireUse?: boolean
}

const FooterLinks: FC<FooterLinksProps> = ({ showInquireUse = true }) => {
  const { t: commonTerm } = useTranslation('common', { keyPrefix: 'common_term' })

  return (
    <Box display="flex" alignItems="center" gap="10px">
      <Separated with={<Box width="1px" height="14px" backgroundColor="gray-300" />}>
        <Box
          cursor="pointer"
          onClick={() => {
            window.open('https://rsquareon.notion.site/6319f62c129a41698955e12582a785ac', '_blank', 'noopener')
          }}
        >
          <Typography variant="caption1" fontWeight="semibold" color="gray-600">
            {commonTerm('service_intro')}
          </Typography>
        </Box>
        {showInquireUse && (
          <Box
            cursor="pointer"
            onClick={() => {
              window.open('https://forms.gle/rodsmow9mgathfNE9', '_blank', 'noopener')
            }}
          >
            <Typography variant="caption1" fontWeight="semibold" color="gray-600">
              {commonTerm('inquire_use')}
            </Typography>
          </Box>
        )}
        <Box
          cursor="pointer"
          onClick={() => {
            window.open('https://docs.channel.io/ra-support/ko/articles/64cf5f60', '_blank', 'noopener')
          }}
        >
          <Typography variant="caption1" fontWeight="semibold" color="gray-600">
            {commonTerm('term_of_use')}
          </Typography>
        </Box>
        <Box
          cursor="pointer"
          onClick={() => {
            window.open('https://privacy.rsquareon.com/', '_blank', 'noopener')
          }}
        >
          <Typography variant="caption1" fontWeight="semibold" color="gray-600" textDecoration="underline">
            {commonTerm('privacy')}
          </Typography>
        </Box>
      </Separated>
    </Box>
  )
}

export default FooterLinks
