import { FC, MouseEventHandler, useRef } from 'react'
import Tooltip from '~/components/Tooltip/Tooltip'
import Box from '~/components/Box/Box'
import { SystemProps } from '@xstyled/styled-components'
import Typography from '~/components/Typography/Typography'
import { TooltipModel } from '@syncfusion/ej2-popups/src/tooltip/tooltip-model'
import { EmitType } from '@syncfusion/ej2-base'
import { TooltipEventArgs } from '@syncfusion/ej2-popups/src/tooltip/tooltip'

interface OverflowWithTooltipProps {
  cellText: string
  tooltipText?: string
  width?: number
  fontWeight?: SystemProps['fontWeight']
  color?: SystemProps['color']
  textDecoration?: SystemProps['textDecoration']
  onClick?: MouseEventHandler<HTMLDivElement>
  tooltipSettings?: {
    tipPointerPosition?: TooltipModel['tipPointerPosition']
    position?: TooltipModel['position']
  }
}

const OverflowWithTooltip: FC<OverflowWithTooltipProps> = ({
  cellText,
  tooltipText,
  width,
  fontWeight = 400,
  color = 'gray-800',
  textDecoration = 'none',
  onClick,
  tooltipSettings = {
    tipPointerPosition: 'Start',
    position: 'TopCenter',
    size: 'normal',
  },
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const onBeforeOpen: EmitType<TooltipEventArgs> = (arg) => {
    if (ref.current && ref.current.clientWidth >= ref.current.scrollWidth) {
      arg.cancel = true
    }
  }

  return (
    <Tooltip
      content={tooltipText ?? cellText}
      tipPointerPosition={tooltipSettings.tipPointerPosition}
      position={tooltipSettings.position}
      beforeOpen={onBeforeOpen}
    >
      <Box
        ref={ref}
        maxW={width}
        whiteSpace="nowrap"
        overflowX="hidden"
        textOverflow="ellipsis"
        onClick={onClick}
        cursor={onClick ? 'pointer' : 'default'}
      >
        <Typography as="span" color={color} fontWeight={fontWeight} textDecoration={textDecoration}>
          {cellText}
        </Typography>
      </Box>
    </Tooltip>
  )
}

export default OverflowWithTooltip
