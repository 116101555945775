import { commaizeNumber, floorToUnit, formatToKoreanNumber, isNotNil, roundToUnit } from '@toss/utils'
import {
  divideByTenThousand,
  roundAndFixedVacancyRate,
  to100MillionsNumber,
  to10ThousandsNumber,
  toOnesNumber,
} from '~/libs/utils/number'
import { format, startOfToday } from 'date-fns'
import { TFunction } from 'next-i18next'

/**
 * 숫자 세번째 자리수마다 콤마를 넣는 형식으로 변환한다.
 * @param num
 */
export const formatCommaizeNumber = (num?: NullishNumber): string => {
  if (isNotNil(num)) {
    return commaizeNumber(num)
  } else {
    return '-'
  }
}

export const formatKoreanNumberOverFiveDigit = (num: number, roundUnit: number = 1) => {
  if (num.toString().length < 5) {
    return formatCommaizeNumber(num)
  }
  return formatToKoreanNumber(roundToUnit(num, roundUnit))
}

/**
 * 쉼표로 천 단위를 구분하고 주어진 자리수로 반올림합니다.
 */
export const formatCommaizeWithRound = (num: number, digit: number) => {
  return formatCommaizeNumber(roundToUnit(num, 10 ** digit))
}

export const formatFloor = (floor: string) => {
  if (floor.startsWith('-')) {
    return floor.replace('-', 'B')
  } else {
    return floor
  }
}

export const formatFloorInfo = (t: TFunction, over?: NullishNumber, under?: NullishNumber) => {
  if (!over && !under) {
    return '-'
  }
  const overground = over ? t('floor_over', { over: over }) : '-'
  const underground = under ? t('floor_under', { under: under }) : '-'
  if (!over) {
    return underground
  }
  if (!under) {
    return overground
  }
  return `${underground}/${overground}`
}

export const formatElevatorInfo = (t: TFunction, customer?: NullishNumber, emergency?: NullishNumber) => {
  if (!customer && !emergency) {
    return '-'
  }
  const customerElevator = customer ? t('customer_elevator_cnt', { customer: customer }) : '-'
  const emergencyElevator = emergency ? t('emergency_elevator_cnt', { emergency: emergency }) : '-'
  if (!customer) {
    return emergencyElevator
  }
  if (!emergency) {
    return customerElevator
  }
  return `${customerElevator}, ${emergencyElevator}`
}

export const formatNearestICDistanceInfo = (
  nearestIc?: NullishString,
  distance?: NullishNumber,
  driveTime?: NullishNumber,
) => {
  if (!nearestIc && !distance && !driveTime) {
    return '-'
  }
  const nearestIcName = nearestIc ?? ''
  const nearestIcDistance = distance ? ` ${distance}km` : ''
  const nearestIcDriveTime = driveTime ? ` (${driveTime}분)` : ''

  return `${nearestIcName}${nearestIcDistance}${nearestIcDriveTime}`
}

export const formatLwhRentFeeInfo = (t: TFunction, roomRentFee: string, lowRentFee: string) => {
  const hasRoomRentFee = roomRentFee !== '-'
  const hasLowRentFee = lowRentFee !== '-'
  if (!hasRoomRentFee && !hasLowRentFee) {
    return ''
  }
  const roomRentFeeInfo = hasRoomRentFee ? `${t('room_temperature')} ${roomRentFee}` : ''
  const lowRentFeeInfo = hasLowRentFee ? `${t('low_temperature')} ${lowRentFee}` : ''
  const middleDot = hasRoomRentFee && hasLowRentFee ? ' · ' : ''

  return `${roomRentFeeInfo}${middleDot}${lowRentFeeInfo}`
}

export const formatTotalUnitsInfo = (
  t: TFunction,
  hhldCnt: NullishNumber,
  hoCnt: NullishNumber,
  fmlyCnt: NullishNumber,
) => {
  const householdCount = hhldCnt ?? '-'
  const hoCount = hoCnt ?? '-'
  const familyCount = fmlyCnt ?? '-'

  return `${householdCount}${t('hhld')} / ${hoCount}${t('ho')} / ${familyCount}${t('family')}`
}

export const formatM2 = (m2: NullishNumber) => {
  if (!isNotNil(m2)) {
    return '-'
  }

  return `${formatCommaizeNumber(roundToUnit(m2, 1))}㎡`
}

export const isArrayAndNotEmpty = <T>(arr: T[] | undefined | null): arr is T[] => Array.isArray(arr) && arr.length !== 0

export const formatWon = (num?: NullishNumber) => {
  if (isNotNil(num)) {
    return `${commaizeNumber(toOnesNumber(num)!)}원`
  } else {
    return '-'
  }
}

export const formatManWon = (num?: NullishNumber) => {
  if (isNotNil(num)) {
    return `${commaizeNumber(to10ThousandsNumber(num)!)}만원`
  } else {
    return '-'
  }
}

export const formatEokWon = (num?: NullishNumber) => {
  if (isNotNil(num)) {
    return `${commaizeNumber(to100MillionsNumber(num)!)}억원`
  } else {
    return '-'
  }
}

export const formatManPy = (num?: number) => {
  if (isNotNil(num)) {
    return `${commaizeNumber(roundToUnit(divideByTenThousand(num)!, 0.1))}만평`
  } else {
    return '-'
  }
}

export const formatTotalArea = (num?: NullishNumber) => {
  if (isNotNil(num)) {
    return `${commaizeNumber(toOnesNumber(num)!)}평`
  } else {
    return '-'
  }
}

export const formatVacancyRate = (num: NullishNumber) => {
  if (isNotNil(num)) {
    return commaizeNumber(roundAndFixedVacancyRate(num)) + '%'
  } else {
    return '-'
  }
}

export const formatExportFileName = (mode: string) => {
  return `${format(startOfToday(), 'yyyyMMdd')}_RA_${mode}`
}

export const formatExcelTableHeader = (headerName: string, format: string) => {
  return `${headerName} (${format})`
}

export const formatOfficeAddress = (jibunAddress: string, roadAddress: NullishString) => {
  if (isNotNil(roadAddress)) {
    return `${jibunAddress} (${roadAddress})`
  }
  return jibunAddress
}

export const formatNumberWithKoreanUnit = (
  num?: NullishNumber,
  floorUnit?: number,
  unit?: string,
): string[] | undefined => {
  if (!isNotNil(num)) {
    return undefined
  }

  const result: string[] = []
  let numberWithKoreanUnit = formatKoreanNumberOverFiveDigit(num, floorUnit)

  if (unit) {
    numberWithKoreanUnit += unit
  }

  const regex = /(\s?\d{1,3}(,\d{3})*)([가-힣]*)/g
  let match = regex.exec(numberWithKoreanUnit)

  while (match) {
    result.push(match[1])

    if (match[3]) {
      result.push(match[3])
    }
    match = regex.exec(numberWithKoreanUnit)
  }

  return result
}

/**
 * 평을 제곱미터로 변환한다.
 * @param py
 */
// TODO: 클라측에서 계산이 필요할때 사용할 가능성이 있어서 남겨둠
// eslint-disable-next-line import/no-unused-modules
export const formatPyToM2 = (py: number) => {
  return floorToUnit(py * 3.30578512, 1000)
}

/**
 * 제곱미터를 평으로 변환한다.
 * @param m2
 */
// TODO: 클라측에서 계산이 필요할때 사용할 가능성이 있어서 남겨둠
// eslint-disable-next-line import/no-unused-modules
export const formatM2ToPy = (m2: number) => {
  return floorToUnit(m2 / 3.30578512, 1000)
}

/**
 * 제곱미터를 평으로 변환한다.
 * (서버(쿼리)에서 계산된 평 값과 기존 프론트 formatM2ToPy로 변환된 평 값이 달라 만든 함수)
 * @param m2
 */
// TODO: 클라측에서 계산이 필요할때 사용할 가능성이 있어서 남겨둠
// eslint-disable-next-line import/no-unused-modules
export const formatM2ToPy2 = (m2: number) => {
  return Math.round(m2 * 0.3025 * 100) / 100
}

/* m2 to 평 */
// TODO: 클라측에서 계산이 필요할때 사용할 가능성이 있어서 남겨둠
// eslint-disable-next-line import/no-unused-modules
export const m2ToPy = (m2?: number) => {
  if (m2) {
    const py = m2 * 0.3025
    return py.toFixed(2)
  }
  return 0
}

export const formatCamelToSnake = (str: string) => {
  return str.replace(/([A-Z])/g, function ($1) {
    return '_' + $1.toLowerCase()
  })
}

export const formatOnes = (num: number) => {
  return `${commaizeNumber(toOnesNumber(num)!)}`
}

// eslint-disable-next-line import/no-unused-modules
export const formatMan = (num: number) => {
  return `${commaizeNumber(to10ThousandsNumber(num)!)}`
}

// eslint-disable-next-line import/no-unused-modules
export const formatEok = (num: number) => {
  return `${commaizeNumber(to100MillionsNumber(num)!)}`
}

export const formatRangeLabel = (minValue?: string, maxValue?: string, unit: string = '') => {
  if (minValue && maxValue) {
    if (minValue === maxValue) {
      return `${minValue}${unit}`
    } else {
      return `${minValue}~${maxValue}${unit}`
    }
  } else if (minValue) {
    return `${minValue}${unit}~`
  } else if (maxValue) {
    return `~${maxValue}${unit}`
  } else {
    return
  }
}

/**
 * @remarks
 * 천 단위마다 쉼표를 표시하고, 반올림 해 소수점 아래 n번째 자리까지 고정한 string 을 리턴합니다.
 * formatCommaizeWithRound 와 유사하지만 소수점 아래 0을 생략하지 않고 n번째 자리까지 채워 표기한다는 차이가 있습니다.
 *
 * @param {NullishNumber} num - 가공할 데이터
 * @param {number} n - 최종적으로 표시할 소수점 아래 자릿수
 *
 * @example
 * 소수 셋째 자리에서 반올림: 소수 둘째 자리까지 표시하므로 n은 2가 됩니다.
 * commaizeRoundAndFixToNDecimal(1234.5678, 2) // 1234.57
 * commaizeRoundAndFixToNDecimal(1234, 2)      // 1234.00
 *
 */
export const formatCommaizeRoundAndFixToNDecimal = (num: NullishNumber, n: number) => {
  if (!isNotNil(num)) {
    return
  }

  const rounded = Number(Math.round(parseFloat(num + 'e' + n)) + 'e-' + n)
  return rounded.toLocaleString('en-US', { minimumFractionDigits: n, maximumFractionDigits: n })
}

export const formatCommaizeRoundToNearestPlace = (num: NullishNumber, n: number) => {
  if (!isNotNil(num)) {
    return
  }

  const rounded = Math.round(num / n) * n
  return rounded.toLocaleString('en-US')
}

export const formatDivideByTenThousandWithDecimal = (num: NullishNumber) => {
  return isNotNil(num) ? roundToUnit(divideByTenThousand(num)!, 0.1).toFixed(1) : num
}

export const unescapeHTML = (str: string) => {
  const formatNewLineWithStr = str.replace(/<br\s*\/?>/gi, '\n')
  const doc = new DOMParser().parseFromString(formatNewLineWithStr, 'text/html')
  return doc.documentElement.textContent ?? formatNewLineWithStr
}

export const formatTextEllipsis = (str: string, maxLength: number, ellipsisLength: number, ellipsisText?: string) => {
  if (str.length > maxLength) {
    return str.slice(0, str.length - ellipsisLength) + (ellipsisText ?? '...')
  }
  return str
}

export const formatYesOrNo = (value: NullishString, yes: string, no: string) => {
  if (isNotNil(value)) {
    if (value === 'Y') {
      return yes
    } else if (value === 'N') {
      return no
    } else {
      return value
    }
  }
  return '-'
}

export const formatDockCnt = (dockCnt: NullishNumber, noExist: string, unspecified: string, daeCount: string) => {
  if (!isNotNil(dockCnt)) {
    return unspecified
  } else if (!dockCnt) {
    return noExist
  } else {
    return daeCount
  }
}

export const formatCorporateRegistrationNumber = (value: NullishString): string => {
  if (isNotNil(value)) {
    return value.slice(0, 6) + '-' + value.slice(6)
  }
  return '-'
}

export const formatOverHundredNumber = (value: NullishNumber): string => {
  if (!value) {
    return ''
  }
  return value >= 100 ? '99+' : value.toString()
}
