import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import DetailTable, { DetailTableType } from '~/components/Table/DetailTable'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'
import { formatBusinessRegistrationNumber } from '@toss/utils'
import { formatCommaizeNumber, formatCorporateRegistrationNumber } from '~/libs/utils/format'
import { formatDateYmd } from '~/libs/utils/date'
import type { ClientOut } from '~/libs/apis/data/model'

interface ClientDetailTableProps {
  data?: ClientOut
}

const ClientDetailTable: FC<ClientDetailTableProps> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'client' })

  if (!data) {
    return null
  }

  const tenantOutlineData: DetailTableType[] = [
    { column: t('ceo_name'), content: data.ceoName },
    {
      column: t('business_registration_number'),
      content: data.businessRegistrationNumber
        ? formatBusinessRegistrationNumber(data.businessRegistrationNumber)
        : '-',
    },
    {
      column: t('corporate_registration_number'),
      content: formatCorporateRegistrationNumber(data.corporateRegistrationNumber),
    },
    { column: t('establishment_date'), content: formatDateYmd(data.establishmentDateYmd) },
    { column: t('company_type_name'), content: data.companyTypeName },
    { column: t('employee_count'), content: formatCommaizeNumber(data.employeeCnt) },
    { column: t('client_sector'), content: data.clientSector },
    { column: t('website_address'), content: data.websiteAddress },
  ]

  return (
    <Box display="flex" flexDirection="column" p="24px 20px" gap="20px">
      <Typography variant="h5" fontWeight="semibold">
        {t('company_outline')}
      </Typography>
      <DetailTable data={tenantOutlineData} />
      <DataSourceUpdate category="detail.lessee.rsquare" />
    </Box>
  )
}

export default ClientDetailTable
