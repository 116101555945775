import { css } from '@xstyled/styled-components'
import IconSuccess from '~/assets/images/icons/system/system-success.svg?url'
import IconInformation from '~/assets/images/icons/system/system-information.svg?url'

const alertCss = css`
  .e-toast-container:has(.e-toast.alert-style-guide) {
    transform: translate(-550px, 0);
    gap: 12px;
  }

  // alert toast 공통 스타일
  .e-toast-container .e-toast.alert-style-guide {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.9);
    padding: 16px 20px 16px 32px;
    margin-bottom: 0;
    box-shadow: var(--shadow-black-06);
    border: none;
    display: flex;
    align-items: center;
    gap: 12px;

    .e-toast-message {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 48px;
    }

    .e-toast-message .e-toast-content {
      text-align: left;
      font-family: var(--font-family);
      white-space: pre-wrap;
      font-size: 16px;
      line-height: 24px;
    }

    .e-toast-icon {
      margin-right: 0;
      mask-repeat: no-repeat;
      mask-size: cover;
      width: 29px;
      height: 24px;
    }

    .e-toast-actions {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
    }

    .e-toast-actions button {
      margin-left: 0;
      padding: 7px 15px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 8px;
      box-shadow: none !important;
      font-weight: 600;

      &:focus {
        outline: none;
      }
    }
  }

  // success alert
  .e-toast-container .e-toast.alert-style-guide:has(.e-success) {
    .e-toast-message .e-toast-content {
      color: var(--color-gray-800);
    }

    .e-toast-icon.e-success {
      mask-image: url(${IconSuccess});
      background: var(--color-gray-800);
    }

    .e-toast-actions button {
      background-color: var(--color-gray-800);
      color: var(--color-system-white);
      border-color: var(--color-gray-800);
    }
  }

  // error alert
  .e-toast-container .e-toast.alert-style-guide:has(.e-error) {
    .e-toast-message .e-toast-content {
      color: var(--color-purple-700);
    }

    .e-toast-icon.e-error {
      mask-image: url(${IconInformation});
      background: var(--color-purple-700);
    }

    .e-toast-actions button {
      background-color: var(--color-purple-700);
      color: var(--color-system-white);
      border-color: var(--color-purple-700);

      &.e-active,
      &:active:not(:disabled) {
        background-color: var(--color-purple-800);
        border-color: var(--color-purple-800);
      }
    }
  }
`

export default alertCss
