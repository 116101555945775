export type TextVariants = 'jumbo' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'subtitle' | 'body' | 'caption1' | 'caption2'
export type FontWeightVariants = 'bold' | 'semibold' | 'regular'

interface FontStyle {
  fontSize: string
  lineHeight: string
}

export const fontFamily =
  '"Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;'

export type FontWeights = Record<FontWeightVariants, string>
export const fontWeights: FontWeights = {
  bold: '700',
  semibold: '600',
  regular: '400',
}

export type FontSizes = Record<TextVariants, string>
export const fontSizes: FontSizes = {
  jumbo: '42px',
  h1: '34px',
  h2: '28px',
  h3: '24px',
  h4: '20px',
  h5: '18px',
  subtitle: '16px',
  body: '14px',
  caption1: '12px',
  caption2: '10px',
}

export type LineHeights = Record<TextVariants, string>
export const lineHeights: LineHeights = {
  jumbo: '50px',
  h1: '44px',
  h2: '40px',
  h3: '32px',
  h4: '30px',
  h5: '26px',
  subtitle: '24px',
  body: '20px',
  caption1: '18px',
  caption2: '16px',
}

export type Texts = Record<TextVariants, FontStyle>
export const texts: Texts = {
  jumbo: {
    fontSize: fontSizes.jumbo,
    lineHeight: lineHeights.jumbo,
  },
  h1: {
    fontSize: fontSizes.h1,
    lineHeight: lineHeights.h1,
  },
  h2: {
    fontSize: fontSizes.h2,
    lineHeight: lineHeights.h2,
  },
  h3: {
    fontSize: fontSizes.h3,
    lineHeight: lineHeights.h3,
  },
  h4: {
    fontSize: fontSizes.h4,
    lineHeight: lineHeights.h4,
  },
  h5: {
    fontSize: fontSizes.h5,
    lineHeight: lineHeights.h5,
  },
  subtitle: {
    fontSize: fontSizes.subtitle,
    lineHeight: lineHeights.subtitle,
  },
  body: {
    fontSize: fontSizes.body,
    lineHeight: lineHeights.body,
  },
  caption1: {
    fontSize: fontSizes.caption1,
    lineHeight: lineHeights.caption1,
  },
  caption2: {
    fontSize: fontSizes.caption2,
    lineHeight: lineHeights.caption2,
  },
}

export const fonts = {
  pretendard: fontFamily,
}
