import React, { FC, MouseEventHandler, useEffect, useRef } from 'react'
import Box from '~/components/Box/Box'
import { CSSProperties } from '@xstyled/styled-components'
import IconStar from '~/assets/images/icons/star-solid.svg'
import { CommonPropertyProps } from '~/components/Search/searchTypes'
import PropertyItem from '~/components/Search/PropertyItem'

interface SearchListItemProps extends CommonPropertyProps {
  width?: CSSProperties['width']
  onClick?: MouseEventHandler
  isSelected?: boolean // 선택여부
  isFocused?: boolean // focus 여부
  isInterested?: boolean // 관심부동산 여부
  preventEventBubbling?: boolean
}
const SearchListItem: FC<SearchListItemProps> = ({
  type,
  title,
  width = '100%',
  onClick,
  jibunAddress,
  buildingName,
  roadNameAddress,
  serviceAreaCode,
  lineCode,
  isSelected,
  isFocused,
  isInterested,
  preventEventBubbling,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.scrollIntoView({ block: 'nearest' })
    }
  }, [isFocused])

  const handleMouseDown: MouseEventHandler = (event) => {
    if (preventEventBubbling) {
      event.preventDefault()
    }
    onClick?.(event)
  }

  return (
    <Box
      ref={ref}
      position="relative"
      width={width}
      backgroundColor={{
        _: !isFocused ? 'none' : 'gray-200',
        hover: !isSelected ? 'gray-200' : 'none',
      }}
      cursor={onClick ? 'pointer' : 'default'}
      onMouseDown={handleMouseDown}
    >
      {isSelected && (
        <Box position="absolute" width="100%" height="100%" backgroundColor="system-white" opacity={0.65} />
      )}
      <PropertyItem
        type={type}
        title={title}
        leading={
          isInterested && (
            <Box display="inline-block" marginRight="6px" marginTop="1px" verticalAlign="text-top">
              <IconStar width="14px" height="14px" color="var(--color-event-bookmark)" />
            </Box>
          )
        }
        buildingName={buildingName}
        jibunAddress={jibunAddress}
        roadNameAddress={roadNameAddress}
        serviceAreaCode={serviceAreaCode}
        lineCode={lineCode}
      />
    </Box>
  )
}

export default SearchListItem
