import { ImageLoaderProps } from 'next/image'

const IMAGE_HOST = process.env.NEXT_PUBLIC_PHOTO_URL

interface LoaderProps extends Omit<ImageLoaderProps, 'width'> {
  width?: number
}

const rtbProxyImageLoader = ({ src }: LoaderProps) => {
  return `${IMAGE_HOST}/${src}`
}

export default rtbProxyImageLoader
