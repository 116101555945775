import SectionContent from '~/templates/place/detail/SectionContent'
import { Trans, useTranslation } from 'next-i18next'
import Typography from '~/components/Typography/Typography'
import { FC, ReactNode, useState } from 'react'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import OfficeRentFeeTrendChart from '~/templates/place/detail/lease/office/OfficeRentFeeTrendChart'
import Box from '~/components/Box/Box'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import ChartToolButton from '~/templates/common/view/chart/ChartToolButton'
import OfficeMarketLeasePriceComparison from '~/templates/place/detail/lease/office/OfficeMarketLeasePriceComparison'
import LwhMarketLeasePriceComparison from '~/templates/place/detail/lease/lwh/LwhMarketLeasePriceComparison'
import { useOfficeDetailDataV1BuildingsOfficeRaIdGet } from '~/libs/apis/data/buildings-office/buildings-office'
import { isNotNil } from '@toss/utils'
import { useLwhDetailDataV1BuildingsLwhRaIdGet } from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import NoDataWithInquiry from '~/templates/place/detail/NoDataWithInquiry'
import GuidePopover from '~/templates/common/popover/GuidePopover'
import OfficeLeasePrdHistoryGrid from '~/templates/place/detail/lease/office/OfficeLeasePrdHistoryGrid'
import LwhLeasePrdHistoryGrid from '~/templates/place/detail/lease/lwh/LwhLeasePrdHistoryGrid'
import { LWH_BOE_FILTER_CODES_WITHOUT_ALL } from '~/libs/constants/filter'

type ContentTitles = 'typicalFloorLeasePrices' | 'vacancy'
type LeaseContentItems = Record<ContentTitles, ReactNode>

interface LeaseContentProps extends LeaseContentItems {
  propertyType: string
  isCompletion: boolean
}

const LeaseContent: FC<LeaseContentProps> = ({ propertyType, isCompletion, typicalFloorLeasePrices, vacancy }) => {
  const { t } = useTranslation()
  const { t: guide } = useTranslation('common', { keyPrefix: 'guide_popover' })
  const { lastUpdate } = useLastUpdateDate()
  const latestYq = lastUpdate.yq
  const isOffice = propertyType === 'PT01_1'
  const { uid, detailType } = useDetailInfo()
  const { data: officeOut } = useOfficeDetailDataV1BuildingsOfficeRaIdGet(uid, {
    swr: {
      keepPreviousData: true,
      enabled: isOffice && isNotNil(uid),
    },
  })
  const { data: lwhOut } = useLwhDetailDataV1BuildingsLwhRaIdGet(uid, {
    swr: {
      keepPreviousData: true,
      enabled: !isOffice && isNotNil(uid),
    },
  })

  const isExistRentFee = isOffice
    ? isNotNil(officeOut?.rentFeePerM2)
    : isNotNil(lwhOut?.lowRentFeePerM2) || isNotNil(lwhOut?.roomRentFeePerM2)

  const boeTypeCode = officeOut?.boeTypeCode
  const buildingSizeCode = officeOut?.buildingSizeCode
  const isShowMarketLeaseTrendComparison =
    LWH_BOE_FILTER_CODES_WITHOUT_ALL.includes(lwhOut?.boeTypeCode ?? '') || isOffice

  const [valueType, setValueType] = useState('rentFee')
  const [period, setPeriod] = useState('5')

  const handleValueTypeChanged = (valueType: string) => {
    setValueType(valueType)
  }
  const handlePeriodChanged = (period: string) => {
    setPeriod(period)
  }

  const marketLeasePriceComparisonSummary = (
    <Typography variant="caption1" color="gray-800">
      {t('detail.lease.market_lease_trend_comparison_explanation')}
    </Typography>
  )

  const leasePriceSummary = (
    <Typography variant="caption1" color="gray-800">
      {t('detail.lease.lease_price_summary', { yq: latestYq })}
    </Typography>
  )

  const vacancySummary = (
    <Typography variant="caption1" color="gray-800">
      {t('detail.lease.vacancy_summary', { yq: latestYq })}
    </Typography>
  )

  const totalAreaSizeGuidePopover = (
    <GuidePopover buttonTitle={guide('total_area_size_guide')} popperWidth="160px">
      <Typography fontWeight="bold" color="blue-700" pb={1.5}>
        {guide('total_area_standard')}
      </Typography>
      <Typography variant="caption1" color="gray-800" whiteSpace="pre-wrap">
        <Trans
          i18nKey={`guide_popover.${isOffice ? 'office' : 'lwh'}_total_area_standard_content`}
          components={{
            style: <Typography variant="caption1" color="gray-800" fontWeight="bold" as="span" />,
          }}
        />
      </Typography>
    </GuidePopover>
  )

  return (
    <>
      {isCompletion && (
        <SectionContent title={t('detail.lease.lease_price')} summary={isExistRentFee && leasePriceSummary}>
          {isExistRentFee ? typicalFloorLeasePrices : <NoDataWithInquiry type="lease_price" />}
        </SectionContent>
      )}
      {isCompletion && isExistRentFee && (
        <SectionContent title={t('detail.lease.vacancy')} summary={vacancySummary}>
          {vacancy}
        </SectionContent>
      )}
      {isOffice && isCompletion && isExistRentFee && (
        <SectionContent title={t('detail.lease.rent_fee_trend')}>
          <OfficeRentFeeTrendChart />
          <DataSourceUpdate category="detail.lease" />
        </SectionContent>
      )}
      <Box position="relative">
        {detailType === 'full' && isOffice && (
          <Box position="absolute" right={0} top={0}>
            <ChartToolButton
              buildingSizeId={uid}
              raIds={uid}
              propertyType={propertyType}
              valueType={valueType}
              period={period}
              boeCodes={boeTypeCode}
              buildingSizeCode={buildingSizeCode}
            />
          </Box>
        )}
        {isShowMarketLeaseTrendComparison && (
          <SectionContent
            title={t('detail.lease.market_lease_trend_comparison')}
            summary={marketLeasePriceComparisonSummary}
            popover={totalAreaSizeGuidePopover}
          >
            {isOffice ? (
              <OfficeMarketLeasePriceComparison
                onValueTypeSelected={handleValueTypeChanged}
                onPeriodSelected={handlePeriodChanged}
              />
            ) : (
              <LwhMarketLeasePriceComparison />
            )}
            <DataSourceUpdate category="detail.lease" />
          </SectionContent>
        )}

        {isCompletion && (
          <SectionContent title={t('detail.lease.lease_product_history')}>
            {isOffice ? (
              <OfficeLeasePrdHistoryGrid key={uid} uid={uid} />
            ) : (
              <LwhLeasePrdHistoryGrid key={uid} uid={uid} />
            )}
            <DataSourceUpdate category="detail.prd.rsquare" />
          </SectionContent>
        )}
      </Box>
    </>
  )
}

export default LeaseContent
