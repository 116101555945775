import { FC } from 'react'
import styled, { CSSObject } from 'styled-components'
import Box from '~/components/Box/Box'
import { css } from '@xstyled/styled-components'
import Tooltip, { TooltipProps } from '~/components/Tooltip/Tooltip'
import { EmitType } from '@syncfusion/ej2-base'
import { TooltipEventArgs } from '@syncfusion/ej2-popups/src/tooltip/tooltip'
import { usePrevious } from '@toss/react'

interface Item {
  text: string
  value: string
  disabled?: boolean
  tooltip?: TooltipProps
  isTooltipOpen?: boolean
}

type TabSize = 'sm' | 'md'
type TabColor = 'gray' | 'primary' | 'pink'

export interface TabButtonProps {
  items: Array<Item>
  size?: TabSize
  color?: TabColor
  selected?: string
  onSelect?: (value: string) => void
}

interface TabItemProps {
  selected: boolean
  tabSize: TabSize
  tabColor: TabColor
}

const TabButton: FC<TabButtonProps> = ({ items, size = 'md', color = 'gray', selected, onSelect }) => {
  const previousSelected = usePrevious(selected)
  function handleClick(this: string) {
    onSelect?.(this)
  }

  const handleDefaultBeforeOpen: EmitType<TooltipEventArgs> = (event) => {
    event.cancel = true
  }

  const selectedKey = items.find((item) => item.value === selected)?.disabled ? previousSelected : selected

  return (
    <Box display="flex" gap={size === 'md' ? '12px' : '8px'}>
      {items.map((item, index) => {
        const isSelected = item.value === (selectedKey || items[0].value)

        return (
          <Tooltip
            key={`tab-button-tool-tip-${index}`}
            content={item.tooltip?.content || ''}
            width={item.tooltip?.width}
            target={item.tooltip?.target}
            position={item.tooltip?.position}
            tipPointerPosition={item.tooltip?.tipPointerPosition}
            beforeOpen={item.isTooltipOpen && item.tooltip ? item.tooltip.beforeOpen : handleDefaultBeforeOpen}
            align={item.tooltip?.align}
          >
            <StyledButton
              key={index}
              selected={isSelected}
              onClick={item.disabled ? () => {} : handleClick.bind(item.value)}
              tabSize={size}
              tabColor={color}
              disabled={item.disabled}
            >
              {item.text}
            </StyledButton>
          </Tooltip>
        )
      })}
    </Box>
  )
}

const sizeStyle: Record<TabSize, CSSObject> = {
  sm: {
    fontSize: 'body',
    height: '32px',
  },
  md: {
    fontSize: 'subtitle',
    height: '36px',
  },
}

const selected: Record<TabColor, CSSObject> = {
  primary: {
    color: 'system-white',
    backgroundColor: 'purple-700',
    fontWeight: 700,
    borderWidth: '1px',
    borderColor: 'purple-700',
  },
  gray: {
    color: 'system-white',
    backgroundColor: 'gray-800',
    fontWeight: 700,
    borderWidth: '1px',
    borderColor: 'system-white',
  },
  pink: {
    color: 'system-white',
    backgroundColor: 'purple-600',
    fontWeight: 700,
    borderWidth: '1px',
    borderColor: 'transparent',
  },
}

const unSelected: Record<TabColor, CSSObject> = {
  primary: {
    color: 'gray-600',
    backgroundColor: 'system-white',
    fontWeight: 600,
    borderWidth: '1px',
    borderColor: 'gray-300',
  },
  gray: {
    color: 'gray-600',
    backgroundColor: 'gray-200',
    fontWeight: 600,
    borderWidth: '1px',
    borderColor: 'gray-200',
  },
  pink: {
    color: 'gray-600',
    backgroundColor: 'transparent',
    fontWeight: 600,
    borderWidth: '1px',
    borderColor: 'transparent',
    '&:disabled': {
      color: 'gray-400',
    },
  },
}

const StyledButton = styled.button<TabItemProps>`
  &:focus {
    outline: none;
  }
  padding: 5px 13px;
  border-radius: 32px;
  ${({ tabSize }) => css(sizeStyle[tabSize])}
  ${(p) => css(p.selected ? selected[p.tabColor] : unSelected[p.tabColor])}
  ${(p) => css({ cursor: p.disabled ? 'default' : 'pointer' })}
`

export default TabButton
