import ky, { HTTPError } from '@toss/ky'
import { Options } from 'ky/distribution/types/options'
import { PostUsersSignInResponse } from '~/libs/apis/service/model'
import RaDataError from '~/libs/errors/RaDataError'
import RaServiceError from '~/libs/errors/RaServiceError'
import { datadogRum } from '@datadog/browser-rum'
import { createSearchParamString } from '@toss/utils'
import NewVersionError from '~/libs/errors/NewVersionError'
import semver from 'semver/preload'

const apiHost = process.env.NEXT_PUBLIC_API_URL

type ResponseType = 'blob' | 'json'

let accessToken: string | undefined

interface RequestType {
  url: string
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  params?: any
  data?: unknown
  headers?: Record<string, string | undefined>
}

interface RequestOptions {
  responseType?: ResponseType
  skipAuthRefresh?: boolean
  timeout?: number
  skipNewVersionCheck?: boolean
}

export function checkNewApiVersion(url: string, headers: Headers) {
  const apiType = url.split('/')[1]
  if (!apiType) {
    return false
  }

  const apiVersionKey = `x-${apiType}-api-version`

  const savedApiVersion = localStorage.getItem(apiVersionKey)
  const currentApiVersion = headers.get(apiVersionKey)

  // 비표준 버전은 무시
  if (semver.valid(currentApiVersion) === null || !currentApiVersion) {
    return false
  }

  if (semver.valid(savedApiVersion) === null || !savedApiVersion) {
    localStorage.setItem(apiVersionKey, currentApiVersion)
    return false
  }

  if (semver.gt(currentApiVersion, savedApiVersion)) {
    localStorage.setItem(apiVersionKey, currentApiVersion)
    throw new NewVersionError()
  }
}

export function setAccessToken(token: PostUsersSignInResponse | undefined) {
  accessToken = token?.accessToken
}

// eslint-disable-next-line import/no-unused-modules
export function getAccessToken() {
  return accessToken
}

// eslint-disable-next-line import/no-unused-modules
export const raApiCall = async <T = unknown>(
  requestType: RequestType,
  option?: RequestOptions,
): Promise<T | undefined> => {
  return customInstance(apiHost, requestType, option)
}

// eslint-disable-next-line import/no-unused-modules
export const customInstance = async <T = unknown>(
  host: string | undefined,
  { url, method, params, data, headers = {} }: RequestType,
  option?: RequestOptions,
): Promise<T | undefined> => {
  if (!host) {
    throw new Error('host is empty')
  }

  if (!option?.skipAuthRefresh) {
    headers['Authorization'] = `Bearer ${accessToken}`
  }

  const options: Options = {
    method: method,
    headers: headers,
    retry: {
      limit: 0,
    },
  }
  if (params) {
    options.searchParams = createSearchParamString(params)
  }
  if (option?.timeout) {
    options.timeout = option?.timeout
  }

  if (data instanceof FormData || data instanceof URLSearchParams) {
    options.body = data
  } else {
    options.json = data
  }

  if (headers['Content-Type'] === 'multipart/form-data') {
    delete headers['Content-Type']
  }
  try {
    const response = await ky(host + url, options)
    // TODO: ky에서는 json parser 실행시 204가 떨어지면 자동으로 empty string을 내려주는 기능이 있는데 제대로 동작하지 않고 있음. 구조 개선 필요
    if (response.status === 204) {
      return Promise.resolve(undefined)
    }

    if (!option?.skipNewVersionCheck) {
      checkNewApiVersion(url, response.headers)
    }

    if (option?.responseType === 'blob') {
      //@ts-ignore Orval 기준 unknown 으로 요청하며 ky는 response type에 generic이 없기때문에 어느 한쪽이 개선될때까지는 일단 무시
      return response.blob()
    } else {
      return response.json()
    }
  } catch (e) {
    if (e instanceof HTTPError) {
      const errorResponseBody = await e.response.json()
      if (errorResponseBody.hasOwnProperty('detail')) {
        throw new RaDataError(e.response.status, errorResponseBody)
      } else {
        throw new RaServiceError(e.response.status, errorResponseBody)
      }
    } else if (e instanceof NewVersionError) {
      throw e
    } else {
      datadogRum.addError(e)
    }
  }
}
