import { FC } from 'react'
import Typography from '~/components/Typography/Typography'
import { Trans } from 'next-i18next'

interface RegistrationCountProps {
  count?: number
}
const RegistrationCount: FC<RegistrationCountProps> = ({ count }) => {
  return (
    <Typography variant="subtitle" fontWeight="semibold" color="gray-800" as="span">
      <Trans
        i18nKey="registration.registration_count"
        values={{ number: count }}
        components={{
          color: <Typography variant="subtitle" fontWeight="semibold" color="purple-700" as="span" />,
        }}
      />
    </Typography>
  )
}

export default RegistrationCount
