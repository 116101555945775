import Box from '~/components/Box/Box'
import FunctionButtons from '~/templates/place/detail/overview/FunctionButtons'
import { ButtonData } from '~/templates/place/detail/overview/OverviewButton'
import Typography from '~/components/Typography/Typography'
import { useDetailScrollState } from '~/templates/DetailScrollProvider'

interface NavBarProps {
  buttonData: ButtonData[]
  name?: string
}

const NavBar = ({ buttonData, name }: NavBarProps) => {
  const { isTop } = useDetailScrollState()

  return (
    <Box
      display="flex"
      gap="20px"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      height={56}
      px={4}
      py={2}
      backgroundColor="system-white"
    >
      <Box display="flex" flex="1" overflow="hidden">
        {!isTop && (
          <Typography
            variant="h5"
            fontWeight="semibold"
            color="gray-800"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {name}
          </Typography>
        )}
      </Box>
      <FunctionButtons data={buttonData} />
    </Box>
  )
}

export default NavBar
