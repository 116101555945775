import React, { FC, PropsWithChildren } from 'react'
import { TextBoxCommonType } from '~/components/TextBox/textBoxTypes'
import styled from '@xstyled/styled-components'
import Typography from '~/components/Typography/Typography'
import Box from '~/components/Box/Box'

const TextBoxWrapper: FC<PropsWithChildren<TextBoxCommonType>> = ({
  leadingIcon,
  trailingIcon,
  unit,
  size = 's',
  children,
}) => {
  const iconSize = size === 's' ? '16px' : '18px'
  const unitVariant = size === 's' ? 'body' : 'subtitle'

  return (
    <Wrapper>
      <div className={`e-input-group e-size-${size}`}>
        {leadingIcon && (
          <Box
            display="flex"
            alignItems="center"
            width={{ _: iconSize, '& > svg': iconSize }}
            height={{ _: iconSize, '& > svg': iconSize }}
            mr="8px"
          >
            {leadingIcon}
          </Box>
        )}
        {children}
        {trailingIcon && (
          <Box
            display="flex"
            alignItems="center"
            width={{ _: iconSize, '& > svg': iconSize }}
            height={{ _: iconSize, '& > svg': iconSize }}
            ml="8px"
          >
            {trailingIcon}
          </Box>
        )}
        {unit && (
          <Typography variant={unitVariant} ml="8px" fontWeight="600" whiteSpace="nowrap">
            {unit}
          </Typography>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  // control
  width: 100%;
  & > .e-input-group {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  & > .e-input-group.e-size-s {
    padding: 8px 16px;
    border-radius: 8px;
  }

  & > .e-input-group.e-size-m {
    padding: 10px 16px;
    border-radius: 10px;

    & .e-control.e-lib.e-input {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & > .e-input-group.e-size-l {
    padding: 10px 16px;
    border-radius: 10px;
    height: 56px;

    & .e-control.e-lib.e-input {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & > .e-input-group:not(.e-success):not(.e-warning):not(.e-error) {
    border: 1px solid var(--color-gray-400);
  }

  &:has(.e-error) {
    > .e-input-group {
      border-color: var(--color-event-error) !important;

      .e-control.e-textbox.e-lib.e-input:not(:focus-within),
      .e-control.e-numerictextbox.e-lib.e-input:not(:focus-within),
      .e-control.e-maskedtextbox.e-lib.e-input:not(:focus-within) {
        color: var(--color-event-error);

        &::placeholder {
          color: var(--color-event-error);
        }
      }
    }
  }

  & > .e-input-group:not(:focus-within),
  & > .e-input-group > .e-control-wrapper {
    box-shadow: none !important;
    border: none;
  }

  & > .e-input-group .e-control.e-lib.e-input {
    height: auto;
    padding: 0;
    margin: 0;
    border-radius: 0;
    color: var(--color-gray-800);
  }

  // focus / active / exist value
  &:not(:has(.e-input[disabled])) {
    & > .e-input-group:active,
    & > .e-input-group:focus-within {
      box-shadow: none !important;
      border-color: var(--color-gray-800) !important;
    }
  }

  // placeholder
  & .e-control.e-lib.e-input::placeholder {
    color: var(--color-gray-500);
  }

  // autofill
  & .e-control.e-lib.e-input:-webkit-autofill,
  & .e-control.e-lib.e-input:-webkit-autofill:hover,
  & .e-control.e-lib.e-input:-webkit-autofill:focus,
  & .e-control.e-lib.e-input:-webkit-autofill:active {
    -webkit-text-fill-color: var(--color-gray-800);
    box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  // disabled
  & > .e-input-group:has(.e-input[disabled]) {
    background: var(--color-gray-200);
    color: var(--color-gray-500);
    -webkit-text-fill-color: var(--color-gray-500);

    & > .e-control-wrapper {
      background: var(--color-gray-200) !important;
      border: none !important;
    }
  }

  .e-input-group.e-control-wrapper .e-input[disabled] {
    -webkit-text-fill-color: var(--color-gray-500);
  }

  // read-only
  .e-input-group:has(.e-input[readonly]) {
    background: var(--color-gray-200);
    -webkit-text-fill-color: var(--color-gray-700);
    color: var(--color-gray-700);
  }

  .e-input-group.e-control-wrapper .e-input[readonly] {
    background: var(--color-gray-200);
  }
`

export default TextBoxWrapper
