import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import { toPyNumber, toSqmNumber } from '~/libs/utils/number'

/**
 * - areaConverterForClient: 데이터의 흐름이 서버 -> 클라이언트인 경우  e.g.) API 응답을 화면에 렌더
 * - areaConverterForServer: 데이터의 흐름이 클라이언트 -> 서버인 경우  e.g.) 사용자 입력값을 필터로 설정
 */
const useAreaUnitTransformer = () => {
  const { areaUnit } = useUserConfiguration()

  return {
    areaConverterForClient: (number: number, type: 'total' | 'per_unit' = 'total') => {
      if (areaUnit === 'M2') {
        return number
      }
      return toPyNumber(number, type)
    },
    areaConverterForServer: (number: number, type: 'total' | 'per_unit' = 'total') => {
      if (areaUnit === 'M2') {
        return number
      }
      return toSqmNumber(number, type)
    },
  }
}

export default useAreaUnitTransformer
