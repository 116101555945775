import React, { FC, ReactNode } from 'react'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { Badge } from '~/components'
import { CommonPropertyProps } from '~/components/Search/searchTypes'
import { useTranslation } from 'next-i18next'
import { SwitchCase } from '@toss/react'
import SubwayLineBadge from '~/templates/common/content/SubwayLineBadge'
import PropertyBadge from '~/templates/common/content/PropertyBadge'

const PropertyItem: FC<
  CommonPropertyProps & {
    leading?: ReactNode
  }
> = ({ type, buildingName, jibunAddress, roadNameAddress, title, serviceAreaCode, lineCode, leading }) => {
  return (
    <Box width="100%" display="flex" padding="12px 16px" alignItems="center">
      <Box flex="1" overflowX="hidden">
        <Box flex="1" display="flex" flexDirection="column" gap="6px" overflowX="hidden">
          <Typography variant="body" fontWeight={type === 'local' || type === 'subway' ? '600' : '400'}>
            {leading}
            {title}
          </Typography>
          <SwitchCase
            caseBy={{
              office: <BuildingSubTitle jibunAddress={jibunAddress} roadNameAddress={roadNameAddress} />,
              lwh: <BuildingSubTitle jibunAddress={jibunAddress} roadNameAddress={roadNameAddress} />,
              land: <LandSubTitle buildingName={buildingName} roadNameAddress={roadNameAddress} />,
            }}
            value={type}
          />
        </Box>
      </Box>
      {type === 'subway' ? (
        <SubwayLineBadge subwayLineCode={lineCode} subwayServiceAreaCode={serviceAreaCode} />
      ) : (
        <PropertyBadge type={type} />
      )}
    </Box>
  )
}

const BuildingSubTitle: FC<Pick<CommonPropertyProps, 'jibunAddress' | 'roadNameAddress'>> = ({
  jibunAddress,
  roadNameAddress,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })

  return (
    (jibunAddress || roadNameAddress) && (
      <Box flex={1} display="flex" flexDirection="column">
        {jibunAddress && (
          <Typography
            variant="caption1"
            color="gray-700"
            overflowX="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {jibunAddress}
          </Typography>
        )}
        {roadNameAddress && (
          <Box flex="1" display="flex" gap="4px" alignItems="center">
            <Badge type="04" size="tiny" content={t('road_name')} />
            <Typography
              flex="1"
              variant="caption1"
              color="gray-700"
              overflowX="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {roadNameAddress}
            </Typography>
          </Box>
        )}
      </Box>
    )
  )
}

const LandSubTitle: FC<Pick<CommonPropertyProps, 'roadNameAddress' | 'buildingName'>> = ({
  buildingName,
  roadNameAddress,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })

  const subTitle: string[] = []

  if (roadNameAddress) {
    subTitle.push(roadNameAddress)
  }

  if (buildingName) {
    subTitle.push(buildingName)
  }

  return (
    (roadNameAddress || buildingName) && (
      <Box flex="1" display="flex" gap="4px" alignItems="center">
        {roadNameAddress && <Badge type="04" size="tiny" content={t('road_name')} />}
        <Typography
          flex="1"
          variant="caption1"
          color="gray-700"
          overflowX="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {subTitle.join(', ')}
        </Typography>
      </Box>
    )
  )
}

export default PropertyItem
