import { FC, useEffect } from 'react'
import TabButton from '~/components/TabButton/TabButton'
import useLwhStackingPlan from '~/templates/common/view/stacking-plan/useLwhStackingPlan'
import StackingPlanParams from '~/templates/common/view/stacking-plan/StackingPlanParams'

interface LwhDongSelectorProps extends StackingPlanParams {
  onDongSelected: (value: string) => void
}

const LwhDongSelector: FC<LwhDongSelectorProps> = ({ raId, dongName, onDongSelected, year, quarter }) => {
  const {
    dongResponse: { data },
  } = useLwhStackingPlan({ raId, year, quarter })
  useEffect(() => {
    if (dongName) {
      return
    }
    const firstDongName = data?.at(0)?.dongName
    if (firstDongName) {
      onDongSelected(firstDongName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  if (!data || data.length === 0) {
    return null
  }

  const items = data.map((item) => ({ text: item.dongName ?? '-', value: item.dongName ?? '' }))
  return <TabButton items={items} selected={dongName ?? undefined} onSelect={onDongSelected} />
}

export default LwhDongSelector
