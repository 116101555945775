import { FC, useState } from 'react'
import {
  useGetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGet,
  useOfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGet,
} from '~/libs/apis/data/buildings-office/buildings-office'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { lowerCase } from 'lower-case'
import { useTranslation } from 'next-i18next'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import { SeriesModel } from '@syncfusion/ej2-charts'
import {
  commonEmptyPointSettings,
  commonPrimaryXAxisSettings,
  commonPrimaryYAxisSettings,
  reverseHeaderFormatToolTipSettings,
} from '~/libs/constants/chart'
import { formatDateYq, parseDateYq } from '~/libs/utils/date'
import { toOnesNumber } from '~/libs/utils/number'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import PeriodFilter, { PeriodType } from '~/templates/common/filter/PeriodFilter'
import CustomizableChart from '~/components/Chart/CustomizableChart'
import { isNotNil } from '@toss/utils'
import useUnitTransformer from '~/libs/hooks/useUnitTransformer'
import type { GetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGetParams } from '~/libs/apis/data/model'

const OfficeRentFeeTrendChart: FC = () => {
  const { uid: raId, detailType } = useDetailInfo()
  const { t } = useTranslation('chart', { keyPrefix: 'label_format' })
  const { t: common } = useTranslation('common', { keyPrefix: 'common_term' })
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const isKrw = currencyUnit === 'KRW'
  const currencyUnitInString = isKrw ? 'won' : 'dollar'
  const { unitConverterForClient } = useUnitTransformer()
  const isFull = detailType === 'full'
  const { lastUpdate } = useLastUpdateDate()
  const [period, setPeriod] = useState<PeriodType>({
    minYear: (+lastUpdate.year - 5 + 1).toString(),
    minQuarter: lastUpdate.quarter,
    maxYear: lastUpdate.year,
    maxQuarter: lastUpdate.quarter,
  })
  const { data: rentFeeData } = useOfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGet(
    {
      ...period,
      raId,
      valueType: 'rentFee',
    },
    { swr: { keepPreviousData: true } },
  )
  const { data: prdInfo } = useGetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGet(
    raId,
    period as GetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGetParams,
  )

  const rentFeeDataSource = rentFeeData?.map((item) => {
    return {
      dateYq: parseDateYq(item.dateYq),
      value: isNotNil(item.officeValue) ? toOnesNumber(unitConverterForClient(item.officeValue, 'per_unit')) : null,
      yearQuarter: formatDateYq(item.dateYq),
    }
  })

  const prdInfoDataSource = prdInfo?.map((item) => {
    return {
      dateYq: parseDateYq(item.year + item.quarter),
      value: toOnesNumber(unitConverterForClient(item.rentFeePerM2 ?? 0, 'per_unit')),
      yearQuarter: formatDateYq(item.year + item.quarter),
    }
  })

  const series: SeriesModel[] = [
    {
      xName: 'dateYq',
      yName: 'value',
      name: common('quote'),
      fill: 'var(--color-chart-10)',
      dataSource: rentFeeDataSource,
      tooltipMappingName: 'yearQuarter',
      emptyPointSettings: commonEmptyPointSettings,
      type: 'Line',
    },
    {
      xName: 'dateYq',
      yName: 'value',
      name: common('contract_price'),
      fill: 'var(--color-chart-13)',
      dataSource: prdInfoDataSource,
      tooltipMappingName: 'yearQuarter',
      emptyPointSettings: commonEmptyPointSettings,
      type: 'Scatter',
    },
  ]

  const handlePeriodFilterClick = (newValue: PeriodType) => setPeriod((prev) => ({ ...prev, ...newValue }))

  return (
    <>
      {isFull && (
        <PeriodFilter
          type="office"
          marketType="lease"
          onClick={handlePeriodFilterClick}
          showTitle={true}
          showAllPeriod={false}
          defaultValue="5"
        />
      )}
      <CustomizableChart
        chartId={`MarketLeasePriceTrend_${detailType}`}
        height={347}
        primaryXAxis={{ valueType: 'DateTime', intervalType: 'Years', ...commonPrimaryXAxisSettings }}
        primaryYAxis={{
          labelFormat: t(`${currencyUnitInString}_${lowerCase(areaUnit)}`),
          ...commonPrimaryYAxisSettings,
        }}
        tooltipSettings={{ ...reverseHeaderFormatToolTipSettings, header: '<b>${point.tooltip}</b>' }}
        series={series}
      />
    </>
  )
}

export default OfficeRentFeeTrendChart
