import { FC } from 'react'
import Typography from '~/components/Typography/Typography'
import Box from '~/components/Box/Box'

interface MessageComponentProps {
  message: string
}
const MessageComponent: FC<MessageComponentProps> = ({ message }) => {
  return (
    <Box display="flex" justifyContent="center" backgroundColor="gray-100" borderRadius={10} py={10}>
      <Typography fontWeight="semibold" color="gray-500" whiteSpace="pre-wrap" textAlign="center">
        {message}
      </Typography>
    </Box>
  )
}

export default MessageComponent
