import { FC } from 'react'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { useTranslation } from 'next-i18next'
import { getAccessToken } from '~/libs/utils/customInstance'
import Button from '~/components/Button/Button'
import IconSentOut from '~/assets/images/icons/sentout.svg'
import { useDetailInfo } from '~/templates/DetailTypeProvider'

interface OpenNewRegistrationProps {
  address: string | null
}

const OpenNewRegistration: FC<OpenNewRegistrationProps> = ({ address }) => {
  const { t } = useTranslation('common', { keyPrefix: 'registration' })
  const { detailType } = useDetailInfo()
  const isFull = detailType === 'full'

  const handleOpenNewRegistration = () => {
    window.open(
      `${process.env.DATAHUB_URL}/register-request?filter=simple&ra-token=${getAccessToken()}&address=${address}`,
      '_blank',
      'noopener',
    )
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={4} mt="10px">
      <Typography variant="subtitle" whiteSpace={isFull ? undefined : 'pre-wrap'} textAlign="center" color="gray-800">
        {t('data_hub_message')}
      </Typography>
      <Button size="lg" content={t('data_hub_link')} icon={<IconSentOut />} onClick={handleOpenNewRegistration} />
    </Box>
  )
}

export default OpenNewRegistration
