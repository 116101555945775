import { DefaultValue, selector } from 'recoil'
import { TENANT_FILTER_KEYS_FROM_DIALOG, TenantsFilterParams } from '~/libs/constants/topic'
import tenantFilterStateFamily from '~/recoil/topic-tenant-filter/atom'

const tenantFilterDialogSelector = selector({
  key: 'tenantFilterDialogSelector',
  get: ({ get }) =>
    TENANT_FILTER_KEYS_FROM_DIALOG.reduce<TenantsFilterParams>(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: get(tenantFilterStateFamily(currentValue)),
      }),
      {},
    ),
  set: ({ set }, newValue) => {
    TENANT_FILTER_KEYS_FROM_DIALOG.forEach((key) =>
      set(tenantFilterStateFamily(key), newValue instanceof DefaultValue ? newValue : undefined),
    )
  },
})

export default tenantFilterDialogSelector
