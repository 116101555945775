import { useTheme } from '@xstyled/styled-components'

const useCommonChartPalette = () => {
  const theme = useTheme()

  return Object.entries(theme.colors)
    .filter(([key]) => key.startsWith('chart'))
    .map((item) => item[1].toString())
}

export default useCommonChartPalette
