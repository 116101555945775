import { useRouter } from 'next/router'
import { ExceptionResponse } from '~/libs/apis/service/model'
import { DialogUtility } from '@syncfusion/ej2-popups'
import { postUsersSignOut } from '~/libs/apis/service/api'
import RaServiceError from '~/libs/errors/RaServiceError'
import { differenceInMinutes } from 'date-fns/differenceInMinutes'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import { useToast } from '~/templates/ToastContextProvider'
import useAuth from '~/libs/hooks/useAuth'
import { datadogRum } from '@datadog/browser-rum'
import { millisecondsInHour, minutesInHour } from 'date-fns/constants'
import { HIGHEST_Z_INDEX } from '~/libs/constants/common'

const useRetainSession = () => {
  const router = useRouter()
  const { logout } = useAuth()
  const { t: common } = useTranslation('common')
  const { show } = useToast()
  const timer = useRef<NodeJS.Timeout>()
  const isExpired = useRef(false)

  const logoutAlert = async (error?: ExceptionResponse) => {
    try {
      if (!isExpired.current) {
        isExpired.current = true

        // error 객체가 없는 경우는 2시간 이상 사용 내역이 없어서 로그아웃 팝업이 뜬 경우만 해당함
        DialogUtility.alert({
          title: common('login.logout'),
          content: error ? error.message : common('logout_msg.idle'),
          position: { X: 'center', Y: 'center' },
          okButton: {
            text: common('common_term.confirm'),
          },
          close: () => {
            logout()
          },
          width: '384px',
          zIndex: HIGHEST_Z_INDEX,
        })

        // API로부터 받은 error 메시지가 따로 존재한다면 로그아웃 API를 호출하지 않음
        if (error === undefined) {
          await postUsersSignOut()
        }
      }
    } catch (e) {
      if (e instanceof RaServiceError) {
        show(e.responseBody.message)
      } else {
        datadogRum.addError(e)
      }
    }
  }

  const idleLogout = () => {
    clearCurrentTimer()

    timer.current = setTimeout(logoutAlert, millisecondsInHour * 2)

    return () => {
      clearCurrentTimer()
    }
  }

  const visibilityChange = () => {
    clearCurrentTimer()

    if (document.hidden && !isExpired.current) {
      localStorage.setItem('document-hidden-time', new Date().getTime().toString())
    } else {
      const documentHiddenTime = localStorage.getItem('document-hidden-time')

      if (documentHiddenTime) {
        localStorage.removeItem('document-hidden-time')

        const diff = differenceInMinutes(new Date().getTime(), Number(documentHiddenTime))

        if (diff >= minutesInHour * 2) {
          logoutAlert()
        }
      }

      idleLogout()
    }
  }

  const clearCurrentTimer = () => {
    try {
      if (timer.current) {
        clearTimeout(timer.current)
        timer.current = undefined
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'production') {
        datadogRum.addError(error)
      }
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (!timer.current && !isExpired.current) {
      idleLogout()
    }

    window.addEventListener('visibilitychange', visibilityChange)
    window.addEventListener('click', idleLogout)
    window.addEventListener('mouseover', idleLogout)
    window.addEventListener('keydown', idleLogout)

    return () => {
      clearCurrentTimer()

      window.removeEventListener('visibilitychange', visibilityChange)
      window.removeEventListener('click', idleLogout)
      window.removeEventListener('mouseover', idleLogout)
      window.removeEventListener('keydown', idleLogout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname])

  return {
    logoutAlert,
  }
}

export default useRetainSession
