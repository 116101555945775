import React, { FC, PropsWithChildren, useRef, useState, useEffect } from 'react'
import styled from '@xstyled/styled-components'
import Box from '~/components/Box/Box'
import IconScrollAngleLeft from '~/assets/images/icons/scroll-angle-left.svg?url'
import IconScrollAngleRight from '~/assets/images/icons/scroll-angle-right.svg?url'
import { useCallbackOnce, useResizeObserver } from '@toss/react'

const scrollStepPercentage = 0.7

const ScrollableTabButtonBox: FC<PropsWithChildren> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [showLeftButton, setShowLeftButton] = useState(false)
  const [showRightButton, setShowRightButton] = useState(false)
  const [scrollStep, setScrollStep] = useState(0)

  const handleScroll = () => {
    if (containerRef.current) {
      setShowLeftButton(containerRef.current.scrollLeft > 0)
      setShowRightButton(
        containerRef.current.scrollLeft < containerRef.current.scrollWidth - containerRef.current.clientWidth - 0.5,
      )
    }
  }

  const handleScrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= scrollStep
    }
  }

  const handleScrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += scrollStep
    }
  }

  useEffect(() => {
    const currentContainer = containerRef.current

    if (currentContainer) {
      handleScroll()
      currentContainer.addEventListener('scroll', handleScroll)
      return () => {
        currentContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [containerRef.current?.scrollWidth])

  const dataRef = useResizeObserver((entry) => {
    const { clientWidth } = entry.target
    const newScrollStep = clientWidth * scrollStepPercentage
    setScrollStep(newScrollStep)
    handleScroll()
  })

  const useCallback = useCallbackOnce((ref: HTMLDivElement) => {
    containerRef.current = ref
    dataRef(ref)
  }, [])

  return (
    <OverflowBoxContainer>
      {showLeftButton && <OverflowLeftButton onClick={handleScrollLeft} />}
      {showRightButton && <OverflowRightButton onClick={handleScrollRight} />}
      <TabButtonWrapper ref={useCallback}>{children}</TabButtonWrapper>
    </OverflowBoxContainer>
  )
}

export default ScrollableTabButtonBox

const OverflowBoxContainer = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
`

const TabButtonWrapper = styled(Box)`
  display: flex;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  flex-direction: row;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
`

const OverflowLeftButton = styled(Box)`
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-image: url(${IconScrollAngleLeft});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
`

const OverflowRightButton = styled(Box)`
  position: absolute;
  right: 0;
  width: 22px;
  height: 22px;
  background-image: url(${IconScrollAngleRight});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
`
