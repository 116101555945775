import { atom } from 'recoil'
import { DetailContent } from '~/templates/FullDetailDialogProvider'
import { InquiryItems } from '~/libs/utils/inquiry'

export type DataInquiryType = {
  type: DetailContent
  targetId: string
  inquiryItems?: InquiryItems[]
  isGuest?: boolean
}

const dataInquiryState = atom<DataInquiryType | null>({
  key: 'dataInquiryState',
  default: null,
})

export default dataInquiryState
