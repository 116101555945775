import { useNextRouter } from '~/libs/hooks/useNextRouter'
import useHasPermission from '~/libs/hooks/useHasPermission'
import { PagePermission, PagePermissionType } from '~/libs/constants/permission'
import { useEffect } from 'react'
import { useGetUserMe, useGetUserSubscriptionsPermissions } from '~/libs/apis/service/api'
import { SWR_IMMUTABLE_OPTION } from '~/libs/constants/common'

const usePermissionEffect = (): void => {
  const router = useNextRouter()
  const { data: me } = useGetUserMe()
  const { isLoading, error } = useGetUserSubscriptionsPermissions(SWR_IMMUTABLE_OPTION)
  const hasPermission = useHasPermission(PagePermission[router.pathname as keyof PagePermissionType])

  useEffect(() => {
    if (me && !isLoading && !hasPermission && !error) {
      router.replace('/403')
    }
  }, [isLoading, hasPermission, router, error, me])
}

export default usePermissionEffect
