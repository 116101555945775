import { atom, atomFamily, RecoilState, selectorFamily } from 'recoil'
import isBuildingMode from '~/recoil/data-type/isBuildingMode'

type DrawingControlType = 'circle' | 'polygon' | undefined

const buildingDrawingControlState = atom<DrawingControlType>({
  key: 'buildingDrawingControlState',
  default: undefined,
})

const landDrawingControlState = atom<DrawingControlType>({
  key: 'landDrawingControlState',
  default: undefined,
})

const topicDrawingControlState = atomFamily<DrawingControlType, MapType>({
  key: 'topicDrawingControlState',
  default: undefined,
})

const drawingControlState = selectorFamily<DrawingControlType, MapType>({
  key: 'drawingControlState',
  get:
    (mapType: MapType) =>
    ({ get }) => {
      if (mapType === 'place') {
        const isBuilding = get(isBuildingMode)
        return isBuilding ? get(buildingDrawingControlState) : get(landDrawingControlState)
      } else {
        return get(topicDrawingControlState(mapType))
      }
    },
  set:
    (mapType: MapType) =>
    ({ get, set }, newValue) => {
      let atom: RecoilState<DrawingControlType>
      if (mapType === 'place') {
        const isBuilding = get(isBuildingMode)
        atom = isBuilding ? buildingDrawingControlState : landDrawingControlState
      } else {
        atom = topicDrawingControlState(mapType)
      }
      set(atom, newValue)
    },
})

export default drawingControlState
