import React, { FC, PropsWithChildren, ReactElement } from 'react'
import { isNotNil } from '@toss/utils'
import Box from '~/components/Box/Box'
import { Separated } from '@toss/react'
import ScrollBox from '~/components/Box/ScrollBox'

interface SearchContentProps {
  header?: ReactElement
}
const SearchContent: FC<PropsWithChildren<SearchContentProps>> = ({ header, children }) => {
  return (
    <Box height="100%" display="flex" flexDirection="column">
      {isNotNil(header) && (
        <Box
          borderRadius={{
            '& > div:first-child': '10px 10px 0 0',
          }}
        >
          {header}
        </Box>
      )}
      <ScrollBox variant="vertical">
        <Separated with={<Box backgroundColor="gray-200" height="1px" width="100%" />}>{children}</Separated>
        <Box backgroundColor="gray-200" height="1px" width="100%" />
      </ScrollBox>
    </Box>
  )
}

export default SearchContent
