import Typography from '~/components/Typography/Typography'
import BuildingMapImageArea from '~/templates/place/detail/overview/BuildingMapImageArea'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useDetailScrollState } from '~/templates/DetailScrollProvider'
import { FC } from 'react'
import { AreaLandOut } from '~/libs/apis/data/model'
import BuildingInformationButton from '~/templates/place/detail/overview/BuildingInformationButton'
import Box from '~/components/Box/Box'
import { useGetLandDetailsDataV1LandsDetailsGet } from '~/libs/apis/data/land/land'
import { formatFloorInfo, isArrayAndNotEmpty } from '~/libs/utils/format'
import { useTranslation } from 'next-i18next'
import { formatDateYm, formatDateYmd } from '~/libs/utils/date'
import { useAreaFormat, useCurrencyFormat } from '~/libs/hooks/useFormat'
import { isNotNil } from '@toss/utils'
import useCommonCode from '~/libs/hooks/useCommonCode'
import { useGetRegistrations } from '~/libs/apis/registration/api'
import { Badge } from '~/components'

interface LandOverviewSectionProps {
  data: AreaLandOut
}

interface InfoLineProps {
  label: string
  value: string
}
const InfoLine: FC<InfoLineProps> = ({ label, value }) => (
  <Box display="flex" alignItems="center">
    <Box display="flex" px="6px" py="1px" mr={2} backgroundColor="gray-200">
      <Typography variant="caption2" fontWeight="semibold" color="gray-700">
        {label}
      </Typography>
    </Box>
    <Typography variant="body" color="gray-800">
      {value}
    </Typography>
  </Box>
)

const LandOverviewSection: FC<LandOverviewSectionProps> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: unit } = useTranslation('common', { keyPrefix: 'unit' })
  const { detailType } = useDetailInfo()
  const { data: pnuData, isLoading } = useGetLandDetailsDataV1LandsDetailsGet({ pnus: data?.pnu })
  const { data: registrationData } = useGetRegistrations(
    { pnu: data?.pnu ?? '' },
    {
      swr: { enabled: Boolean(data?.pnu), shouldRetryOnError: false },
    },
  )
  const hasRegistrationData = isArrayAndNotEmpty(registrationData)

  const areaFormatter = useAreaFormat()
  const currencyFormatter = useCurrencyFormat()
  const landData = pnuData?.data?.[0]
  const isFull = detailType === 'full'
  const { isTop } = useDetailScrollState()
  const { codeToName } = useCommonCode()
  const commonTypography = (
    <>
      {isTop ? (
        <Box display="flex" flexDirection="column" gap="14px">
          {hasRegistrationData && <Badge type="02" size="md" category="blue" content={t('has_registration')} />}
          <Typography variant="h3" fontWeight="bold" color="gray-800">
            {data?.address}
          </Typography>
        </Box>
      ) : (
        <Typography
          variant="h3"
          textOverflow="ellipsis"
          overflowX="hidden"
          whiteSpace="nowrap"
          fontWeight="bold"
          color="gray-800"
        >
          {data?.address}
        </Typography>
      )}
      <Typography color="gray-800">{landData?.buildingName}</Typography>
    </>
  )
  const boundary = data.areaPaths ?? null

  const formatEstateLine = () => {
    const values: string[] = []

    if (isNotNil(landData?.landArea)) {
      values.push(areaFormatter(landData?.landArea, { rounded: 'n2' }))
    }

    if (isNotNil(landData?.ldcgName)) {
      values.push(landData?.ldcgName)
    }

    if (isNotNil(landData?.landPurposeName)) {
      values.push(landData?.landPurposeName)
    }
    return values.join(' · ')
  }

  const hasEstateValues =
    isNotNil(landData?.landArea) || isNotNil(landData?.ldcgName) || isNotNil(landData?.landPurposeName)

  const formatBuildingLine = () => {
    const values: string[] = []

    if (isNotNil(landData?.totalArea)) {
      values.push(areaFormatter(landData?.totalArea, { rounded: 'n2' }))
    }

    if (isNotNil(landData?.mainPurposeIndicationCode)) {
      const mainPurposeName = codeToName(landData?.mainPurposeIndicationCode)

      if (isNotNil(mainPurposeName)) {
        values.push(mainPurposeName)
      }
    }

    if (isNotNil(landData?.floorCnt) || isNotNil(landData?.baseFloorCnt)) {
      values.push(formatFloorInfo(unit, landData?.floorCnt, landData?.baseFloorCnt))
    }

    if (isNotNil(landData?.constructDateY)) {
      values.push(formatDateYmd(landData?.constructDateY))
    }

    return values.join(' · ')
  }

  const hasBuildingValues =
    isNotNil(landData?.totalArea) ||
    isNotNil(landData?.mainPurposeName) ||
    isNotNil(landData?.floorCnt) ||
    isNotNil(landData?.baseFloorCnt) ||
    isNotNil(landData?.constructDateY)

  const formatTradeLine = () => {
    const values: string[] = []

    if (isNotNil(landData?.realTradeTypeName)) {
      values.push(landData?.realTradeTypeName)
    }

    if (isNotNil(landData?.realTradePrice)) {
      values.push(
        currencyFormatter(landData?.realTradePrice, {
          format: 'eok',
          rounded: 'n1',
        }),
      )
    }

    if (isNotNil(landData?.realTradeDateYm)) {
      values.push(formatDateYm(landData?.realTradeDateYm))
    }

    return values.join(' · ')
  }

  const hasTradeValues =
    isNotNil(landData?.realTradeTypeName) || isNotNil(landData?.realTradePrice) || isNotNil(landData?.realTradeDateYm)

  return (
    <>
      {isFull && (
        <BuildingMapImageArea
          position={{ lat: data.centerLat, lng: data.centerLng }}
          boundary={boundary}
          markerContent={data.address}
          markerType="land"
        />
      )}
      <Box px={isFull ? '30px' : '20px'} py={isFull ? '24px' : '20px'}>
        <Box display="flex" flexDirection="column" gap={1} backgroundColor="system-white" pb={3.5}>
          {commonTypography}
        </Box>
        <Box>
          {!isLoading && (
            <Box display="flex" flexDirection={isFull ? 'row' : 'column'} gap={isFull ? 4 : 1} pb={3.5}>
              {hasEstateValues && <InfoLine label={t('estate')} value={formatEstateLine()} />}
              {hasBuildingValues && <InfoLine label={t('building')} value={formatBuildingLine()} />}
              {hasTradeValues && <InfoLine label={t('trade')} value={formatTradeLine()} />}
            </Box>
          )}
          {isTop && <BuildingInformationButton pnu={data?.pnu ?? ''} address={data?.address ?? ''} />}
        </Box>
      </Box>
    </>
  )
}

export default LandOverviewSection
