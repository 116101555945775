import { useGetDowntimeNotices } from '~/libs/apis/service/api'

const useDowntimeNotices = () => {
  return useGetDowntimeNotices({
    swr: { keepPreviousData: true, revalidateOnFocus: true },
    request: { skipAuthRefresh: true, skipNewVersionCheck: true },
  })
}

export default useDowntimeNotices
