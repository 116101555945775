import { FC, Fragment, ReactNode } from 'react'
import Typography from '~/components/Typography/Typography'
import { SystemProps } from '@xstyled/styled-components'
import { isNotNil } from '@toss/utils'

interface MultiCellTemplateProps {
  content: (number | string | ReactNode | undefined | null)[]
  textAlign?: SystemProps['textAlign']
  isHeader?: boolean
}

const MultiTemplate: FC<MultiCellTemplateProps> = ({ content, textAlign = 'left', isHeader = false }) => {
  const isAllNillable = content.every((data) => !isNotNil(data))

  if (isAllNillable) {
    return (
      <Typography display="block" variant="caption1" fontWeight={isHeader ? 600 : 400} textAlign={textAlign}>
        -
      </Typography>
    )
  }

  return (
    <>
      {content.map((data, index) =>
        typeof data !== 'object' ? (
          <Typography
            key={index}
            display="block"
            variant="body"
            fontWeight={isHeader ? 600 : 400}
            textAlign={textAlign}
            as="div"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflowX="hidden"
          >
            {data ?? '-'}
          </Typography>
        ) : (
          <Fragment key={index}>{data ?? '-'}</Fragment>
        ),
      )}
    </>
  )
}

export default MultiTemplate
