import { FC } from 'react'
import { useLwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGet } from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import { LwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGetParams } from '~/libs/apis/data/model'
import InOutLesseeStatus from '~/templates/place/detail/lessee/InOutLesseeStatus'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'

interface LwhLesseeChangeProps extends LwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGetParams {
  raId: string
}
const LwhInOutLesseeStatus: FC<LwhLesseeChangeProps> = ({ raId, ...params }) => {
  const { data, isLoading } = useLwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGet(raId, params)
  if (isLoading) {
    return <SkeletonComponent height="60px" />
  }
  return <InOutLesseeStatus data={data} />
}

export default LwhInOutLesseeStatus
