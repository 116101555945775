import { FC } from 'react'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import useCommonCode from '~/libs/hooks/useCommonCode'

const StackingPlanLegend: FC = () => {
  const { codeToName } = useCommonCode()
  return (
    <Box display="flex" gap={2.5} alignSelf="flex-end">
      <Box display="flex" gap={1.5} alignItems="center">
        <Box
          width="14px"
          height="14px"
          backgroundColor="data-1"
          borderColor="gray-300"
          borderWidth="1px"
          borderRadius="full"
        />
        <Typography variant="body" fontWeight="semibold">
          {codeToName('RT01_01')}
        </Typography>
      </Box>
      <Box display="flex" gap={1.5} alignItems="center">
        <Box
          width="14px"
          height="14px"
          backgroundColor="data-2"
          borderColor="gray-300"
          borderWidth="1px"
          borderRadius="full"
        />
        <Typography variant="body" fontWeight="semibold">
          {codeToName('RT01_02')}
        </Typography>
      </Box>
      <Box display="flex" gap={1.5} alignItems="center">
        <Box
          width="14px"
          height="14px"
          backgroundColor="gray-100"
          borderColor="gray-300"
          borderWidth="1px"
          borderRadius="full"
        />
        <Typography variant="body" fontWeight="semibold">
          {codeToName('RT01_99')}
        </Typography>
      </Box>
    </Box>
  )
}

export default StackingPlanLegend
