import { FC } from 'react'
import LesseeInfoGrid from '~/templates/place/detail/lessee/LesseeInfoGrid'
import { SchemasBuildingsLwhLesseePagingOut } from '~/libs/apis/data/model'

interface LwhLeasseeInfoProps {
  data: SchemasBuildingsLwhLesseePagingOut | undefined
  onPageChange: (page: number) => void
  isLoading: boolean
}

const LwhLesseeInfo: FC<LwhLeasseeInfoProps> = ({ data, onPageChange, isLoading }) => {
  return (
    <LesseeInfoGrid dataSource={data?.data} pageModel={data?.page} onPageChange={onPageChange} isLoading={isLoading} />
  )
}

export default LwhLesseeInfo
