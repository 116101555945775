import { commaizeNumber } from '@toss/utils'
import { Trans, useTranslation } from 'next-i18next'
import { FC, useState } from 'react'
import Box from '~/components/Box/Box'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'
import NoData from '~/components/NoData'
import NoticeBox from '~/components/NoticeBox/NoticeBox'
import Typography from '~/components/Typography/Typography'
import {
  type GetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGetParams,
  TenantsAggregationsOutClientId,
} from '~/libs/apis/data/model'
import { useGetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGet } from '~/libs/apis/data/tenants/tenants'
import OccupancyStatusGrid from '~/templates/common/detail/OccupancyStatusGrid'
import OccupancyStatusMap from '~/templates/common/detail/OccupancyStatusMap'

interface OccupancyStatusProps {
  clientIds: TenantsAggregationsOutClientId
}

const PAGE_SIZE = 10

const OccupancyStatus: FC<OccupancyStatusProps> = ({ clientIds }) => {
  const { t } = useTranslation('common', { keyPrefix: 'client' })
  const [activeIndex, setActiveIndex] = useState<number | undefined>()

  const [params, setParams] = useState<GetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGetParams>({
    pageNum: 1,
    pageSize: PAGE_SIZE,
  })
  const { data: detail } = useGetTenantsAggregationsDetailsDataV1TenantsAggregationsDetailsGet(
    {
      ...params,
      clientIds,
    },
    { swr: { keepPreviousData: true, enabled: Boolean(clientIds) } },
  )

  const page = detail?.page
  const pagerModel = page
    ? {
        pageSize: page.pageSize,
        currentPage: page.page,
        totalRecordsCount: page.totalSize,
      }
    : undefined
  const count = page?.totalSize

  const handlePageChange = (object: { currentPage: number }) =>
    setParams((prev) => ({ ...prev, pageNum: object.currentPage }))

  const noData = !detail?.data || detail?.data?.length === 0

  return (
    <Box display="flex" flexDirection="column" p="24px 20px" gap="20px">
      <Typography variant="h5" fontWeight="semibold" color="gray-800" as="span">
        <Trans
          i18nKey="client.company_occupancy_status"
          values={{ number: commaizeNumber(count ?? 0) }}
          components={{
            color: <Typography variant="h5" fontWeight="semibold" color="purple-700" as="span" />,
          }}
        />
      </Typography>
      {noData ? (
        <NoData height={430} />
      ) : (
        <>
          <NoticeBox
            type="normal"
            content={
              <Typography variant="body" whiteSpace="pre-wrap">
                {t('message.company_occupancy_status_information')}
              </Typography>
            }
          />
          <OccupancyStatusMap list={detail?.data} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
          <OccupancyStatusGrid
            dataSource={detail?.data}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            pagerModel={pagerModel}
            page={page}
            onPageChange={handlePageChange}
          />
          <DataSourceUpdate category="detail.lessee.rsquare" />
        </>
      )}
    </Box>
  )
}

export default OccupancyStatus
