import React, { FC, useEffect, useState } from 'react'
import type { GetUsersInquiryResponseInquiryInquiryItemsItem } from '~/libs/apis/service/model'
import Checkbox from '~/components/Checkbox/Checkbox'
import LabeledContainer from '~/components/LabeledContainer/LabeledContainer'
import { useTranslation } from 'react-i18next'
import { getStrInquiryItems, InquiryItems, InquiryItemType, UserInquiryType } from '~/libs/utils/inquiry'
import Box from '~/components/Box/Box'
import { objectEntries } from '@toss/utils'

interface InquiryItemFieldProps {
  inquiryType: string
  inquiryItems?: GetUsersInquiryResponseInquiryInquiryItemsItem[] | null
}

const InquiryItemField: FC<InquiryItemFieldProps> = ({ inquiryType, inquiryItems }) => {
  const { t: term } = useTranslation('common', { keyPrefix: 'data_inquiry' })
  const [selected, setSelected] = useState<InquiryItems[]>(['BUILDING_INFORMATION'])

  useEffect(() => {
    if (inquiryItems && inquiryItems.length > 0) {
      const filtered = getStrInquiryItems(inquiryItems)
      setSelected(filtered)
    }
  }, [inquiryItems])

  if (inquiryType !== UserInquiryType['data']) {
    return null
  }

  const handleChange = (value: InquiryItems) => {
    setSelected((prev) => (prev?.includes(value) ? prev?.filter((item) => item !== value) : [...prev, value]))
  }

  return (
    <LabeledContainer label={term('field.inquiry_item')} subLabel={`(${term('duplicate_possible')})`}>
      <Box display="flex" gap="20px">
        {objectEntries(InquiryItemType).map(([key, value]) => (
          <Checkbox
            key={key}
            size="sm"
            label={term(`inquiry_item.${value}`)}
            value={value}
            checked={selected?.includes(key)}
            onChange={() => handleChange(key)}
          />
        ))}
      </Box>
      <input type="hidden" name="inquiryItems" value={selected ? selected.join(',') : ''} />
    </LabeledContainer>
  )
}

export default InquiryItemField
