import { AccordionComponent } from '@syncfusion/ej2-react-navigations'
import styled from '@xstyled/styled-components'
import AngleDown from '~/assets/images/icons/angle-down.svg?url'
import { forwardRef, ReactNode, useRef } from 'react'
import { useCombinedRefs } from '@toss/react'

export interface AccordionData {
  header: string
  content: ReactNode
  disabled?: boolean
}

interface AccordionProps {
  data: AccordionData[]
}

const Accordion = forwardRef<AccordionComponent, AccordionProps>(({ data }, parentRef) => {
  const accordionRef = useRef<AccordionComponent>(null)
  const ref = useCombinedRefs(accordionRef, parentRef)

  const handleCreated = () => {
    data.forEach((item, index) => {
      if (item.disabled) {
        accordionRef.current?.enableItem(index, false)
      }
    })
  }

  return (
    <Wrapper>
      <AccordionComponent ref={ref} created={handleCreated}>
        {data?.map((item, index) => (
          <div key={index}>
            <div>
              <div>{item.header}</div>
            </div>
            <div>
              <div>{item.content}</div>
            </div>
          </div>
        ))}
      </AccordionComponent>
    </Wrapper>
  )
})

// TODO: global theme 수정되기 전까지 무시
// noinspection CssInvalidPropertyValue
const Wrapper = styled.div`
  .e-accordion {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--color-gray-400);

    .e-acrdn-item {
      .e-acrdn-header {
        padding: 20px 0 !important;
        max-height: 70px;
        background-color: var(--color-system-white) !important;

        .e-acrdn-header-content {
          font-weight: semibold;
          font-size: 16px;
          color: var(--color-gray-800) !important;
          text-decoration: none !important;
        }

        .e-toggle-icon {
          top: 25px;
          right: 0;
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
          .e-icons {
            width: inherit !important;
            height: inherit !important;
            &::before {
              content: '';
              display: block;
              mask-image: url('${AngleDown}');
              mask-size: 20px;
              width: 20px;
              height: 20px;
              background-color: var(--color-gray-800);
            }
          }
        }
      }

      .e-acrdn-panel {
        .e-acrdn-content {
          padding: 0;
          border-top: none;
        }
      }

      [aria-disabled='true'] {
        pointer-events: none;

        .e-acrdn-header-content {
          color: var(--color-gray-500) !important;
        }

        .e-toggle-icon {
          display: none;
        }
      }
    }
  }
`

Accordion.displayName = 'Accordion'

export default Accordion
