import { FC, useState } from 'react'
import { useLwhTradeDataV1BuildingsLwhTradeGet } from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import TradeHistoryGrid from '~/templates/place/detail/trade/TradeHistoryGrid'
import { useDetailInfo } from '~/templates/DetailTypeProvider'

const PAGE_SIZE = 5
const LwhTradeHistory: FC = () => {
  const { uid: raId } = useDetailInfo()
  const [pageNum, setPageNum] = useState(1)
  const { data } = useLwhTradeDataV1BuildingsLwhTradeGet({ raId, pageNum, pageSize: PAGE_SIZE })

  const handlePageChange = (page: number) => setPageNum(page)

  return <TradeHistoryGrid dataSource={data?.data} pageModel={data?.page} onPageChange={handlePageChange} />
}

export default LwhTradeHistory
