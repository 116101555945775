import { forwardRef, ReactNode } from 'react'
import Typography from '~/components/Typography/Typography'
import Box from '~/components/Box/Box'
import { CSSProperties } from '@xstyled/styled-components'

interface LabeledContainerProps {
  label: string
  subLabel?: string
  rightContent?: ReactNode
  type?: 'caption' | 'title'
  width?: CSSProperties['width']
  isRequired?: boolean
  children: ReactNode
  tooltip?: ReactNode
  popover?: ReactNode
}
const LabeledContainer = forwardRef<HTMLDivElement, LabeledContainerProps>(
  ({ label, subLabel, rightContent, type = 'title', width, isRequired, children, tooltip, popover }, ref) => {
    const isCaptionType = type === 'caption'

    return (
      <Box ref={ref} display="flex" flexDirection="column" gap={isCaptionType ? '6px' : '10px'} width={width}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={isCaptionType ? '2px' : 0} color={isCaptionType ? 'gray-700' : 'gray-800'}>
            <Box display="flex" gap={tooltip ? 1 : '10px'} alignItems="center">
              <Typography
                variant={isCaptionType ? 'caption1' : 'body'}
                color="inherit"
                paddingLeft={isCaptionType ? '4px' : undefined}
                fontWeight="semibold"
              >
                {label}
              </Typography>
              {tooltip}
              {popover}
            </Box>
            {isRequired && (
              <Typography
                display="inline"
                variant={isCaptionType ? 'caption1' : 'body'}
                fontWeight="semibold"
                color="purple-700"
              >
                &nbsp;*
              </Typography>
            )}
            {subLabel && (
              <Typography variant={isCaptionType ? 'caption1' : 'body'} color="inherit">
                {subLabel}
              </Typography>
            )}
          </Box>
          {rightContent ?? null}
        </Box>
        {children}
      </Box>
    )
  },
)

LabeledContainer.displayName = 'LabeledContainer'

export default LabeledContainer
