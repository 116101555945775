import { FC } from 'react'
import { Box } from '~/components'
import Typography from '~/components/Typography/Typography'
import { useTranslation } from 'next-i18next'
import Button from '~/components/Button/Button'
import styled from '@xstyled/styled-components'
import Tooltip from '~/components/Tooltip/Tooltip'
import useInquiryControl from '~/libs/hooks/useInquiryControl'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { ClickArea } from '@toss/react'

export interface FixedPosition {
  top: number
  left: number
  right: number
  bottom: number
}

interface LesseeDataQuestionProps {
  position?: FixedPosition
  count: number
}

const COUNT_STANDARD = 2
const DataInquiryOverlay: FC<LesseeDataQuestionProps> = ({ position, count }) => {
  const { t } = useTranslation('place')
  const { openDialog } = useInquiryControl()
  const { detailContent, uid } = useDetailInfo()

  if (!position) {
    return null
  }
  const handleDataInquiryOpen = () => {
    openDialog({
      type: detailContent,
      targetId: uid,
    })
  }

  return (
    <BlurOverlay className="lessee-blur-overlay" {...position}>
      {count > COUNT_STANDARD ? (
        <>
          <Typography variant="body" fontWeight="semibold" color="gray-800" w="146px" textAlign="center">
            {t('message.data_inquiry')}
          </Typography>
          <Button content={t('place_term.data_inquiry')} onClick={handleDataInquiryOpen} />
        </>
      ) : (
        <Tooltip content={t('message.data_inquiry')} position="BottomCenter">
          <ClickArea onClick={handleDataInquiryOpen}>
            <Typography
              variant="body"
              fontWeight="semibold"
              color="gray-800"
              textDecoration="underline"
              cursor="pointer"
            >
              {t('place_term.data_inquiry')}
            </Typography>
          </ClickArea>
        </Tooltip>
      )}
    </BlurOverlay>
  )
}

const BlurOverlay = styled(Box)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(6px);
`

export default DataInquiryOverlay
