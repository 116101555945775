/**
 * @constructs *_OFFICE: 오피스 탐색 권한
 * @constructs *_OFFICE_FILTER: 오피스 필터 권한
 * @constructs *_OFFICE_DETAIL: 오피스 상세 권한
 * @constructs *_OFFICE_COMPARISON: 오피스 비교 권한
 * @constructs *_OFFICE_PERIPHERAL: 오피스 주변시세 권한
 * @constructs *_OFFICE_REPORT: 오피스 시장보고서 권한
 * @constructs *_OFFICE_EXCEL: 오피스 엑셀다운로드 권한
 * @constructs *_WAREHOUSE: 물류창고 탐색 권한
 * @constructs *_WAREHOUSE_FILTER: 물류창고 필터 권한
 * @constructs *_WAREHOUSE_DETAIL: 물류창고 상세 권한
 * @constructs *_WAREHOUSE_COMPARISON: 물류창고 비교 권한
 * @constructs *_WAREHOUSE_PERIPHERAL: 물류창고 주변시세 권한
 * @constructs *_WAREHOUSE_REPORT: 물류창고 시장보고서 권한
 * @constructs *_WAREHOUSE_EXCEL: 물류창고 엑셀다운로드 권한
 */
// eslint-disable-next-line import/no-unused-modules
export const PermissionCode = {
  BASIC_OFFICE: 'realty-search:basic-office',
  BASIC_OFFICE_FILTER: 'realty-search:basic-office:filter',
  BASIC_OFFICE_DETAIL: 'realty-detail:basic-office',
  BASIC_OFFICE_COMPARISON: 'realty-comparison:basic-office',
  BASIC_OFFICE_PERIPHERAL: 'peripheral-analysis:basic-office',
  BASIC_OFFICE_REPORT: 'market-report:basic-office',
  BASIC_OFFICE_EXCEL: 'excel-download:basic-office',
  BASIC_WAREHOUSE: 'realty-search:basic-warehouse',
  BASIC_WAREHOUSE_FILTER: 'realty-search:basic-warehouse:filter',
  BASIC_WAREHOUSE_DETAIL: 'realty-search detail:basic-warehouse',
  BASIC_WAREHOUSE_COMPARISON: 'realty-comparison:basic-warehouse',
  BASIC_WAREHOUSE_PERIPHERAL: 'peripheral-analysis:basic-warehouse',
  BASIC_WAREHOUSE_REPORT: 'market-report:basic-warehouse',
  BASIC_WAREHOUSE_EXCEL: 'excel-download:basic-warehouse',
  BASIC_ALL: 'realty-search:basic-all',
  BASIC_ALL_FILTER: 'realty-search:basic-all:filter',
  BASIC_ALL_DETAIL: 'realty-detail:basic-all',
  BASIC_ALL_COMPARISON: 'realty-comparison:basic-all',
  BASIC_ALL_PERIPHERAL: 'peripheral-analysis:basic-all',
  BASIC_ALL_REPORT: 'market-report:basic-all',
  BASIC_ALL_EXCEL: 'excel-download:basic-all',
  PREMIUM_OFFICE: 'realty-search:premium-office',
  PREMIUM_OFFICE_FILTER: 'realty-search:premium-office:filter',
  PREMIUM_OFFICE_DETAIL: 'realty-detail:premium-office',
  PREMIUM_OFFICE_COMPARISON: 'realty-comparison:premium-office',
  PREMIUM_OFFICE_PERIPHERAL: 'peripheral-analysis:premium-office',
  PREMIUM_OFFICE_REPORT: 'market-report:premium-office',
  PREMIUM_OFFICE_EXCEL: 'excel-download:premium-office',
  PREMIUM_WAREHOUSE: 'realty-search:premium-warehouse',
  PREMIUM_WAREHOUSE_FILTER: 'realty-search:premium-warehouse:filter',
  PREMIUM_WAREHOUSE_DETAIL: 'realty-search detail:premium-warehouse',
  PREMIUM_WAREHOUSE_COMPARISON: 'realty-comparison:premium-warehouse',
  PREMIUM_WAREHOUSE_PERIPHERAL: 'peripheral-analysis:premium-warehouse',
  PREMIUM_WAREHOUSE_REPORT: 'market-report:premium-warehouse',
  PREMIUM_WAREHOUSE_EXCEL: 'excel-download:premium-warehouse',
  PREMIUM_ALL: 'realty-search:premium-all',
  PREMIUM_ALL_FILTER: 'realty-search:premium-all:filter',
  PREMIUM_ALL_DETAIL: 'realty-detail:premium-all',
  PREMIUM_ALL_COMPARISON: 'realty-comparison:premium-all',
  PREMIUM_ALL_PERIPHERAL: 'peripheral-analysis:premium-all',
  PREMIUM_ALL_REPORT: 'market-report:premium-all',
  PREMIUM_ALL_EXCEL: 'excel-download:premium-all',
} as const

export type PermissionCodeType = (typeof PermissionCode)[keyof typeof PermissionCode]

export const PageRoutes = {
  place: '/[locale]/place',
  topic: '/[locale]/topic',
  topicTenant: '/[locale]/topic/tenant',
  topicRegistration: '/[locale]/topic/registration',
  topicLeaseProduct: '/[locale]/topic/lease-product',
  compare: '/[locale]/compare',
  compareOffice: '/[locale]/compare/office',
  compareLwh: '/[locale]/compare/lwh',
  nearby: '/[locale]/nearby',
  report: '/[locale]/report',
  reportOffice: '/[locale]/report/office',
  reportLwh: '/[locale]/report/lwh',
}

// eslint-disable-next-line import/no-unused-modules
export type PagePermissionType = { [url: (typeof PageRoutes)[keyof typeof PageRoutes]]: Array<PermissionCodeType> }

export const PagePermission: PagePermissionType = {
  [PageRoutes.place]: [
    PermissionCode.BASIC_ALL,
    PermissionCode.BASIC_OFFICE,
    PermissionCode.BASIC_WAREHOUSE,
    PermissionCode.PREMIUM_ALL,
    PermissionCode.PREMIUM_OFFICE,
    PermissionCode.PREMIUM_WAREHOUSE,
  ],
  [PageRoutes.topic]: [
    PermissionCode.BASIC_ALL,
    PermissionCode.BASIC_OFFICE,
    PermissionCode.BASIC_WAREHOUSE,
    PermissionCode.PREMIUM_ALL,
    PermissionCode.PREMIUM_OFFICE,
    PermissionCode.PREMIUM_WAREHOUSE,
  ],
  [PageRoutes.topicTenant]: [
    PermissionCode.BASIC_ALL,
    PermissionCode.BASIC_OFFICE,
    PermissionCode.BASIC_WAREHOUSE,
    PermissionCode.PREMIUM_ALL,
    PermissionCode.PREMIUM_OFFICE,
    PermissionCode.PREMIUM_WAREHOUSE,
  ],
  [PageRoutes.compare]: [
    PermissionCode.PREMIUM_ALL_COMPARISON,
    PermissionCode.PREMIUM_OFFICE_COMPARISON,
    PermissionCode.PREMIUM_WAREHOUSE_COMPARISON,
  ],
  [PageRoutes.compareOffice]: [PermissionCode.PREMIUM_ALL_COMPARISON, PermissionCode.PREMIUM_OFFICE_COMPARISON],
  [PageRoutes.compareLwh]: [PermissionCode.PREMIUM_ALL_COMPARISON, PermissionCode.PREMIUM_WAREHOUSE_COMPARISON],
  [PageRoutes.nearby]: [
    PermissionCode.PREMIUM_ALL_PERIPHERAL,
    PermissionCode.PREMIUM_OFFICE_PERIPHERAL,
    PermissionCode.PREMIUM_WAREHOUSE_PERIPHERAL,
  ],
  [PageRoutes.report]: [
    PermissionCode.PREMIUM_ALL_REPORT,
    PermissionCode.PREMIUM_OFFICE_REPORT,
    PermissionCode.PREMIUM_WAREHOUSE_REPORT,
  ],
  [PageRoutes.reportOffice]: [PermissionCode.PREMIUM_ALL_REPORT, PermissionCode.PREMIUM_OFFICE_REPORT],
  [PageRoutes.reportLwh]: [PermissionCode.PREMIUM_ALL_REPORT, PermissionCode.PREMIUM_WAREHOUSE_REPORT],
} as PagePermissionType

type PropertyPermissionKey = 'office' | 'warehouse'
type PropertyPermission = Record<PropertyPermissionKey, Array<PermissionCodeType>>

export const DetailPermission: PropertyPermission = {
  office: [
    PermissionCode.BASIC_ALL,
    PermissionCode.BASIC_OFFICE,
    PermissionCode.PREMIUM_ALL,
    PermissionCode.PREMIUM_OFFICE,
  ],
  warehouse: [
    PermissionCode.BASIC_ALL,
    PermissionCode.BASIC_WAREHOUSE,
    PermissionCode.PREMIUM_ALL,
    PermissionCode.PREMIUM_WAREHOUSE,
  ],
}

export const ExcelDownloadPermission = [
  PermissionCode.PREMIUM_ALL_EXCEL,
  PermissionCode.PREMIUM_OFFICE_EXCEL,
  PermissionCode.PREMIUM_WAREHOUSE_EXCEL,
]

export const PremiumPermission: PropertyPermission = {
  office: [PermissionCode.PREMIUM_ALL_COMPARISON, PermissionCode.PREMIUM_OFFICE_COMPARISON],
  warehouse: [PermissionCode.PREMIUM_ALL_COMPARISON, PermissionCode.PREMIUM_WAREHOUSE_COMPARISON],
}
