import DetailTable, { DetailTableType } from '~/components/Table/DetailTable'
import { useTranslation } from 'next-i18next'
import { OfficeOut } from '~/libs/apis/data/model'
import { formatVacancyRate } from '~/libs/utils/format'
import { useAreaFormat } from '~/libs/hooks/useFormat'

interface VacancyProps {
  data: TableData
}

type TableData = Pick<OfficeOut, 'emptyRate' | 'officeArea' | 'emptyArea'>

const OfficeVacancy = ({ data }: VacancyProps) => {
  const { t } = useTranslation()
  const { emptyRate, officeArea, emptyArea } = data
  const areaFormatter = useAreaFormat()

  const tableData: DetailTableType[] = [
    {
      column: t('common_term.office_area'),
      content: areaFormatter(officeArea, { rounded: 'n2' }),
    },
    {
      column: t('common_term.vacancy_rate'),
      content: formatVacancyRate(emptyRate),
    },
    {
      column: t('common_term.vacancy_area'),
      content: areaFormatter(emptyArea, { rounded: 'n2' }),
    },
  ]

  return <DetailTable data={tableData} />
}

export default OfficeVacancy
