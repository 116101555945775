import { style, SystemProp } from '@xstyled/system'
import { compose, ITheme, Theme } from '@xstyled/styled-components'
import * as CSS from 'csstype'

interface ScrollMarginProps<T extends ITheme = Theme> {
  scrollMargin?: SystemProp<CSS.Property.ScrollMargin, T>
}

const scrollMargin = style<ScrollMarginProps>({
  prop: 'scrollMargin',
})

interface ScrollMarginBottomProps<T extends ITheme = Theme> {
  scrollMarginBottom?: SystemProp<CSS.Property.ScrollMarginBottom, T>
}

const scrollMarginBottom = style<ScrollMarginBottomProps>({
  prop: 'scrollMarginBottom',
})

interface ScrollMarginLeftProps<T extends ITheme = Theme> {
  scrollMarginLeft?: SystemProp<CSS.Property.ScrollMarginLeft, T>
}

const scrollMarginLeft = style<ScrollMarginLeftProps>({
  prop: 'scrollMarginLeft',
})

interface ScrollMarginRightProps<T extends ITheme = Theme> {
  scrollMarginRight?: SystemProp<CSS.Property.ScrollMarginRight, T>
}

const scrollMarginRight = style<ScrollMarginRightProps>({
  prop: 'scrollMarginRight',
})

interface ScrollMarginTopProps<T extends ITheme = Theme> {
  scrollMarginTop?: SystemProp<CSS.Property.ScrollMarginTop, T>
}

const scrollMarginTop = style<ScrollMarginTopProps>({
  prop: 'scrollMarginTop',
})

interface ScrollMarginsProps<T extends ITheme = Theme>
  extends ScrollMarginProps<T>,
    ScrollMarginBottomProps<T>,
    ScrollMarginLeftProps<T>,
    ScrollMarginRightProps<T>,
    ScrollMarginTopProps<T> {}

const scrollMargins = compose<ScrollMarginsProps>(
  scrollMargin,
  scrollMarginBottom,
  scrollMarginLeft,
  scrollMarginRight,
  scrollMarginTop,
)

export default scrollMargins
