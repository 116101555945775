import { FC } from 'react'
import { OfficeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGetParams } from '~/libs/apis/data/model'
import { useOfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGet } from '~/libs/apis/data/buildings-office/buildings-office'
import InOutLesseeStatus from '~/templates/place/detail/lessee/InOutLesseeStatus'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'

interface OfficeLesseeChangeProps extends OfficeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGetParams {
  raId: string
}

const OfficeInOutLesseeStatus: FC<OfficeLesseeChangeProps> = ({ raId, ...params }) => {
  const { data, isLoading } = useOfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGet(raId, params)
  if (isLoading) {
    return <SkeletonComponent height="60px" />
  }
  return <InOutLesseeStatus data={data} />
}

export default OfficeInOutLesseeStatus
