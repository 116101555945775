import {
  AnnotationDirective,
  AnnotationsDirective,
  AxesDirective,
  AxisDirective,
  Category,
  ChartAnnotation,
  ChartComponent,
  Crosshair,
  DateTime,
  DateTimeCategory,
  Export,
  Inject,
  Legend,
  LineSeries,
  ScrollBar,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
} from '@syncfusion/ej2-react-charts'
import {
  ChartProps,
  commonChartComponentProps,
  commonLegendSettings,
  commonLineStyles,
  commonPrimaryXAxisSettings,
  commonPrimaryYAxisSettings,
  commonTooltipSettings,
  zoomSettings,
} from '~/libs/constants/chart'
import { FC } from 'react'
import { EmitType } from '@syncfusion/ej2-base'
import { ITooltipRenderEventArgs } from '@syncfusion/ej2-charts/src/chart/model/chart-interface'
import { AxisModel } from '@syncfusion/ej2-charts/src/chart/axis/axis-model'
import ChartWrapper from '~/components/Chart/ChartWrapper'
import useCommonChartPalette from '~/libs/hooks/useCommonChartPalette'

interface LineChartProps extends ChartProps {
  tooltipRender?: EmitType<ITooltipRenderEventArgs>
  additionalYAxis?: AxisModel
}

const LineChart: FC<LineChartProps> = ({
  chartId,
  width,
  height,
  refCallback,
  isLoading,
  primaryXAxis,
  primaryYAxis,
  series,
  tooltipRender,
  additionalYAxis,
  beforeExport,
  afterExport,
  isCursorPointer,
  noData,
  recentFiveYearsOnly,
  pointMove,
  legendSettings,
  annotations,
  tooltipSettings,
  axisLabelRender,
}) => {
  const commonChartPalette = useCommonChartPalette()

  return (
    <ChartWrapper
      chartId={chartId}
      isLoading={isLoading}
      isCursorPointer={isCursorPointer}
      width={width}
      height={height}
      noData={noData}
      recentFiveYearsOnly={recentFiveYearsOnly}
    >
      <ChartComponent
        {...commonChartComponentProps}
        id={chartId}
        ref={refCallback}
        palettes={commonChartPalette}
        primaryXAxis={{
          ...commonPrimaryXAxisSettings,
          ...primaryXAxis,
          edgeLabelPlacement: 'Shift',
        }}
        primaryYAxis={{
          ...commonPrimaryYAxisSettings,
          ...primaryYAxis,
        }}
        axisLabelRender={axisLabelRender}
        zoomSettings={zoomSettings}
        legendSettings={{ ...commonLegendSettings, ...legendSettings }}
        tooltip={{ ...commonTooltipSettings, ...tooltipSettings, shared: true, header: '<b>${point.tooltip}</b>' }}
        sharedTooltipRender={tooltipRender}
        crosshair={{ enable: true, lineType: 'Vertical' }}
        beforeExport={beforeExport}
        afterExport={afterExport}
        width={width?.toString()}
        height={height?.toString()}
        pointMove={pointMove}
      >
        <Inject
          services={[
            LineSeries,
            Legend,
            Tooltip,
            DateTime,
            Crosshair,
            DateTimeCategory,
            Category,
            Export,
            ChartAnnotation,
            ScrollBar,
          ]}
        />
        {additionalYAxis && (
          <AxesDirective>
            <AxisDirective {...additionalYAxis} />
          </AxesDirective>
        )}
        {annotations && (
          <AnnotationsDirective>
            {annotations.map((annotationDirective, index) => (
              <AnnotationDirective key={index} {...annotationDirective} />
            ))}
          </AnnotationsDirective>
        )}
        <SeriesCollectionDirective>
          {series?.map((seriesDirective, index) => {
            return <SeriesDirective key={index} {...commonLineStyles} type="Line" {...seriesDirective} />
          })}
        </SeriesCollectionDirective>
      </ChartComponent>
    </ChartWrapper>
  )
}

export default LineChart
