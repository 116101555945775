import { forwardRef, useEffect, useRef, useState } from 'react'
import { GridModel } from '@syncfusion/ej2-grids'
import Button from '~/components/Button/Button'
import Box from '~/components/Box/Box'
import { useTranslation } from 'next-i18next'
import { Page } from '~/libs/apis/data/model'
import DataGrid from '~/components/DataGrid/DataGrid'
import { ColumnModel, GridComponent } from '@syncfusion/ej2-react-grids'
import DataInquiryOverlay, { FixedPosition } from '~/templates/place/detail/lessee/DataInquiryOverlay'
import { useCombinedRefs } from '@toss/react'
import ScrollBox from '~/components/Box/ScrollBox'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import SkeletonDataGrid from '~/components/DataGrid/SkeletonDataGrid'

type BlurColumns = {
  start: string
  end: string
}

interface LoadMoreDataGridProps extends GridModel {
  columns: ColumnModel[]
  dataSource: object[] | undefined
  pageModel: Page | undefined
  onPageChange: (page: number) => void
  enableInquiryOverlay?: boolean
  isLoading?: boolean
  blurColumns?: BlurColumns
}

const DEFAULT_PAGE_MODEL = {
  pageSize: 0,
  totalSize: 0,
  lastPage: false,
  firstPage: true,
  page: 1,
}

const AUTO_INCREASE_INDEX = 'autoIncreaseIndex'

const LoadMoreDataGrid = forwardRef<GridComponent, LoadMoreDataGridProps>(
  (
    {
      columns,
      dataSource,
      pageModel = DEFAULT_PAGE_MODEL,
      onPageChange,
      enableInquiryOverlay = false,
      isLoading = false,
      blurColumns = { start: 'rentStartDate', end: 'rentEndDate' },
      ...gridModelProps
    },
    forwardedRef,
  ) => {
    const { t } = useTranslation('common', { keyPrefix: 'common_term' })

    const { pageSize, totalSize, lastPage, firstPage, page } = pageModel
    const isMoreShow = totalSize > pageSize
    const buttonContent = lastPage ? t('fold') : t('more_view')
    const loadMoreGridRef = useRef<GridComponent>(null)
    const ref = useCombinedRefs(loadMoreGridRef, forwardedRef)
    const boxRef = useRef<HTMLDivElement>(null)
    const [bluryPosition, setBluryPosition] = useState<FixedPosition>()
    const { areaUnit, currencyUnit } = useUserConfiguration()

    const hasPrimaryField = !!columns?.find((column) => column.isPrimaryKey)

    // primary key 가 없으면, autoIndex 키를 primary key로 설정되도록
    if (!hasPrimaryField) {
      columns?.unshift({
        field: AUTO_INCREASE_INDEX,
        isPrimaryKey: true,
        visible: false,
      })
    }

    const generateAutoIndexData = (dataSource: object[] | undefined): object[] => {
      if (!dataSource) {
        return []
      }

      return dataSource.map((data, index) => ({
        ...data,
        [AUTO_INCREASE_INDEX]: (page - 1) * (pageSize ?? 5) + index,
      }))
    }

    const [shownDataSource, setShownDataSource] = useState(generateAutoIndexData(dataSource))
    const handleDataBound = () => {
      if (loadMoreGridRef.current && enableInquiryOverlay && shownDataSource && boxRef.current) {
        const firstRow = loadMoreGridRef.current.getRowByIndex(0)
        if (!firstRow) {
          return
        }
        const lastRow = loadMoreGridRef.current.getRowByIndex(shownDataSource.length - 1)
        const startHeader = loadMoreGridRef.current.getColumnHeaderByField(blurColumns.start)
        const lastHeader = loadMoreGridRef.current.getColumnHeaderByField(blurColumns.end)
        if (!startHeader) {
          handleColumnError()
          return
        }
        if (!lastHeader) {
          handleColumnError()
          return
        }
        const parentRect = boxRef.current.getBoundingClientRect()
        setBluryPosition({
          top: firstRow.getBoundingClientRect().top - parentRect.top,
          left: startHeader.getBoundingClientRect().left - parentRect.left,
          right: parentRect.right - lastHeader.getBoundingClientRect().right,
          bottom: parentRect.bottom - lastRow.getBoundingClientRect().bottom,
        })
      }
    }

    const handleColumnError = () => {
      setBluryPosition(undefined)
    }

    useEffect(() => {
      if (!dataSource) {
        return
      }

      if (firstPage) {
        setShownDataSource(generateAutoIndexData(dataSource))
      } else {
        const generatedDatasource = generateAutoIndexData(dataSource)
        setShownDataSource((prev) => prev?.concat(generatedDatasource))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource])

    // ImmutableMode 은 이미 그려진 행은 재렌더링을 하지 않음.
    // 하지만, 평/원화 변경이 변경된 평/원화에 맞춰 다시 그려져야 하기 때문에
    // 강제로 dataSource를 바꾸는 이벤트 실행
    useEffect(() => {
      if (shownDataSource.length === 0) {
        return
      }

      if (!loadMoreGridRef.current) {
        return
      }
      loadMoreGridRef.current.changeDataSource(shownDataSource, columns)
    }, [areaUnit, currencyUnit, t])

    const handleClick = () => {
      const changePageNum = lastPage ? 1 : page + 1
      onPageChange(changePageNum)
    }

    const dataGridProps = {
      dataSource: shownDataSource,
      columns: columns,
      allowPaging: false,
      enableImmutableMode: true,
      ...gridModelProps,
    }

    if (enableInquiryOverlay) {
      dataGridProps.dataBound = handleDataBound
    }

    return (
      <Box display="flex" flexDirection="column" gap="18px">
        <ScrollBox variant="horizontal">
          <Box ref={boxRef} width="fit-content" height="fit-content" position="relative">
            {isLoading && firstPage ? (
              <SkeletonDataGrid columns={columns} dataLength={5} />
            ) : (
              <>
                <DataGrid ref={ref} {...dataGridProps} />
                {enableInquiryOverlay && (
                  <DataInquiryOverlay count={shownDataSource?.length ?? 0} position={bluryPosition} />
                )}
              </>
            )}
          </Box>
        </ScrollBox>
        {isMoreShow && (
          <Button width="100%" size="md" variant="filled" color="gray" content={buttonContent} onClick={handleClick} />
        )}
      </Box>
    )
  },
)

LoadMoreDataGrid.displayName = 'LoadMoreDataGrid'

// eslint-disable-next-line import/no-unused-modules
export default LoadMoreDataGrid
