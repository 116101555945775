import React, { FC, useRef } from 'react'
import { Box } from '~/components'
import { useTranslation } from 'next-i18next'
import { format, parseISO } from 'date-fns'
import LogoWhite from '~/assets/images/logo/logo-white.svg'
import LogoBack from '~/assets/images/logo/logo-back.svg?url'
import Typography from '~/components/Typography/Typography'
import { type GetDowntimeNoticesResponseItem } from '~/libs/apis/service/model'
import { isNil } from '@toss/utils'
import { differenceInMinutes } from 'date-fns/differenceInMinutes'
import { ko } from 'date-fns/locale/ko'

const formatFullDateTime = (dateString: string | undefined) => {
  if (!dateString) {
    return ''
  }
  const date = parseISO(dateString)
  return format(date, 'yyyy-MM-dd(eee) HH:mm', { locale: ko })
}

const getDowntimePeriod = (notice: GetDowntimeNoticesResponseItem | undefined) => {
  if (!notice || isNil(notice?.downtimeStartAt) || isNil(notice?.downtimeEndAt)) {
    return ''
  }
  return differenceInMinutes(notice?.downtimeEndAt, notice?.downtimeStartAt)
}

interface DowntimeNoticeProps {
  data?: GetDowntimeNoticesResponseItem
}

const DowntimeNotice: FC<DowntimeNoticeProps> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'downtime_notice' })
  const dataRef = useRef<GetDowntimeNoticesResponseItem>()

  if (data) {
    dataRef.current = data
  }

  return (
    <Box
      height="580px"
      background={`url(${LogoBack}) top / 560px 426px no-repeat`}
      padding="58px 30px 43px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="30px"
      whiteSpace="pre-wrap"
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap="12px">
        <LogoWhite width={360} height={37} />
        <Typography variant="h1" fontWeight="bold" color="system-white" textAlign="center">
          {t('title')}
        </Typography>
      </Box>
      <Typography variant="subtitle" color="system-white" textAlign="center">
        {t('subtitle')}
      </Typography>
      <Box
        width="500px"
        height="200px"
        backgroundColor="purple-800-a60"
        borderRadius="11px"
        padding="30px 40px"
        display="flex"
        flexDirection="column"
        gap="20px"
      >
        <Box display="flex" width="100%">
          <Typography variant="body" color="system-white" marginRight="20px">
            {t('downtime_period')}
          </Typography>
          <Typography
            variant="body"
            fontWeight="semibold"
            color="system-white"
            lineClamp="2"
            flex="1 1 0"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {t('downtime_contents', {
              start: formatFullDateTime(dataRef.current?.downtimeStartAt),
              end: formatFullDateTime(dataRef.current?.downtimeEndAt),
              period: getDowntimePeriod(dataRef.current),
            })}
          </Typography>
        </Box>
        <Box display="flex" width="100%">
          <Typography variant="body" color="system-white" marginRight="20px">
            {t('content')}
          </Typography>
          <Typography
            variant="body"
            fontWeight="semibold"
            color="system-white"
            lineClamp="2"
            flex="1 1 0"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {dataRef.current?.content}
          </Typography>
        </Box>
        <Box display="flex" width="100%">
          <Typography variant="body" color="system-white" marginRight="20px">
            {t('contact')}
          </Typography>
          <Typography variant="body" fontWeight="semibold" color="system-white">
            {dataRef.current?.contact}
          </Typography>
        </Box>
      </Box>
      <Typography variant="subtitle" fontWeight="semibold" color="system-white" textAlign="center">
        {t('footer')}
      </Typography>
    </Box>
  )
}

export default DowntimeNotice
