import OverviewButton, { ButtonData } from '~/templates/place/detail/overview/OverviewButton'
import Box from '~/components/Box/Box'

interface FunctionButtonsProps {
  data: ButtonData[]
}

const FunctionButtons = ({ data }: FunctionButtonsProps) => {
  return (
    <Box display="flex" gap="10px" justifyContent="center" alignItems="center">
      {data?.map((button) => (
        <OverviewButton
          key={button.iconKey}
          label={button.label}
          iconKey={button.iconKey}
          active={button.active}
          onClick={button.onClick}
        />
      ))}
    </Box>
  )
}

export default FunctionButtons
