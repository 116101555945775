import { LwhOut } from '~/libs/apis/data/model'
import LwhTable from '~/templates/place/detail/building/LwhTable'
import { FC } from 'react'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'
import Box from '~/components/Box/Box'
import Button from '~/components/Button/Button'
import BuildingCommonData from '~/templates/place/detail/building/BuildingCommonData'
import { commonDatas } from '~/templates/place/detail/building/BuildingInfo'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useTranslation } from 'next-i18next'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { isNotNil } from '@toss/utils'
import LwhDetailTable from '~/templates/place/detail/building/LwhDetailTable'
import { isCompletion } from '~/libs/utils/detail'

interface LwhInfoProps {
  data: LwhOut
}

const LwhInfo: FC<LwhInfoProps> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'detail.common_data' })
  const { showLwh } = useFullDetailDialog()
  const { detailType, uid } = useDetailInfo()
  const hasPnu = isNotNil(data.pnu)
  return (
    <>
      {detailType === 'panel' ? <LwhTable data={data} /> : <LwhDetailTable data={data} />}
      {detailType === 'panel' ? (
        <Box display="flex" gap="9px">
          {commonDatas.map((item) => (
            <Button
              key={item}
              size="md"
              variant="filled"
              color="gray"
              content={t(item)}
              width="100%"
              disabled={!hasPnu || (item === 'ledger' && !isCompletion(data))}
              onClick={() => {
                showLwh(uid, item)
              }}
            />
          ))}
        </Box>
      ) : (
        <BuildingCommonData pnu={data.pnu} isGroup={isNotNil(data.groupRaId)} isCompletion={isCompletion(data)} />
      )}
      <DataSourceUpdate category="detail.building.info" />
    </>
  )
}

export default LwhInfo
