import Box from '~/components/Box/Box'
import Tooltip from '~/components/Tooltip/Tooltip'
import { FC, MouseEventHandler } from 'react'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import Typography from '~/components/Typography/Typography'
import { useTranslation } from 'next-i18next'
import { Separated } from '@toss/react'
import { useAreaUnitLabel } from '~/libs/hooks/useFormat'

interface OptionMenuItemProps {
  onClick?: MouseEventHandler
}

const OptionMenuItem: FC<OptionMenuItemProps> = ({ onClick }) => {
  const { currencyUnit, language } = useUserConfiguration()
  const { t } = useTranslation()
  const areaUnitLabel = useAreaUnitLabel()

  return (
    <Tooltip content={t('nav.setting')}>
      <Box
        display="flex"
        alignItems="center"
        gap="3px"
        borderRadius="6px"
        padding="4px 10px"
        cursor="pointer"
        backgroundColor="gray-200"
        userSelect="none"
        onClick={onClick}
      >
        <Separated with={<Box width="2px" height="9px" backgroundColor="var(--color-gray-400)" />}>
          <Typography variant="caption1" fontWeight="semibold" color="gray-700">
            {areaUnitLabel}
          </Typography>
          <Typography variant="caption1" fontWeight="semibold" color="gray-700">
            {currencyUnit}
          </Typography>
          <Typography variant="caption1" fontWeight="semibold" color="gray-700">
            {language === 'KOREAN' ? t('configuration.korean') : t('configuration.english')}
          </Typography>
        </Separated>
      </Box>
    </Tooltip>
  )
}

export default OptionMenuItem
