import { useTranslation } from 'next-i18next'
import { usePerUnitFormat } from '~/libs/hooks/useFormat'

const useFormatTemperatureRentFee = () => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const perUnitFormat = usePerUnitFormat()

  return (roomTemperature?: NullishNumber, lowTemperature?: NullishNumber) => {
    if (!roomTemperature && !lowTemperature) {
      return '-'
    }
    const roomTemperatureRentFeePerPy = roomTemperature
      ? `${t('room_temperature')} ${perUnitFormat(roomTemperature)}`
      : ''
    const lowTemperatureRentFeePerPy = lowTemperature ? `${t('low_temperature')} ${perUnitFormat(lowTemperature)}` : ''
    if (!roomTemperature) {
      return lowTemperatureRentFeePerPy
    }
    if (!lowTemperature) {
      return roomTemperatureRentFeePerPy
    }
    return `${roomTemperatureRentFeePerPy} | ${lowTemperatureRentFeePerPy}`
  }
}

export default useFormatTemperatureRentFee
