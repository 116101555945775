import { forwardRef, PropsWithChildren, useDeferredValue, useEffect, useRef } from 'react'
import Box from '~/components/Box/Box'
import { LandingContent } from '~/templates/FullDetailDialogProvider'
import { SwitchCase, useCombinedRefs, useIsMounted } from '@toss/react'
import * as CSS from 'csstype'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import useOfficeFullDetailLoading from '~/templates/place/detail/useOfficeFullDetailLoading'
import { isNotNil } from '@toss/utils'
import useLwhFullDetailLoading from '~/templates/place/detail/useLwhFullDetailLoading'
import useLandFullDetailLoading from '~/templates/place/detail/useLandFullDetailLoading'

interface SectionProps {
  landingContent?: LandingContent
  scrollMarginTop: CSS.Property.ScrollMarginTop
}

const SectionBox = forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(({ children, scrollMarginTop }, ref) => (
  <Box
    ref={ref}
    padding="24px 20px"
    display="flex"
    flexDirection="column"
    gap={4}
    backgroundColor="system-white"
    scrollMarginTop={scrollMarginTop}
  >
    {children}
  </Box>
))

const PanelSection = forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(
  ({ children, scrollMarginTop }, ref) => {
    return (
      <SectionBox ref={ref} scrollMarginTop={scrollMarginTop}>
        {children}
      </SectionBox>
    )
  },
)

const OfficeFullDetailSection = forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(
  ({ children, landingContent, scrollMarginTop }, parentRef) => {
    const { landing, uid } = useDetailInfo()
    const boxRef = useRef<HTMLDivElement>(null)
    const ref = useCombinedRefs(boxRef, parentRef)
    const isDetailLoading = useOfficeFullDetailLoading(uid)
    const isMounted = useDeferredValue(useIsMounted())

    useEffect(() => {
      if (isNotNil(landingContent) && boxRef.current && landing === landingContent && !isDetailLoading && isMounted) {
        boxRef.current?.scrollIntoView()
      }
    }, [landing, landingContent, ref, isDetailLoading, isMounted])

    return (
      <SectionBox ref={ref} scrollMarginTop={scrollMarginTop}>
        {children}
      </SectionBox>
    )
  },
)

const LwhFullDetailSection = forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(
  ({ children, landingContent, scrollMarginTop }, parentRef) => {
    const { landing, uid } = useDetailInfo()
    const boxRef = useRef<HTMLDivElement>(null)
    const ref = useCombinedRefs(boxRef, parentRef)
    const isDetailLoading = useLwhFullDetailLoading(uid)
    const isMounted = useDeferredValue(useIsMounted())

    useEffect(() => {
      if (isNotNil(landingContent) && boxRef.current && landing === landingContent && !isDetailLoading && isMounted) {
        boxRef.current?.scrollIntoView()
      }
    }, [landing, landingContent, ref, isDetailLoading, isMounted])

    return (
      <SectionBox ref={ref} scrollMarginTop={scrollMarginTop}>
        {children}
      </SectionBox>
    )
  },
)

const LandFullDetailSection = forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(
  ({ children, landingContent, scrollMarginTop }, parentRef) => {
    const { landing, uid } = useDetailInfo()
    const boxRef = useRef<HTMLDivElement>(null)
    const ref = useCombinedRefs(boxRef, parentRef)
    const isDetailLoading = useLandFullDetailLoading(uid)
    const isMounted = useDeferredValue(useIsMounted())

    useEffect(() => {
      if (isNotNil(landingContent) && boxRef.current && landing === landingContent && !isDetailLoading && isMounted) {
        boxRef.current?.scrollIntoView()
      }
    }, [landing, landingContent, ref, isDetailLoading, isMounted])

    return (
      <SectionBox ref={ref} scrollMarginTop={scrollMarginTop}>
        {children}
      </SectionBox>
    )
  },
)

const DetailSection = forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(
  ({ children, landingContent, scrollMarginTop }, parentRef) => {
    const { detailType, detailContent } = useDetailInfo()
    const isFull = detailType === 'full'

    return isFull ? (
      <SwitchCase
        value={detailContent}
        caseBy={{
          office: (
            <OfficeFullDetailSection ref={parentRef} landingContent={landingContent} scrollMarginTop={scrollMarginTop}>
              {children}
            </OfficeFullDetailSection>
          ),
          lwh: (
            <LwhFullDetailSection ref={parentRef} landingContent={landingContent} scrollMarginTop={scrollMarginTop}>
              {children}
            </LwhFullDetailSection>
          ),
          land: (
            <LandFullDetailSection ref={parentRef} landingContent={landingContent} scrollMarginTop={scrollMarginTop}>
              {children}
            </LandFullDetailSection>
          ),
        }}
      />
    ) : (
      <PanelSection ref={parentRef} scrollMarginTop={scrollMarginTop}>
        {children}
      </PanelSection>
    )
  },
)

PanelSection.displayName = 'PanelSection'
SectionBox.displayName = 'SectionBox'
OfficeFullDetailSection.displayName = 'FullDetailSection'
LwhFullDetailSection.displayName = 'LwhFullDetailSection'
LandFullDetailSection.displayName = 'LandFullDetailSection'
DetailSection.displayName = 'DetailSection'

export default DetailSection
