import useLands from '~/templates/place/hooks/useLands'
import { useSWRConfig } from 'swr'
import { isNotNil } from '@toss/utils'
import useInterestService from '~/libs/hooks/useInterestService'
import { getGetLandDetailsDataV1LandsDetailsGetKey } from '~/libs/apis/data/land/land'
import useLandTableViewData from '~/templates/place/table-view/useLandTableViewData'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useEffect, useState } from 'react'

interface InterestItemOption {
  pnu: string
  interestBuildingId: number | null
}

const useInterestLand = ({ pnu, interestBuildingId }: InterestItemOption) => {
  const { mutate: mutateLandList } = useLands()
  const { mutate: mutateLandTableList } = useLandTableViewData()
  const { mutate: globalMutate } = useSWRConfig()
  const { addInterest, deleteInterest } = useInterestService()
  const { globalSwrKeys } = useDetailInfo()
  const [stateInterestedId, setStateInterestedId] = useState(interestBuildingId)

  useEffect(() => {
    setStateInterestedId(interestBuildingId)
  }, [interestBuildingId])

  const refreshLands = async () => {
    await globalMutate(getGetLandDetailsDataV1LandsDetailsGetKey({ pnus: pnu }))
    await mutateLandList()
    globalSwrKeys?.forEach(async (swrKey) => await globalMutate(swrKey))
    await mutateLandTableList()
  }

  const handleAddition = () => {
    addInterest({ realEstateType: 'LAND', pnu }, refreshLands)
      ?.then((response) => {
        if (response) {
          const interest = response.interestBuildingId
          setStateInterestedId(interest)
        } else {
          setStateInterestedId(null)
        }
      })
      .catch(() => setStateInterestedId(null))
  }

  const handleDeletion = () => {
    if (!isNotNil(interestBuildingId)) {
      return
    }
    deleteInterest(interestBuildingId, refreshLands)
      ?.then(() => {
        setStateInterestedId(null)
      })
      .catch(() => setStateInterestedId(interestBuildingId))
  }

  return {
    handleAddition,
    handleDeletion,
    stateInterestedId,
  }
}

export default useInterestLand
