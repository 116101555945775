import { css } from 'styled-components'

const TooltipCss = css`
  .e-tooltip-wrap[role='tooltip'] {
    border: none;
    border-radius: 8px;
    filter: drop-shadow(var(--shadow-black-03));
    background: none;
    --tooltip-bg-color: var(--color-system-black-a75);
    --tooltip-font-color: var(--color-white);

    &.white {
      --tooltip-bg-color: var(--color-system-white-a80);
      --tooltip-font-color: var(--color-black);
      box-shadow: var(--shadow-black-05);
    }

    & .e-tip-content {
      background-color: var(--tooltip-bg-color);
      color: var(--tooltip-font-color);
      font-family: var(--font-family);
      padding: 5px 10px;
      font-size: caption1;
      line-height: caption2;
      font-weight: regular;
    }

    &.align-left .e-tip-content {
      transform: translateX(calc(50% - 20px));
    }

    &.align-right .e-tip-content {
      transform: translateX(calc(-50% + 20px));
    }

    &.e-tooltip-wrap .e-arrow-tip.e-tip-bottom {
      height: 6px;
      width: 12px;
    }

    &.e-tooltip-wrap .e-arrow-tip.e-tip-top {
      height: 6px;
      width: 12px;
    }

    &.e-tooltip-wrap .e-arrow-tip.e-tip-left {
      height: 12px;
      width: 6px;
    }

    &.e-tooltip-wrap .e-arrow-tip.e-tip-right {
      height: 12px;
      width: 6px;
    }

    & .e-arrow-tip-inner.e-tip-bottom::before,
    & .e-arrow-tip-inner.e-tip-left::before,
    & .e-arrow-tip-inner.e-tip-right::before,
    & .e-arrow-tip-inner.e-tip-top::before {
      background: none;
      color: transparent;
    }

    & .e-arrow-tip.e-tip-top {
      transform: translateY(-1px);
    }

    & .e-arrow-tip.e-tip-left {
      transform: translateX(-1px);
    }

    & .e-arrow-tip-outer.e-tip-bottom {
      border-top: 6px solid var(--tooltip-bg-color);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }

    & .e-arrow-tip-outer.e-tip-top {
      border-bottom: 6px solid var(--tooltip-bg-color);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }

    & .e-arrow-tip-outer.e-tip-left {
      border-right: 6px solid var(--tooltip-bg-color);
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
    }

    & .e-arrow-tip-outer.e-tip-right {
      border-left: 6px solid var(--tooltip-bg-color);
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      transform: translateX(-1px);
    }
  }
`

export default TooltipCss
