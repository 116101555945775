import { FC, MouseEventHandler } from 'react'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import Box from '~/components/Box/Box'
import Button from '~/components/Button/Button'
import { useTranslation } from 'next-i18next'
import Tooltip from '~/components/Tooltip/Tooltip'
import useInquiryControl from '~/libs/hooks/useInquiryControl'
import { DETAIL_FOOTER_BUTTONS_Z_INDEX } from '~/libs/constants/place'

interface DetailFooterButtonsProps {
  isExistsContactNumber?: boolean | null
  onFullClick: MouseEventHandler
}
const DetailFooterButtons: FC<DetailFooterButtonsProps> = ({ isExistsContactNumber = false, onFullClick }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: inquiryTerm } = useTranslation('common', { keyPrefix: 'data_inquiry' })
  const { detailType, detailContent, uid } = useDetailInfo()
  const { hide } = useFullDetailDialog()
  const { openDialog: openInquiryDialog } = useInquiryControl()

  const inquiryButtonWidth = detailType === 'panel' ? '120px' : '280px'

  const handleInquiryButtonClick = () => {
    openInquiryDialog({ type: detailContent, targetId: uid })
  }

  return (
    <Box
      key="fullButton"
      width="100%"
      position="absolute"
      bottom={0}
      p={5}
      backgroundColor="system-white"
      zIndex={DETAIL_FOOTER_BUTTONS_Z_INDEX}
    >
      <Box display="flex" gap="10px">
        {isExistsContactNumber ? (
          <Box flex={1}>
            <Tooltip
              content={inquiryTerm('fast_inquiry_description')}
              color="white"
              position="TopCenter"
              opensOn="Hover"
            >
              <Button
                size="lg"
                width={inquiryButtonWidth}
                content={inquiryTerm('fast_inquiry')}
                color="darkGray"
                onClick={handleInquiryButtonClick}
              />
            </Tooltip>
          </Box>
        ) : (
          <Box flex={1}>
            <Button
              width={inquiryButtonWidth}
              size="lg"
              content={inquiryTerm('on_inquiry')}
              color="darkGray"
              onClick={handleInquiryButtonClick}
            />
          </Box>
        )}
        {detailType === 'panel' ? (
          <Button width="100%" size="lg" content={t('dtl_view')} color="black" onClick={onFullClick} />
        ) : (
          <Button width="100%" size="lg" content={t('close')} color="black" onClick={hide} />
        )}
      </Box>
    </Box>
  )
}

export default DetailFooterButtons
