import {
  LwhLeaseTrendValueType,
  LwhOut,
  OfficeLeaseTrendOut,
  OfficeLeaseTrendValueType,
  OfficeOut,
} from '~/libs/apis/data/model'
import { commaizeNumber, isNotNil, roundToUnit } from '@toss/utils'
import { formatDateYq } from '~/libs/utils/date'
import { OfficeLeaseCompareType } from '~/templates/compare/office/content/chart/CompareOfficeLineChart'
import { useTranslation } from 'next-i18next'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { formatVacancyRate } from '~/libs/utils/format'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import { useAreaUnitLabel, usePerUnitFormat } from '~/libs/hooks/useFormat'
import { LwhLeaseCompareType } from '~/templates/compare/lwh/content/chart/CompareLwhLineChart'
import useUnitTransformer from '~/libs/hooks/useUnitTransformer'

interface GridDataType {
  [key: string]: string
}

function useCompareGrid(
  compareType: OfficeLeaseCompareType | LwhLeaseCompareType | OfficeLeaseTrendValueType | LwhLeaseTrendValueType,
  buildings: OfficeOut[] | LwhOut[],
) {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const unitFormatter = usePerUnitFormat()
  const { unitConverterForClient } = useUnitTransformer()

  const isEmptyRate = compareType.endsWith('Rate')
  const isEmptyArea = compareType.endsWith('Area')

  const gridItem = buildings.map((item) => ({
    name: item.buildingName,
    raId: item.raId,
  }))

  const headerColumn: ColumnModel = {
    field: 'dateYq',
    headerText: t('quarterly'),
    textAlign: 'Left',
    valueAccessor: (field: string, data: any) => formatDateYq(data[field]),
  }

  const columns: ColumnModel[] = gridItem.map((item) => ({
    field: item.raId,
    headerText: item.name,
    clipMode: 'EllipsisWithTooltip',
    textAlign: 'Left',
    valueAccessor: useNilableValueAccessor,
  }))
  const areaFormat = useAreaUnitLabel()

  const getDataValue = (value: NullishNumber) => {
    if (isNotNil(value)) {
      if (isEmptyRate) {
        return formatVacancyRate(value)
      } else if (isEmptyArea) {
        return commaizeNumber(roundToUnit(unitConverterForClient(value), 0.01).toFixed(2)) + areaFormat
      } else {
        return unitFormatter(value, { format: 'won' })
      }
    }
    return '-'
  }

  const getGridDataSource = (data: OfficeLeaseTrendOut[] | undefined) => {
    if (!data) {
      return []
    }
    const groupedData: Record<string, GridDataType> = {}
    data.forEach(({ raId, dateYq, value }) => {
      if (!groupedData[dateYq]) {
        groupedData[dateYq] = { dateYq }
      }
      groupedData[dateYq][raId] = getDataValue(value)
    })
    return Object.values(groupedData).sort((a, b) => b.dateYq.localeCompare(a.dateYq))
  }

  return { columns, headerColumn, getGridDataSource }
}

export default useCompareGrid
