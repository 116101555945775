import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react'
import { noop } from '@toss/utils'
import Box from '~/components/Box/Box'

export type InquiryPageViewModeType = 'list' | 'new' | 'modify'

interface InquiryPageProviderType {
  targetRaInquiryId: number | null
  goListPage: () => void
  goNewPage: () => void
  goModifyPage: (raInquiryId: number) => void
}

const InquiryPageContext = createContext<InquiryPageProviderType>({
  targetRaInquiryId: null,
  goListPage: noop,
  goNewPage: noop,
  goModifyPage: noop,
})

const InquiryPageContextProvider: FC<
  PropsWithChildren<{
    setViewMode: (viewMode: InquiryPageViewModeType) => void
  }>
> = ({ setViewMode, children }) => {
  const [targetRaInquiryId, setTargetRaInquiryId] = useState<number | null>(null)
  const value = useMemo(
    () => ({
      targetRaInquiryId,
      goListPage: () => {
        setTargetRaInquiryId(null)
        setViewMode('list')
      },
      goNewPage: () => {
        setTargetRaInquiryId(null)
        setViewMode('new')
      },
      goModifyPage: (raInquiryId: number | null) => {
        setTargetRaInquiryId(raInquiryId)
        setViewMode('modify')
      },
    }),
    [setViewMode, targetRaInquiryId],
  )

  return (
    <InquiryPageContext.Provider value={value}>
      <Box height="100%">{children}</Box>
    </InquiryPageContext.Provider>
  )
}

const useInquiryPageContext = () => {
  return useContext(InquiryPageContext)
}

export { useInquiryPageContext }

export default InquiryPageContextProvider
