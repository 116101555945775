import { isNotNil } from '@toss/utils'
import { getOfficeDetailDataV1BuildingsOfficeRaIdGetKey } from '~/libs/apis/data/buildings-office/buildings-office'
import { getLwhDetailDataV1BuildingsLwhRaIdGetKey } from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import useBuildings from '~/templates/place/hooks/useBuildings'
import { useSWRConfig } from 'swr'
import useInterestService from '~/libs/hooks/useInterestService'
import useBuildingTableViewData from '~/templates/place/table-view/useBuildingTableViewData'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useEffect, useState } from 'react'

interface UseInterestBuildingProps {
  raId: string
  interestBuildingId: number | null
  propertyTypeCode: string
}

const useInterestBuilding = ({ raId, interestBuildingId, propertyTypeCode }: UseInterestBuildingProps) => {
  const { mutate: mutateBuildingList } = useBuildings()
  const { mutate: mutateBuildingTableList } = useBuildingTableViewData()
  const { mutate: globalMutate } = useSWRConfig()
  const { addInterest, deleteInterest } = useInterestService()
  const { globalSwrKeys } = useDetailInfo()
  const [stateInterestedId, setStateInterestedId] = useState(interestBuildingId)

  useEffect(() => {
    setStateInterestedId(interestBuildingId)
  }, [interestBuildingId])

  const refreshBuildings = async (): Promise<void> => {
    if (propertyTypeCode === 'PT01_1') {
      await globalMutate(getOfficeDetailDataV1BuildingsOfficeRaIdGetKey(raId))
    } else {
      await globalMutate(getLwhDetailDataV1BuildingsLwhRaIdGetKey(raId))
    }
    await mutateBuildingList()
    globalSwrKeys?.forEach(async (swrKey) => await globalMutate(swrKey))
    await mutateBuildingTableList()
  }

  const handleAddition = () => {
    addInterest({ realEstateType: 'BUILDING', propertyTypeCode, raId }, refreshBuildings)
      ?.then((response) => {
        if (response) {
          const interest = response.interestBuildingId
          setStateInterestedId(interest)
        } else {
          setStateInterestedId(null)
        }
      })
      .catch(() => {
        setStateInterestedId(null)
      })
  }

  const handleDeletion = () => {
    if (!isNotNil(stateInterestedId)) {
      return
    }

    deleteInterest(stateInterestedId, refreshBuildings)
      ?.then(() => {
        setStateInterestedId(null)
      })
      .catch(() => {
        setStateInterestedId(interestBuildingId)
      })
  }

  return {
    handleAddition,
    handleDeletion,
    stateInterestedId,
  }
}

export default useInterestBuilding
