import { FC, useState } from 'react'
import { useOfficeTradeDataV1BuildingsOfficeTradeGet } from '~/libs/apis/data/buildings-office/buildings-office'
import TradeHistoryGrid from '~/templates/place/detail/trade/TradeHistoryGrid'
import { useDetailInfo } from '~/templates/DetailTypeProvider'

const PAGE_SIZE = 5
const OfficeTradeHistory: FC = () => {
  const { uid: raId } = useDetailInfo()
  const [pageNum, setPageNum] = useState(1)

  const { data } = useOfficeTradeDataV1BuildingsOfficeTradeGet(
    { raId: raId, pageNum, pageSize: PAGE_SIZE },
    {
      swr: { keepPreviousData: true },
    },
  )

  const handlePageChange = (page: number) => setPageNum(page)

  return <TradeHistoryGrid dataSource={data?.data} pageModel={data?.page} onPageChange={handlePageChange} />
}

export default OfficeTradeHistory
