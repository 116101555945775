import React, { FC, FormEventHandler, MouseEventHandler, useEffect, useRef } from 'react'
import TextBox from '~/components/TextBox/TextBox'
import { useTranslation } from 'react-i18next'
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-inputs'
import Box from '~/components/Box/Box'
import InquiryFileField from '~/templates/common/inquiry/InquiryFileField'
import { GetUsersInquiryResponseInquiry } from '~/libs/apis/service/model'
import styled from '@xstyled/styled-components'
import { isEmail } from '@toss/validators'
import FormFieldSetForGuest from '~/templates/common/inquiry/FormFieldSetForGuest'
import FormFieldSetForUser from '~/templates/common/inquiry/FormFieldSetForUser'
import { FormEventArgs } from '@syncfusion/ej2-inputs/src/form-validator/form-validator'
import { unescapeHTML } from '~/libs/utils/format'
import {
  formatInquiryFormData,
  patchInquiryForUser,
  postInquiryForGuest,
  postInquiryForUser,
} from '~/libs/utils/inquiry'
import RaServiceError from '~/libs/errors/RaServiceError'
import { datadogRum } from '@datadog/browser-rum'
import { useToast } from '~/templates/ToastContextProvider'
import { isNotNil } from '@toss/utils'
import { useInquiryPageContext } from '~/templates/my-page/inquiry/InquiryPageProvider'
import Button from '~/components/Button/Button'
import { getUsersInquiriesLatest } from '~/libs/apis/service/api'
import { SERVICE_ERROR_CODE } from '~/libs/constants/common'
import { useBooleanState } from '@toss/react'

interface InquiryFormProps {
  mode: 'new' | 'modify'
  isGuest?: boolean // 비회원 용 여부
  data?: GetUsersInquiryResponseInquiry
  onInit: (validator: FormValidator) => void // validator 가 셋팅될 때
  onValidate: (arg: FormEventArgs) => void // validation 이 일어날 때 호출되는 이벤트
  onSubmitSuccess: () => void // 제출이 성공적으로 완료되었을 때 호출되는 함수
}

const checkEmailValidation = ({ value }: { value: string }) => {
  return isEmail(value)
}

const checkTextFieldValidation = ({ value }: { value: string }) => {
  return value !== '' && value.length > 0
}

const InquiryForm: FC<InquiryFormProps> = ({ mode, isGuest, data, onInit, onSubmitSuccess, onValidate }) => {
  const isLoading = useRef(false)
  const validatorRef = useRef<FormValidator>()
  const { targetRaInquiryId } = useInquiryPageContext()
  const { show } = useToast()

  const [isEditChecked, setIsEditChecked] = useBooleanState(false)
  const { t: term } = useTranslation('common', { keyPrefix: 'data_inquiry' })
  const { t: commonMsg } = useTranslation('common', { keyPrefix: 'common_msg' })
  const { t: commonTerm } = useTranslation('common', { keyPrefix: 'common_term' })

  const formOptions: FormValidatorModel = {
    rules: {
      contents: {
        required: [checkTextFieldValidation, ''],
      },
    },
    validationComplete: (arg) => {
      onValidate(arg)
    },
    keyup: () => {
      validatorRef.current?.addRules('contents', {
        required: [checkTextFieldValidation, ''],
      })
      validatorRef.current?.validate()
    },
    // focusout 시 validate 가 일어나면 blur 이벤트가 발생해 버튼 클릭이벤트가 동작하지 않으므로 rule 제거
    focusout: () => {
      validatorRef.current?.removeRules('contents')
    },
  }

  useEffect(() => {
    validatorRef.current = new FormValidator('#data-inquiry-form', formOptions)
    if (isGuest) {
      validatorRef.current?.addRules('email', {
        required: [checkEmailValidation, commonMsg('request_for_valid_email_type')],
      })
      validatorRef.current?.addRules('name', {
        required: [checkTextFieldValidation, ''],
      })
    }

    onInit(validatorRef.current)
    return () => {
      validatorRef.current = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const patchInquiry = (formData: FormData) => {
    if (!isNotNil(targetRaInquiryId)) {
      return
    }
    return patchInquiryForUser(targetRaInquiryId, formData)
  }

  const postInquiry = (formData: FormData) => {
    return isGuest ? postInquiryForGuest(formData) : postInquiryForUser(formData)
  }

  const handleInquiryAction = async () => {
    if (isLoading.current) {
      return
    }
    isLoading.current = true

    const formElement = validatorRef.current?.element

    if (!formElement) {
      return
    }

    try {
      const formData = formatInquiryFormData(formElement, data, isGuest)
      const inquiryAction = mode === 'new' ? postInquiry : patchInquiry

      if (!inquiryAction) {
        return
      }

      await inquiryAction(formData)
      onSubmitSuccess()
    } catch (e) {
      if (e instanceof RaServiceError) {
        show(e.message)
      } else {
        datadogRum.addError(e)
      }
    } finally {
      isLoading.current = false
    }
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = (arg) => {
    arg.preventDefault()
    arg.stopPropagation()
    handleInquiryAction()
  }

  const loadLatestContent: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      const latestContent = await getUsersInquiriesLatest()

      if (!latestContent?.contents) {
        throw new RaServiceError(400, { code: SERVICE_ERROR_CODE.INQUIRY_CONTENT_EMPTY, message: '' })
      }

      if (validatorRef.current?.element['contents']) {
        validatorRef.current.element['contents'].value = unescapeHTML(latestContent.contents)
        setIsEditChecked()
      }
    } catch (e) {
      if (e instanceof RaServiceError) {
        if (e.responseBody.code === SERVICE_ERROR_CODE.INQUIRY_CONTENT_EMPTY) {
          show(term('cannot_be_loaded_to_content'))
        } else {
          show(e.responseBody.message)
        }
      } else {
        show(commonTerm('unknown_error'))
        datadogRum.addError(e)
      }
    }
  }

  return (
    <StyledBox padding="30px">
      <form id="data-inquiry-form" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap="30px">
          {!isGuest ? <FormFieldSetForUser data={data} /> : <FormFieldSetForGuest />}
          <Box display="flex" flexDirection="column" gap="10px">
            <TextBox
              multiline
              value={data?.contents ? unescapeHTML(data.contents) : term('to_inquiry')}
              name="contents"
              maxLength={1000}
              input={() => setIsEditChecked()}
              placeholder={term('placeholder.content')}
            />
            <input type="checkbox" hidden readOnly name="isEditChecked" checked={isEditChecked} />
            {!isGuest && (
              <Button
                width="140px"
                content={term('load_latest_content')}
                size="sm"
                variant="line"
                color="gray"
                onClick={loadLatestContent}
              />
            )}
          </Box>
          {!isGuest && <InquiryFileField data={data} />}
        </Box>
      </form>
    </StyledBox>
  )
}

const StyledBox = styled(Box)`
  & form[id='data-inquiry-form'] {
    // textbox의 textarea의 height를 조절하려면 css style을 수정해주어야 함.
    .e-input-group.e-size-s:has(textarea) {
      padding: 7px 16px !important;
    }

    // form validator 로는 값 검사만 하고 input style 은 적용되지 않도록
    &:has(.e-error) .e-input-group {
      &:not(:has([name='email'])) {
        border-color: var(--color-gray-400) !important;
      }

      input:not([name='email']) {
        color: var(--color-gray-800) !important;
      }

      input:not([name='email'])::placeholder {
        color: var(--color-gray-400) !important;
      }
    }

    textarea {
      resize: none !important;
      height: 100px !important;
      padding: 0 !important;

      &::placeholder {
        color: var(--color-gray-500) !important;
      }
    }
  }
`

export default InquiryForm
