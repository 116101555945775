import { css, CSSProperties, styled } from '@xstyled/styled-components'
import classNames from 'classnames'
import React, { forwardRef, PropsWithChildren, RefObject, useEffect, useImperativeHandle, useRef } from 'react'
import { createPortal } from 'react-dom'
import Box from '~/components/Box/Box'
import { POPPER_Z_INDEX } from '~/libs/constants/common'

export interface PopperPosition {
  top?: CSSProperties['top']
  left?: CSSProperties['left']
  right?: CSSProperties['right']
  bottom?: CSSProperties['bottom']
}

export interface PopperProps extends PopperPosition {
  onClose?: () => void
  /** 클릭해도 Popper가 닫히지 않는 Popper을 여는 콘텐츠 영역 지정 */
  anchorEl?: HTMLElement | null
  popperWidth?: CSSProperties['width']
  popperHeight?: CSSProperties['height']
  margin?: CSSProperties['margin']
  zIndex?: CSSProperties['zIndex']
  /** 기본 padding 값 적용 여부 */
  gutter?: boolean
  /** 스크롤 적용 여부 */
  scroll?: boolean
  /** 클릭해도 Popper이 닫히지 않는 Content 지정 */
  containerRef?: RefObject<HTMLElement>
  closeOnOutsideClick?: boolean
  portalContainer?: Element | DocumentFragment | null
}

const Popper = forwardRef<HTMLDivElement, PropsWithChildren<PopperProps>>(
  (
    {
      anchorEl,
      top,
      left,
      right,
      bottom,
      popperWidth,
      popperHeight,
      margin,
      zIndex,
      onClose,
      children,
      gutter = true,
      scroll = true,
      containerRef,
      portalContainer,
      closeOnOutsideClick = true,
    },
    forwardingRef,
  ) => {
    const ref = useRef<HTMLDivElement>(null)

    useImperativeHandle(forwardingRef, () => ref.current!, [])

    useEffect(() => {
      if (!onClose || !closeOnOutsideClick) {
        return
      }

      const handleMousedown = (e: MouseEvent) => {
        const eventTarget = e.target as HTMLElement

        if (!ref.current || ref.current.contains(eventTarget) || anchorEl?.contains(eventTarget)) {
          return
        }

        if (containerRef?.current && containerRef.current.contains(eventTarget)) {
          return
        }

        /* NOTE: 싱크퓨전 컴포넌트 중 옵션을 포함하는 컴포넌트(i.e. Dropdown, DatePicker)의 옵션은 시각적으로는 Popper의 내부에 있지만,
                 실제로는 body의 자식으로 렌더돼 Popper의 외부에 위치함. 이 요소들은 클릭 시 Popper가 닫히지 않아야 하므로 예외 처리 */
        if (eventTarget.closest('.prevent-popper-close')) {
          return
        }

        onClose()
      }

      window.addEventListener('mousedown', handleMousedown)

      return () => {
        window.removeEventListener('mousedown', handleMousedown)
      }
    }, [])

    return createPortal(
      <PopperBase
        padding={gutter ? 2 : 0}
        ref={ref}
        top={top}
        bottom={bottom}
        left={left}
        right={right}
        margin={margin}
        zIndex={zIndex ?? POPPER_Z_INDEX}
        width={popperWidth || 'fit-content'}
        height={popperHeight || 'fit-content'}
        className={classNames({ 'has-scroll': scroll })}
      >
        <Box flexDirection="column" display="flex" maxHeight={scroll ? 'auto' : 'inherit'}>
          {children}
        </Box>
      </PopperBase>,
      portalContainer ?? document.body,
    )
  },
)

Popper.displayName = 'Popper'

export default Popper

export const scrollCss = css`
  padding-right: 0;
  scrollbar-gutter: stable;
  overflow: auto;

  &::-webkit-scrollbar {
    background: none;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    border: solid 4px transparent;
    box-shadow: inset 0 0 5px 5px var(--color-gray-400);
  }
`

const PopperBase = styled(Box)`
  box-sizing: border-box;
  position: absolute;
  border-radius: 8px;
  background-color: system-white;
  overflow: hidden;
  box-shadow: black-06;

  &.has-scroll {
    ${scrollCss}
  }
`
