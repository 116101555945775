import { divideByTenThousand } from '~/libs/utils/number'
import { format, parse, toDate } from 'date-fns'
import { IAxisLabelRenderEventArgs } from '@syncfusion/ej2-react-charts'

export const getAnnotationsSubtotalManPy = (chartData?: Record<string, any>[], keys?: string[]) =>
  chartData?.map((item) => {
    const totalArea = keys?.reduce((accumulator, currentValue) => accumulator + item[currentValue], 0)
    const totalManPyNumber = divideByTenThousand(totalArea) ?? 0
    return {
      x: item['boeTypeCodeName'],
      y: totalManPyNumber + 20,
      content: divideByTenThousand(totalArea)?.toFixed(1),
    }
  })

const convertQuarterToMonthString = (quarter: string) => {
  switch (quarter) {
    case 'Q1':
      return '01'
    case 'Q2':
      return '04'
    case 'Q3':
      return '07'
    default:
      return '10'
  }
}

export const getDateByYearAndQuarter = (year: string, quarter: string) => {
  return parse(`${year}${convertQuarterToMonthString(quarter)}`, 'yyyyMM', new Date())
}

export const renderXAxisLabelAsYearQuarter = (args: IAxisLabelRenderEventArgs) => {
  if (args.axis.name === 'primaryXAxis') {
    const date: Date = toDate(args.value)
    args.text = format(date, 'yyyy-QQQ')
  }
}
