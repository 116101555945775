import { useTranslation } from 'next-i18next'
import Box from '~/components/Box/Box'
import MenuItem from '~/templates/MenuItem'
import GnbUserOff from '~/assets/images/icons/gnb/gnb-user-off.svg'
import GnbUserOn from '~/assets/images/icons/gnb/gnb-user-on.svg'
import GnbMoreOff from '~/assets/images/icons/gnb/gnb-more-off.svg'
import GnbMoreOn from '~/assets/images/icons/gnb/gnb-more-on.svg'
import GnbLogOut from '~/assets/images/icons/gnb/gnb-logout.svg'
import useAuth from '~/libs/hooks/useAuth'
import { useToast } from '~/templates/ToastContextProvider'
import { postUsersSignOut } from '~/libs/apis/service/api'
import RaServiceError from '~/libs/errors/RaServiceError'
import { useConfigurationDialog } from '~/templates/ConfigurationContextProvider'
import { datadogRum } from '@datadog/browser-rum'
import CommonMenuItem from '~/templates/CommonMenuItem'
import { useNextRouter } from '~/libs/hooks/useNextRouter'
import OptionMenuItem from '~/templates/OptionMenuItem'
import MoreInfoDialog from '~/templates/MoreInfoDialog'
import React, { useState } from 'react'

const Menu = () => {
  const router = useNextRouter()
  const { t } = useTranslation()
  const { logout } = useAuth()
  const { show } = useToast()
  const configurationDialogRef = useConfigurationDialog()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleLogout = async () => {
    try {
      await postUsersSignOut()
      await logout()
    } catch (e) {
      if (e instanceof RaServiceError) {
        show(e.responseBody.message)
      } else {
        datadogRum.addError(e)
      }
    }
  }

  const handleClickMyPage = () => {
    router.push('/my-page')
  }
  const handleClickMoreInfo = () => {
    setIsOpen(true)
  }
  const handleCloseMoreInfo = () => {
    setIsOpen(false)
  }

  const handleConfigurationClick = () => {
    configurationDialogRef?.current?.show()
  }

  return (
    <>
      <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center" gap="30px">
        <MenuItem text={t('nav.search_real_estate')} url="/[locale]/place" />
        <MenuItem text={t('nav.theme')} url="/[locale]/topic" />
        <MenuItem text={t('nav.compare')} url="/[locale]/compare" />
        <MenuItem text={t('nav.nearby')} url="/[locale]/nearby" />
        <MenuItem
          text={t('nav.market_report')}
          subMenu={[
            { text: t('nav.office_market_report'), url: '/[locale]/report/office' },
            { text: t('nav.lwh_market_report'), url: '/[locale]/report/lwh' },
          ]}
          url="/[locale]/report"
        />
      </Box>
      <Box display="flex" gap="24px">
        <CommonMenuItem
          icon={<GnbUserOff width="24px" height="24px" />}
          selectedIcon={<GnbUserOn width="24px" height="24px" />}
          selected={router.route.startsWith('/[locale]/my-page')}
          text={t('nav.my_page')}
          onClick={handleClickMyPage}
        />
        <CommonMenuItem
          icon={<GnbMoreOff width="24px" height="24px" />}
          selectedIcon={<GnbMoreOn width="24px" height="24px" />}
          selected={isOpen}
          text={t('nav.more')}
          onClick={handleClickMoreInfo}
        />
        <OptionMenuItem onClick={handleConfigurationClick} />
      </Box>
      <Box ml="30px">
        <CommonMenuItem
          icon={<GnbLogOut width="24px" height="24px" />}
          selectedIcon={<GnbUserOff width="24px" height="24px" />}
          text={t('nav.logout')}
          onClick={handleLogout}
        />
      </Box>

      <MoreInfoDialog open={isOpen} onClose={handleCloseMoreInfo} />
    </>
  )
}

export default Menu
