import { FC, PropsWithChildren } from 'react'
import Box from '~/components/Box/Box'
import Tail from '~/assets/images/marker/tail.svg'
import { SystemProps } from '@xstyled/styled-components'

interface BubbleWrapProps {
  backgroundColor: string
  borderColor: string
  width?: SystemProps['w']
  opacity?: string
}

const BubbleWrap: FC<PropsWithChildren<BubbleWrapProps>> = ({
  backgroundColor,
  borderColor,
  width,
  opacity,
  children,
}) => {
  return (
    <Box width={width ?? 'fit-content'} cursor="pointer">
      <Box
        className="bubble-children"
        display="flex"
        overflow="hidden"
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        borderWidth="1px"
        borderRadius="8px"
        boxShadow="marker-01"
        opacity={opacity}
      >
        {children}
      </Box>
      <Box display="flex" position="relative" justifyContent="center">
        <Box
          position="absolute"
          className="tail-outer-icon"
          as={Tail}
          width="12px"
          height="9px"
          marginTop="-1px"
          color={borderColor}
          opacity={opacity}
        />
        <Box
          position="absolute"
          className="tail-inner-icon"
          as={Tail}
          width="12px"
          height="8px"
          marginTop="-2px"
          color={backgroundColor}
          opacity={opacity}
        />
      </Box>
    </Box>
  )
}

export default BubbleWrap
