import { ExchangesOut } from '~/libs/apis/data/model'
import { useGetExchangesDataV1ExchangesGet } from '~/libs/apis/data/default/default'
import { SWR_IMMUTABLE_OPTION } from '~/libs/constants/common'

const useUsdExchange = (): ExchangesOut => {
  const { data } = useGetExchangesDataV1ExchangesGet(SWR_IMMUTABLE_OPTION)
  return data?.find((item) => item.currency === 'USD') ?? { ammount: 1, currency: 'USD', updateDateYm: '' }
}

export default useUsdExchange
