import { useTranslation } from 'next-i18next'
import React, { FC, useState } from 'react'
import OfficeMarketLeasePriceComparisonContent from '~/templates/place/detail/lease/office/OfficeMarketLeasePriceComparisonContent'
import TabButton from '~/components/TabButton/TabButton'
import { OfficeLeaseTrendValueType } from '~/libs/apis/data/model'
import Checkbox from '~/components/Checkbox/Checkbox'
import Box from '~/components/Box/Box'
import Tooltip from '~/components/Tooltip/Tooltip'
import InfoIcon from '~/assets/images/icons/info.svg'

interface OfficeMarketLeasePriceComparisonProps {
  onValueTypeSelected: (valueType: string) => void
  onPeriodSelected: (period: string) => void
}

const OfficeMarketLeasePriceComparison: FC<OfficeMarketLeasePriceComparisonProps> = ({
  onValueTypeSelected,
  onPeriodSelected,
}) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<OfficeLeaseTrendValueType>('rentFee')
  const [checked, setChecked] = useState<boolean>(true)
  const tabItems = [
    { text: t('common_term.rent_fee'), value: 'rentFee' },
    {
      text: t('common_term.maintenance_fee'),
      value: 'maintenanceFee',
    },
    { text: t('common_term.noc'), value: 'noc' },
    { text: t('common_term.vacancy_rate'), value: 'emptyRate' },
  ]

  const handleSelected = (value: string) => {
    setSelected(value as OfficeLeaseTrendValueType)
    onValueTypeSelected(value)
  }

  return (
    <>
      <TabButton items={tabItems} size="sm" onSelect={handleSelected} selected={selected} />
      {selected === 'emptyRate' && (
        <Box display="flex" alignItems="center" gap="6px">
          <Checkbox
            size="sm"
            label={t('common:common_term:empty_rate_newest')}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <Tooltip content={t('common_msg.empty_rate_office_notice')} align="left">
            <InfoIcon width="14px" height="14px" color="var(--color-gray-500)" />
          </Tooltip>
        </Box>
      )}
      <OfficeMarketLeasePriceComparisonContent
        type={!checked && selected === 'emptyRate' ? 'emptyRateExcludeNewest' : selected}
        onPeriodSelected={onPeriodSelected}
      />
    </>
  )
}

export default OfficeMarketLeasePriceComparison
