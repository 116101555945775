import { Theme, defaultTheme, generateHexAlphaVariants } from '@xstyled/styled-components'
import colors from '~/libs/theme/colors'
import sizes from '~/libs/theme/sizes'
import shadows from '~/libs/theme/shadows'
import { fontSizes, fontWeights, texts, fonts, lineHeights } from '~/libs/theme/fonts'
import animations from '~/libs/theme/animations'

export const theme: Theme = {
  ...defaultTheme,
  sizes: {
    ...defaultTheme.sizes,
    ...sizes,
  },
  colors: {
    ...defaultTheme.colors,
    ...generateHexAlphaVariants({ ...colors }),
  },
  shadows: {
    ...defaultTheme.shadows,
    ...shadows,
  },
  texts: {
    ...defaultTheme.texts,
    ...texts,
  },
  lineHeights: {
    ...defaultTheme.lineHeights,
    ...lineHeights,
  },
  fontWeights: {
    ...defaultTheme.fontWeights,
    ...fontWeights,
  },
  fontSizes: {
    ...defaultTheme.fontSizes,
    ...fontSizes,
  },
  fonts: {
    ...defaultTheme.fonts,
    ...fonts,
  },
  animations: {
    ...defaultTheme.animations,
    ...animations,
  },
}

export default theme
