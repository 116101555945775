import React, { FC } from 'react'
import { GeomJson } from '~/libs/apis/data/model'
import { geomJsonToMultiPositions } from '~/libs/utils/map'
import { PolygonMarker } from '@mint-ui/map'
import { useColor } from '@xstyled/styled-components'

interface BuildingBoundaryMarkerProps {
  boundary: GeomJson | null
}

const BuildingBoundaryMarker: FC<BuildingBoundaryMarkerProps> = ({ boundary }) => {
  const fillColor = useColor('purple-500-a50') as string
  const strokeColor = useColor('purple-600-a70') as string
  if (!boundary) {
    return null
  }

  return geomJsonToMultiPositions(boundary).map((polygon, polygonIndex) => (
    <PolygonMarker
      key={`${polygonIndex}_${polygon[0].lat}_${polygon[0].lng}`}
      position={polygon}
      shapeProperties={{
        fill: fillColor,
        stroke: strokeColor,
        strokeWidth: '3px',
        pointerEvents: 'none',
      }}
    />
  ))
}

export default BuildingBoundaryMarker
