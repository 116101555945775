import { useOfficeLeaseTrendDataV1BuildingsOfficeLeaseTrendGet } from '~/libs/apis/data/buildings-office/buildings-office'
import { OfficeLeaseTrendOut, OfficeLeaseTrendOutValue } from '~/libs/apis/data/model'
import { isNotNil, omit, roundToUnit } from '@toss/utils'
import { formatDateYq, parseDateYq } from '~/libs/utils/date'
import { SeriesModel } from '@syncfusion/ej2-charts'
import { commonCircleMarkerSettings, commonEmptyPointSettings } from '~/libs/constants/chart'
import { AxisModel } from '@syncfusion/ej2-charts/src/chart/axis/axis-model'
import { PeriodType } from '~/templates/common/filter/PeriodFilter'
import { OfficeLeaseCompareType } from '~/templates/compare/office/content/chart/CompareOfficeLineChart'
import { useTranslation } from 'next-i18next'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { ThemeColor, useTheme } from '@xstyled/styled-components'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import useCompareGrid from '~/templates/compare/common/hooks/useCompareGrid'
import useUnitTransformer from '~/libs/hooks/useUnitTransformer'
import useGetLabelFormat from '~/libs/hooks/useGetLabelFormat'
import { useCompareListContext } from '~/templates/compare/common/CompareListProvider'
import { OfficeItem } from '~/templates/compare/office/CompareOffice'

export interface LegendType {
  label: string
  color: string
  dataKey: string
}

export const getChartColorKey = (index: number): ThemeColor => {
  if (index > 19) {
    return 'chart-20'
  }
  return `chart-${index + 1}` as ThemeColor
}

function useOfficeCompareChart(compareType: OfficeLeaseCompareType, period: PeriodType) {
  const theme = useTheme()
  const { t: chart } = useTranslation('chart', { keyPrefix: 'label_format' })
  const { list: offices } = useCompareListContext<OfficeItem>()
  const raIds = offices.map((item) => item.raId).join(',')
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const labelFormat = useGetLabelFormat(areaUnit, currencyUnit)
  const { unitConverterForClient } = useUnitTransformer()
  const { columns, headerColumn, getGridDataSource } = useCompareGrid(compareType, offices)
  const { data } = useOfficeLeaseTrendDataV1BuildingsOfficeLeaseTrendGet({
    ...omit(period, ['value']),
    raIds,
    valueType: compareType,
  })
  const isEmptyRate = compareType === 'emptyRate'

  const legend: LegendType[] = offices.map((option, index) => ({
    label: option.buildingName,
    color: theme.colors[getChartColorKey(index)].toString(),
    dataKey: option.raId,
  }))

  const getDataValue = (value: OfficeLeaseTrendOutValue) => {
    if (isNotNil(value)) {
      if (isEmptyRate) {
        return roundToUnit(value, 0.01)
      }
      return roundToUnit(unitConverterForClient(value, 'per_unit'), 1)
    }
    return undefined
  }

  const getDataSource = (data: OfficeLeaseTrendOut[] | undefined) => {
    const chartData = data?.map?.((item) => ({
      dateYq: parseDateYq(item.dateYq),
      raId: item.raId,
      value: getDataValue(item.value),
      yearQuarter: formatDateYq(item.dateYq),
    }))
    return Object.groupBy(chartData ?? [], ({ raId }) => raId)
  }

  const series: SeriesModel[] = legend.map((legendItem) => ({
    xName: 'dateYq',
    yName: 'value',
    fill: legendItem.color,
    dataSource: getDataSource(data)[legendItem.dataKey],
    name: legendItem.label,
    tooltipMappingName: 'yearQuarter',
    marker: commonCircleMarkerSettings,
    emptyPointSettings: commonEmptyPointSettings,
  }))

  const primaryXAxis: AxisModel = {
    interval: 1,
    intervalType: 'Years',
    valueType: 'DateTime',
    labelFormat: 'yyyy',
  }

  const primaryYAxis: AxisModel = {
    labelFormat: isEmptyRate ? chart('percent') : labelFormat,
  }

  const column: ColumnModel[] = [headerColumn, ...columns]

  const dataSource = getGridDataSource(data)

  return { data, series, primaryXAxis, primaryYAxis, column, dataSource }
}

export default useOfficeCompareChart
