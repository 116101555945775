import Typography from '~/components/Typography/Typography'
import { Box } from '~/components'
import { FC } from 'react'

interface NoLesseeInfoProps {
  text: string
  subText?: string
}

const NoLesseeInfo: FC<NoLesseeInfoProps> = ({ text, subText }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="120px"
      borderRadius="10px"
      gap="4px"
      padding="39px 40px"
      backgroundColor="gray-100"
    >
      <Typography variant="body" fontWeight="semibold" color="gray-600">
        {text}
      </Typography>
      {subText && (
        <Typography variant="caption1" fontWeight="400" color="gray-600">
          {subText}
        </Typography>
      )}
    </Box>
  )
}

export default NoLesseeInfo
