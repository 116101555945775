import { OfficeLeaseMarketTrendValueType } from '~/libs/apis/data/model'
import Box from '~/components/Box/Box'
import MarketLeasePriceComparisonChart from '~/templates/place/detail/lease/MarketLeasePriceComparisonChart'
import OfficeMarketLeasePriceComparisonGrid from './OfficeMarketLeasePriceComparisonGrid'
import {
  useOfficeDetailDataV1BuildingsOfficeRaIdGet,
  useOfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGet,
} from '~/libs/apis/data/buildings-office/buildings-office'
import useNamesForComparison from '~/libs/hooks/useNamesForComparison'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useState } from 'react'
import { PeriodType } from '~/templates/common/filter/PeriodFilter'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import { omit } from '@toss/utils'

interface MarketLeasePriceComparisonContentProps {
  type: OfficeLeaseMarketTrendValueType
  onPeriodSelected: (period: string) => void
}

const OfficeMarketLeasePriceComparisonContent = ({
  type,
  onPeriodSelected,
}: MarketLeasePriceComparisonContentProps) => {
  const { uid: raId } = useDetailInfo()
  const { lastUpdate } = useLastUpdateDate()
  const [period, setPeriod] = useState<PeriodType>({
    minYear: (+lastUpdate.year - 5 + 1).toString(),
    minQuarter: lastUpdate.quarter,
    maxYear: lastUpdate.year,
    maxQuarter: lastUpdate.quarter,
  })

  const { data: office } = useOfficeDetailDataV1BuildingsOfficeRaIdGet(raId, {
    swr: {
      enabled: Boolean(raId),
      keepPreviousData: true,
    },
  })

  const { data } = useOfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGet(
    {
      ...omit(period, ['value']),
      raId,
      valueType: type,
    },
    { swr: { keepPreviousData: true } },
  )

  const names = useNamesForComparison(office)
  const handlePeriodChange = (newValue: PeriodType) => {
    setPeriod((prev) => ({ ...prev, ...newValue }))
    if (newValue.value) {
      onPeriodSelected(newValue.value)
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap="24px" pt={1.5}>
      <OfficeMarketLeasePriceComparisonGrid type={type} data={data} names={names} />
      <MarketLeasePriceComparisonChart type={type} data={data} names={names} onPeriodChange={handlePeriodChange} />
    </Box>
  )
}

export default OfficeMarketLeasePriceComparisonContent
