import { ColumnModel } from '@syncfusion/ej2-react-grids'
import { isNotNil } from '@toss/utils'
import { useTranslation } from 'next-i18next'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import { formatVacancyRate } from '~/libs/utils/format'
import DataGrid from '~/components/DataGrid/DataGrid'
import { LwhLeaseMarketTrendValueType } from '~/libs/apis/data/model'
import {
  useLwhDetailDataV1BuildingsLwhRaIdGet,
  useLwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGet,
} from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import useNamesForComparison from '~/libs/hooks/useNamesForComparison'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { usePerUnitValueAccessor } from '~/libs/hooks/useFormat'
import RateChange from '~/templates/common/content/RateChange'
import { getValueForRateChange } from '~/libs/utils/number'
import { useCallback, useMemo } from 'react'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import { EmptyRateArray } from '~/libs/constants/common'

interface MarketLeasePriceGridData {
  category: string | null
  lowTemperatureLatestValue: NullishNumber
  lowTemperaturePreviousQuarterValue: NullishNumber
  lowTemperaturePreviousYearValue: NullishNumber
  roomTemperatureLatestValue: NullishNumber
  roomTemperaturePreviousQuarterValue: NullishNumber
  roomTemperaturePreviousYearValue: NullishNumber
}

interface LwhMarketLeasePriceComparisonGridProps {
  type: LwhLeaseMarketTrendValueType
}

const LwhMarketLeasePriceComparisonGrid = ({ type }: LwhMarketLeasePriceComparisonGridProps) => {
  const { uid: raId, detailType } = useDetailInfo()
  const isFull = detailType === 'full'
  const { t } = useTranslation()
  const { lastUpdate } = useLastUpdateDate()
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const perUnitValueAccessor = usePerUnitValueAccessor(areaUnit, currencyUnit)
  const isEmptyRate = EmptyRateArray.includes(type)
  const isShowYoyValue = type !== 'emptyRate'
  const rateChangeKey = isEmptyRate ? 'value' : 'rate'

  const { data: lwh } = useLwhDetailDataV1BuildingsLwhRaIdGet(raId, {
    swr: {
      enabled: Boolean(raId),
    },
  })

  const { data } = useLwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGet(
    {
      minYear: (+lastUpdate.year - 1).toString(),
      minQuarter: lastUpdate.quarter,
      maxYear: lastUpdate.year,
      maxQuarter: lastUpdate.quarter,
      raId,
      valueType: type,
    },
    { swr: { keepPreviousData: true } },
  )

  const names = useNamesForComparison(lwh)

  const latestDataIndex = data?.findIndex((item) => item.dateYq === lastUpdate.year + lastUpdate.quarter) ?? -1
  const latestData = data?.at(latestDataIndex)
  const previousQuarterData = data?.at(latestDataIndex - 1)
  const previousYearData = data?.at(latestDataIndex - 4)

  const roomQoqTemplate = useCallback(
    (props: any) => (
      <RateChange
        value={getValueForRateChange(
          props.roomTemperaturePreviousQuarterValue,
          props.roomTemperatureLatestValue,
          rateChangeKey,
        )}
        unit={isEmptyRate ? '%p' : '%'}
        variant="body"
        fontWeight="regular"
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  const roomYoyTemplate = useCallback(
    (props: any) => (
      <RateChange
        value={getValueForRateChange(
          props.roomTemperaturePreviousYearValue,
          props.roomTemperatureLatestValue,
          rateChangeKey,
        )}
        unit={isEmptyRate ? '%p' : '%'}
        variant="body"
        fontWeight="regular"
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  const lowQoqTemplate = useCallback(
    (props: any) => (
      <RateChange
        value={getValueForRateChange(
          props.lowTemperaturePreviousQuarterValue,
          props.lowTemperatureLatestValue,
          rateChangeKey,
        )}
        unit={isEmptyRate ? '%p' : '%'}
        variant="body"
        fontWeight="regular"
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  const lowYoyTemplate = useCallback(
    (props: any) => (
      <RateChange
        value={getValueForRateChange(
          props.lowTemperaturePreviousYearValue,
          props.lowTemperatureLatestValue,
          rateChangeKey,
        )}
        unit={isEmptyRate ? '%p' : '%'}
        variant="body"
        fontWeight="regular"
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  const columns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'category',
        headerText: '',
      },
      {
        headerText: `${t('common_term.room_temperature')} ${lastUpdate.yq}`,
        field: 'roomTemperatureLatestValue',
        valueAccessor: isEmptyRate
          ? (_, data: any) => formatVacancyRate(data.roomTemperatureLatestValue)
          : perUnitValueAccessor(),
      },
      {
        headerText: t('common_term.qoq'),
        template: roomQoqTemplate,
      },
      {
        headerText: t('common_term.yoy'),
        template: roomYoyTemplate,
      },
      {
        headerText: `${t('common_term.low_temperature')} ${lastUpdate.yq}`,
        field: 'lowTemperatureLatestValue',
        valueAccessor: isEmptyRate
          ? (_, data: any) => formatVacancyRate(data.lowTemperatureLatestValue)
          : perUnitValueAccessor(),
      },
      {
        headerText: t('common_term.qoq'),
        template: lowQoqTemplate,
      },
      {
        headerText: t('common_term.yoy'),
        template: lowYoyTemplate,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, data, areaUnit, currencyUnit],
  )

  const roomColumns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'category',
        headerText: '',
        clipMode: 'EllipsisWithTooltip',
      },
      {
        headerText: `${t('common_term.room_temperature')} ${lastUpdate.yq}`,
        clipMode: 'EllipsisWithTooltip',
        field: 'roomTemperatureLatestValue',
        valueAccessor: isEmptyRate
          ? (_, data: any) => formatVacancyRate(data.roomTemperatureLatestValue)
          : perUnitValueAccessor(),
      },
      {
        headerText: t('common_term.qoq'),
        clipMode: 'EllipsisWithTooltip',
        visible: isFull,
        template: roomQoqTemplate,
      },
      {
        headerText: t('common_term.yoy'),
        clipMode: 'EllipsisWithTooltip',
        template: roomYoyTemplate,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, data, areaUnit, currencyUnit],
  )

  const lowColumns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'category',
        headerText: '',
        clipMode: 'EllipsisWithTooltip',
      },
      {
        headerText: `${t('common_term.low_temperature')} ${lastUpdate.yq}`,
        clipMode: 'EllipsisWithTooltip',
        field: 'lowTemperatureLatestValue',
        valueAccessor: isEmptyRate
          ? (_, data: any) => formatVacancyRate(data.lowTemperatureLatestValue)
          : perUnitValueAccessor(),
      },
      {
        headerText: t('common_term.qoq'),
        clipMode: 'EllipsisWithTooltip',
        visible: isFull,
        template: lowQoqTemplate,
      },
      {
        headerText: t('common_term.yoy'),
        clipMode: 'EllipsisWithTooltip',
        template: lowYoyTemplate,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, data, areaUnit, currencyUnit],
  )

  const dataSourceForGrid: MarketLeasePriceGridData[] = isNotNil(latestData)
    ? [
        {
          category: names.self,
          lowTemperatureLatestValue: latestData.lwhLowValue,
          lowTemperaturePreviousQuarterValue: previousQuarterData?.lwhLowValue,
          lowTemperaturePreviousYearValue: previousYearData?.lwhLowValue,
          roomTemperatureLatestValue: latestData.lwhRoomValue,
          roomTemperaturePreviousQuarterValue: previousQuarterData?.lwhRoomValue,
          roomTemperaturePreviousYearValue: previousYearData?.lwhRoomValue,
        },
        {
          category: names.boeSize,
          lowTemperatureLatestValue: latestData.boeSizeLowValue,
          lowTemperaturePreviousQuarterValue: previousQuarterData?.boeSizeLowValue,
          lowTemperaturePreviousYearValue: isShowYoyValue ? previousYearData?.boeSizeLowValue : null,
          roomTemperatureLatestValue: latestData.boeSizeRoomValue,
          roomTemperaturePreviousQuarterValue: previousQuarterData?.boeSizeRoomValue,
          roomTemperaturePreviousYearValue: isShowYoyValue ? previousYearData?.boeSizeRoomValue : null,
        },
        {
          category: names.boe,
          lowTemperatureLatestValue: latestData.boeLowValue,
          lowTemperaturePreviousQuarterValue: previousQuarterData?.boeLowValue,
          lowTemperaturePreviousYearValue: isShowYoyValue ? previousYearData?.boeLowValue : null,
          roomTemperatureLatestValue: latestData.boeRoomValue,
          roomTemperaturePreviousQuarterValue: previousQuarterData?.boeRoomValue,
          roomTemperaturePreviousYearValue: isShowYoyValue ? previousYearData?.boeRoomValue : null,
        },
      ]
    : []

  return isFull ? (
    <DataGrid dataSource={dataSourceForGrid} columns={columns} allowPaging={false} allowSelection={false} />
  ) : (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="subtitle" fontWeight="semibold">
        {t('common_term.room_temperature')}
      </Typography>
      <DataGrid dataSource={dataSourceForGrid} columns={roomColumns} allowPaging={false} allowSelection={false} />
      <Typography variant="subtitle" fontWeight="semibold">
        {t('common_term.low_temperature')}
      </Typography>
      <DataGrid dataSource={dataSourceForGrid} columns={lowColumns} allowPaging={false} allowSelection={false} />
    </Box>
  )
}

export default LwhMarketLeasePriceComparisonGrid
