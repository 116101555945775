import React, { FC, ReactElement, ReactNode, RefObject, UIEventHandler, useCallback, useEffect, useRef } from 'react'
import StickyBox from '~/templates/StickyBox'
import Tab from '~/components/Tab/Tab'
import Box from '~/components/Box/Box'
import { SelectingEventArgs, TabComponent } from '@syncfusion/ej2-react-navigations'
import { EmitType } from '@syncfusion/ej2-base'
import { SizingProps } from '@xstyled/styled-components'
import DetailFooter from '~/templates/DetailFooter'
import { useDetailScrollState } from '~/templates/DetailScrollProvider'
import { SelectEventArgs } from '@syncfusion/ej2-navigations/src/tab/tab'

interface ScrollHighlightWrapperProps {
  stickyAreaHeight: number
  sections: RefObject<HTMLDivElement>[]
  stickyArea: ReactNode
  tabItems: string[]
  tabTop?: number
  sectionContent: ReactElement
  testId?: string
  height?: SizingProps['h']
  showFooter?: boolean
  autoFocus?: boolean
}

const ScrollHighlightWrapperWithTab: FC<ScrollHighlightWrapperProps> = ({
  stickyAreaHeight,
  sections,
  stickyArea,
  tabTop,
  tabItems,
  sectionContent,
  testId,
  height = '100%',
  showFooter,
  autoFocus = true,
}) => {
  const isInteracted = useRef(false)
  const wrapperRef = useRef<HTMLDivElement>()
  const tabRef = useRef<TabComponent>(null)
  const { setIsTop } = useDetailScrollState()
  const handleScroll: UIEventHandler<HTMLDivElement> = (args) => {
    const index = sections.findLastIndex((section) => {
      return section.current && section.current.offsetTop <= args.currentTarget.scrollTop + stickyAreaHeight
    })
    if (!isInteracted.current) {
      const isBottom =
        args.currentTarget.scrollHeight - args.currentTarget.scrollTop - args.currentTarget.clientHeight === 0
      const selectIndex = index === -1 ? 0 : index
      tabRef?.current?.select(isBottom ? sections.length - 1 : selectIndex)
    }
    if (args.currentTarget.scrollTop === 0) {
      setIsTop(true)
    } else {
      setIsTop(false)
    }
  }

  const handleScrollEnd = () => {
    if (isInteracted.current) {
      isInteracted.current = false
    }
  }
  const wrapperRefCallback = useCallback((wrapper: HTMLDivElement | null) => {
    if (wrapper) {
      wrapperRef.current = wrapper
      wrapper.addEventListener('scrollend', handleScrollEnd)
      if (autoFocus) {
        wrapper.focus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //clean up
  useEffect(() => {
    return () => {
      if (wrapperRef.current) {
        wrapperRef.current?.removeEventListener('scrollend', handleScrollEnd)
      }
    }
  }, [])

  const handleSelecting: EmitType<SelectingEventArgs> = (args) => {
    isInteracted.current = args.isInteracted
    if (wrapperRef.current && args.isInteracted) {
      wrapperRef.current?.scrollTo({
        top: (sections[args.selectingIndex]?.current?.offsetTop ?? 0) - stickyAreaHeight,
        behavior: 'smooth',
      })
    }
  }

  const handleSelected: EmitType<SelectEventArgs> = (args) => {
    args.preventFocus = true
  }

  return (
    <Box
      data-testid={testId}
      ref={wrapperRefCallback}
      height={height}
      overflowY="scroll"
      overflowX="hidden"
      backgroundColor="system-white"
      onScroll={handleScroll}
      tabIndex={0}
      outline="none"
    >
      {stickyArea}
      <StickyBox top={tabTop} backgroundColor="system-white">
        <Tab
          ref={tabRef}
          tabItems={tabItems}
          size="lg"
          selecting={handleSelecting}
          selected={handleSelected}
          fullWidth
          color="black"
        />
      </StickyBox>
      {sectionContent}
      {showFooter && <DetailFooter />}
    </Box>
  )
}

export default ScrollHighlightWrapperWithTab
