import { FC } from 'react'
import styled, { css } from 'styled-components'
import { PagerComponent } from '@syncfusion/ej2-react-grids'
import { PagerModel } from '@syncfusion/ej2-grids/src/pager/pager-model'
import IconArrowFirst from '~/assets/images/icons/angles-left.svg?url'
import IconArrowLeft from '~/assets/images/icons/angle-left.svg?url'
import IconArrowRight from '~/assets/images/icons/angle-right.svg?url'
import IconArrowEnd from '~/assets/images/icons/angles-right.svg?url'

const Pager: FC<PagerModel> = ({ pageCount, totalRecordsCount, ...props }) => {
  return <StyledPageComponent pageCount={pageCount || 1} totalRecordsCount={totalRecordsCount || 1} {...props} />
}

export const pageCss = css`
  // 페이지네이션
  display: flex;
  justify-content: center;
  background-color: transparent;
  margin-top: 20px;
  border: none;
  padding: 0;

  .e-pagercontainer {
    display: flex;
    gap: 4px;
    align-items: center;
    border: none;
    background: transparent;

    .e-icon-first,
    .e-icon-last,
    .e-icon-prev,
    .e-icon-next {
      position: relative;
      padding: 0;
      width: 22px;
      height: 20px;
      border-radius: 8px;
      background: transparent;

      &:hover {
        background-color: var(--color-gray-300);
      }
    }

    .e-firstpagedisabled,
    .e-prevpagedisabled,
    .e-nextpagedisabled,
    .e-lastpagedisabled {
      cursor: default;
      &:hover {
        background-color: transparent;
      }
    }

    .e-icon-first::before,
    .e-icon-prev::before,
    .e-icon-next::before,
    .e-icon-last::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .e-firstpage,
    .e-prevpage,
    .e-firstpagedisabled,
    .e-prevpagedisabled,
    .e-nextpage,
    .e-lastpage,
    .e-nextpagedisabled,
    .e-lastpagedisabled {
      border-right: none;
    }

    .e-icon-first::before,
    .e-icon-prev::before,
    .e-icon-last::before,
    .e-icon-next::before {
      min-width: 0;
      content: '';
      width: 12px;
      height: 12px;
    }

    .e-icon-first::before {
      background: url(${IconArrowFirst});
      background-size: cover;
    }

    .e-icon-prev::before {
      background: url(${IconArrowLeft});
      background-size: cover;
    }

    .e-icon-last::before {
      background: url(${IconArrowEnd});
      background-size: cover;
    }

    .e-icon-next::before {
      background: url(${IconArrowRight});
      background-size: cover;
    }

    .e-numericcontainer {
      .e-numericitem {
        font-size: 12px;
        min-width: 22px;
        height: 20px;
        display: inline-block;
        border: none;
        line-height: 20px;
        border-radius: 8px;
        background-color: transparent;
        padding: 0 !important;
        margin-right: 4px;
        box-shadow: none;
        font-weight: regular;

        &.e-active {
          font-weight: bold;
        }
      }

      .e-numericitem:hover {
        background-color: var(--color-gray-300);
      }

      .e-link {
        color: var(--color-gray-800);

        &.e-currentitem {
          background-color: var(--color-purple-600);
          color: #fff;
        }
      }

      .e-link:focus {
        outline: none;
      }
    }
    .e-pp,
    .e-np {
      color: var(--color-gray-800);
      background-color: transparent;
      border: none;
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      padding: 0;
      min-width: 10px;
    }
  }

  .e-parentmsgbar {
    display: none;
  }

  .e-mfirst,
  .e-mprev,
  .e-mnext,
  .e-mlast {
    display: none;
  }
`

const StyledPageComponent = styled(PagerComponent)`
  &.e-pager {
    min-width: 22px;
    .e-pagercontainer {
      background: transparent;
    }
    .e-prevpage,
    .e-nextpage,
    .e-nextpagedisabled,
    .e-lastpagedisabled,
    .e-lastpage,
    .e-firstpage,
    .e-firstpagedisabled {
      background: transparent;
    }
    ${pageCss}
  }
`

export default Pager
