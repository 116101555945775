import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import Box from '~/components/Box/Box'
import { OfficeStackingPlanOut } from '~/libs/apis/data/model'
import Typography from '~/components/Typography/Typography'
import OverflowWithTooltip from '~/components/Tooltip/OverflowWithTooltip'

interface StackingPlanLesseeTableProps {
  data?: OfficeStackingPlanOut[]
}

const leaseTypeColor: Record<string, string> = {
  RT01_01: 'data-1',
  RT01_02: 'data-2',
  RT01_99: 'gray-100',
}

const StackingPlanLesseeTable: FC<StackingPlanLesseeTableProps> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })

  if (!data) {
    return null
  }

  //이름은 Table이지만 colspan의 계산을 피하기 위해 neted flexbox로 구성
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
      flexGrow={1}
      borderColor="gray-300"
      borderWidth="1px"
      divideY
      divideColor="gray-300"
      overflowX="hidden"
    >
      <Box height="40px" backgroundColor="gray-200" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body" fontWeight="semibold">
          {t('lessee')}
        </Typography>
      </Box>
      {data.map(({ lessee, floor }, index) => (
        <Box key={`${floor}_${index}`} display="flex" alignItems="center" divideX divideColor="gray-300">
          {lessee ? (
            lessee?.map((lesseeItem, index) => (
              <Box
                key={index}
                flexGrow={1}
                height="40px"
                backgroundColor={leaseTypeColor[lesseeItem.leaseTypeCode ?? 'RT01_99']}
                display="flex"
                flex="1 1 0"
                px={1}
                alignItems="center"
                justifyContent="center"
                overflowX="hidden"
              >
                <OverflowWithTooltip cellText={lesseeItem.name ?? ''} />
              </Box>
            ))
          ) : (
            <Box
              flexGrow={1}
              height="40px"
              backgroundColor="gray-100"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="body">-</Typography>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  )
}

export default StackingPlanLesseeTable
