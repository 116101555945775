import { useTranslation } from 'react-i18next'
import { ButtonGroupItem } from '~/components/Button/ButtonGroup'
import useLastUpdateDate, { DataId } from '~/libs/hooks/useLastUpdateDate'
import ButtonGroupFilter from './ButtonGroupFilter'
import { MINIMUM_YEAR_BY_LWH_MARKET, MINIMUM_YEAR_BY_OFFICE_MARKET } from '~/libs/constants/filter'
import { Quarter } from '~/libs/apis/data/model'

export interface PeriodType {
  minYear: string
  maxYear?: string
  minQuarter?: Quarter
  maxQuarter?: Quarter
  value?: string
}

interface PeriodFilterProps {
  type: 'office' | 'lwh'
  marketType: 'supply' | 'lease' | 'trade'
  onClick: (params: PeriodType) => void
  showTitle?: boolean
  defaultValue?: '3' | '5' | '10' | 'all'
  showAllPeriod?: boolean
  dataId?: DataId
}

const PeriodFilter = ({
  type,
  marketType,
  onClick,
  showTitle = true,
  defaultValue = '10',
  showAllPeriod = true,
  dataId,
}: PeriodFilterProps) => {
  const { t } = useTranslation()
  const { lastUpdate } = useLastUpdateDate(undefined, dataId)

  const items: ButtonGroupItem[] = [
    { label: t('unit.year', { year: 3 }), value: '3' },
    { label: t('unit.year', { year: 5 }), value: '5' },
    { label: t('unit.year', { year: 10 }), value: '10' },
  ]

  if (showAllPeriod) {
    items.push({ label: t('common_term.all'), value: 'all' })
  }

  const handleClick = (value: string) => {
    if (value === 'all') {
      onClick({
        minYear: type === 'office' ? MINIMUM_YEAR_BY_OFFICE_MARKET[marketType] : MINIMUM_YEAR_BY_LWH_MARKET[marketType],
        value,
      })
    } else {
      onClick({
        minYear: (+lastUpdate.year - +value + 1).toString(),
        value,
      })
    }
  }

  return (
    <ButtonGroupFilter
      title={showTitle && t('common_term.period')}
      name="chart-period-filter"
      items={items}
      defaultValue={defaultValue}
      onClick={handleClick}
    />
  )
}

export default PeriodFilter
