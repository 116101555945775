import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { SOURCE_UPDATE_ID } from '~/libs/constants/common'
import useDataVersion from '~/libs/hooks/useDataVersion'

interface DataSourceProps {
  category: string
}

const getIds = (category: string) => {
  const keys = category.split('.')
  let ids: any = SOURCE_UPDATE_ID
  for (const key of keys) {
    const item = ids[key]
    if (Array.isArray(item)) {
      return item
    } else {
      ids = item
    }
  }
}

/**
 *
 * @param category
 * @constructor
 */
const DataSourceUpdate: FC<DataSourceProps> = ({ category }) => {
  const { data } = useDataVersion()
  const { t } = useTranslation('common', { keyPrefix: 'source' })

  const sourceIds = getIds(category)

  const sourceObjects = data?.sourceUpdate.filter((update) => sourceIds?.includes(update.id))

  const sourceItems = sourceObjects?.map(
    (sourceUpdate) => `${t('detail.' + sourceUpdate.id)}, ${sourceUpdate.updateDateYm}`,
  )

  if (!data) {
    return null
  }

  if (sourceObjects && sourceObjects.length === 0) {
    return null
  }

  return (
    <Box textAlign="right">
      <Typography variant="caption2" color="gray-600" wordBreak="keep-all">
        {`[${t('origin')}: ${sourceItems?.join(' | ')} ${t('update')}]`}
      </Typography>
    </Box>
  )
}

export default DataSourceUpdate
