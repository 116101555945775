import React, { FC, PropsWithChildren } from 'react'
import StackingPlanAreaTable from '~/templates/common/view/stacking-plan/StackingPlanAreaTable'
import StackingPlanLesseeTable from '~/templates/common/view/stacking-plan/StackingPlanLesseeTable'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'
import { isNotNil } from '@toss/utils'
import NoData from '~/components/NoData'
import { useTranslation } from 'next-i18next'
import Box from '~/components/Box/Box'
import StackingPlanLegend from '~/templates/common/view/stacking-plan/StackingPlanLegend'
import { OfficeStackingPlanOut } from '~/libs/apis/data/model'

interface OfficeStackingPlanTablesProps {
  data: OfficeStackingPlanOut[] | undefined
  isLoading: boolean
  isPastDate: boolean
}

const OfficeStackingPlanTables: FC<PropsWithChildren<OfficeStackingPlanTablesProps>> = ({
  data,
  isLoading,
  isPastDate,
}) => {
  const { t: common } = useTranslation('common')

  if (isLoading) {
    return <SkeletonComponent width="100%" height="200px" />
  }

  return (
    <>
      {isNotNil(data) && data.length > 0 && <StackingPlanLegend />}
      <Box display="flex" justifyContent="space-between" gap={6}>
        <StackingPlanAreaTable data={data} onlyFloorColumn={isPastDate} />
        <StackingPlanLesseeTable data={data} />
      </Box>
      {(!isNotNil(data) || data.length === 0) && <NoData noDataMessage={common('message.no_data')} />}
    </>
  )
}

export default OfficeStackingPlanTables
