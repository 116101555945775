import { FC } from 'react'
import { BuildingsLesseeOutBase } from '~/libs/apis/data/model'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { isNotNil } from '@toss/utils'
import Badge from '~/components/Badge/Badge'
import { isArrayAndNotEmpty } from '~/libs/utils/format'
import { useTranslation } from 'react-i18next'

interface CommonLesseeChangeOut {
  inLessees: BuildingsLesseeOutBase[] | null
  outLessees: BuildingsLesseeOutBase[] | null
}

interface LesseeChangeProps {
  data: CommonLesseeChangeOut | undefined
}

const getLesseeString = (data: BuildingsLesseeOutBase[]) => {
  return data.map((o) => o.lessee).join(',')
}

const InOutLesseeStatus: FC<LesseeChangeProps> = ({ data }) => {
  const { t } = useTranslation('common')

  if (!isNotNil(data) || (!isNotNil(data.inLessees) && !isNotNil(data.outLessees))) {
    return (
      <Box flex="1" display="flex" alignItems="center" flexDirection="column" gap="6px">
        <Typography variant="subtitle" color="gray-600" fontWeight="600">
          {t('detail.lessee.no_change_information')}
        </Typography>
        <Typography color="gray-500" variant="caption1" fontWeight="600">
          {t('detail.lessee.check_change_information_select_quarter')}
        </Typography>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      {isArrayAndNotEmpty(data.inLessees) && (
        <Box display="flex" gap="6px" alignItems="center">
          <Box alignSelf="self-start">
            <Badge content={t('common_term.in_lessees')} size="md" type="03" category="gray01" />
          </Box>
          <Typography color="gray-700">{getLesseeString(data.inLessees)}</Typography>
        </Box>
      )}
      {isArrayAndNotEmpty(data.outLessees) && (
        <Box display="flex" gap="6px" alignItems="center">
          <Box alignSelf="self-start">
            <Badge content={t('common_term.out_lessees')} size="md" type="03" category="gray01" />
          </Box>
          <Typography color="gray-700">{getLesseeString(data.outLessees)}</Typography>
        </Box>
      )}
    </Box>
  )
}

export default InOutLesseeStatus
