import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { useTranslation } from 'next-i18next'
import { useGetLandUsePlanDataV1GovLandUsePlanGet } from '~/libs/apis/data/gov/gov'
import { FC } from 'react'
import { LAND_USE_ETC_LAWS_KEY, LAND_USE_TERRITORY_LAWS_KEY } from '~/libs/constants/common'

interface LandUseTableProps {
  pnu: string
  lawType: 'territory_laws' | 'etc_laws'
}

const LandUseTable: FC<LandUseTableProps> = ({ pnu, lawType }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { data: landUse } = useGetLandUsePlanDataV1GovLandUsePlanGet({ pnu })

  const getLawsContent = (gubunName: string) => {
    if (landUse?.length === 0) {
      return '-'
    }

    const gubun = landUse?.filter((item) => item.gubunName === gubunName)
    const content = gubun?.map((item) => item.jiyukJiguName + ` (${item.conflictName})\n`)

    return (
      <Typography whiteSpace="pre-line" color="gray-800" fontWeight="semibold" textAlign="right">
        {content?.map((item) => item)}
      </Typography>
    )
  }
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      paddingBottom={3}
      borderBottom="1px solid var(--color-gray-300)"
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography color="gray-700">{t('land_use_plan')}</Typography>
          <Typography variant="caption2" color="gray-700">
            ({t(lawType)})
          </Typography>
        </Box>
        {getLawsContent(lawType === 'territory_laws' ? LAND_USE_TERRITORY_LAWS_KEY : LAND_USE_ETC_LAWS_KEY)}
      </Box>
    </Box>
  )
}

export default LandUseTable
