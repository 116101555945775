import { FC } from 'react'
import Box from '~/components/Box/Box'
import ScrollBox from '~/components/Box/ScrollBox'
import Typography from '~/components/Typography/Typography'
import { GetNoticesResponseItem } from '~/libs/apis/service/model'
import { TEXT_NOTICE_SKIP_ID_KEY } from '~/libs/constants/common'
import NoticeDialog from '~/templates/common/content/NoticeDialog'

interface NoticeTextDialogProps {
  notice: GetNoticesResponseItem | undefined
}

const NoticeTextDialog: FC<NoticeTextDialogProps> = ({ notice }) => {
  return (
    <NoticeDialog storageKey={TEXT_NOTICE_SKIP_ID_KEY} notice={notice}>
      <Box width="400px" height="440px" px="24px" py="30px" overflow="hidden">
        <ScrollBox
          variant="vertical"
          display="flex"
          flexDirection="column"
          gap={6}
          width="100%"
          height="100%"
          paddingRight="8px"
        >
          {notice?.showTitleYn && (
            <Typography variant="h4" fontWeight="bold">
              {notice?.title}
            </Typography>
          )}
          <div dangerouslySetInnerHTML={{ __html: notice?.content ?? '' }} />
        </ScrollBox>
      </Box>
    </NoticeDialog>
  )
}

export default NoticeTextDialog
