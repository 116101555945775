import { FC } from 'react'
import BubbleWrap from '~/components/Map/Marker/BubbleWrap'
import Typography from '~/components/Typography/Typography'

interface AddressMarkerProps {
  address: string
  isActive?: boolean
}

const colorMap = {
  normal: 'blue-200',
  active: 'blue-700',
  border: 'blue-500',
}

const AddressMarker: FC<AddressMarkerProps> = ({ address, isActive }) => {
  return (
    <BubbleWrap
      backgroundColor={!isActive ? 'system-white' : colorMap.active}
      borderColor={!isActive ? colorMap.border : colorMap.active}
    >
      <Typography
        variant="caption1"
        fontWeight="600"
        padding="6px 10px"
        whiteSpace="nowrap"
        backgroundColor={!isActive ? 'system-white' : colorMap.active}
        color={!isActive ? 'gray-800' : 'system-white'}
      >
        {address}
      </Typography>
    </BubbleWrap>
  )
}

// eslint-disable-next-line import/no-unused-modules
export default AddressMarker
