/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.6.0
 */

export type ClusterType = typeof ClusterType[keyof typeof ClusterType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterType = {
  sido: 'sido',
  grid: 'grid',
  dbscan: 'dbscan',
} as const;
