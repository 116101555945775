import { FC, useEffect, useState } from 'react'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { useTranslation } from 'next-i18next'
import Dropdown from '~/components/Dropdown/Dropdown'
import BuildingGeneralTradeGrid from '~/templates/place/detail/trade/BuildingGeneralTradeGrid'
import { SwitchCase } from '@toss/react'
import BuildingMultiOwnedTradeGrid from '~/templates/place/detail/trade/BuildingMultiOwnedTradeGrid'
import BuildingLandsTradeGrid from '~/templates/place/detail/trade/BuildingLandsTradeGrid'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'
import { useGetLandDetailsDataV1LandsDetailsGet } from '~/libs/apis/data/land/land'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'

interface SbizProps {
  pnu: string | null
}

const Trade: FC<SbizProps> = ({ pnu }) => {
  const { t } = useTranslation('common', { keyPrefix: 'detail.common_data' })
  const { t: common } = useTranslation('common', { keyPrefix: 'common_term' })
  const TRADE_TAB_OPTIONS = [
    { value: 'general', label: t('general') },
    { value: 'multiOwned', label: t('multi_owned') },
  ]
  const { data, isLoading } = useGetLandDetailsDataV1LandsDetailsGet(
    {
      pnus: pnu,
    },
    { swr: { enabled: Boolean(pnu) } },
  )
  const gubun = data?.data[0]?.realTradeRegistGubunName
  const initialTab = gubun === common('set') ? TRADE_TAB_OPTIONS[1].value : TRADE_TAB_OPTIONS[0].value
  const [tab, setTab] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!isLoading && !tab) {
      setTab(initialTab)
    }
  }, [isLoading, tab, initialTab])

  useEffect(() => {
    if (!isLoading) {
      setTab(initialTab)
    }
  }, [isLoading, pnu, initialTab])

  if (!pnu) {
    return null
  }

  if (!tab) {
    return <SkeletonComponent width="100%" height={250} />
  }

  const handleTabChange = (val?: string) => {
    if (val) {
      setTab(val)
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap="30px">
      <Box display="flex" flexDirection="column" gap="16px">
        <Typography fontWeight="semibold" variant="h5" color="gray-800">
          {t('building_trade')}
        </Typography>
        <Dropdown width="150px" options={TRADE_TAB_OPTIONS} value={tab} onChange={handleTabChange} />
        <SwitchCase
          value={tab}
          caseBy={{
            general: <BuildingGeneralTradeGrid pnu={pnu} />,
            multiOwned: <BuildingMultiOwnedTradeGrid pnu={pnu} />,
          }}
        />
        <DataSourceUpdate category="detail.trade.gov" />
      </Box>
      <Box display="flex" flexDirection="column" gap="16px">
        <Typography fontWeight="semibold" variant="h5" color="gray-800">
          {t('land_trade')}
        </Typography>
        <BuildingLandsTradeGrid pnu={pnu} />
        <DataSourceUpdate category="detail.trade.gov" />
      </Box>
    </Box>
  )
}

export default Trade
