import { isNotNil, roundToUnit } from '@toss/utils'

export const HUNDRED = 100
export const THOUSAND = 1000
export const TEN_THOUSAND = 10000
export const MILLION = 1000000
export const HUNDRED_MILLION = 100000000

const roundAndDivideNumber = (num: number, unit: number) => {
  return roundToUnit(num, unit) / unit
}

export const roundAndFixedVacancyRate = (vacancyRate: number) => roundToUnit(vacancyRate, 0.01).toFixed(2)

export const toOnesNumber = (num: NullishNumber) => {
  if (!isNotNil(num)) {
    return
  }
  return roundToUnit(num, 1)
}

export const to10ThousandsNumber = (num: NullishNumber) => {
  if (!isNotNil(num)) {
    return
  }
  return roundAndDivideNumber(num, TEN_THOUSAND)
}

export const to100MillionsNumber = (num: NullishNumber) => {
  if (!isNotNil(num)) {
    return
  }
  return roundAndDivideNumber(num, HUNDRED_MILLION)
}

export const toMillionNumber = (num: NullishNumber) => {
  if (!isNotNil(num)) {
    return
  }
  return roundAndDivideNumber(num, MILLION)
}

export const divideByThousand = (num: NullishNumber) => {
  if (!isNotNil(num)) {
    return
  }
  return num / THOUSAND
}

export const divideByTenThousand = (num: NullishNumber) => {
  if (!isNotNil(num)) {
    return
  }
  return num / TEN_THOUSAND
}

export const divideByMillion = (num: NullishNumber) => {
  if (!isNotNil(num)) {
    return
  }
  return num / MILLION
}

export const divideByHundredMillion = (num: NullishNumber) => {
  if (!isNotNil(num)) {
    return
  }
  return num / HUNDRED_MILLION
}

export const multipliedByTenThousand = (num: NullishNumber) => {
  if (!isNotNil(num)) {
    return
  }
  return num * TEN_THOUSAND
}

// eslint-disable-next-line import/no-unused-modules
export const multipliedBy100Million = (num: NullishNumber) => {
  if (!isNotNil(num)) {
    return
  }
  return num * HUNDRED_MILLION
}

export const toVacancyRateNumber = (num: NullishNumber) => {
  if (!isNotNil(num)) {
    return
  }
  return roundAndFixedVacancyRate(num)
}

export enum ChangeState {
  SAME,
  INCREASE,
  DECREASE,
}

type SignType = '+' | '-' | ''

interface NumberChange {
  value: number
  rate: number
  state: ChangeState
  sign: SignType
}

const Sign: Record<ChangeState, SignType> = {
  [ChangeState.SAME]: '',
  [ChangeState.INCREASE]: '+',
  [ChangeState.DECREASE]: '-',
}

const toChangeState = (change: number) => {
  if (change === 0) {
    return ChangeState.SAME
  } else if (change > 0) {
    return ChangeState.INCREASE
  } else {
    return ChangeState.DECREASE
  }
}

export const toNumberChange = (previous: number, latest: number): NumberChange => {
  const value = latest - previous
  const state = toChangeState(value)
  const absValue = Math.abs(value)
  return {
    value: absValue,
    rate: (absValue / previous) * HUNDRED,
    state,
    sign: Sign[state],
  }
}

/**
 * <RateChange> 컴포넌트의 value prop 에 넘길 변동률을 계산합니다.
 *
 * @params
 * - prev: 이전 데이터
 * - curr: 현재 데이터
 * - key: 표시할 변동률의 단위가 %p일 경우 'value', %일 경우 'rate'
 */
export const getValueForRateChange = (prev: NullishNumber, curr: NullishNumber, key: 'value' | 'rate') => {
  if (!isNotNil(prev) || !isNotNil(curr)) {
    return null
  }

  const numberChangeObject = toNumberChange(prev, curr)
  if (numberChangeObject.sign === '-') {
    numberChangeObject.rate *= -1
    numberChangeObject.value *= -1
  }
  return numberChangeObject[key]
}

export const toSqmNumber = (py: number, type: 'total' | 'per_unit' = 'total') => {
  return type === 'total' ? py * 3.305785 : py / 3.305785
}

export const toPyNumber = (sqm: number, type: 'total' | 'per_unit' = 'total') => {
  return type === 'total' ? sqm / 3.305785 : sqm * 3.305785
}

export const commaizeWithDecimals = (num: number, decimalPoint: number = 2) => {
  return new Intl.NumberFormat('ko-KR', {
    minimumFractionDigits: decimalPoint,
    maximumFractionDigits: decimalPoint,
  }).format(num)
}
