import { useGetRegistrationsDataV1RegistrationsGet } from '~/libs/apis/data/registrations/registrations'

/**
 * 단일 pnu로 등기 목록을 가져오는 hook
 * @param pnu
 * @returns 등기 목록
 */
const usePnusRegistrations = (pnu: string | undefined | null) => {
  return useGetRegistrationsDataV1RegistrationsGet(
    { pnus: pnu },
    {
      swr: { enabled: Boolean(pnu), shouldRetryOnError: false },
    },
  )
}

export default usePnusRegistrations
