import AddressMarker from '~/components/Map/Marker/AddressMarker'
import { BuildingType } from '~/templates/place/detail/overview/BuildingMapImageArea'
import { FC } from 'react'
import CommonMarker from '~/components/Map/Marker/CommonMarker'

interface BuildingMapMarkerProps {
  content: string
  type: BuildingType
}

const BuildingMapMarker: FC<BuildingMapMarkerProps> = ({ content, type }) => {
  switch (type) {
    case 'office':
      return <CommonMarker width="fit-content" isActive headerText={content} />
    case 'lwh':
      return <CommonMarker width="fit-content" isActive headerText={content} />
    case 'land':
      return <AddressMarker isActive address={content} />
    default:
      return null
  }
}

export default BuildingMapMarker
