import { atomFamily } from 'recoil'
import { MapEventParam } from '@mint-ui/map'

export type MapState = MapEventParam

/**
 * MintMap의 지도 좌표계를 api level에서 판단할때 도와주는 atom
 */
const mapEventFamilyState = atomFamily<MapState, MapType>({
  key: 'mapEventState',
  default: undefined,
})

export default mapEventFamilyState
