import { SystemProps, x } from '@xstyled/styled-components'
import Typography from '~/components/Typography/Typography'
import { FC } from 'react'
import Box from '~/components/Box/Box'
import { isNotNil } from '@toss/utils'
import { TextVariants } from '~/libs/theme/fonts'

export type BadgeType = '01' | '02' | '03' | '04'
type BadgeType1Category = 'gray01' | 'gray02' | 'gray03' | 'purple01' | 'purple02' | 'yellow'
export type BadgeType2Category = 'green' | 'purple' | 'blue' | 'gray01' | 'gray02'
type BadgeSize = 'tiny' | 'sm' | 'md' | 'lg'

const Type1CategoryDetail: Record<string, any> = {
  gray01: { backgroundColor: 'gray-800', color: 'system-white', borderColor: 'gray-800' },
  gray02: { backgroundColor: 'gray-700', color: 'system-white', borderColor: 'gray-700' },
  gray03: { backgroundColor: 'gray-600', color: 'system-white', borderColor: 'gray-600' },
  purple01: { backgroundColor: 'purple-600', color: 'system-white', borderColor: 'purple-600' },
  purple02: { backgroundColor: 'purple-700', color: 'system-white', borderColor: 'purple-700' },
  yellow: { backgroundColor: 'event-bookmark', color: 'system-white', borderColor: 'event-bookmark' },
}

const Type2CategoryDetail: Record<string, any> = {
  green: { backgroundColor: 'green-200', color: 'green-600', borderColor: 'green-200' },
  purple: { backgroundColor: 'purple-200', color: 'purple-600', borderColor: 'purple-200' },
  blue: { backgroundColor: 'blue-200', color: 'blue-600', borderColor: 'blue-200' },
  gray01: { backgroundColor: 'gray-200', color: 'gray-700', borderColor: 'gray-200' },
  gray02: { backgroundColor: 'gray-300', color: 'gray-700', borderColor: 'gray-300' },
}

const Type3CategoryDetail = {
  backgroundColor: 'system-white',
  borderColor: 'gray-300',
  color: 'gray-600',
}

const Type4CategoryDetail = {
  backgroundColor: 'system-white',
  borderColor: 'gray-400',
  color: 'gray-700',
}

const SizeProps: Record<
  BadgeSize,
  { padding: SystemProps['padding']; variant: TextVariants; fontWeight: SystemProps['fontWeight'] }
> = {
  tiny: { padding: '0 4px', variant: 'caption2', fontWeight: 400 },
  sm: { padding: '0 6px', variant: 'caption2', fontWeight: 600 },
  md: { padding: '2px 10px', variant: 'caption1', fontWeight: 600 },
  lg: { padding: '2px 10px', variant: 'body', fontWeight: 600 },
}

const getColorTemplate = (type: BadgeType, category?: BadgeType1Category | BadgeType2Category) => {
  switch (type) {
    case '01':
      return Type1CategoryDetail[category ?? 'gray01'] ?? Type1CategoryDetail['gray01']
    case '02':
      return Type2CategoryDetail[category ?? 'purple'] ?? Type2CategoryDetail['purple']
    case '03':
      return Type3CategoryDetail
    case '04':
      return Type4CategoryDetail
    default:
      return Type1CategoryDetail[category ?? 'gray01'] ?? Type1CategoryDetail['gray01']
  }
}

export interface BadgeProps {
  type?: BadgeType
  size?: BadgeSize
  category?: BadgeType1Category | BadgeType2Category
  trailingIcon?: never
  content: string
}

const Badge: FC<BadgeProps> = ({ type = '01', size = 'sm', category, trailingIcon, content }) => {
  const colorTemplate = getColorTemplate(type, category)

  return (
    <x.span
      w="fit-content"
      h="fit-content"
      display="inline-flex"
      alignItems="center"
      gap="4px"
      padding={SizeProps[size].padding}
      borderRadius={type === '04' ? '3px' : '12px'}
      backgroundColor={colorTemplate.backgroundColor}
      borderColor={colorTemplate.borderColor}
      borderWidth="1px"
      borderStyle="solid"
    >
      <Typography
        variant={SizeProps[size].variant}
        color={colorTemplate.color}
        fontWeight={SizeProps[size].fontWeight}
        whiteSpace="nowrap"
      >
        {content}
      </Typography>
      {isNotNil(trailingIcon) && (
        <Box className="badge-trailing-icon" as={trailingIcon} color={colorTemplate.color} width="12px" height="12px" />
      )}
    </x.span>
  )
}

export default Badge
