import { useGetLandDataV1AreaLandGet } from '~/libs/apis/data/area/area'
import { useRecoilValue } from 'recoil'
import detailTypeState from '~/recoil/detail/atom'

const useLand = (mapType: MapType = 'place') => {
  const detailType = useRecoilValue(detailTypeState(mapType))

  const { data } = useGetLandDataV1AreaLandGet(
    { pnu: detailType?.uid },
    { swr: { enabled: detailType?.dataType === 'land' } },
  )
  return data
}

export default useLand
