import { createContext, ReactNode, useContext } from 'react'
import { DetailContent, LandingContent } from '~/templates/FullDetailDialogProvider'
import { Arguments } from 'swr'

interface DetailInfo {
  uid: string
  detailContent: DetailContent
  detailType: DetailType
  selectedRegistrationId?: number
  landing?: LandingContent
  globalSwrKeys?: Arguments[]
}

type DetailType = 'full' | 'panel'

const DetailInfoContext = createContext<DetailInfo>({ uid: '', detailContent: 'office', detailType: 'panel' })

interface DetailTypeContextProviderProps {
  initialValue: DetailInfo
  children: ReactNode
}

const DetailInfoContextProvider = ({ initialValue, children }: DetailTypeContextProviderProps) => {
  return <DetailInfoContext.Provider value={initialValue}>{children}</DetailInfoContext.Provider>
}

const useDetailInfo = () => {
  return useContext(DetailInfoContext)
}

export { useDetailInfo }

export default DetailInfoContextProvider
