import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import DetailTable, { DetailTableType } from '~/components/Table/DetailTable'
import {
  formatCommaizeRoundAndFixToNDecimal,
  formatDockCnt,
  formatFloorInfo,
  formatNearestICDistanceInfo,
  formatVacancyRate,
  formatYesOrNo,
} from '~/libs/utils/format'
import { LwhOut } from '~/libs/apis/data/model'
import { formatDateString, formatDateYmd, formatDateYq } from '~/libs/utils/date'
import { useAreaFormat, usePerUnitFormat } from '~/libs/hooks/useFormat'
import useFormatTemperatureRentFee from '~/templates/place/detail/building/useFormatTemperatureRentFee'
import useCommonCode from '~/libs/hooks/useCommonCode'
import useConstructStatus from '~/libs/hooks/useConstructStatus'

interface LwhTableProps {
  data: LwhOut
}

const LwhTable: FC<LwhTableProps> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: unit } = useTranslation('common', { keyPrefix: 'unit' })
  const { constructStatus } = useConstructStatus(data.constructStatusCode)
  const perUnitFormat = usePerUnitFormat()
  const areaFormat = useAreaFormat()
  const formatTemperatureRentFee = useFormatTemperatureRentFee()
  const { codeToName } = useCommonCode()

  const rentFee = formatTemperatureRentFee(data.roomRentFeePerM2, data.lowRentFeePerM2)

  const recentTrade = `${data?.recentTradeDateYmd ? formatDateString(data.recentTradeDateYmd, 'yyyy-MM') : '-'} ${
    data?.recentTradePricePerM2 ? `(${perUnitFormat(data?.recentTradePricePerM2, { format: 'man' })})` : ''
  }`

  const commonData: DetailTableType[] = [
    { column: t('regist_division'), content: data.registGubunName },
    {
      column: t('main_purpose'),
      content: data.mainPurpose ?? '-',
    },
    { column: t('warehouse_type'), content: data.lwhTypeCode ? codeToName(data.lwhTypeCode) : '-' },
    {
      column: t('construction_year'),
      content: data.constructDateY ? unit('year', { year: data.constructDateY }) : '-',
    },
    {
      column: t('remodeling_year'),
      content: data.remodelingDateY ? unit('year', { year: data.remodelingDateY }) : '-',
    },
    {
      column: t('plat_area'),
      content: areaFormat(data.platArea, { rounded: 'n2' }),
    },
    {
      column: t('architecture_area'),
      content: areaFormat(data.archArea, { rounded: 'n2' }),
    },
    { column: t('bc_rat'), content: formatVacancyRate(data.bcRat) },
    {
      column: t('gra_py'),
      content: areaFormat(data.totalArea, { rounded: 'n2' }),
    },
    { column: t('vl_rat'), content: formatVacancyRate(data.vlRat) },
    { column: t('floor_cnt'), content: formatFloorInfo(unit, data.floorCnt, data.baseFloorCnt) },
    {
      column: t('standard_floor_height'),
      content: data.floorHeight ? unit('m', { number: formatCommaizeRoundAndFixToNDecimal(data.floorHeight, 1) }) : '-',
    },
    { column: t('car_docking'), content: data.carDocking },
    { column: t('container_docking'), content: formatYesOrNo(data.containerDocking, t('possible'), t('impossible')) },
    {
      column: t('dock_cnt'),
      content: formatDockCnt(
        data.dockCnt,
        t('not_exists'),
        t('unspecified'),
        unit('dae_count', { number: data.dockCnt }),
      ),
    },
    { column: t('lamp_yn'), content: formatYesOrNo(data.lampYn, t('exists'), t('not_exists')) },
    {
      column: t('nearest_IC_distance'),
      content: formatNearestICDistanceInfo(data.nearestIcName, data.nearestIcDistance, data.nearestIcDriveTime),
      oneLine: true,
    },
    { column: `${t('owner')} (${t('rsquare_research')})`, content: data.ownerName, oneLine: true },
    { column: `${t('owner')} (${t('registration')})`, content: data.registrationOwnerSummary, oneLine: true },
  ]

  const completionData: DetailTableType[] = [
    { column: t('rent_fee'), content: rentFee, oneLine: true },
    { column: t('recent_trade'), content: recentTrade, oneLine: true },
    ...commonData,
  ]

  const scheduledData: DetailTableType[] = [
    { column: t('regist_division'), content: data.registGubunName },
    {
      column: t('main_purpose'),
      content: data.mainPurpose ?? '-',
    },
    { column: t('warehouse_type'), content: data.lwhTypeCode ? codeToName(data.lwhTypeCode) : '-' },
    {
      column: t('permission_construction_date'),
      content: formatDateYmd(data.permissionConstructionDateYmd),
    },
    {
      column: t('real_construction_date'),
      content: formatDateYmd(data.realConstructionDateYmd),
    },
    {
      column: t('supply_scheduled_time'),
      content: formatDateYq(data.scheduledSupplyDateYq),
    },
    {
      column: t('plat_area'),
      content: areaFormat(data.platArea, { rounded: 'n2' }),
    },
    {
      column: t('architecture_area'),
      content: areaFormat(data.archArea, { rounded: 'n2' }),
    },
    { column: t('bc_rat'), content: formatVacancyRate(data.bcRat) },
    {
      column: t('gra_py'),
      content: areaFormat(data.totalArea, { rounded: 'n2' }),
    },
    { column: t('vl_rat'), content: formatVacancyRate(data.vlRat) },
    { column: t('floor_cnt'), content: formatFloorInfo(unit, data.floorCnt, data.baseFloorCnt) },
    {
      column: t('nearest_IC_distance'),
      content: formatNearestICDistanceInfo(data.nearestIcName, data.nearestIcDistance, data.nearestIcDriveTime),
      oneLine: true,
    },
    { column: t('fiduciary'), content: data.fiduciary },
    { column: t('implementer'), content: data.implementer },
    { column: `${t('owner')} (${t('rsquare_research')})`, content: data.ownerName, oneLine: true },
    { column: `${t('owner')} (${t('registration')})`, content: data.registrationOwnerSummary, oneLine: true },
  ]

  const demolishData: DetailTableType[] = [
    {
      column: t('demolish_year'),
      content: data.demolishDateY ? unit('year', { year: data.demolishDateY }) : '-',
      oneLine: true,
    },
    { column: t('recent_trade'), content: recentTrade, oneLine: true },
    ...commonData,
  ]

  const getBuildingData = () => {
    switch (constructStatus) {
      case 'supplyCompleted':
        return completionData
      case 'supplyScheduled':
        return scheduledData
      case 'demolish':
        return demolishData
      default:
        return completionData
    }
  }

  return <DetailTable data={getBuildingData()} />
}

export default LwhTable
