import React, { FC, useEffect, useState } from 'react'
import { GetUsersInquiryResponseInquiryInquiryTarget } from '~/libs/apis/service/model'
import useGetInquiryTargetDetail from '~/templates/common/inquiry/useGetInquiryTargetDetail'
import Button from '~/components/Button/Button'
import InquiryTargetSearch from '~/templates/common/inquiry/InquiryTargetSearch'
import { useTranslation } from 'react-i18next'
import LabeledContainer from '~/components/LabeledContainer/LabeledContainer'
import { x } from '@xstyled/styled-components'
import PropertyItem from '~/components/Search/PropertyItem'
import { UserInquiryType } from '~/libs/utils/inquiry'
import Typography from '~/components/Typography/Typography'

interface InquiryTargetFieldProps {
  inquiryType: string
  data?: GetUsersInquiryResponseInquiryInquiryTarget
}

const InquiryTargetField: FC<InquiryTargetFieldProps> = ({ inquiryType, data }) => {
  const { t: inquiryTerm } = useTranslation('common', { keyPrefix: 'data_inquiry' })
  const defaultTargetDetail = useGetInquiryTargetDetail()
  const [target, setTarget] = useState<GetUsersInquiryResponseInquiryInquiryTarget | undefined>(defaultTargetDetail)

  useEffect(() => {
    if (defaultTargetDetail !== undefined) {
      setTarget(defaultTargetDetail)
    }

    if (data !== undefined) {
      setTarget(data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTargetDetail?.raId, defaultTargetDetail?.pnu, data])

  const onClick = (data: GetUsersInquiryResponseInquiryInquiryTarget) => {
    setTarget(data)
  }

  if (inquiryType !== UserInquiryType['data']) {
    return null
  }

  const propertyType = target?.propertyTypeCode === 'PT01_1' ? 'office' : 'lwh'

  return (
    <LabeledContainer
      label={inquiryTerm('field.inquiry_target')}
      rightContent={
        target ? (
          <Button
            size="sm"
            content={inquiryTerm('re_select')}
            variant="line"
            color="gray"
            onClick={() => setTarget(undefined)}
          />
        ) : null
      }
    >
      {target ? (
        <>
          <x.div backgroundColor="gray-100" borderRadius="10px" borderColor="gray-300">
            <PropertyItem
              type={target.inquiryTargetType === 'PNU' ? 'land' : propertyType}
              title={
                <Typography fontWeight={600} overflowX="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  {(target.inquiryTargetType === 'PNU' ? target.address : target.buildingName) ?? '-'}
                </Typography>
              }
              jibunAddress={target.address}
              roadNameAddress={target.roadAddress}
            />
          </x.div>
          {/* form data를 위해 추가 */}
          <input type="hidden" name="raId" value={target.raId ?? ''} />
          <input type="hidden" name="pnu" value={target.pnu ?? ''} />
          <input type="hidden" name="targetType" value={target.inquiryTargetType} />
        </>
      ) : (
        <InquiryTargetSearch onClick={onClick} />
      )}
    </LabeledContainer>
  )
}

export default InquiryTargetField
