import { ChartWrapperProps } from '~/libs/constants/chart'
import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Box } from '~/components'
import NoData from '../NoData'
import { useTranslation } from 'next-i18next'

const ChartWrapper: FC<PropsWithChildren<ChartWrapperProps>> = ({
  chartId,
  width,
  height,
  isLoading,
  isCursorPointer,
  children,
  noData,
  recentFiveYearsOnly,
}) => {
  const { t } = useTranslation('common')
  const noDataMessage = recentFiveYearsOnly && t('message.no_data_in_five_years')
  return (
    <Box>
      {noData && !isLoading ? (
        <NoData noDataMessage={noDataMessage} />
      ) : (
        <Wrapper chartId={chartId} isCursorPointer={isCursorPointer} width={width} height={height}>
          {!isLoading && children}
        </Wrapper>
      )}
    </Box>
  )
}

const Wrapper = styled(Box)<{
  chartId: string
  isCursorPointer?: boolean
}>`
  ${({ chartId, isCursorPointer }) =>
    isCursorPointer
      ? `#${chartId}_svg{
          path[id*='${chartId}_Series_']:hover, ellipse[id*='${chartId}_Series_']:hover {
            cursor: pointer;
            }
         }`
      : null}
`

export default ChartWrapper
