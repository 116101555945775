import { FC } from 'react'
import Typography from '~/components/Typography/Typography'
import Box from '../components/Box/Box'
import { SubMenu } from '~/templates/MenuItem'
import { useNextRouter } from '~/libs/hooks/useNextRouter'
import { NextRouter } from 'next/router'
import useHasPermission from '~/libs/hooks/useHasPermission'
import { PagePermission } from '~/libs/constants/permission'
import Tooltip from '~/components/Tooltip/Tooltip'
import { useTranslation } from 'next-i18next'
import { EmitType } from '@syncfusion/ej2-base'
import { TooltipEventArgs } from '@syncfusion/ej2-popups/src/tooltip/tooltip'
import { useBooleanState } from '@toss/react'

interface SubMenuItemProps {
  item: SubMenu
}

const checkCurrentSubMenuPath = (router: NextRouter, url?: string) => {
  if (!url) {
    return false
  }
  return router.pathname === url
}

const SubMenuItem: FC<SubMenuItemProps> = ({ item }) => {
  const router = useNextRouter()
  const hasPermission = useHasPermission(PagePermission[item.url])
  const isCurrentPath = checkCurrentSubMenuPath(router, item.url)
  const { t } = useTranslation('common', { keyPrefix: 'no_permission' })
  const [hover, setHover, setLeave] = useBooleanState(false)

  const handleBeforeOpen: EmitType<TooltipEventArgs> = (event) => {
    if (hasPermission) {
      event.cancel = true
    }
  }
  const handleClick = () => {
    if (hasPermission) {
      return router.push(item.url)
    }
  }

  const getColor = () => {
    if (!hasPermission) {
      return 'gray-400'
    } else if (isCurrentPath || hover) {
      return 'purple-700'
    } else {
      return 'gray-700'
    }
  }
  const getFontWeight = () => {
    if (hasPermission) {
      if (isCurrentPath || hover) {
        return 'semibold'
      }
    }
    return 'regular'
  }

  return (
    <Tooltip content={t('has_no_permission_tooltip')} position="LeftCenter" beforeOpen={handleBeforeOpen}>
      <Box
        textAlign="center"
        padding="10px 18px"
        onClick={handleClick}
        onMouseEnter={setHover}
        onMouseLeave={setLeave}
        backgroundColor={isCurrentPath ? 'purple-100' : 'system-white'}
      >
        <Typography color={getColor()} variant="body" fontWeight={getFontWeight()}>
          {item.text}
        </Typography>
      </Box>
    </Tooltip>
  )
}

export default SubMenuItem
