import { DefaultValue, selector } from 'recoil'
import { BUILDINGS_FILTER_KEYS_FROM_DIALOG, BuildingsFilterParams } from '~/libs/constants/place'
import buildingFilterStateFamily from '~/recoil/building-filter/atom'

/**
 * 빌딩 필터 다이얼로그에서 사용되는 개념의 필터만 존재하는 selector
 * setter는 어떻게 호출해도 reset처럼 동작한다.
 */
const buildingFilterDialogSelector = selector({
  key: 'buildingFilterDialogSelector',
  get: ({ get }) =>
    BUILDINGS_FILTER_KEYS_FROM_DIALOG.reduce<BuildingsFilterParams>(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: get(buildingFilterStateFamily(currentValue)),
      }),
      {},
    ),
  set: ({ set }, newValue) => {
    BUILDINGS_FILTER_KEYS_FROM_DIALOG.forEach((key) =>
      set(buildingFilterStateFamily(key), newValue instanceof DefaultValue ? newValue : undefined),
    )
  },
})

export default buildingFilterDialogSelector
