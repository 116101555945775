import { Box } from '~/components'
import Typography from '~/components/Typography/Typography'
import { styled, SystemProps } from '@xstyled/styled-components'
import { TextVariants } from '~/libs/theme/fonts'
import { isNotNil, roundToUnit } from '@toss/utils'
import { commaizeWithDecimals } from '~/libs/utils/number'
import React, { ComponentType } from 'react'

interface RateChangeItem {
  value: number | null
  unit?: string
  variant?: TextVariants
  fontWeight?: SystemProps['fontWeight']
  as?: ComponentType<any> | keyof React.JSX.IntrinsicElements | string
}

const RateChange = ({ value, unit = '%', variant = 'h3', fontWeight = 'semibold', as }: RateChangeItem) => {
  if (!isNotNil(value) || !isFinite(value)) {
    return (
      <Typography variant={variant} color="gray-800" fontWeight={fontWeight} as={as}>
        -
      </Typography>
    )
  }

  const toRoundValue = roundToUnit(Math.abs(value), 0.01)

  // 반올림한 값이 0이면 (ex. 0.00)
  if (toRoundValue === 0) {
    return (
      <Container className="normal">
        <Typography variant={variant} color="gray-800" fontWeight={fontWeight} as={as}>
          {toRoundValue.toFixed(2)}
          {unit}
        </Typography>
      </Container>
    )
  }

  const className = value > 0 ? 'positive' : 'negative'
  const sign = value > 0 ? '+' : '-'
  const color = value > 0 ? 'chart-1' : 'chart-15'

  return (
    <Container className={className}>
      {/* +와 -의 width 가 달라 box 로 감싸 고정폭 지정 */}

      <Typography variant={variant} color={color} fontWeight={fontWeight} as={as}>
        {sign}
        {commaizeWithDecimals(toRoundValue)}
        {unit}
      </Typography>
    </Container>
  )
}

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  &.positive svg {
    color: var(--color-purple-700);
  }

  &.negative svg {
    color: var(--color-blue-600);
  }

  &.normal svg {
    color: var(--color-gray-800);
  }
`

export default RateChange
