import { isNotNil } from '@toss/utils'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'
import { createPortal } from 'react-dom'
import Indicator from '~/components/Indicator/Indicator'
import { useOfficeDetailDataV1BuildingsOfficeRaIdGet } from '~/libs/apis/data/buildings-office/buildings-office'
import useInterestBuilding from '~/libs/hooks/useInterestBuilding'
import usePnusRegistrations from '~/libs/hooks/usePnusRegistrations'
import { isCompletion, isDemolish } from '~/libs/utils/detail'
import { formatOverHundredNumber } from '~/libs/utils/format'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import StickyBox from '~/templates/StickyBox'
import useMapFilterHandler from '~/templates/common/hooks/useMapFilterHandler'
import BuildingDetail from '~/templates/place/detail/BuildingDetail'
import BuildingGroupSection from '~/templates/place/detail/BuildingGroupSection'
import BuildingSkeleton from '~/templates/place/detail/BuildingSkeleton'
import NavBar from '~/templates/place/detail/NavBar'
import BuildingInfo from '~/templates/place/detail/building/BuildingInfo'
import Land from '~/templates/place/detail/land/Land'
import LeaseContent from '~/templates/place/detail/lease/LeaseContent'
import OfficeTypicalFloorLeasePrices from '~/templates/place/detail/lease/office/OfficeTypicalFloorLeasePrices'
import OfficeVacancy from '~/templates/place/detail/lease/office/OfficeVacancy'
import LesseeInfo from '~/templates/place/detail/lessee/LesseeInfo'
import StackingPlan from '~/templates/place/detail/lessee/StackingPlan'
import { ButtonData } from '~/templates/place/detail/overview/OverviewButton'
import OverviewSection from '~/templates/place/detail/overview/OverviewSection'
import StreetViewModal from '~/templates/place/detail/overview/StreetViewModal'
import RegistrationContent from '~/templates/place/detail/registration/RegistrationContent'
import TradeHistory from '~/templates/place/detail/trade/TradeHistory'
import useOfficeFullDetailLoading from '~/templates/place/detail/useOfficeFullDetailLoading'

interface OfficeDetailProps {
  raId: string
}

const OfficeDetail: FC<OfficeDetailProps> = ({ raId }) => {
  const { selectBuilding } = useMapFilterHandler()
  const { data, isLoading, isValidating } = useOfficeDetailDataV1BuildingsOfficeRaIdGet(raId, {
    swr: {
      enabled: Boolean(raId),
    },
  })
  const isUpdating = isLoading || isValidating
  const [showStreetViewModal, setShowStreetViewModal] = useState(false)
  const toggleShowStreetViewModal = () => setShowStreetViewModal((prev) => !prev)
  const { t } = useTranslation('place', { keyPrefix: 'place_term' })
  const { showOffice, hide } = useFullDetailDialog()
  const { detailType } = useDetailInfo()
  const isFull = detailType === 'full'
  const isGroup = isNotNil(data?.groupRaId)
  const { handleAddition, handleDeletion, stateInterestedId } = useInterestBuilding({
    raId,
    interestBuildingId: data?.interestBuildingId ?? null,
    propertyTypeCode: 'PT01_1',
  })
  const isDetailLoading = useOfficeFullDetailLoading(raId)
  const { data: registrations } = usePnusRegistrations(data?.pnu)
  const registrationCount = formatOverHundredNumber(registrations?.page.totalSize)

  const handleFullClick = () => {
    showOffice(raId)
  }

  if (isLoading) {
    return <BuildingSkeleton />
  }

  if (!data) {
    return null
  }

  const handleOnClose = () => {
    return selectBuilding(raId, data.propertyTypeCode)
  }

  const handleInterestButtonClick = (active?: boolean) => {
    if (active) {
      handleDeletion()
    } else {
      handleAddition()
    }
  }

  const buttonData: ButtonData[] = [
    {
      iconKey: 'streetview',
      label: t('street_view'),
      onClick: toggleShowStreetViewModal,
    },
    {
      iconKey: 'interest',
      label: t('bookmarked'),
      active: stateInterestedId !== null,
      onClick: !isUpdating ? handleInterestButtonClick : undefined,
    },
    {
      iconKey: 'close',
      label: t('close'),
      onClick: isFull ? hide : handleOnClose,
    },
  ]

  const overview = (
    <>
      <StickyBox top={0} backgroundColor="system-white">
        <NavBar buttonData={buttonData} name={data.buildingName} />
      </StickyBox>
      {isGroup && <BuildingGroupSection groupRaId={data.groupRaId!} />}
      <OverviewSection data={data} />
    </>
  )
  const building = <BuildingInfo data={data} />
  const land = <Land raId={raId} pnu={data.pnu} />
  const rental = (
    <LeaseContent
      propertyType={data.propertyTypeCode}
      isCompletion={isCompletion(data)}
      typicalFloorLeasePrices={<OfficeTypicalFloorLeasePrices data={data} />}
      vacancy={<OfficeVacancy data={data} />}
    />
  )
  const lessee = (
    <>
      {isFull && isNotNil(data.lessee) && <StackingPlan />}
      <LesseeInfo pnu={data.pnu} />
    </>
  )
  const trade = <TradeHistory pnu={data.pnu} />
  const registration = <RegistrationContent pnu={data.pnu} address={data.address} />

  return (
    <>
      <Indicator loading={isDetailLoading} />
      <BuildingDetail
        overview={overview}
        building={building}
        land={land}
        rental={rental}
        lessee={lessee}
        trade={trade}
        registration={registration}
        isGroup={isNotNil(data?.groupRaId)}
        isCompletion={isCompletion(data)}
        isDemolish={isDemolish(data)}
        isExistsContactNumber={data?.isExistsContactNumber}
        hasPnu={isNotNil(data?.pnu)}
        onFullClick={handleFullClick}
        registrationCount={registrationCount}
      />
      {showStreetViewModal &&
        createPortal(
          <StreetViewModal
            center={{ lat: data.lat, lng: data.lng }}
            boundary={data.buildingBoundary}
            onClose={toggleShowStreetViewModal}
          />,
          document.body,
        )}
    </>
  )
}

export default OfficeDetail
