import { FC, ReactNode } from 'react'
import Typography from '~/components/Typography/Typography'
import Box from '~/components/Box/Box'
import OverflowWithTooltip from '~/components/Tooltip/OverflowWithTooltip'

export interface DetailTableType {
  column: string | ReactNode
  content?: string | ReactNode
  oneLine?: boolean
}
interface DetailTableProps {
  data: DetailTableType[]
}

const DetailTable: FC<DetailTableProps> = ({ data }) => {
  return (
    <Box display="flex" flexWrap="wrap" columnGap="20px">
      {data.map((item, index) => {
        return (
          <Box
            key={index}
            width={item.oneLine ? '100%' : 'calc(50% - 10px)'}
            display="flex"
            gap="10px"
            justifyContent="space-between"
            padding="12px 0"
            borderBottom="1px solid var(--color-gray-300)"
            whiteSpace="nowrap"
          >
            <Typography color="gray-700" as="div">
              {item.column}
            </Typography>
            <Typography color="gray-800" fontWeight="semibold" as="div" overflow="hidden" textOverflow="ellipsis">
              {typeof item.content === 'string' ? (
                <OverflowWithTooltip cellText={item.content} fontWeight="semibold" />
              ) : (
                (item.content ?? '-')
              )}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

export default DetailTable
