import { FC, useState } from 'react'
import { useSbizDataV1GovSbizGet } from '~/libs/apis/data/gov/gov'
import SbizGrid from '~/templates/place/detail/lessee/SbizGrid'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import { useTranslation } from 'next-i18next'
import NoLesseeInfo from '~/templates/place/detail/lessee/NoLesseeInfo'

interface SbizProps {
  pnu: string
}

const PAGE_SIZE = 5
const Sbiz: FC<SbizProps> = ({ pnu }) => {
  const [pageNum, setPageNum] = useState(1)
  const { lastUpdate } = useLastUpdateDate()
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { data, isLoading } = useSbizDataV1GovSbizGet(
    { pnu, pageNum, pageSize: PAGE_SIZE, year: lastUpdate.year, quarter: lastUpdate.quarter },
    {
      swr: {
        keepPreviousData: true,
      },
    },
  )

  if (!data?.data?.length && !isLoading) {
    return <NoLesseeInfo text={t('no_information')} />
  }

  const handlePageChange = (page: number) => setPageNum(page)

  return (
    <SbizGrid dataSource={data?.data} pageModel={data?.page} onPageChange={handlePageChange} isLoading={isLoading} />
  )
}

export default Sbiz
