import Typography from '~/components/Typography/Typography'
import GuidePopover from '~/templates/common/popover/GuidePopover'
import { useTranslation } from 'next-i18next'
import Box from '~/components/Box/Box'
import { FC } from 'react'

const ChangeInfoGuidePopover: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'guide_popover' })
  return (
    <GuidePopover buttonTitle={t('change_info_guide')} popperWidth="240px" iconPosition="left" popperPosition="left">
      <Box display="flex" flexDirection="column">
        <Typography fontWeight="bold" color="blue-700" pb={1.5}>
          {t('change_info_guide_title')}
        </Typography>
        <Typography variant="caption1" color="gray-800" whiteSpace="pre-wrap">
          {t('change_info_guide_content')}
        </Typography>
      </Box>
    </GuidePopover>
  )
}

export default ChangeInfoGuidePopover
