import { atomFamily } from 'recoil'

export type MapDrawOption =
  | 'NONE'
  | 'DRAWING_CIRCLE'
  | 'DRAWING_POLYGON'
  | 'CIRCLE'
  | 'POLYGON'
  | 'MODIFYING_CIRCLE'
  | 'MODIFYING_POLYGON'

const drawState = atomFamily<MapDrawOption, MapType>({
  key: 'drawState',
  default: 'NONE',
})

export default drawState
