import styled, { x } from '@xstyled/styled-components'
import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { isNotNil } from '@toss/utils'
import { OfficeStackingPlanOut } from '~/libs/apis/data/model'
import { useAreaFormat } from '~/libs/hooks/useFormat'
import { formatFloor } from '~/libs/utils/format'

interface StackingPlanAreaTableProps {
  data?: OfficeStackingPlanOut[]
  onlyFloorColumn?: boolean
}

const StackingPlanAreaTable: FC<StackingPlanAreaTableProps> = ({ data, onlyFloorColumn }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })

  const areaFormat = useAreaFormat()

  if (!data) {
    return null
  }

  if (onlyFloorColumn) {
    return (
      <AreaTable>
        <thead>
          <tr>
            <x.th w="50px">{t('floor')}</x.th>
          </tr>
        </thead>
        <tbody>
          {data.map((stackingPlan) => {
            return (
              <tr key={stackingPlan.callFloor + stackingPlan.floor}>
                <td>{formatFloor(stackingPlan.callFloor)}</td>
              </tr>
            )
          })}
        </tbody>
      </AreaTable>
    )
  }

  // 웹 표준상 table 태그는 지양해야 하지만 xstyled 가이드를 따르기로 함
  return (
    <AreaTable>
      <thead>
        <tr>
          <x.th w="50px">{t('floor')}</x.th>
          <x.th w="180px">{t('rent_area')}</x.th>
          <x.th w="180px">{t('nla_area')}</x.th>
        </tr>
      </thead>
      <tbody>
        {data.map((stackingPlan) => {
          return (
            <tr key={stackingPlan.callFloor + stackingPlan.floor}>
              <td>{formatFloor(stackingPlan.callFloor)}</td>
              <x.td backgroundColor={isNotNil(stackingPlan.rentArea) ? 'system-white' : 'gray-100'}>
                {areaFormat(stackingPlan.rentArea, { rounded: 'n2' }) ?? '-'}
              </x.td>
              <x.td backgroundColor={isNotNil(stackingPlan.nlaArea) ? 'system-white' : 'gray-100'}>
                {areaFormat(stackingPlan.nlaArea, { rounded: 'n2' }) ?? '-'}
              </x.td>
            </tr>
          )
        })}
      </tbody>
    </AreaTable>
  )
}

const AreaTable = styled.table`
  border-collapse: collapse;
  border: 1px solid var(--color-gray-300);
  font-size: 14px;
  tr {
    height: 40px;
    white-space: nowrap;
  }
  th {
    text-align: left;
    background-color: var(--color-gray-200);
    vertical-align: middle;
    padding: 0 10px;
    font-weight: semibold;
    border: 1px solid var(--color-gray-300);
  }
  td {
    padding: 0 10px;
    border: 1px solid var(--color-gray-300);
  }
`

export default StackingPlanAreaTable
