import React, { FC, useEffect } from 'react'
import { Box } from '~/components'
import Button from '~/components/Button/Button'
import { useTranslation } from 'next-i18next'
import { useBooleanState, useStorageState } from '@toss/react'
import { DOWNTIME_NOTICE_CLOSED_KEY, DOWNTIME_NOTICE_SKIP_TIME_KEY } from '~/libs/constants/common'
import { addDays, isBefore } from 'date-fns'
import styled from '@xstyled/styled-components'
import { DialogComponent } from '@syncfusion/ej2-react-popups'
import { commonDialogCss } from '~/components/Dialog/dialogStyle'
import DowntimeNotice from '~/templates/DowntimeNotice'
import useDowntimeNotices from '~/libs/hooks/useDowntimeNotices'

/**
 * 점검에 대한 사전알림용 다이얼로그
 * @constructor
 */
const DowntimeNoticeDialog: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'downtime_notice' })
  const [isDialogVisible, open, close] = useBooleanState(false)
  const [state, setState] = useStorageState(DOWNTIME_NOTICE_CLOSED_KEY, {
    defaultValue: false,
  })
  const [skipTime, setSkipTime] = useStorageState<number>(DOWNTIME_NOTICE_SKIP_TIME_KEY, {
    defaultValue: undefined,
  })

  const { data } = useDowntimeNotices()
  const notice = data?.at(0)

  const handleClickSkipButton = () => {
    setSkipTime(addDays(new Date(), 1).getTime())
    close()
  }

  const handleClickCloseButton = () => {
    setState(true)
    close()
  }

  useEffect(() => {
    if (skipTime) {
      const skipDate = new Date(skipTime)
      if (isBefore(new Date(), skipDate)) {
        return
      }
    }
    if (state) {
      return
    }
    if (notice) {
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notice, open])

  const footerTemplate = () => (
    <Box display="flex" justifyContent="center" gap={4} py="22px">
      <Button
        size="lg"
        width="164px"
        variant="line"
        color="gray"
        content={t('skip_one_day')}
        onClick={handleClickSkipButton}
      />
      <Button size="lg" width="164px" content={t('close')} onClick={handleClickCloseButton} />
    </Box>
  )

  return (
    <StyledDialog width={560} visible={isDialogVisible} footerTemplate={footerTemplate} isModal>
      <DowntimeNotice data={notice} />
    </StyledDialog>
  )
}

const StyledDialog = styled(DialogComponent)`
  ${commonDialogCss}
  & {
    overflow: hidden;
    .e-dlg-content {
      padding: 0;
      background-color: var(--color-purple-700);
    }
  }
`

export default DowntimeNoticeDialog
