import { FC } from 'react'
import useSubwayUtil from '~/libs/hooks/useSubwayUtil'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'

interface SubwayLineBadgeProps {
  subwayLineCode: NullishString
  subwayServiceAreaCode: NullishString
}
const SubwayLineBadge: FC<SubwayLineBadgeProps> = ({ subwayLineCode, subwayServiceAreaCode }) => {
  const { getSubwayLineColor, getSubwayLineName } = useSubwayUtil()

  const lineName = getSubwayLineName(subwayServiceAreaCode, subwayLineCode)
  const lineColor = getSubwayLineColor(subwayServiceAreaCode, subwayLineCode)

  if (!subwayLineCode || !subwayServiceAreaCode) {
    return null
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      minWidth="22px"
      py="1px"
      px={lineName?.length === 1 ? '6px' : '8px'}
      justifyContent="center"
      borderColor={lineColor ?? '#000000'}
      borderWidth={1}
      borderRadius="12px"
    >
      <Typography variant="caption1" fontWeight={600} color={lineColor ?? '#000000'}>
        {lineName}
      </Typography>
    </Box>
  )
}

export default SubwayLineBadge
