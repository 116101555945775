import styled, { CSSProperties, css } from '@xstyled/styled-components'
import Box from '~/components/Box/Box'

type ScrollVariant = 'vertical' | 'horizontal'
interface ScrollBoxProps {
  variant?: ScrollVariant
  scrollPadding?: CSSProperties['scrollPadding']
}

const ScrollBox = styled(Box)<ScrollBoxProps>`
  ${({ scrollPadding }) => `scroll-padding: ${scrollPadding};`}
  ${({ variant }) => {
    switch (variant) {
      case 'vertical':
        return ScrollVertical
      case 'horizontal':
        return ScrollHorizontal
      default:
        return ScrollBoth
    }
  }}

  &::-webkit-scrollbar {
    background-color: var(--color-gray-100);
    height: 10px;
    width: 10px;
    border-radius: 17px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-400);
    border: 1px solid transparent;
    border-radius: 23px;
    background-clip: padding-box;
  }
`

/**
 * Box overflow styles
 */
const ScrollBoth = css`
  overflow: auto;
`
const ScrollVertical = css`
  overflow-x: hidden;
  overflow-y: auto;
`
const ScrollHorizontal = css`
  overflow-x: auto;
  overflow-y: hidden;
`

export default ScrollBox
