import { FC, useMemo } from 'react'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import MultiTemplate from '~/components/DataGrid/MultiTemplate'
import { formatDateYmd } from '~/libs/utils/date'
import { useTranslation } from 'next-i18next'
import DataGrid from '~/components/DataGrid/DataGrid'
import { useAreaFormat } from '~/libs/hooks/useFormat'
import { PagerModel } from '@syncfusion/ej2-grids/src/pager/pager-model'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { RecordClickEventArgs } from '@syncfusion/ej2-react-grids'
import { EmitType } from '@syncfusion/ej2/base'
import useCodeNameValueAccessor from '~/libs/hooks/useCodeNameValueAccessor'

interface PermissionGridProps {
  dataSource: object[] | undefined
  pagerModel?: PagerModel
  onPageChange: (object: { currentPage: number }) => void
}

const PermissionGrid: FC<PermissionGridProps> = ({ dataSource, pagerModel, onPageChange }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { openPermitDialog } = useFullDetailDialog()
  const areaFormatter = useAreaFormat()
  const { areaUnit } = useUserConfiguration()
  const codeNameValueAccessor = useCodeNameValueAccessor()

  const columns: ColumnModel[] = useMemo(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () => [
      {
        field: 'archGubun',
        headerText: t('arch_gubun'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 80,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'permissionStep',
        headerText: t('process_step'),
        textAlign: 'Left',
        width: 90,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'buildingName',
        headerText: t('building_name'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 120,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'mainPurposeIndicationCode',
        headerText: t('main_purpose'),
        textAlign: 'Left',
        width: 80,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: codeNameValueAccessor,
      },
      {
        field: 'area',
        width: 100,
        textAlign: 'Left',
        headerTemplate: () => <MultiTemplate isHeader content={[t('plat_area'), t('gra_py')]} textAlign="left" />,
        template: (props: any) => (
          <MultiTemplate
            content={[
              props.platArea ? areaFormatter(props.platArea, { rounded: 'n2' }) : undefined,
              props.totalArea ? areaFormatter(props.totalArea, { rounded: 'n2' }) : undefined,
            ]}
            textAlign="left"
          />
        ),
      },
      {
        field: 'permissionConstructionDateYmd',
        headerText: t('permission_construction_date'),
        width: 100,
        valueAccessor: (field: string, data: any) => formatDateYmd(data[field]),
      },
      {
        field: 'realConstructionDateYmd',
        headerText: t('real_construction_date'),
        width: 100,
        textAlign: 'Left',
        valueAccessor: (field: string, data: any) => formatDateYmd(data[field]),
      },
      {
        field: 'useAprDateYmd',
        headerText: t('use_approval_date'),
        width: 100,
        textAlign: 'Left',
        valueAccessor: (field: string, data: any) => formatDateYmd(data[field]),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areaUnit],
  )

  const handleRecordClick: EmitType<RecordClickEventArgs> = (args) => {
    if (!args.rowData?.mgmPmsrgstPk) {
      return
    }
    openPermitDialog(args.rowData.mgmPmsrgstPk)
  }

  return (
    <DataGrid
      dataSource={dataSource}
      pagerModel={pagerModel}
      columns={columns}
      paginationMode="server"
      onPageChange={onPageChange}
      allowSelection
      recordClick={handleRecordClick}
    />
  )
}

export default PermissionGrid
