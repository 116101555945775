import {
  GetLeaseProductsAggregationsDataV1LeaseProductsAggregationsGetParams,
  GetRegistrationsMarkerDataV1RegistrationsMarkerGetParams,
  GetLeaseProductsAggregationsMarkerDataV1LeaseProductsAggregationsMarkerGetParams,
  GetTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGetParams,
} from '~/libs/apis/data/model'

export type TenantsFilterParams = GetTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGetParams

export const TENANT_FILTER_KEYS_FROM_UI: Array<keyof TenantsFilterParams> = [
  'paths',
  'circleLat',
  'circleLng',
  'radius',
  'pointLat',
  'pointLng',
  'neLat',
  'neLng',
  'swLat',
  'swLng',
  'clientIds',
  'raIds',
  'clientSectors',
  'companyTypeNames',
  'minRentArea',
  'maxRentArea',
  'boeTypeCode',
  'regionCode',
  'propertyTypeCode',
  'minTotalArea',
  'maxTotalArea',
  'minNocPerM2',
  'maxNocPerM2',
]

type TenantsFilterDialogParams = Omit<
  GetTenantsBuildingsMarkerDataV1TenantsBuildingsMarkerGetParams,
  | 'paths'
  | 'circleLat'
  | 'circleLng'
  | 'radius'
  | 'pointLat'
  | 'pointLng'
  | 'neLat'
  | 'neLng'
  | 'swLat'
  | 'swLng'
  | 'clientIds'
  | 'raIds'
  | 'regionCode'
>

export const TENANT_FILTER_KEYS_FROM_DIALOG: Array<keyof TenantsFilterDialogParams> = [
  'clientSectors',
  'companyTypeNames',
  'minRentArea',
  'maxRentArea',
  'boeTypeCode',
  'propertyTypeCode',
  'minTotalArea',
  'maxTotalArea',
  'minNocPerM2',
  'maxNocPerM2',
]

export const TENANTS_FILTER_KEYS_SELECT_BOE: Readonly<Array<keyof TenantsFilterParams>> = ['boeTypeCode']

export const TENANTS_FILTER_KEYS_REGION_SEARCH: Readonly<Array<keyof TenantsFilterParams>> = ['regionCode']

export const TENANTS_FILTER_KEYS_CIRCLE_DRAW: Readonly<Array<keyof TenantsFilterParams>> = [
  'radius',
  'circleLat',
  'circleLng',
]

export const TENANTS_FILTER_KEYS_POLYGON_DRAW: Readonly<Array<keyof TenantsFilterParams>> = ['paths']

export type RegistrationFilterParams = GetRegistrationsMarkerDataV1RegistrationsMarkerGetParams

export const REGISTRATION_FILTER_KEYS_FROM_UI: Array<keyof RegistrationFilterParams> = [
  'paths',
  'circleLat',
  'circleLng',
  'radius',
  'pointLat',
  'pointLng',
  'neLat',
  'neLng',
  'swLat',
  'swLng',
  'pnus',
  'regionCode',
  'registrationId',
  'minRegistrationCompletedDateYmd',
  'maxRegistrationCompletedDateYmd',
  'registrationProperties',
  'registrationOwnerTypes',
  'residentRegistrationNumber',
  'minRegistrationChangeDateYmd',
  'maxRegistrationChangeDateYmd',
  'registrationObjectives',
  'registrationProcessStatuses',
]

type RegistrationsFilterDialogParams = Omit<
  GetRegistrationsMarkerDataV1RegistrationsMarkerGetParams,
  | 'paths'
  | 'circleLat'
  | 'circleLng'
  | 'radius'
  | 'pointLat'
  | 'pointLng'
  | 'neLat'
  | 'neLng'
  | 'swLat'
  | 'swLng'
  | 'pnus'
  | 'regionCode'
  | 'registrationId'
>

export const REGISTRATION_FILTER_KEYS_FROM_DIALOG: Array<keyof RegistrationsFilterDialogParams> = [
  'minRegistrationCompletedDateYmd',
  'maxRegistrationCompletedDateYmd',
  'registrationProperties',
  'registrationOwnerTypes',
  'residentRegistrationNumber',
  'minRegistrationChangeDateYmd',
  'maxRegistrationChangeDateYmd',
  'registrationObjectives',
  'registrationProcessStatuses',
]

export const REGISTRATIONS_FILTER_KEYS_REGION_SEARCH: Readonly<Array<keyof RegistrationFilterParams>> = ['regionCode']

export const REGISTRATIONS_FILTER_KEYS_CIRCLE_DRAW: Readonly<Array<keyof RegistrationFilterParams>> = [
  'radius',
  'circleLat',
  'circleLng',
]

export const REGISTRATIONS_FILTER_KEYS_POLYGON_DRAW: Readonly<Array<keyof RegistrationFilterParams>> = ['paths']

export type LeaseProductFilterParams = GetLeaseProductsAggregationsDataV1LeaseProductsAggregationsGetParams

export const LEASE_PRODUCT_FILTER_KEYS_FROM_UI: Array<keyof LeaseProductFilterParams> = [
  'pageNum',
  'pageSize',
  'orderBy',
  'paths',
  'circleLat',
  'circleLng',
  'radius',
  'pointLat',
  'pointLng',
  'neLat',
  'neLng',
  'swLat',
  'swLng',
  'pnu',
  'regionCode',
  'minProductCreateDateYm',
  'maxProductCreateDateYm',
  'propertyTypeCodes',
  'lwhTypeCodes',
  'mainPurposeCodes',
  'minRentFeePerM2',
  'maxRentFeePerM2',
  'minNocPerM2',
  'maxNocPerM2',
  'minTotalArea',
  'maxTotalArea',
  'minRentArea',
  'maxRentArea',
  'tradeStates',
  'minConstructDateY',
  'maxConstructDateY',
  'floorIndicationCodes',
]

type LeaseProductFilterDialogParams = Omit<
  GetLeaseProductsAggregationsMarkerDataV1LeaseProductsAggregationsMarkerGetParams,
  | 'paths'
  | 'circleLat'
  | 'circleLng'
  | 'radius'
  | 'pointLat'
  | 'pointLng'
  | 'neLat'
  | 'neLng'
  | 'swLat'
  | 'swLng'
  | 'pnu'
  | 'regionCode'
>

export const LEASE_PRODUCT_FILTER_KEYS_FROM_DIALOG: Array<keyof LeaseProductFilterDialogParams> = [
  'minProductCreateDateYm',
  'maxProductCreateDateYm',
  'tradeStates',
  'propertyTypeCodes',
  'lwhTypeCodes',
  'minRentFeePerM2',
  'maxRentFeePerM2',
  'minNocPerM2',
  'maxNocPerM2',
  'floorIndicationCodes',
  'minRentArea',
  'maxRentArea',
  'mainPurposeCodes',
  'minConstructDateY',
  'maxConstructDateY',
  'minTotalArea',
  'maxTotalArea',
]

export const LEASE_PRODUCT_FILTER_KEYS_REGION_SEARCH: Readonly<Array<keyof LeaseProductFilterParams>> = ['regionCode']

export const LEASE_PRODUCT_FILTER_KEYS_CIRCLE_DRAW: Readonly<Array<keyof LeaseProductFilterParams>> = [
  'radius',
  'circleLat',
  'circleLng',
]

export const LEASE_PRODUCT_FILTER_KEYS_POLYGON_DRAW: Readonly<Array<keyof LeaseProductFilterParams>> = ['paths']

export const TOPIC_SHOW_CLUSTER_COUNT = 1000
export const REGISTRATION_SHOW_CLUSTER_COUNT = 500
