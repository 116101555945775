import { forwardRef, MutableRefObject, useState } from 'react'
import styled from '@xstyled/styled-components'
import { BeforeOpenEventArgs, DialogComponent } from '@syncfusion/ej2-react-popups'
import OfficeDetail from '~/templates/place/detail/OfficeDetail'
import { EmitType } from '@syncfusion/ej2-base'
import LwhDetail from '~/templates/place/detail/LwhDetail'
import { SwitchCase } from '@toss/react'
import DetailInfoContextProvider from '~/templates/DetailTypeProvider'
import Box from '~/components/Box/Box'
import LandDetail from '~/templates/place/detail/LandDetail'
import { DetailContent, LandingContent } from '~/templates/FullDetailDialogProvider'
import { Arguments } from 'swr'

interface FullDetailDialogProps {
  uid?: MutableRefObject<string>
  targetId?: MutableRefObject<number | undefined>
  content: MutableRefObject<DetailContent>
  landing?: MutableRefObject<LandingContent | undefined>
  globalSwrKeys?: MutableRefObject<Arguments[] | undefined>
}

const FullDetailDialog = forwardRef<DialogComponent, FullDetailDialogProps>(
  ({ uid, targetId, content, landing, globalSwrKeys }, ref) => {
    const [visible, setVisible] = useState(false)

    const beforeOpen: EmitType<BeforeOpenEventArgs> = (args) => {
      args.maxHeight = '100vh'
    }

    const handleOpen = () => {
      setVisible(true)
    }

    const handleClose = () => {
      setVisible(false)
    }

    return (
      <StyledDialog
        ref={ref}
        visible={false}
        width="1000px"
        target="#dialog-target"
        position={{ X: 'center', Y: 'top' }}
        beforeOpen={beforeOpen}
        open={handleOpen}
        close={handleClose}
        closeOnEscape={false}
        $disableOverlayClose={true}
        isModal
      >
        <FullDetailWrapper>
          {visible && uid?.current && (
            <DetailInfoContextProvider
              initialValue={{
                uid: uid?.current,
                detailType: 'full',
                selectedRegistrationId: targetId?.current,
                detailContent: content.current,
                landing: landing?.current,
                globalSwrKeys: globalSwrKeys?.current,
              }}
            >
              <SwitchCase
                value={content.current}
                caseBy={{
                  office: <OfficeDetail raId={uid?.current} />,
                  lwh: <LwhDetail raId={uid?.current} />,
                  land: <LandDetail pnu={uid?.current} />,
                }}
              />
            </DetailInfoContextProvider>
          )}
        </FullDetailWrapper>
      </StyledDialog>
    )
  },
)

const StyledDialog = styled(DialogComponent)`
  &.e-dialog {
    border: 0;
    border-radius: 0;

    .e-dlg-header-content {
      position: absolute;
      top: 0;
      right: -88px;
      padding: 0;
      border: 0 !important;
      border-radius: 0 !important;
      z-index: 2000;
    }

    .e-dlg-content {
      padding: 0;
    }
  }
`

const FullDetailWrapper = styled(Box)`
  height: 100vh;
  scroll-behavior: smooth;

  & > div::-webkit-scrollbar {
    display: none;
  }
`

FullDetailDialog.displayName = 'FullDetailDialog'

export default FullDetailDialog
