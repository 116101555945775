const sizes = {
  contentAreaMaxWidth: 1200,
  headerHeight: 52,
  tabHeight: 42,
  filterHeight: 117.39,
  officeHeaderHeight: 186,
  officeHeaderSmHeight: 111,
  filterBarHeight: 53,
  officeListHeaderHeight: 53.8,
  windowMinimumWidth: 1280,
} as const

export type Sizes = typeof sizes

export default sizes
