import useLeaseProductsFilterQuery from '~/templates/topic/lease-product/hooks/useLeaseProductsFilterQuery'
import { format, subMonths } from 'date-fns'

const today = format(new Date(), 'yyyyMM')
const sixMonthsAgo = format(subMonths(new Date(), 5), 'yyyyMM')

const useLeaseProductsFilterCount = (): number => {
  const filterQuery = useLeaseProductsFilterQuery()
  const productCreateDateYm = Object(filterQuery['productCreateDateYm'])
  if (productCreateDateYm && productCreateDateYm.min === sixMonthsAgo && productCreateDateYm.max === today) {
    return Object.keys(filterQuery).length - 1
  }

  return Object.keys(filterQuery).length
}

export default useLeaseProductsFilterCount
