import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { CompareState, useCompareStateContext } from '~/templates/compare/common/CompareStateProvider'
import { sortArrayById } from '~/libs/constants/compare'
import { useToast } from '~/templates/ToastContextProvider'
import { useTranslation } from 'next-i18next'

export type CompareItemRequiredFields = {
  id: string
  lat: number
  lng: number
}

interface CompareListContextType<T extends CompareItemRequiredFields> {
  list: T[]
  handleAddItem: (newItem: T) => void
  handleReorderItem: (ids: string[]) => void
  handleDeleteItem: (id: string) => void
  handleClearList: () => void
  handleLoadList: (comparisonName: string, comparisonId: number) => void
}

const CompareListContext = createContext<CompareListContextType<any>>({
  list: [],
  handleAddItem: () => null,
  handleReorderItem: () => null,
  handleDeleteItem: () => null,
  handleClearList: () => null,
  handleLoadList: () => null,
})

interface CompareListContextProviderProps<T> {
  initialValue: T[] | undefined
  children: ReactNode
}

type AddAction<T> = {
  type: 'ADD'
  item: T
}
type DeleteAction = {
  type: 'DELETE'
  id: string
}
type SetAction<T> = {
  type: 'SET'
  newList: T[]
}
type Actions<T> = AddAction<T> | DeleteAction | SetAction<T>

const reducer = <T extends { id: string }>(state: T[], action: Actions<T>) => {
  switch (action.type) {
    case 'ADD':
      return [...state, action.item]
    case 'DELETE':
      return state.filter((item) => item.id !== action.id)
    case 'SET':
      return action.newList
    default:
      return state
  }
}

const CompareListContextProvider = <T extends { id: string }>({
  initialValue = [],
  children,
}: CompareListContextProviderProps<T>) => {
  const [list, dispatch] = useReducer(reducer, initialValue)
  const originalList = initialValue
  const { state, comparisonId: currentComparisonId, dispatch: stateDispatch } = useCompareStateContext()
  const { show } = useToast()
  const { t } = useTranslation()

  const handleAddBuilding = useCallback(
    (item: T) => {
      if (list.length >= 20) {
        show(t('common_msg.maximum_building_count'))
        return
      }
      dispatch({ type: 'ADD', item: item })
      show(t('common_msg.target_added'))
      if (state === CompareState.SAVED) {
        stateDispatch({ type: 'EDIT' })
      }
    },
    [list.length, show, state, stateDispatch, t],
  )

  const handleReorderBuildings = useCallback(
    (ids: string[]) => {
      dispatch({ type: 'SET', newList: sortArrayById(list, ids) })
      if (state === CompareState.SAVED) {
        stateDispatch({ type: 'EDIT' })
      }
    },
    [list, state, stateDispatch],
  )

  const handleDeleteBuilding = useCallback(
    (id: string) => {
      dispatch({ type: 'DELETE', id: id })
      if (state === CompareState.SAVED) {
        stateDispatch({ type: 'EDIT' })
      }
    },
    [state, stateDispatch],
  )

  const handleClearBuildings = useCallback(() => {
    dispatch({ type: 'SET', newList: [] })
    stateDispatch({ type: 'NEW' })
  }, [stateDispatch])

  const handleLoadBuildings = useCallback(
    (comparisonName: string, comparisonId: number) => {
      if (comparisonId === currentComparisonId) {
        dispatch({ type: 'SET', newList: originalList })
      }
      stateDispatch({ type: 'TO_SAVED', comparisonName: comparisonName, comparisonId: comparisonId })
    },
    [currentComparisonId, originalList, stateDispatch],
  )

  useEffect(() => {
    dispatch({ type: 'SET', newList: initialValue ?? [] })
  }, [initialValue])

  const value = useMemo(
    () => ({
      list,
      handleAddItem: handleAddBuilding,
      handleReorderItem: handleReorderBuildings,
      handleDeleteItem: handleDeleteBuilding,
      handleClearList: handleClearBuildings,
      handleLoadList: handleLoadBuildings,
    }),
    [handleAddBuilding, handleClearBuildings, handleDeleteBuilding, handleLoadBuildings, handleReorderBuildings, list],
  )

  return <CompareListContext.Provider value={value}>{children}</CompareListContext.Provider>
}

export const useCompareListContext = <T extends CompareItemRequiredFields>() =>
  useContext<CompareListContextType<T>>(CompareListContext)

export default CompareListContextProvider
