import { PropsWithChildren, ReactNode, forwardRef } from 'react'
import styled from '@xstyled/styled-components'
import { DialogComponent, DialogModel } from '@syncfusion/ej2-react-popups'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { basicDialogCss, commonDialogCss, simpleDialogCss } from './dialogStyle'

type DialogVariant = 'basic' | 'simple'

export interface DialogProps
  extends Pick<
    DialogModel,
    | 'footerTemplate'
    | 'open'
    | 'beforeOpen'
    | 'beforeClose'
    | 'close'
    | 'visible'
    | 'width'
    | 'height'
    | 'target'
    | 'closeOnEscape'
    | 'minHeight'
    | 'content'
    | 'animationSettings'
    | 'cssClass'
  > {
  /** `Dialog` 상단에 출력되는 제목 */
  title: string
  /** `Dialog` 상단에 출력되는 아이콘 */
  icon?: ReactNode
  /** `Dialog` 유형 */
  variant?: DialogVariant
  /** overlay되는 dimming 영역 클릭시 `close` 이벤트 콜백 호출 여부 */
  disableOverlayClose?: boolean
  /** 다이얼로그에 id값을 넘길 수 있도록 **/
  id?: string
}

/**
 * `Dialog` 컴포넌트: 전체화면에 overlay되며 배경이 dimming 처리되는 컴포넌트
 */
const Dialog = forwardRef<DialogComponent, PropsWithChildren<DialogProps>>(
  ({ title, icon, footerTemplate, children, close, disableOverlayClose, variant = 'basic', width, ...props }, ref) => {
    const isSimpleDialog = variant === 'simple'
    const defaultWidth = isSimpleDialog ? '384px' : undefined

    const header = () => (
      <Box display="flex" flexDirection="column" gap="12px" flex="1" alignItems="center">
        {isSimpleDialog && icon && (
          <Box width="36px" height="36px">
            {icon}
          </Box>
        )}
        <Typography variant={isSimpleDialog ? 'h5' : 'h3'} fontWeight="semibold" whiteSpace="pre-wrap">
          {title}
        </Typography>
      </Box>
    )
    return (
      <StyledDialog
        ref={ref}
        header={header}
        footerTemplate={footerTemplate}
        close={close}
        overlayClick={disableOverlayClose ? undefined : close}
        isModal
        width={width || defaultWidth}
        $isSimpleDialog={isSimpleDialog}
        {...props}
      >
        {children}
      </StyledDialog>
    )
  },
)

Dialog.displayName = 'Dialog'

const StyledDialog = styled(DialogComponent)<{ width: string | number | undefined; $isSimpleDialog: boolean }>`
  ${commonDialogCss}
  ${({ $isSimpleDialog }) => ($isSimpleDialog ? simpleDialogCss : basicDialogCss)}}
`

export default Dialog
