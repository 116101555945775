import { css } from '@xstyled/styled-components'

export const customAnimations = css`
  * {
    @keyframes x-cluster {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      75%,
      100% {
        transform: scale(1.5);
        opacity: 0;
      }
    }
  }
`

const animations = {
  cluster: 'x-cluster 1.5s cubic-bezier(0,0,0.2,1) infinite',
}

export default animations
