import { FC, RefObject, PropsWithChildren, useState } from 'react'
import ScrollBox from '~/components/Box/ScrollBox'
import FilterOverlay from '~/templates/common/filter/FilterOverlay'
import styled from '@xstyled/styled-components'
import { PopoverProps } from '~/components/Dropdown/Popover'
import { useResizeObserver } from '@toss/react'

interface FilterDialogProps {
  popoverProps: PopoverProps
  onReset: () => void
  scrollRefs: Record<string, RefObject<HTMLDivElement>>
}

export const FilterDivider = styled.div`
  min-height: 12px;
  background-color: gray-200;
`

export const FilterOffset = styled.div`
  min-height: 100px;
  background-color: gray-200;
`

const FILTER_OVERLAY_HEADER_HEIGHT = 102

const FilterDialog: FC<PropsWithChildren<FilterDialogProps>> = ({ popoverProps, onReset, children, scrollRefs }) => {
  const [filterOverlayHeaderHeight, setFilterOverlayHeaderHeight] = useState<number>(FILTER_OVERLAY_HEADER_HEIGHT)
  const filterOverlayRef = useResizeObserver<HTMLDivElement>((entry) => {
    const { height } = entry.contentRect
    setFilterOverlayHeaderHeight(height)
  })

  const scrollToComponent = (refName: string) => {
    const ref = scrollRefs[refName]
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <FilterOverlay
      popoverProps={{
        ...popoverProps,
        popperWidth: '380px',
      }}
      onReset={onReset}
      onScroll={scrollToComponent}
      ref={filterOverlayRef}
    >
      <ScrollBox
        variant="vertical"
        display="flex"
        maxHeight={`calc((-168px + 100vh) - ${filterOverlayHeaderHeight}px)`}
        flexDirection="column"
        backgroundColor="gray-200"
      >
        {children}
      </ScrollBox>
    </FilterOverlay>
  )
}

export default FilterDialog
