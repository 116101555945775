import { FC } from 'react'
import { OfficeOut } from '~/libs/apis/data/model'
import useSubwayUtil from '~/libs/hooks/useSubwayUtil'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import SubwayLineBadge from '~/templates/common/content/SubwayLineBadge'

interface OfficeSubwayDataProps {
  data: OfficeOut
}
const OfficeSubwayData: FC<OfficeSubwayDataProps> = ({ data }) => {
  const { getSubwayStationWalkingInfo } = useSubwayUtil()

  const subwayStationWalkingInfo = getSubwayStationWalkingInfo(
    data.nearestSubwayStationName,
    data.nearestSubwayWalkingDistance,
    data.nearestSubwayWalkingTime,
  )
  const subwayLineCodes = data.nearestSubwayLineCode?.split(',')

  return (
    <Box display="flex" gap="5px" alignItems="center">
      <Box display="flex" gap="3px">
        {subwayLineCodes?.map((lineCode, idx) => (
          <SubwayLineBadge
            key={`${lineCode}-${idx}`}
            subwayLineCode={lineCode}
            subwayServiceAreaCode={data.nearestSubwayServiceAreaCode}
          />
        ))}
      </Box>
      <Typography>{subwayStationWalkingInfo}</Typography>
    </Box>
  )
}

export default OfficeSubwayData
