import { atomFamily } from 'recoil'
import { SearchBuildingOut, SearchSubwayOut } from '~/libs/apis/data/model'

type SearchType = 'building' | 'land' | 'area' | 'subway' | 'client'

export interface SearchInfo {
  keyword: string
  searchType?: SearchType
  isSearch: boolean
  buildingInfo?: SearchBuildingOut // 검색한 빌딩 정볼르 표시하기 위한 property
  subwayInfo?: SearchSubwayOut // 검색한 지하철 정보를 표시하기 위한 property
}

const searchInfoState = atomFamily<SearchInfo, MapType>({
  key: 'searchInfoState',
  default: {
    keyword: '',
    isSearch: false,
  },
})

export default searchInfoState
