import TooltipCss from '~/components/Tooltip/tooltipStyle'
import toastCss from '~/components/Toast/toastStyle'
import dialogCss from '~/components/Dialog/dialogStyle'
import ContextMenuStyle from '~/components/ContextMenu/ContextMenuStyle'
import { createGlobalStyle, css, Preflight } from '@xstyled/styled-components'
import { customAnimations } from '~/libs/theme/animations'
import alertCss from '~/components/Toast/alertStyle'

const baseCss = css(
  ({ theme: { sizes, colors, shadows, fonts } }) => `
  :root {
    --header-height: ${sizes.headerHeight}px;
    --content-area-max-width: ${sizes.contentAreaMaxWidth}px;
    --filter-height: ${sizes.headerHeight + sizes.tabHeight + sizes.filterHeight}px;
    --office-map-height: ${sizes.filterBarHeight}px;
    --filter-bar-height: ${sizes.filterBarHeight}px;
    --office-header-height: ${sizes.officeHeaderHeight}px;
    --office-header-sm-height: ${sizes.officeHeaderSmHeight}px;

    ${Array.from(Object.entries(colors))
      .map(
        ([key, value]) => `
      --color-${key}: ${value};
    `,
      )
      .join('')}

    ${Array.from(Object.entries(shadows))
      .map(
        ([key, value]) => `
      --shadow-${key}: ${value};
    `,
      )
      .join('')}

    --font-family: ${fonts.pretendard};
  }

  body {
    min-width: ${sizes.windowMinimumWidth}px;
    overflow: hidden;
  }

  * {
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
  }
`,
)

const BaseGlobalStyle = createGlobalStyle`
  ${baseCss}
  ${TooltipCss}
  ${toastCss}
  ${alertCss}
  ${dialogCss}
  ${ContextMenuStyle}
  ${customAnimations}
`
const GlobalStyle = () => (
  <>
    <Preflight />
    <BaseGlobalStyle />
  </>
)

export default GlobalStyle
