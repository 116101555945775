import { FC, useLayoutEffect, useRef } from 'react'
import { css, CSSProperties, styled } from '@xstyled/styled-components'
import Box from '~/components/Box/Box'

import { Option } from '~/components/Dropdown/ListSelect'
import { ChangeEventArgs, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'

import { dropdownIconCss, DropDownListComponentBase } from '~/components/Dropdown/Dropdown'

interface TitleDropdownBaseProps {
  /** 선택된 항목의 `value` 식별자 값 */
  value: string
  /** `TitleDropdown`의 `width` 값 */
  width?: CSSProperties['width']
  /** 항목 선택시 발생되는 이벤트 핸들러, 선택된 항목의 `value` 식별자 값이 첫번째 인자, `option` 정보가 두번째 인자 */
  onChange?: (value: string, option?: Option) => void
}

interface OptionWithChip extends Option {
  chip: string
}

interface TitleDropdownChipProps extends TitleDropdownBaseProps {
  /** 선택가능한 항목 배열 `label`: 표시될 텍스트, `value`: 항목 식별자, `chip`: 칩 텍스트 내용 */
  options: OptionWithChip[]
  /** 칩 텍스트 출력 여부, 초기 값에서 리렌더링되지 않음 */
  hasChip: true
}

interface TitleDropdownDefaultProps extends TitleDropdownBaseProps {
  /** 선택가능한 항목 배열 `label`: 표시될 텍스트, `value`: 항목 식별자 */
  options: Option[]
  /** 칩 텍스트 출력 여부, 초기 값에서 리렌더링되지 않음 */
  hasChip?: false
}

type TitleDropdownProps = TitleDropdownChipProps | TitleDropdownDefaultProps

/** 단일 선택 `TitleDropdown` 컴포넌트 */
const TitleDropdown: FC<TitleDropdownProps> = ({ value, width, onChange, options, hasChip }) => {
  const ref = useRef<DropDownListComponent | null>(null)

  const handleChange = (e: ChangeEventArgs) => {
    onChange?.(e.value?.toString(), e.itemData as Option)
  }

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.value = (value || null) as string
      ref.current.dataBind()
    }
  }, [value])

  return (
    <StyledDropDownListComponent
      ref={ref}
      width={width}
      popupWidth={width}
      popupHeight={300}
      // TODO: syncfusion의 타입을 제대로 활용하지 못하고 있음. 리팩토링 대상
      // eslint-disable-next-line @typescript-eslint/ban-types
      dataSource={options as Array<{}>}
      valueTemplate={({ label, chip }: OptionWithChip) => (
        <Box
          display="flex"
          alignItems="center"
          height="42px"
          width={width}
          gap={4}
          pl={hasChip ? 4 : 8}
          pr={8}
          borderRightColor="gray-400"
        >
          {hasChip && <Chip>{chip}</Chip>}
          <ValueLabel>{label}</ValueLabel>
        </Box>
      )}
      itemTemplate={({ chip, label }: OptionWithChip) => (
        <Box
          display="flex"
          alignItems="center"
          height="42px"
          gap={4}
          pr={4}
          py={4}
          pl={hasChip ? 4 : 8}
          borderRightColor="gray-400"
        >
          {hasChip && <Chip>{chip}</Chip>}
          <ValueLabel>{label}</ValueLabel>
        </Box>
      )}
      value={value}
      fields={{ text: 'label', value: 'value' }}
      change={handleChange}
    />
  )
}

export default TitleDropdown

const ValueLabel = styled(Box)`
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  flex: 1;
  font-size: subtitle;
  line-height: subtitle;
  font-weight: semibold;
  color: inherit;
`

const Chip = styled(Box)`
  font-size: caption1;
  line-height: caption1;
  font-weight: semibold;
  padding: 6px 8px;
  border-radius: 8px;
  background-color: var(--color-gray-800);
  color: var(--color-system-white);
`

const dropdownBaseCss = css`
  input.e-input,
  input.e-input::placeholder,
  input.e-dropdownbase,
  input.e-dropdownbase::placeholder {
    height: 42px;
    font-size: h4;
    line-height: h4;
    color: var(--color-gray-800);
    background: none;
  }
  .e-input-value,
  .e-delim-values {
    font-size: h4;
    line-height: 42px;
    font-weight: semibold;
    color: var(--color-gray-800);
  }
  .e-delim-values {
    padding-left: 2;
  }
  &.e-input-focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: var(--color-gray-400) !important;
    color: var(--color-gray-800);
  }

  border-radius: 12px;
  border: 1px solid;
  border-color: var(--color-gray-400);

  &.e-icon-anim {
    border-radius: 12px 12px 0 0;
    box-shadow: var(--shadow-black-04);
  }
`

const StyledDropDownListComponent = styled(DropDownListComponentBase)`
  * {
    font-family: var(--font-family);
  }
  &.e-input-group.e-control-wrapper,
  &.e-input-group.e-control-wrapper.e-ddl.e-lib {
    ${dropdownBaseCss}
    ${dropdownIconCss}

    .e-ddl-icon {
      width: 14px;
      min-width: 14px;
      height: 42px;
      margin-right: 4;

      &::before {
        width: 14px;
        height: 14px;
      }
    }
  }

  &.e-popup.e-ddl {
    box-sizing: border-box;
    margin-top: 0;
    border-radius: 0 0 12px 12px;
    border-top: 0;
    overflow: hidden;
    box-shadow: var(--shadow-black-04);

    .e-dropdownbase {
      padding: 0;

      .e-list-item:not(:last-child) {
        border-bottom: 1px solid;
        border-color: var(--color-gray-400);
      }

      .e-list-item,
      .e-list-item.e-item-focus {
        font-size: h4;
        line-height: h4;
        text-indent: initial;
        transition: background-color 100ms;
        color: var(--color-gray-800);
      }

      .e-list-item.e-item-focus,
      .e-list-item.e-active,
      .e-list-item.e-active.e-hover,
      .e-list-item.e-hover {
        background-color: var(--color-gray-200);
        font-weight: semibold;
        color: var(--color-gray-800);
      }
    }
  }
`
