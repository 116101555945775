import { Dispatch, FC, SetStateAction, useRef } from 'react'
import { MapMarkerWrapper, Offset, Position } from '@mint-ui/map'
import Box from '~/components/Box/Box'
import ContentMintMap from '~/components/Map/ContentMintMap'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { type ClientRegistrationsOwnershipOut } from '~/libs/apis/data/model'
import { UNDEFINED_ANCHOR_Y } from '~/libs/constants/common'
import CommonMarker from '~/components/Map/Marker/CommonMarker'
import { LONGEST_LAND_ADDRESS_MARKER_WIDTH } from '~/libs/constants/place'

interface RegistrationOwnershipMapProps {
  activeIndex: number | undefined
  setActiveIndex: Dispatch<SetStateAction<number | undefined>>
  list?: ClientRegistrationsOwnershipOut[]
}

const MARKER_Z_INDICES = {
  NEARBY_BUILDING: 1,
  ACTIVE_BUILDING: 2,
}

const RegistrationOwnershipMap: FC<RegistrationOwnershipMapProps> = ({ list, activeIndex, setActiveIndex }) => {
  const { showLand } = useFullDetailDialog()
  const position = list?.map((item) => new Position(item.lat, item.lng))
  const mapRef = useRef<HTMLDivElement>(null)
  const anchor = new Offset(LONGEST_LAND_ADDRESS_MARKER_WIDTH / 2, UNDEFINED_ANCHOR_Y)
  const handleMouseOver = (index: number) => () => setActiveIndex(index)
  const handleMouseOut = () => setActiveIndex(undefined)
  const createClickHandler = (item: ClientRegistrationsOwnershipOut) => () => {
    showLand(item.pnu, 'registration')
  }

  return (
    <Box ref={mapRef} borderRadius="10px" height="400px" overflow="hidden">
      <ContentMintMap focusBoundary={position}>
        {list?.map((item, index) => (
          <MapMarkerWrapper
            key={item.registrationId}
            anchor={anchor}
            position={{ lat: item.lat, lng: item.lng }}
            zIndex={activeIndex === index ? MARKER_Z_INDICES.ACTIVE_BUILDING : MARKER_Z_INDICES.NEARBY_BUILDING - index}
          >
            <Box width={LONGEST_LAND_ADDRESS_MARKER_WIDTH} display="flex" justifyContent="center">
              <Box onMouseOver={handleMouseOver(index)} onMouseOut={handleMouseOut} onClick={createClickHandler(item)}>
                <CommonMarker width="100%" headerText={item.address} isActive={activeIndex === index} />
              </Box>
            </Box>
          </MapMarkerWrapper>
        ))}
      </ContentMintMap>
    </Box>
  )
}

export default RegistrationOwnershipMap
