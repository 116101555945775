import { SVGProps, FC } from 'react'
import NextImage, { ImageProps } from '~/components/Image/NextImage'

import DefaultNoImage from '~/assets/images/building-no-image-default.svg'
import OfficeNoImage from '~/assets/images/building-no-image-office.svg'
import WarehouseNoImage from '~/assets/images/building-no-image-warehouse.svg'
import Box from '~/components/Box/Box'
import rtbProxyImageLoader from '~/libs/utils/rtbProxyImageLoader'

type BuildingImageType = 'default' | 'office' | 'warehouse'

interface BuildingImageProps extends ImageProps {
  type?: BuildingImageType
  noImageSvgWidth?: number
  noImageSvgHeight?: number
}

interface NoImageList {
  component: FC<SVGProps<SVGSVGElement>>
}

const noImages: Record<BuildingImageType, NoImageList> = {
  default: {
    component: DefaultNoImage,
  },
  office: {
    component: OfficeNoImage,
  },
  warehouse: {
    component: WarehouseNoImage,
  },
}

const BuildingImage: FC<BuildingImageProps> = ({
  id,
  url,
  width,
  height,
  type = 'default',
  noImageSvgWidth,
  noImageSvgHeight,
  ...props
}) => {
  const noImage = noImages[type]
  const NoImage = noImage.component
  return (
    <NextImage
      id={id}
      url={url}
      width={width}
      height={height}
      loader={rtbProxyImageLoader}
      {...props}
      fallbackImage={
        <Box
          display="flex"
          width={width}
          height={height}
          alignItems="center"
          justifyContent="center"
          style={props.style}
        >
          <NoImage width={noImageSvgWidth || width * 0.7} height={noImageSvgHeight || height * 0.7} />
        </Box>
      }
    />
  )
}

export default BuildingImage
