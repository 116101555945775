import { FC } from 'react'
import RegistrationInfo from '~/templates/place/detail/registration/RegistrationInfo'

interface RegistrationContentProps {
  pnu: string | null
  address: string | null
}

const RegistrationContent: FC<RegistrationContentProps> = ({ pnu, address }) => {
  if (!pnu) {
    return null
  }
  return <RegistrationInfo pnu={pnu} address={address} />
}

export default RegistrationContent
