import { LandInfoOut } from '~/libs/apis/data/model'
import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import DetailTable, { DetailTableType } from '~/components/Table/DetailTable'
import Box from '~/components/Box/Box'
import Button from '~/components/Button/Button'
import { useAreaFormat } from '~/libs/hooks/useFormat'
import LandUseTable from '~/templates/place/detail/land/LandUseTable'
import { useDetailInfo } from '~/templates/DetailTypeProvider'

interface LandInfoProps {
  data: LandInfoOut
  representativeJibun: NullishString
  attachedJibun: NullishString
}

const LandInfo: FC<LandInfoProps> = ({ data, representativeJibun, attachedJibun }) => {
  const sggCode = data.pnu.substring(0, 5)
  const areaFormatter = useAreaFormat()
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { detailContent } = useDetailInfo()

  const detailTableInfo: DetailTableType[] = [
    { column: t('ldcg_name'), content: data.ldcgName ?? '-' },
    {
      column: t('land_area'),
      content: areaFormatter(data.area, { rounded: 'n2' }),
    },
    { column: t('land_purpose'), content: data.landPurpose },
    { column: t('land_use'), content: data.landUse },
    { column: t('road_contact'), content: data.roadContact },
    { column: t('terrian_height'), content: data.terrianHeight },
    ...(detailContent !== 'land'
      ? [
          { column: t('representative_jibun'), content: representativeJibun },
          { column: t('attached_jibun'), content: attachedJibun },
        ]
      : []),
    { column: t('terrian_shape'), content: data.terrianShape, oneLine: true },
  ]

  const handleOpenLandLegerPage = () => {
    window.open(
      'https://www.gov.kr/mw/AA020InfoCappView.do?HighCtgCD=A09005&CappBizCD=13100000026&tp_seq=03',
      '_blank',
      'noopener',
    )
  }

  const handleOpenLandPlanPage = () => {
    window.open(
      `https://www.eum.go.kr/web/ar/lu/luLandDet.jsp?pnu=${data.pnu}&sggcd=${sggCode}&isNoScr=script&mode=search`,
      '_blank',
      'noopener',
    )
  }

  const handleOpenCityPlanPage = () => {
    window.open(
      `https://www.eum.go.kr/web/cp/cv/cvUpisDet.jsp?pnu=${data.pnu}&sggcd=${sggCode}&isNoScr=script&mode=search`,
      '_blank',
      'noopener',
    )
  }

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <DetailTable data={detailTableInfo} />
      <LandUseTable pnu={data.pnu} lawType="territory_laws" />
      <LandUseTable pnu={data.pnu} lawType="etc_laws" />
      <Box display="flex" gap="9px">
        <Button
          size="md"
          variant="filled"
          color="gray"
          content={t('land_ledger')}
          width="100%"
          onClick={handleOpenLandLegerPage}
        />
        <Button
          size="md"
          variant="filled"
          color="gray"
          content={t('land_use_plan')}
          width="100%"
          onClick={handleOpenLandPlanPage}
        />
        <Button
          size="md"
          variant="filled"
          color="gray"
          content={t('city_plan_drawing')}
          width="100%"
          onClick={handleOpenCityPlanPage}
        />
      </Box>
    </Box>
  )
}

export default LandInfo
