import { useGetR3CommonCode } from '~/libs/apis/registration/api'
import { ValueAccessor } from '@syncfusion/ej2-grids/src/grid/base/type'
import { GetR3CommonCodeResponse } from '~/libs/apis/registration/model'
import { SWR_IMMUTABLE_OPTION } from '~/libs/constants/common'

interface R3CommonCodeObject {
  codeToName: (code: string) => string | undefined
  filterCodes: (codes: string[]) => GetR3CommonCodeResponse | undefined
}

const useR3CommonCode = (groupCode: string): R3CommonCodeObject => {
  const { data } = useGetR3CommonCode(undefined, SWR_IMMUTABLE_OPTION)

  const codeToName = (code: string) =>
    data?.find((commonCode) => commonCode.code === code && commonCode.groupCode === groupCode)?.codeName

  const filterCodes = (codes: string[]) => data?.filter((commonCode) => codes.includes(commonCode.code))

  return { codeToName, filterCodes }
}

export const useR3CommonCodeValueAccessor = (groupCode: string): ValueAccessor => {
  const { codeToName } = useR3CommonCode(groupCode)
  return (field: string, data: Record<string, any>) => codeToName(data[field]) ?? '-'
}

// eslint-disable-next-line import/no-unused-modules
export default useR3CommonCode
