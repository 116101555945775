import { atomFamily } from 'recoil'

export interface DetailType {
  uid: string
  dataType: 'building' | 'land'
  propertyType?: string
  selectedRegistrationId?: number
}

const detailTypeState = atomFamily<DetailType | undefined, MapType>({
  key: 'detailTypeState',
  default: undefined,
})

export default detailTypeState
