import { DefaultValue, selector } from 'recoil'
import { LANDS_FILTER_KEYS_FROM_DIALOG, LandsFilterParams } from '~/libs/constants/place'
import landFilterStateFamily from '~/recoil/land-filter/atom'

/**
 * 공공데이터 필터 다이얼로그에서 사용되는 개념의 필터만 존재하는 selector
 * setter는 어떻게 호출해도 reset처럼 동작한다.
 */
const landFilterDialogSelector = selector({
  key: 'landFilterDialogSelector',
  get: ({ get }) =>
    LANDS_FILTER_KEYS_FROM_DIALOG.reduce<LandsFilterParams>((previousValue, currentValue) => {
      if (currentValue === 'registrationRightfulPerson') {
        return previousValue
      }
      return {
        ...previousValue,
        [currentValue]: get(landFilterStateFamily(currentValue)),
      }
    }, {}),
  set: ({ set }, newValue) => {
    LANDS_FILTER_KEYS_FROM_DIALOG.forEach((key) => {
      if (key === 'registrationRightfulPerson') {
        return
      }
      set(landFilterStateFamily(key), newValue instanceof DefaultValue ? newValue : undefined)
    })
  },
})

export default landFilterDialogSelector
