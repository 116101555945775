import { format, parse, parseISO } from 'date-fns'
import { isNotNil } from '@toss/utils'

export const formatDateYmd = (str: NullishString) => {
  if (isNotNil(str)) {
    try {
      return format(parseDateYmd(str), 'yyyy-MM-dd')
    } catch (e) {
      return str
    }
  } else {
    return '-'
  }
}

export const formatDateYm = (str: NullishString) => {
  if (isNotNil(str)) {
    try {
      return format(parseDateYm(str), 'yyyy-MM')
    } catch (e) {
      return str
    }
  } else {
    return '-'
  }
}

export const formatDateYq = (str: NullishString) => {
  if (isNotNil(str)) {
    try {
      return format(parseDateYq(str), 'yyyy-QQQ')
    } catch (e) {
      return str
    }
  } else {
    return '-'
  }
}

/* 공급예정 빌딩에서 'yyyyQn'으로 넘어오는 값을 'yyyyQQQ' 으로 수정. (예시) 하이픈이 들어간  '2025-'는 '2025'로 반환 */
export const formatPlannedConstructionDateYq = (str: NullishString) => {
  if (isNotNil(str)) {
    if (str.includes('-')) {
      return str.replace('-', '')
    }
    try {
      return format(parseDateYq(str), 'yyyy-QQQ')
    } catch (e) {
      return str
    }
  } else {
    return '-'
  }
}

/* 서버에서 Ymd 형식으로 전송한 데이터를 파라미터로 넘긴 포맷의 스트링으로 변환합니다. */
export const formatDateString = (str: NullishString, form: 'yyyy' | 'yyyy-MM' | 'yyyy-QQQ' | 'yyyy-MM-dd') => {
  if (isNotNil(str)) {
    try {
      return format(parseDateYmd(str), form)
    } catch (e) {
      return str
    }
  } else {
    return '-'
  }
}

export const formatISODateYmd = (str: NullishString) => {
  if (isNotNil(str)) {
    try {
      return format(parseISO(str), 'yyyy-MM-dd')
    } catch (e) {
      return str
    }
  } else {
    return '-'
  }
}

export const formatISODateTime = (str: NullishString) => {
  if (isNotNil(str)) {
    try {
      return format(parseISO(str), 'yyyy-MM-dd HH:mm')
    } catch (e) {
      return str
    }
  } else {
    return '-'
  }
}

export const formatISODateTimeSeconds = (str: NullishString) => {
  if (isNotNil(str)) {
    try {
      return format(parseISO(str), 'yyyyMMddHHmmss')
    } catch (e) {
      return str
    }
  } else {
    return '-'
  }
}

export const parseDateYmd = (str: string) => {
  return parse(str, 'yyyyMMdd', new Date())
}

export const parseDateYm = (str: string) => {
  return parse(str, 'yyyyMM', new Date())
}

export const parseDateYq = (str: string) => {
  return parse(str, 'yyyyQQQ', new Date())
}

/**
 * @remark 한국식 나이 계산 방식을 따르는 숫자를 계산합니다. e.g.) 준공연차
 * @example 2023년 기준, 2020년 준공된 건물의 준공연차는 4년차
 */
export const inclusiveDifferenceInYears = (dateYmd?: string) => {
  if (isNotNil(dateYmd)) {
    try {
      const currentYear = new Date().getFullYear()
      const inputYear = parseDateYmd(dateYmd).getFullYear()
      return currentYear - inputYear + 1
    } catch (e) {
      return
    }
  } else {
    return
  }
}
