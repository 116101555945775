import { useTranslation } from 'next-i18next'
import { FC, PropsWithChildren } from 'react'
import Button from '~/components/Button/Button'
import Dialog, { DialogProps } from '~/components/Dialog/Dialog'
import FaceXIcon from '~/assets/images/icons/system/system-face-x.svg'

interface ErrorDialogProps extends Omit<DialogProps, 'title'> {
  message: string
  onClose: (confirmed?: boolean) => void
}

// TODO: 현재 공용모달이라는 형태로 디자인이 나온게 Dialog의 children이 필요해서 받을수 있는 구조로 변경했는데, 이게 공용으로 정착된다면 string으로 받는게 좋을듯함
const ErrorDialog: FC<PropsWithChildren<ErrorDialogProps>> = ({ message, onClose, ...props }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })

  const handleConfirmation = () => {
    onClose(true)
  }

  const handleCancellation = () => {
    onClose()
  }

  const footerTemplate = () => <Button content={t('confirm')} width="120px" size="lg" onClick={handleConfirmation} />

  return (
    <Dialog
      target="#dialog-target"
      variant="simple"
      icon={<FaceXIcon />}
      title={message}
      close={handleCancellation}
      footerTemplate={footerTemplate}
      {...props}
    />
  )
}

export default ErrorDialog
