import ButtonGroup, { ButtonGroupProps } from '~/components/Button/ButtonGroup'
import LabeledContainer from '~/components/LabeledContainer/LabeledContainer'

interface ButtonGroupFilterProps extends ButtonGroupProps {
  title?: string
}

const ButtonGroupFilter = ({ title, name, items, onClick, defaultValue }: ButtonGroupFilterProps) => {
  return title ? (
    <LabeledContainer label={title} type="caption">
      <ButtonGroup name={name} items={items} onClick={onClick} defaultValue={defaultValue} />
    </LabeledContainer>
  ) : (
    <ButtonGroup name={name} items={items} onClick={onClick} defaultValue={defaultValue} />
  )
}

export default ButtonGroupFilter
