import { FC } from 'react'
import { useGetNotices } from '~/libs/apis/service/api'
import { SWR_IMMUTABLE_OPTION } from '~/libs/constants/common'
import NoticeImageDialog from '~/templates/NoticeImageDialog'
import NoticeTextDialog from '~/templates/NoticeTextDialog'

const NoticeDialogs: FC = () => {
  const { data } = useGetNotices(SWR_IMMUTABLE_OPTION)

  const imageNotice = data?.filter((notice) => notice.noticeType === 'IMAGE').at(0)
  const textNotice = data?.filter((notice) => notice.noticeType === 'TEXT').at(0)

  return (
    <>
      <NoticeTextDialog notice={textNotice} />
      <NoticeImageDialog notice={imageNotice} />
    </>
  )
}

export default NoticeDialogs
