import { FC, useEffect, useState } from 'react'
import Box from '~/components/Box/Box'
import { useLandInfoDataV1GovLandGet } from '~/libs/apis/data/gov/gov'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'
import { useTranslation } from 'next-i18next'
import LandInfo from '~/templates/place/detail/land/LandInfo'
import LandPrice from '~/templates/place/detail/land/LandPrice'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'
import LandChangeOwner from '~/templates/place/detail/land/LandChangeOwner'
import TabButton from '~/components/TabButton/TabButton'
import { SwitchCase } from '@toss/react'
import ScrollableTabButtonBox from '~/components/TabButton/ScrollableTabButtonBox'
import { isNotNil } from '@toss/utils'
import NoData from '~/components/NoData'
import Button from '~/components/Button/Button'
import { useDetailInfo } from '~/templates/DetailTypeProvider'

interface LandProps {
  pnu: string | null
  raId?: string
}

const Land: FC<LandProps> = ({ pnu, raId }) => {
  const { t: unit } = useTranslation('common', { keyPrefix: 'unit' })
  const { t: term } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: msg } = useTranslation('common', { keyPrefix: 'common_msg' })
  const param = raId ? { raId: raId } : { pnu: pnu }
  const { data, isLoading } = useLandInfoDataV1GovLandGet(param)
  const [selected, setSelected] = useState<string>('0')
  const { detailContent } = useDetailInfo()
  const isLand = detailContent === 'land'

  useEffect(() => {
    setSelected('0')
  }, [pnu, raId])

  if (isLoading) {
    return (
      <Box padding="24px 0" display="flex" flexDirection="column" gap="24px">
        <SkeletonComponent width="100%" height={56} />
        <SkeletonComponent width="100%" height={318} />
      </Box>
    )
  }

  if (!isNotNil(data) || data.length === 0) {
    return (
      <Box display="flex" flexDirection="column" gap="16px">
        {!isLand && (
          <TabButton
            items={[{ text: term('representative_jibun'), value: term('representative_jibun') }]}
            size="sm"
            selected={term('representative_jibun')}
          />
        )}
        <NoData noDataMessage={msg('no_land_information')} />
        <Box display="flex" gap="9px">
          <Button size="md" variant="filled" color="gray" disabled content={term('land_ledger')} width="100%" />
          <Button size="md" variant="filled" color="gray" content={term('land_use_plan')} disabled width="100%" />
          <Button size="md" variant="filled" color="gray" content={term('city_plan_drawing')} disabled width="100%" />
        </Box>
      </Box>
    )
  }

  const representative = data.length > 0 ? data[0].jibun : undefined
  const attachedOne = data.length > 1 ? data[1].jibun : undefined

  const exceptAttached = data.length > 2 ? unit('except_count', { number: data.length - 2 }) : undefined
  const attached = attachedOne ? `${attachedOne} ${exceptAttached ?? ''}` : undefined

  const getTabHeaderText = (jibun: string, index: number) =>
    index === 0 ? unit('representative_jibun', { jibun }) : unit('attached_jibun', { jibun })

  const tabItems = data.map((info, index) => ({
    text: getTabHeaderText(info.jibun ?? '-', index),
    value: index.toString(),
  }))

  const landCaseBy: Record<string, JSX.Element> = data.reduce((acc, info, index) => {
    return {
      ...acc,
      [index.toString()]: (
        <Box key={info.pnu} paddingTop="16px" display="flex" flexDirection="column" gap="16px">
          <LandInfo data={info} representativeJibun={representative} attachedJibun={attached} />
          <DataSourceUpdate category="detail.land.info" />
          <LandChangeOwner pnu={info.pnu} />
          <DataSourceUpdate category="detail.land.owner" />
          <LandPrice pnu={info.pnu} />
          <DataSourceUpdate category="detail.land.price" />
        </Box>
      ),
    }
  }, {})

  const handleTabChange = (value: string) => {
    setSelected(value)
  }

  return (
    <Box>
      {!isLand && (
        <ScrollableTabButtonBox>
          <TabButton items={tabItems} size="sm" onSelect={handleTabChange} selected={selected} />
        </ScrollableTabButtonBox>
      )}
      <SwitchCase caseBy={landCaseBy} value={selected} />
    </Box>
  )
}

export default Land
