import { useTranslation } from 'next-i18next'
import { FC, PropsWithChildren } from 'react'
import { Box } from '~/components'
import Button from '~/components/Button/Button'
import Dialog, { DialogProps } from '~/components/Dialog/Dialog'

interface ConfirmationDialogProps extends DialogProps {
  onClose: (confirmed?: boolean) => void
  confirmButtonText?: string
  cancelButtonText?: string
}

const ConfirmationDialog: FC<PropsWithChildren<ConfirmationDialogProps>> = ({
  onClose,
  children,
  confirmButtonText,
  cancelButtonText,
  ...props
}) => {
  const { t } = useTranslation()

  const handleConfirmation = () => {
    onClose(true)
  }

  const handleCancellation = () => {
    onClose()
  }

  const footerTemplate = () => (
    <Box display="flex" gap={2} justifyContent="center">
      <Button
        content={cancelButtonText ?? t('common_term.cancel')}
        variant="line"
        color="gray"
        width="120px"
        size="lg"
        onClick={handleCancellation}
      />
      <Button
        content={confirmButtonText ?? t('common_term.confirm')}
        width="120px"
        size="lg"
        onClick={handleConfirmation}
      />
    </Box>
  )
  return (
    <Dialog target="#dialog-target" close={handleCancellation} footerTemplate={footerTemplate} {...props}>
      {children}
    </Dialog>
  )
}

export default ConfirmationDialog
