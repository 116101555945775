import { BuildingType } from '~/templates/place/detail/overview/BuildingMapImageArea'
import { SwitchCase } from '@toss/react'
import { Badge } from '~/components'
import React from 'react'
import { useTranslation } from 'next-i18next'

interface PropertyBadgeProps {
  type: BuildingType | 'local' | 'client'
  content?: string
}
const PropertyBadge = ({ type, content }: PropertyBadgeProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  return (
    <SwitchCase
      caseBy={{
        office: <Badge type="02" category="purple" size="md" content={content ?? t('office')} />,
        lwh: <Badge type="02" category="purple" size="md" content={content ?? t('lwh')} />,
        land: <Badge type="02" category="blue" size="md" content={content ?? t('land')} />,
        local: <Badge type="03" size="md" content={content ?? t('local')} />,
        client: <Badge type="02" category="purple" size="md" content={content ?? t('tenant')} />,
      }}
      value={type}
    />
  )
}

export default PropertyBadge
