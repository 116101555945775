import { DefaultValue, selector } from 'recoil'
import { REGISTRATION_FILTER_KEYS_FROM_DIALOG, RegistrationFilterParams } from '~/libs/constants/topic'
import registrationFilterStateFamily from '~/recoil/topic-registration-filter/atom'

const registrationFilterDialogSelector = selector({
  key: 'registrationFilterDialogSelector',
  get: ({ get }) =>
    REGISTRATION_FILTER_KEYS_FROM_DIALOG.reduce<RegistrationFilterParams>(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: get(registrationFilterStateFamily(currentValue)),
      }),
      {},
    ),
  set: ({ set }, newValue) => {
    REGISTRATION_FILTER_KEYS_FROM_DIALOG.forEach((key) =>
      set(registrationFilterStateFamily(key), newValue instanceof DefaultValue ? newValue : undefined),
    )
  },
})

export default registrationFilterDialogSelector
