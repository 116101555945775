import { CSSProperties } from '@xstyled/styled-components'

const shadowVariants = [
  'black-01',
  'black-02',
  'black-03',
  'black-04',
  'black-05',
  'black-06',
  'purple-01',
  'purple-02',
  'marker-01',
] as const

type ShadowVariants = (typeof shadowVariants)[number]

export type Shadows = Record<ShadowVariants, Exclude<CSSProperties['boxShadow'], undefined>>

const shadows: Shadows = {
  'black-01': '0px 1px 3px 0px rgba(0, 0, 0, 0.25)',
  'black-02': '0px 8px 15px 0px rgba(0, 0, 0, 0.03)',
  'black-03': '0px 4px 10px 0px rgba(0, 0, 0, 0.06)',
  'black-04': '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
  'black-05': '0px 3px 12px 0px rgba(0, 0, 0, 0.20)',
  'black-06': '0px 12px 25px 0px rgba(0, 0, 0, 0.15)',
  'purple-01': '0px 2px 4px 0px rgba(94, 68, 203, 0.18)',
  'purple-02': '0px 4px 14px -5px rgba(94, 68, 203, 0.5)',
  'marker-01': '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
}

export default shadows
