import { FC, PropsWithChildren, useRef } from 'react'
import { Box } from '~/components'
import Button from '~/components/Button/Button'
import { useTranslation } from 'react-i18next'
import { BeforeOpenEventArgs } from '@syncfusion/ej2-popups/src/dialog/dialog'
import styled from '@xstyled/styled-components'
import { DialogComponent } from '@syncfusion/ej2-react-popups'
import { commonDialogCss } from '~/components/Dialog/dialogStyle'
import ScrollBox from '~/components/Box/ScrollBox'

interface GuideDialogProps {
  visible: boolean
  onClose: () => void
}

const GuideDialog: FC<PropsWithChildren<GuideDialogProps>> = ({ visible, onClose, children }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const contentRef = useRef<HTMLDivElement>(null)

  const footerTemplate = () => (
    <Box display="flex" justifyContent="center" gap={2}>
      <Button
        width="164px"
        size="lg"
        variant="line"
        color="gray"
        type="button"
        content={t('close')}
        onClick={onClose}
      />
    </Box>
  )

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }

  const handleBeforeClose = () => {
    scrollToTop()
  }

  const handleBeforeOpen = (args: BeforeOpenEventArgs) => {
    args.maxHeight = '670px'
  }

  return (
    <StyledDialog
      target="#dialog-target"
      beforeOpen={handleBeforeOpen}
      beforeClose={handleBeforeClose}
      width={560}
      visible={visible}
      close={onClose}
      footerTemplate={footerTemplate}
      isModal
    >
      <Box height={20} />
      <ScrollBox ref={contentRef} p="10px 20px 30px 30px" variant="vertical" maxHeight={560}>
        {children}
      </ScrollBox>
    </StyledDialog>
  )
}

const StyledDialog = styled(DialogComponent)`
  ${commonDialogCss}

  & {
    overflow: hidden;
    .e-dlg-content {
      padding: 0;
    }
  }
`

export default GuideDialog
