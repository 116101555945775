import useMapMover from '~/libs/hooks/useMapMover'
import { useEffect } from 'react'
import useLand from '~/templates/common/hooks/useLand'
import { useRecoilValue } from 'recoil'
import searchInfoState from '~/recoil/search-info/atom'
import { NMapZoomLevel } from '~/libs/constants/common'
import useMapType from '~/libs/hooks/useMapType'

const useSearchResultLand = () => {
  const { mapType } = useMapType()
  const handleMapMove = useMapMover(mapType)
  const land = useLand(mapType)
  const searchInfo = useRecoilValue(searchInfoState(mapType))

  useEffect(() => {
    if (land && searchInfo.searchType === 'land') {
      handleMapMove({
        center: { lat: land.centerLat, lng: land.centerLng },
        zoomLevel: NMapZoomLevel['20m'],
      })
    }
  }, [handleMapMove, land, searchInfo.searchType])
}

export default useSearchResultLand
