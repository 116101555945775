import { useGetUserSubscriptionsPermissions } from '~/libs/apis/service/api'
import { PermissionCodeType } from '~/libs/constants/permission'
import { SWR_IMMUTABLE_OPTION } from '~/libs/constants/common'

const useHasPermission = (permissionKeyList: Array<PermissionCodeType> = []): boolean => {
  const { data } = useGetUserSubscriptionsPermissions(SWR_IMMUTABLE_OPTION)

  // permissionKeyList가 비어있으면 권한을 허용
  return permissionKeyList.length > 0
    ? data?.permissions.some((permission) => {
        return permission.isOffice || permission.isWarehouse
          ? permissionKeyList.some((permissionKey) => permissionKey === permission.permissionName)
          : false
      }) || false
    : true
}

export default useHasPermission
