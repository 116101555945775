import { FC } from 'react'
import Typography from '~/components/Typography/Typography'
import { useTranslation } from 'next-i18next'
import Box from '~/components/Box/Box'
import { isNotNil } from '@toss/utils'

interface StackingPlanHeaderProps {
  subTitle?: string
}
const StackingPlanHeader: FC<StackingPlanHeaderProps> = ({ subTitle }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="subtitle" fontWeight="semibold">
        {t('stacking_plan')}
      </Typography>
      {isNotNil(subTitle) && (
        <Typography variant="caption1" lineHeight="20px">
          {subTitle}
        </Typography>
      )}
    </Box>
  )
}

export default StackingPlanHeader
