/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.6.0
 */

export type OfficeLesseeStatusCompareType = typeof OfficeLesseeStatusCompareType[keyof typeof OfficeLesseeStatusCompareType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfficeLesseeStatusCompareType = {
  lessee: 'lessee',
  lesseeSector: 'lesseeSector',
} as const;
