import { LwhOut, OfficeOut } from '~/libs/apis/data/model'
import useCommonCode from '~/libs/hooks/useCommonCode'

export type ComparisonCategories = 'self' | 'boeSize' | 'boe'

const useNamesForComparison = (data: OfficeOut | LwhOut | undefined) => {
  const { codeToName } = useCommonCode()
  const size = data?.buildingSizeCode ? codeToName(data.buildingSizeCode) : ''

  return {
    self: data?.buildingName ?? '',
    boeSize: `${codeToName(data?.boeTypeCode ?? '-')} ${size}`,
    boe: data?.boeTypeCode ? codeToName(data?.boeTypeCode) : '',
  } as Record<ComparisonCategories, string>
}

export default useNamesForComparison
