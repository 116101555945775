import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import BuildingImageArea from '~/templates/place/detail/overview/BuildingImageArea'
import { LwhOut, OfficeOut, PropertyTypeCode } from '~/libs/apis/data/model'
import BuildingMapImageArea from '~/templates/place/detail/overview/BuildingMapImageArea'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { officeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGet } from '~/libs/apis/data/buildings-office/buildings-office'
import RaDataError from '~/libs/errors/RaDataError'
import { useTranslation } from 'next-i18next'
import { useNextRouter } from '~/libs/hooks/useNextRouter'
import { FC, useState } from 'react'
import useHasPermission from '~/libs/hooks/useHasPermission'
import { PagePermission, PageRoutes } from '~/libs/constants/permission'
import useConstructStatus from '~/libs/hooks/useConstructStatus'
import { useToast } from '~/templates/ToastContextProvider'
import { lwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGet } from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import AnalyticsGraphicIcon from '~/assets/images/icons/analytics-graphic.svg'
import CompareGraphicIcon from '~/assets/images/icons/compare-graphic.svg'
import BuildingActionButton from '~/templates/place/detail/overview/BuildingActionButton'

interface OverviewSectionProps {
  data: OfficeOut | LwhOut
  type?: 'office' | 'lwh'
}

const OverviewSection: FC<OverviewSectionProps> = ({ data, type = 'office' }) => {
  const isOffice = type === 'office'
  const raId = data?.raId
  const { detailType } = useDetailInfo()
  const { show } = useToast()
  const { t } = useTranslation('place', { keyPrefix: 'place_term' })
  const { t: message } = useTranslation('compare', { keyPrefix: 'message' })
  const router = useNextRouter()
  const [isSimilarLoading, setIsSimilarLoading] = useState(false)
  const hasLwhComparePermission = useHasPermission(PagePermission[PageRoutes.compareLwh])
  const hasOfficeComparePermission = useHasPermission(PagePermission[PageRoutes.compareOffice])
  const hasComparePermission = isOffice ? hasOfficeComparePermission : hasLwhComparePermission
  const hasNearByPermission = useHasPermission(PagePermission[PageRoutes.nearby])
  const { isCompletedBuilding } = useConstructStatus(data?.constructStatusCode)
  const buildingImage = data.imageUrl && <BuildingImageArea imageUrl={data.imageUrl} />
  const boundary = data.buildingBoundary ?? null
  const buildingMapImage = (
    <BuildingMapImageArea
      imageUrl={data.imageUrl}
      position={{ lat: data.lat, lng: data.lng }}
      boundary={boundary}
      markerContent={data.buildingName}
      markerType={type}
    />
  )
  const handleOfficeCompare = () => {
    setIsSimilarLoading(true)
    officeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGet(raId, { similarType: 'lease' })
      .then((similarBuildings) => {
        const raIds = similarBuildings?.map((item) => item.raId)
        raIds?.unshift(raId)
        router
          .push({
            pathname: '/compare',
            query: {
              ids: raIds?.join(','),
              realEstateType: 'building',
              comparePropertyType: PropertyTypeCode.PT01_1,
            },
          })
          .then(() => {
            show(message('organized_comparison_list'))
          })
      })
      .catch((error) => {
        if (error instanceof RaDataError) {
          show(error.responseBody.detail.description)
        } else {
          show(t('common_term.unknown_error'))
        }
      })
      .finally(() => {
        setIsSimilarLoading(false)
      })
  }

  const handleLwhCompare = () => {
    setIsSimilarLoading(true)
    lwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGet(raId, { similarType: 'lease' })
      .then((similarBuildings) => {
        const raIds = similarBuildings?.map((item) => item.raId)
        raIds?.unshift(raId)
        router
          .push({
            pathname: '/compare',
            query: {
              ids: raIds?.join(','),
              realEstateType: 'building',
              comparePropertyType: PropertyTypeCode.PT01_2,
            },
          })
          .then(() => {
            show(message('organized_comparison_list'))
          })
      })
      .catch((error) => {
        if (error instanceof RaDataError) {
          show(error.responseBody.detail.description)
        } else {
          show(t('common_term.unknown_error'))
        }
      })
      .finally(() => {
        setIsSimilarLoading(false)
      })
  }

  const handleCompare = isOffice ? handleOfficeCompare : handleLwhCompare
  const handleNearbyClick = () => {
    router.push({
      pathname: '/nearby',
      query: {
        nearbyRaId: raId,
        nearbyPropertyType: data.propertyTypeCode,
      },
    })
  }
  const isNearbyAvailable = hasNearByPermission && isCompletedBuilding
  const isCompareAvailable = hasComparePermission && !isSimilarLoading
  const isFull = detailType === 'full'

  return (
    <>
      {isFull ? buildingMapImage : buildingImage}
      <Box
        display="flex"
        flexDirection="column"
        gap={isFull ? '14px' : '16px'}
        px={isFull ? '30px' : '20px'}
        py={isFull ? '24px' : '20px'}
        justifyContent={isFull ? 'space-between' : undefined}
      >
        <Box flex="1 1 0">
          <Typography color="gray-800" pb={1}>
            {data.address} {data.indicationRoadAddress && `(${data.indicationRoadAddress})`}
          </Typography>
          <Typography variant="h3" fontWeight="bold" color="gray-800">
            {data.buildingName}
          </Typography>
        </Box>
        <Box display="flex" gap="10px">
          <BuildingActionButton
            hasPermission={hasNearByPermission}
            isAvailable={isNearbyAvailable}
            contentKey="analyze"
            handleClick={handleNearbyClick}
            icon={<AnalyticsGraphicIcon />}
            color="analysisPurple"
          />
          <BuildingActionButton
            hasPermission={hasComparePermission}
            isAvailable={isCompareAvailable}
            contentKey="compare"
            handleClick={handleCompare}
            icon={<CompareGraphicIcon />}
            color="guide"
          />
        </Box>
      </Box>
    </>
  )
}

export default OverviewSection
