import { MutableRefObject, useRef, useState } from 'react'
import { FormEventArgs, FormValidator } from '@syncfusion/ej2-inputs'

const NEW_VALIDATE_KEY = ['contents', 'phone']
const MODIFY_VALIDATE_KEY = ['contents', 'phone']
const GUEST_VALIDATE_KEY = ['email', 'contents', 'phone', 'name']

type ValidateStatusType = {
  email: boolean
  contents: boolean
  phone: boolean
  name: boolean
}

const ValidationKeys = {
  new: NEW_VALIDATE_KEY,
  modify: MODIFY_VALIDATE_KEY,
  guest: GUEST_VALIDATE_KEY,
}

interface InquiryValidationOption {
  ref: MutableRefObject<FormValidator | undefined>
  validStatus: Partial<ValidateStatusType>
  onInit: (validator: FormValidator) => void // validator 가 셋팅될 때
  onValidate: (arg: FormEventArgs) => void // validation 이 일어날 때 호출되는 이벤트
  onReset: () => void
  isEditChecked: () => boolean
}

const useInquiryValidation = (mode: 'new' | 'modify' | 'guest'): InquiryValidationOption => {
  const ref = useRef<FormValidator>()

  const keys = ValidationKeys[mode]
  const isEditChecked = () => ref.current?.element['isEditChecked'].checked

  const defaultStatus =
    mode === 'guest'
      ? {
          email: false,
          contents: true,
          name: false,
        }
      : {
          contents: true,
        }

  const [validStatus, setValidStatus] = useState<Partial<ValidateStatusType>>(defaultStatus)

  const onInit = (validator: FormValidator) => {
    ref.current = validator
  }

  const onValidate = (arg: FormEventArgs) => {
    const inputName = arg.inputName
    const isValidate = arg.status === 'success'
    if (keys.includes(inputName)) {
      setValidStatus((prev) => ({ ...prev, [inputName]: isValidate }))
    }
  }

  return {
    ref,
    validStatus,
    onInit,
    onValidate,
    onReset: () => setValidStatus(defaultStatus),
    isEditChecked,
  }
}

export default useInquiryValidation
