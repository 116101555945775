import { format, subMonths } from 'date-fns'
import { atomFamily } from 'recoil'
import { LeaseProductFilterParams } from '~/libs/constants/topic'

const today = format(new Date(), 'yyyyMM')
const sixMonthsAgo = format(subMonths(new Date(), 5), 'yyyyMM')

const leaseProductFilterStateFamily = atomFamily<string | undefined, keyof LeaseProductFilterParams>({
  key: 'leaseProductFilterStateFamily',
  default: (param) => {
    if (param === 'minProductCreateDateYm') {
      return sixMonthsAgo
    }
    if (param === 'maxProductCreateDateYm') {
      return today
    }
    return undefined
  },
})

export default leaseProductFilterStateFamily
