import { ReactNode } from 'react'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'

interface SectionContentProps {
  title: string
  children: ReactNode
  popover?: ReactNode
  summary?: ReactNode
}

const SectionContent = ({ title, children, popover, summary }: SectionContentProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={2.5}>
      <div>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle" fontWeight="semibold" color="gray-800" py={2}>
            {title}
          </Typography>
          {popover && <Box pl={2.5}>{popover}</Box>}
        </Box>
        {summary && <Box pb={1.5}>{summary}</Box>}
      </div>
      {children}
    </Box>
  )
}

export default SectionContent
