import { ITheme, Theme } from '@xstyled/styled-components'
import { style, SystemProp } from '@xstyled/system'
import * as CSS from 'csstype'

export interface WordBreakProps<T extends ITheme = Theme> {
  wordBreak?: SystemProp<CSS.Property.WordBreak, T>
}

const wordBreak = style<WordBreakProps>({
  prop: 'wordBreak',
})

export default wordBreak
