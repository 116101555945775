import { Position } from '@mint-ui/map'
import { GeomJson } from '~/libs/apis/data/model'
import { datadogRum } from '@datadog/browser-rum'
import { chunk } from '@toss/utils'

export const geomJsonToMultiPositions = (paths: GeomJson): Position[][] => {
  try {
    return paths.coordinates.flatMap((multiPolygon) =>
      multiPolygon.map((polygon) => polygon.map((coordinate) => ({ lat: coordinate[1], lng: coordinate[0] }))),
    )
  } catch (e) {
    datadogRum.addError(e)
    return []
  }
}

export const polygonPathStringtoPositions = (paths: string): Position[] => {
  return chunk(Array.from(paths.match(/(-?\d*\.?\d+|\d+\.?\d*)/g)?.values() ?? []), 2).map((chunked) => ({
    lat: Number(chunked[1]),
    lng: Number(chunked[0]),
  }))
}
export const changeMouseCursor = (cursorUrl: string, map?: naver.maps.Map) => {
  if (!map) {
    return
  }

  const naverMapDiv = map.getElement().firstElementChild as HTMLDivElement
  if (naverMapDiv) {
    naverMapDiv.style.cursor = cursorUrl
  }
}
