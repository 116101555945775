import { useRecoilValue } from 'recoil'
import { getSelectedEntriesFiltered } from '~/libs/utils/query'
import leaseProductFilterDialogSelector from '~/recoil/topic-lease-product-filter/leaseProductFilterDialogSelector'

const useLeaseProductsFilterQuery = () => {
  const params = useRecoilValue(leaseProductFilterDialogSelector)
  return getSelectedEntriesFiltered(params)
}

export default useLeaseProductsFilterQuery
