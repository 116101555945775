import { type LandDetailOut, LwhOut, OfficeOut } from '~/libs/apis/data/model'

type OfficeOutKeys = keyof OfficeOut
type LwhOutKeys = keyof LwhOut

export const INIT_LAND_TABLE_PROP_KEYS: Readonly<(keyof LandDetailOut)[]> = [
  'ldcgName',
  'landArea',
  'officialLandPrice',
  'buildingName',
  'registGubunName',
  'mainPurposeName',
  'useAprDateYmd',
  'totalArea',
  'floorCnt',
  'realTradeTypeName',
  'realTradeDateYmd',
  'realTradePrice',
]

export const INIT_OFFICE_DATA_PROP_KEYS: Readonly<OfficeOutKeys[]> = [
  'constructStatusCode',
  'address',
  'totalArea',
  'constructDateY',
  'remodelingDateY',
  'platArea',
  'archArea',
  'bcRat',
  'vlRat',
  'floorCnt',
  'ownerName',
  'boeTypeCode',
  'rentFeePerM2',
  'nocPerM2',
  'emptyRate',
  'tradeArea',
]

export const INIT_LWH_DATA_PROP_KEYS: Readonly<LwhOutKeys[]> = [
  'constructStatusCode',
  'address',
  'totalArea',
  'constructDateY',
  'remodelingDateY',
  'platArea',
  'archArea',
  'bcRat',
  'vlRat',
  'floorCnt',
  'ownerName',
  'boeTypeCode',
  'roomRentFeePerM2',
  'lowRentFeePerM2',
  'roomEmptyRate',
  'lowEmptyRate',
  'tradeArea',
]

export const sortArrayById = <T extends { id: string }>(array: T[], ids: string[]): T[] => {
  return array.toSorted((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id))
}
