import { FC, ReactNode } from 'react'
import Box from '~/components/Box/Box'
import { x } from '@xstyled/styled-components'
import CloseIcon from '~/assets/images/icons/x-graphic.svg'
import Popover, { PopoverProps } from '~/components/Dropdown/Popover'

interface GuideOverlayProps {
  popoverProps: PopoverProps
  children: ReactNode
}

const GuideOverlay: FC<GuideOverlayProps> = ({ popoverProps, children }) => {
  return (
    <Popover margin="8px 0 0 0" gutter={false} scroll={false} {...popoverProps}>
      <Box position="relative" border="1px solid" borderRadius="8px" borderColor="blue-400" boxShadow="black-05" p={4}>
        <Box position="absolute" right={0} top={0} pt={3} pr={3}>
          <x.button backgroundColor="transparent" outline={{ focus: 'none' }} p={0} onClick={popoverProps.onClose}>
            <CloseIcon width="14px" height="14px" color="var(--color-gray-500)" />
          </x.button>
        </Box>
        {children}
      </Box>
    </Popover>
  )
}

export default GuideOverlay
