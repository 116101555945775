import { selectorFamily } from 'recoil'
import searchInfoState from '~/recoil/search-info/atom'

const isItemSearchState = selectorFamily<boolean, MapType>({
  key: 'isItemSearchState',
  get:
    (mapType) =>
    ({ get }) =>
      get(searchInfoState(mapType)).searchType !== 'area',
})

export default isItemSearchState
