import { useCallback } from 'react'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'

const usePreventSubmitEvent = () => {
  return useCallback((ref: TextBoxComponent) => {
    ref?.element.addEventListener('keydown', (e) => {
      const { code } = e as KeyboardEvent
      // enter시 submit 방지
      if (code === 'Enter') {
        e.stopPropagation()
        e.preventDefault()
      }
    })
  }, [])
}

export default usePreventSubmitEvent
