import useAreaUnitTransformer from '~/libs/hooks/useAreaUnitTransformer'
import useCurrencyUnitTransformer from '~/libs/hooks/useCurrencyUnitTransformer'

/**
 * - unitConverterForClient: 데이터의 흐름이 서버 -> 클라이언트인 경우  e.g.) API 응답을 화면에 렌더
 * - unitConverterForServer: 데이터의 흐름이 클라이언트 -> 서버인 경우  e.g.) 사용자 입력값을 필터로 설정
 */
const useUnitTransformer = () => {
  const { areaConverterForClient, areaConverterForServer } = useAreaUnitTransformer()
  const { currencyConverterForClient, currencyConverterForServer } = useCurrencyUnitTransformer()

  return {
    unitConverterForClient: (number: number, type: 'total' | 'per_unit' = 'total') => {
      const numberTransformedWithCurrency = currencyConverterForClient(number)
      return areaConverterForClient(numberTransformedWithCurrency, type)
    },
    unitConverterForServer: (number: number, type: 'total' | 'per_unit' = 'total') => {
      const numberTransformedWithCurrency = currencyConverterForServer(number)
      return areaConverterForServer(numberTransformedWithCurrency, type)
    },
  }
}

export default useUnitTransformer
