import { FC, useMemo, useState } from 'react'
import { useGetKakaoLesseeDataV1ExternalKakaoLesseeGet } from '~/libs/apis/data/external/external'
import { useTranslation } from 'next-i18next'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import LoadMoreDataGrid from '~/templates/place/detail/LoadMoreDataGrid'
import NoLesseeInfo from '~/templates/place/detail/lessee/NoLesseeInfo'

interface KakaoProps {
  pnu: string
}

const PAGE_SIZE = 5
const KakaoGrid: FC<KakaoProps> = ({ pnu }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: place } = useTranslation('place', { keyPrefix: 'message' })
  const [pageNum, setPageNum] = useState(1)

  const { data: kakao, isLoading } = useGetKakaoLesseeDataV1ExternalKakaoLesseeGet(
    { pnu, pageNum, pageSize: PAGE_SIZE },
    {
      swr: {
        keepPreviousData: true,
      },
    },
  )

  const handlePageChange = (page: number) => setPageNum(page)

  const columns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'lesseeName',
        headerText: t('tenant'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: (_: any, data: any) => data.lesseeName ?? '-',
      },
      {
        field: 'category',
        headerText: t('sector'),
        ttextAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: (_: any, data: any) => data.category ?? '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  if (!kakao?.data?.length && !isLoading) {
    return <NoLesseeInfo text={t('no_information')} subText={place('supply_info_over_100py')} />
  }

  return (
    <LoadMoreDataGrid
      columns={columns}
      dataSource={kakao?.data}
      pageModel={kakao?.page}
      onPageChange={handlePageChange}
      allowSelection={false}
      isLoading={isLoading}
    />
  )
}

export default KakaoGrid
