import { OfficeOut } from '~/libs/apis/data/model'
import BuildingTable from '~/templates/place/detail/building/BuildingTable'
import { FC } from 'react'
import DataSourceUpdate from '~/components/DataSource/DataSourceUpdate'
import Button from '~/components/Button/Button'
import Box from '~/components/Box/Box'
import { useTranslation } from 'next-i18next'
import { isNotNil } from '@toss/utils'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import BuildingCommonData from '~/templates/place/detail/building/BuildingCommonData'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import BuildingDetailTable from '~/templates/place/detail/building/BuildingDetailTable'
import { isCompletion } from '~/libs/utils/detail'

interface BuildingInfoProps {
  data: OfficeOut
}

export const commonDatas = ['ledger', 'permission', 'demolish'] as const

const BuildingInfo: FC<BuildingInfoProps> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'detail.common_data' })
  const { showOffice } = useFullDetailDialog()
  const { detailType, uid } = useDetailInfo()
  const hasPnu = isNotNil(data.pnu)
  return (
    <>
      {detailType === 'panel' ? <BuildingTable data={data} /> : <BuildingDetailTable data={data} />}
      {detailType === 'panel' ? (
        <Box display="flex" gap="9px">
          {commonDatas.map((item) => (
            <Button
              key={item}
              size="md"
              variant="filled"
              color="gray"
              content={t(item)}
              width="100%"
              disabled={!hasPnu || (item === 'ledger' && !isCompletion(data))}
              onClick={() => {
                showOffice(uid, item)
              }}
            />
          ))}
        </Box>
      ) : (
        <BuildingCommonData pnu={data.pnu} isGroup={isNotNil(data.groupRaId)} isCompletion={isCompletion(data)} />
      )}
      <DataSourceUpdate category="detail.building.info" />
    </>
  )
}

export default BuildingInfo
